import React, { useEffect, useState } from 'react'
import { Box, Typography, Tooltip, ClickAwayListener, withStyles, makeStyles, IconButton, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Paper, requirePropFactory, Button, FormControl, InputLabel, Select, MenuItem, Menu } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import InfoIcon from '@material-ui/icons/Info';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import Pagination from '@material-ui/lab/Pagination';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useDispatch, useSelector } from 'react-redux'
import { getReportWeeklyIndi, getWeeklyIndi } from '../../../../../../store/actions/reportAction'
import DetailReportWeekly from '../../../../component/Report/Modal/DetailReportWeekly/indexIndicator';

const useStylesIndicator = makeStyles((theme) => ({
    arrow: {
        color: "#2C304D",
    },
    tooltip: {
        backgroundColor: "#2C304D",
    },
}));

function IndicatorTooltip(props) {
    const classes = useStylesIndicator();

    return <Tooltip arrow classes={classes} {...props} />;
}

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#F3F3F3",
        color: "#999999",
        fontWeight: 500,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "#FAFAFA",
        },
    },
}))(TableRow);

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
        borderRadius: "4px"
    }
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            // vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            // vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const useStyles = makeStyles({
    table: {
        minWidth: 700,
        boxShadow: "none"
    },
    tableContainer: {
        boxShadow: "none",
        border: "1px solid #CCCCCC",
        borderRadius: "4px"
    },
    itemMenu: {
        fontSize: "12px",
        padding: "0.5rem 1rem",
        color: "#999999"
    },
    iconMenu: {
        fontSize: "16px",
        marginRight: "0.5rem",
        color: "#999999"
    }
});

const StatusTips = withStyles({
    tooltip: {
      color: "white",
      backgroundColor: "rgba(28, 28, 28, 0.8)",
      fontSize: 12
    }
  })(Tooltip);

export default function PIN(props) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [category, setCategory] = useState('')
    const [year, setYear] = useState('')
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const indicatorReport = useSelector(state=> state.reportState.indicatorWeekly)
    const weekly = useSelector(state=> state.reportState.weekly)
    const filterYears = useSelector(state=> state.filterState.years)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function onChangeCategory(e) {
        setCategory(e.target.value)
    }

    function onChangeYear(e) {
        setYear(e.target.value)
        if (filterYears) {
            filterYears.map(year=>{
                if (year.year == e.target.value) {
                    dispatch(getReportWeeklyIndi(year.id))
                    dispatch(getWeeklyIndi(year.id))
                }
            })
        }
    }

    function addReport() {
        history.push('/vito/report/weekly/'+year)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    useEffect(()=>{
        if (filterYears) {
            filterYears.map(year=>{
                if (year.year == new Date().getFullYear()) {
                    setYear(year.year)
                    dispatch(getReportWeeklyIndi(year.id))
                    dispatch(getWeeklyIndi(year.id))
                }
            })
        }
    },[filterYears])

    const getWeek = (id) => {
        let find = '-'
        if (weekly) {
            weekly.map(data=>{
                if (data.id == id) {
                    find = data.week
                }
            })
        }
        return find
    }

    const getYear = (id) => {
        let find = '-'
        if (filterYears) {
            filterYears.map(data=>{
                if (data.id == id) {
                    find = data.year
                }
            })
        }
        return find
    }

    const getDate = (dateParams) => {
        if (dateParams) {
            let start = new Date(dateParams).getDate()
            let startMonth = new Date(dateParams).getMonth()
            let monthStart = ''
            let yearResult = new Date(dateParams).getFullYear()
            switch (startMonth) {
                case 0 :
                    monthStart = '01'
                    break;
                case 1 :
                    monthStart = '02'
                    break;
                case 2 :
                    monthStart = '03'
                    break;
                case 3 :
                    monthStart = '04'
                    break;
                case 4 :
                    monthStart = '05'
                    break;
                case 5 :
                    monthStart = '06'
                    break;
                case 6 :
                    monthStart = '07'
                    break;
                case 7 :
                    monthStart = '08'
                    break;
                case 8 :
                    monthStart = '09'
                    break;
                case 9 :
                    monthStart = '10'
                    break;
                case 10 :
                    monthStart = '11'
                    break;
                case 11 :
                    monthStart = '12'
                    break;
                default:
                    monthStart = ''
                    break;
            }
            let hour = new Date(dateParams).getHours()
            let minute = new Date(dateParams).getMinutes()
            let second = new Date(dateParams).getSeconds()
            
            return yearResult+'-'+ monthStart +'-'+ start

            // +' '+ (hour < 10 ? '0'+hour : hour) +':'+(minute < 10 ? '0'+minute : minute)+':'+(second < 10 ? '0'+second : second)
        }
    }

    const getTime = (dateParams) => {
        if (dateParams) {
            let hour = new Date(dateParams).getHours()
            let minute = new Date(dateParams).getMinutes()
            let second = new Date(dateParams).getSeconds()
            
            return (hour < 10 ? '0'+hour : hour) +':'+(minute < 10 ? '0'+minute : minute)+':'+(second < 10 ? '0'+second : second)
        }
    }

    const getCategory = (id) => {
        let find = '-'
        if (filterYears) {
            switch (id) {
                case 1:
                    find = 'NETWORKING & PARTNERSHIP'
                    break;
                case 2:
                    find = 'MARKET INTELLIGENCE'
                    break;
                // case 3:
                //     find = 'PROMOTIONAL SUPPORT'
                //     break;
                default:
                    break;
            }
        }
        return find
    }

    const checkIndicator = (time, deadline) => {
        let result = 'On Time'
        let find

        if (weekly) {
            weekly.map(data=>{
                if (data.id == deadline) {
                    find = data
                }
            })
        }

        if (find) {
            let date = new Date(time)
            const [day, month, year] = find.end.split("/")
            let deadlineDate = new Date(year, month - 1, day)
            deadlineDate.setDate(deadlineDate.getDate() + 1);

            if (date <= deadlineDate) {
                result = 'On Time'
            } else {
                result = 'Late'
            }
        }

        return result
    }

    return (
        <>
            <div className="dashboard" >
                <span className="breadcrumb-txt-black" >
                    Weekly Report
            </span>
                <span className="breadcrumb-slash-black" >
                    /
            </span>
            </div>
            <div className="vito-table">
                <Box width='100%'>
                    <Box padding="1em 1.5em">
                        <Typography variant="h5" style={{ fontWeight: "500" }}>WEEKLY REPORT</Typography>
                    </Box>
                    <Box paddingLeft="1.5em" paddingRight="1em" paddingTop="2em">
                        <Box display="flex" justifyContent="space-between">
                            <Box width={1 / 2} display="flex">
                                {/* <Box width={1 / 2}><TextField label="Search" color="secondary" size="small" variant="outlined" borderColor="#C4C4C4" fullWidth /></Box> */}
                                <Box width={1 / 4}>
                                    <FormControl color="secondary" variant="outlined" fullWidth size="small" >
                                    <InputLabel id="demo-simple-select-outlined-label">Choose Year</InputLabel>
                                        <Select
                                            value={year}
                                            onChange={onChangeYear}
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            label="Choose Year"
                                        >
                                            {
                                                filterYears &&
                                                filterYears.map(({year}, i)=>(
                                                    <MenuItem key={i} value={year}>{year}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box width={1 / 4} style={{ marginLeft: '1vw', width: 275 }} >
                                    <FormControl color="secondary" variant="outlined" fullWidth size="small" >
                                    <InputLabel id="demo-simple-select-outlined-label">Filter Category</InputLabel>
                                    <Select
                                        value={category}
                                        onChange={onChangeCategory}
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        label="Filter Category"
                                    >
                                        <MenuItem value="" selected>none</MenuItem>
                                        <MenuItem value={1}>NETWORKING & PARTNERSHIP</MenuItem>
                                        <MenuItem value={2}>MARKET INTELLIGENCE</MenuItem>
                                        {/* <MenuItem value={3}>PROMOTIONAL SUPPORT</MenuItem> */}
                                    </Select>
                                </FormControl></Box>
                            </Box>
                            <Box>
                                <Button variant="contained" size="large" color="secondary" style={{ backgroundColor: "#E23F81", textTransform: "none" }} onClick={addReport}>Add New Weekly Report</Button>
                            </Box>
                        </Box>
                        <Box paddingTop="1em">
                            <TableContainer component={Paper} className={classes.tableContainer}>
                                <Table className={classes.table} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">NO</StyledTableCell>
                                            <StyledTableCell align="left">CATEGORY</StyledTableCell>
                                            <StyledTableCell align="center">WEEK</StyledTableCell>
                                            <StyledTableCell align="center">YEAR</StyledTableCell>
                                            <StyledTableCell align="left">COUNTRY</StyledTableCell>
                                            <StyledTableCell align="left">CREATED</StyledTableCell>
                                            <StyledTableCell align="left">MODIFIED</StyledTableCell>
                                            <StyledTableCell align="left">TYPE</StyledTableCell>
                                            <StyledTableCell align="center">INDICATOR</StyledTableCell>
                                            {/* <StyledTableCell align="center">ACTION</StyledTableCell> */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                        indicatorReport
                                        .filter(a=> category ? category === a.CategoryId : a )
                                        .slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
                                        .map((row, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell align="center">{((page - 1) * rowsPerPage) + index + 1}</StyledTableCell>
                                                <StyledTableCell align="left">{row.CategoryId && getCategory(row.CategoryId)}</StyledTableCell>
                                                <StyledTableCell align="center">{row.WeekId && getWeek(row.WeekId)}</StyledTableCell>
                                                <StyledTableCell align="center">{row.YearId && getYear(row.YearId)}</StyledTableCell>
                                                <StyledTableCell align="left" style={{ textTransform: 'uppercase' }}>{row.User && row.User.country ? row.User.country : '-'}</StyledTableCell>
                                                <StyledTableCell align="left">
                                                    <div>
                                                        <span>
                                                            {row.reportCreatedAt ? getDate(row.reportCreatedAt) : '-'}
                                                        </span>
                                                        <span style={{ marginLeft: 5, color : '#8e8e8e', fontSize: 13 }} >
                                                            {row.reportCreatedAt && getTime(row.reportCreatedAt)}
                                                        </span>
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                    <div>
                                                        <span>
                                                            {row.reportUpdateAt ? getDate(row.reportUpdateAt) : '-'}
                                                        </span>
                                                        <span style={{ marginLeft: 5, color : '#8e8e8e', fontSize: 13 }} >
                                                            {row.reportUpdateAt && getTime(row.reportUpdateAt)}
                                                        </span>
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell align="left" style={{ textTransform: 'capitalize' }} >
                                                    {
                                                        row.status ? row.status === 'report' ? 'Work Plan > Report' : row.status : '-'
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <StatusTips placement="top" title={row.reportCreatedAt ? checkIndicator(row.reportCreatedAt, row.WeekId) : 'No Report'} arrow>
                                                        <FiberManualRecordIcon style={{ height: 30, width: 30,
                                                            color: 
                                                            row.reportCreatedAt ? checkIndicator(row.reportCreatedAt, row.WeekId) === 'On Time' ?
                                                            "green" : '#c3352b' : '#a4a4a4'
                                                        }} />
                                                    </StatusTips>
                                                </StyledTableCell>
                                                {/* <StyledTableCell align="center">
                                                    <ClickAway history={history} data={row} weekly={weekly}/> */}

                                                    {/* <IconButton aria-controls="simple-menu" aria-haspopup="true" size="small" onClick={handleClick}><MoreHorizIcon style={{ color: "#C4C4C4" }} /></IconButton>
                                                    <StyledMenu
                                                        id="simple-menu"
                                                        anchorEl={anchorEl}
                                                        keepMounted
                                                        open={Boolean(anchorEl)}
                                                        onClose={handleClose}
                                                    >
                                                        <MenuItem className={classes.itemMenu} ><InfoIcon className={classes.iconMenu} />{row.id}</MenuItem>
                                                    </StyledMenu> */}
                                                {/* </StyledTableCell> */}
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>

                                </Table>
                            </TableContainer>
                            <Box display="flex" marginTop="2rem">
                                <Box display="flex" alignItems="center" width={1 / 3}>
                                    <Box >
                                        <Typography style={{ color: "#999999" }}>Show</Typography>
                                    </Box>
                                    <Box marginX="0.5rem">
                                        <FormControl variant="outlined" color="secondary" size="small" >
                                            <Select
                                                style={{ color: "#999999" }}
                                                value={rowsPerPage}
                                                onChange={handleChangeRowsPerPage}
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                            >
                                                <MenuItem value={10}>10</MenuItem>
                                                <MenuItem value={20}>20</MenuItem>
                                                <MenuItem value={0}>All</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box >
                                        <Typography style={{ color: "#999999" }}>from {indicatorReport
                                        .filter(a=> category ? category === a.CategoryId : a )
                                        .length} Data</Typography>
                                    </Box>
                                </Box>
                                <Box width={1 / 3} display="flex" alignItems="center" justifyContent="center">
                                    <Pagination page={page} onChange={handleChangePage} count={Math.ceil(indicatorReport
                                        .filter(a=> category ? category === a.CategoryId : a )
                                        .length / rowsPerPage)} variant="outlined" shape="rounded" />
                                </Box>
                            </Box>
                        </Box>

                    </Box>
                </Box>
            </div>
        </>
    )
}

const useStylesBtn = makeStyles((theme) => ({
    root: {
        position: 'absolute',
    },
    dropdown: {
        justifyContent: 'center',
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        width: '100px',
        position: 'absolute',
        left: -10,
        zIndex: 99,
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        boxShadow: '6px 5px 33px -14px rgba(0,0,0,0.75)'
    },
    boxLast:{
        width: '100px',
        height: 35,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: 15,
        paddingRight: 15,
        color: '#999999',
        borderRadius: 4,
        cursor: 'pointer',
        '&:hover':{
            width: '70px',
            color: 'white',
            backgroundColor: '#E23F81'
        }
    }
}));

function ClickAway(props) {
    let { data, history, weekly } = props
    const classes = useStylesBtn();
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway} >
            <div >
                <div className="rqs-dta-btn-option" style={{ backgroundColor:'#FFFFFF', margin: '0 auto' }} onClick={handleClick} >
                    <MoreHorizIcon />
                </div>
                <div className={classes.root}>
                    { open ? (
                    <div className={classes.dropdown} >
                        <div className={classes.boxLast}>
                            <DetailReportWeekly
                                data={data}
                                weeklyList={weekly}
                            />
                        </div>
                    </div>
                    ) : null}
                </div>
            </div>
        </ClickAwayListener>
    );
}