import React,{ useState } from 'react'
import { ClickAwayListener, Box, Typography, TextField, withStyles, makeStyles, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Paper, requirePropFactory, Button } from '@material-ui/core'
import './styles.scss'
import logoWI from '../../../images/bird.png'
import {useHistory} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Axios from 'axios'
import { SERVER } from '../../../../../server'
import Swal from 'sweetalert2'
import ModalChangePassword from '../../../component/Modal/changePassword'
import { deleteVito } from '../../../../../store/actions/vitoAction'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

// COMPONENT

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#F3F3F3",
        color: "#999999",
        fontWeight: 500,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "#FAFAFA",
        },

    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 700,
        boxShadow: "none"
    },
    buttonViewProfile: {
        background: "#FCECF2",
        color: "#E23F81",
        boxShadow: "none"
    },
    titleTable: {
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor: "#DDDDDD",
        paddingTop:"1em",
        paddingBottom:"1em",
        paddingLeft:"1.5em",
        backgroundColor: 'white'
    },
    tableContainer: {
        boxShadow: "none",
        border: "1px solid #CCCCCC",
        borderRadius: "4px"
    }
});

export default function PIN(props) {
    const classes = useStyles();
    const history = useHistory();
    const [search, setSearch] = useState('')
    const vitos = useSelector(state=> state.vitoState.vitos)
    const roleProfile = useSelector(state=> state.profileState.role)

    function viewProfile (id) {
        history.push(`/admin/vito/${id}`)
    }

    function addVito () {
        history.push("/admin/vito/add")
    }

    return (
        <>
            <div className="dashboard" >
                <span className="breadcrumb-txt-black" >
                    VITO
            </span>
                <span className="breadcrumb-slash-black" >
                    /
            </span>
            </div>
            <div className="vito-table">
                <Box width='100%'>
                    <Box className={classes.titleTable}>
                        <Typography variant="h5"><b>Daftar VITO</b></Typography>
                    </Box>
                    <Box paddingLeft="1.5em" paddingRight="1em" paddingTop="2em" style={{ backgroundColor: 'white', paddingBottom: '20px' }} >
                        <Box display="flex" justifyContent="space-between">
                            <form autoComplete="off">
                                <TextField onChange={e=>setSearch(e.target.value)} label="Cari VITO" size="small" variant="outlined" color="secondary" borderColor="#C4C4C4" />
                            </form>
                            {
                                roleProfile &&
                                (
                                    roleProfile !== 'picpasar' &&
                                    roleProfile !== 'markom' &&
                                    roleProfile !== 'deputi' &&
                                    roleProfile !== 'direktorat' &&
                                    roleProfile !== 'guest'
                                ) &&
                                <Button variant="contained" size="large" color="secondary" style={{backgroundColor:"#E23F81", textTransform:"none"}} onClick={addVito}>Tambah VITO</Button>
                            }
                        </Box>
                        <Box paddingTop="1em">
                            <TableContainer component={Paper} className={classes.tableContainer}>
                                <Table className={classes.table} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>NO</StyledTableCell>
                                            <StyledTableCell align="left">NAMA</StyledTableCell>
                                            <StyledTableCell align="left">NEGARA</StyledTableCell>
                                            <StyledTableCell align="left">AREA</StyledTableCell>
                                            <StyledTableCell align="left">EMAIL</StyledTableCell>
                                            <StyledTableCell align="left">NO. TELP</StyledTableCell>
                                            {
                                                roleProfile === 'admin' &&
                                                <StyledTableCell align="left">AKSI</StyledTableCell>
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                        vitos &&
                                        vitos
                                        .filter(a=>search ? ((a.name).toLowerCase()).includes((search).toLowerCase()) : a)
                                        .map((row,i) => (
                                            <StyledTableRow key={i}>
                                                <StyledTableCell align="left">{i+=1}</StyledTableCell>
                                                <StyledTableCell align="left">
                                                    <Box display="flex" alignItems="center">
                                                        <Box height="2em" width="2em" border={1} borderRadius="50%" display="flex" justifyContent="center" alignItems="center" borderColor="grey.500" marginRight="1em">
                                                            {
                                                                row &&
                                                                row.photo ?
                                                                <img src={row.photo} width="100%" height="100%" style={{ borderRadius: 50 }} />
                                                                :
                                                                <img width="100%" height="100%" style={{ borderRadius: 50 }} src={logoWI}/>
                                                            }
                                                        </Box>
                                                    {row && row.name ? row.name : '-'}
                                                    </Box>
                                                </StyledTableCell>
                                                <StyledTableCell align="left">{row && row.country ? row.country : '-'}</StyledTableCell>
                                                <StyledTableCell align="left">{row && row.area ? row.area !== 'tidak ada area' ? row.area : '-' : '-'}</StyledTableCell>
                                                <StyledTableCell align="left">{row && row.email ? row.email : '-'}</StyledTableCell>
                                                <StyledTableCell align="left">{row && row.phone ? row.phone : '-'}</StyledTableCell>
                                                {
                                                    roleProfile === 'admin' &&
                                                    <StyledTableCell align="left">
                                                        <ClickAway id={row.id} history={history} name={row.name} style={{ top: 0 }} />
                                                    </StyledTableCell>
                                                }
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>

                    </Box>
                </Box>
            </div>
        </>
    )
}

const useStylesBtn = makeStyles((theme) => ({
    root: {
        position: 'absolute',
    },
    dropdown: {
        justifyContent: 'center',
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        width: '155px',
        position: 'absolute',
        // top: 28,
        // right: 0,
        left: -60,
        zIndex: 99,
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        boxShadow: '6px 5px 33px -14px rgba(0,0,0,0.75)'
    },
    label:{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "16px",
        color: "#999999",
    },
    box:{
        borderBottom: '0.5px solid #DDDDDD',
        width: '100%',
        height: 35,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: 15,
        paddingRight: 15,
        color: '#999999',
        cursor: 'pointer',
        borderRadius: 4,
        '&:hover':{
            color: 'white',
            backgroundColor: '#E23F81',
            width: '-webkit-fill-available'
        }
    },
    boxLast:{
        width: '100%',
        height: 35,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: 15,
        paddingRight: 15,
        color: '#999999',
        borderRadius: 4,
        cursor: 'pointer',
        '&:hover':{
            color: 'white',
            backgroundColor: '#E23F81',
            width: '-webkit-fill-available'
        }
    }
}));

function ClickAway(props) {
    let { id, name, history } = props
    const classes = useStylesBtn();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch()
    const [modalToggle , setModalToggle] = useState(false);
    const [showPassword, setShowPassword] = useState(false)
    const [password, setPassword] = useState('')
    const [Null, setNull] = useState(false)
    const [showPasswordRepeat, setShowPasswordRepeat] = useState(false)
    const [passwordRepeat, setPasswordRepeat] = useState('')
    const [NullRepeat, setNullRepeat] = useState(false)
    const [error, setError] = useState(false)

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    const deleteUserAction = () => {
        dispatch(deleteVito(id))
        setOpen(false);
    }

    const seeProfile = () => {
        history.push('/admin/vito/'+id)
    }

    const UpdateProfile = () => {
        history.push('/admin/vito/edit/'+id)
    }

    const modalHandler = (e, data) => {
        if (e) {
            e.preventDefault();
        }
        handleClickAway()
        setModalToggle(!modalToggle)
    }

    const passwordHandler = () => {
        setShowPassword(!showPassword)
    }

    const passwordHandlerRepeat = () => {
        setShowPasswordRepeat(!showPasswordRepeat)
    }

    const handleChangePass = () => {
        setNull(true)
        if(password && passwordRepeat) {
            Axios({
                method: 'PATCH',
                url: `${SERVER}/user/${id}`,
                data: { password, passwordRepeat },
                headers: { token: localStorage.getItem('token') }
            })
            .then(({data})=>{
                setNull(null)
                setNullRepeat(null)
                setError(null)
                modalHandler()
                setPasswordRepeat('')
                setPassword('')
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
                Toast.fire({
                    icon: 'success',
                    title: 'Ganti Password Berhasil'
                })
            })
            .catch(error=>{
                if (error.response) {
                    if (error.response.data) {
                        if (error.response.data.message) {
                            if (error.response.data.message === 'password tidak sama') {
                                setNullRepeat(true)
                            }
                            setError(error.response.data.message)
                        }
                        if (error.response.data.errors) {
                            setError(error.response.data.errors[0])
                        }
                    }
                }
            })
        }
    }

    const handleModalIN = (e) => {
        if (e) {
            e.preventDefault();
        }
        handleClickAway()
        setModalToggle(!modalToggle)
        setShowPassword(null)
        setPassword('')
        setNull(null)
        setShowPasswordRepeat(null)
        setPasswordRepeat('')
        setNullRepeat(null)
        setError(null)
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div>
                <div className="rqs-dta-btn-option" style={{ backgroundColor:'#FFFFFF', marginTop: 0 }} onClick={handleClick} >
                    <MoreHorizIcon/>
                </div>
                <div className={classes.root}>
                    { open ? (
                    <div className={classes.dropdown} >
                        <div className={classes.box} onClick={()=>seeProfile()}>
                            <i className="fas fa-user"/>
                            <span style={{ marginLeft: 10 }} >
                                Lihat Profil
                            </span>
                        </div>
                        <div className={classes.box} onClick={(e)=>UpdateProfile(e)}>
                            <i className="fas fa-pencil-alt"/>
                            <span style={{ marginLeft: 10 }} >
                                Ubah
                            </span>
                        </div>
                        <div className={classes.box} onClick={(e)=>handleModalIN(e)}>
                            <i className="fas fa-key"/>
                            <span style={{ marginLeft: 10 }} >
                                Ganti Password
                            </span>
                        </div>
                        <div className={classes.boxLast} onClick={()=>deleteUserAction()}>
                            <i className="fas fa-trash"/>
                            <span style={{ marginLeft: 10 }}>
                                Hapus
                            </span>
                        </div>
                    </div>
                    ) : null}
                </div>
            <ModalChangePassword 
                show={modalToggle} modalClosed={modalHandler} children={"modal"} name={name}
                passwordHandler={passwordHandler}
                passwordHandlerRepeat={passwordHandlerRepeat}
                password={password}
                setPassword={setPassword}
                Null={Null}
                showPassword={showPassword}
                passwordRepeat={passwordRepeat}
                NullRepeat={NullRepeat}
                showPasswordRepeat={showPasswordRepeat}
                setPasswordRepeat={setPasswordRepeat}
                error={error}
                handleChangePass={handleChangePass}
                setNull={setNull}
                setNullRepeat={setNullRepeat}
            />
            </div>
        </ClickAwayListener>
    );
}