import React, { useEffect, useState } from 'react'
import { Box, makeStyles, Button, FormControl, RadioGroup, Checkbox, FormControlLabel } from '@material-ui/core'
import Typography from '@material-ui/core/Typography';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import UploadImg from '../../../../../images/uploadImg.png'
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import { useHistory, useParams } from 'react-router-dom'
import { getOneVito } from '../../../../../../../store/actions/evaluationActions'
import Axios from 'axios'
import { SERVER } from '../../../../../../../server'
import { EditorState, convertToRaw, ContentState, convertFromHTML, convertFromRaw } from 'draft-js';
import { useDispatch, useSelector } from 'react-redux'

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        padding: "2em",
        width: '98%',
        minHeight: '98%',
        background: "white",
        boxShadow: "none"
    },
    editor: {
        fontSize: "16px",
        lineHeight: "19px",
        padding: "15px 21px",
        minHeight: "100%"
    },
    boxYellow: {
        background: "rgba(218, 157, 2, 0.1)",
        border: "1px solid #DA9D02",
        padding: "0.5em 1em",
        margin: "1em 0em"
    },
    boxDelete: {
        background: "white",
        position: "absolute",
        width: "1.8rem",
        height: "1.8rem",
        top: "-1rem",
        left: "14rem",
        display: "flex",
        justifyContent: "center",
        borderRadius: "50%",
        zIndex: "99999999"
    },
    dropZone: {
        minHeight: '100%'
    },
    boxRadioActive: {
        background: "white",
        border: "1px solid  #DDDDDD",
        width: "40%",
        margin: "0em",
        marginBottom: "0.5rem",
        borderRadius: "4px",
        color: "#E23F81"
    },
    boxRadio: {
        background: "white",
        border: "1px solid  #DDDDDD",
        width: "40%",
        margin: "0em",
        marginBottom: "0.5rem",
        borderRadius: "4px",
    },
})

export default function PIN(props) {
    const classes = useStyles()
    const history = useHistory()
    const Dispatch = useDispatch()
    const [flag, setFlag] = useState(false)
    const [photos, setPhotos] = useState([])
    const [photosend, setPhotosend] = useState([])
    const [photoDeleteRead, setPhotoDeleteRead] = useState([])
    const [photoDelete, setPhotoDelete] = useState([])
    const [data, setData] = useState(null)
    const { year } = useParams()
    const [week, setWeek] = useState(null)
    const [sendText, setSend] = useState(null)
    const [readText, setRead] = useState(null)
    const [category, setCategory] = useState(false);
    const [options, setOptions] = useState([])
    const [credit, setCredit] = useState(false);
    const vito = useSelector(state=> state.evaluationState.vito)
    const [indicator, setIndicator] = useState(false);
    const [Triggered, setTriggered] = useState(false);
    const [savingTriggered, setSavingTriggered] = useState(false);

    useEffect(()=>{
        if (history.location.state) {
            if (history.location.state.options) {
                setOptions(history.location.state.options)
            }
            if (history.location.state.data) {
                setData(history.location.state.data)
                if (history.location.state.data && (history.location.state.data.report && history.location.state.data.report != 'null')) {
                    setRead(EditorState.createWithContent(convertFromRaw(JSON.parse(history.location.state.data.report))))
                }
                setSend(history.location.state.data.report)
                if (history.location.state.data.credit) {
                    if (!(history.location.state.data.credit == 'false')) {
                        setCredit('I want to submit this report as my credit')
                    }
                }
                if (history.location.state.data.category) {
                    setCategory(history.location.state.data.category)
                }
                if (history.location.state.data.indicator) {
                    setIndicator(history.location.state.data.indicator)
                }
                if (history.location.state.data.photos) {
                    setPhotoDeleteRead(history.location.state.data.photos.split(','))
                }
            }
            if (history.location.state.weeklyList) {
                let weekFix
                history.location.state.weeklyList.forEach((week)=>{
                    if (week.id == history.location.state.data.WeekId) {
                        weekFix = week
                        return
                    }
                })
                setWeek(weekFix)
            }
        } else {
            history.replace('/vito/report/weekly/'+new Date().getFullYear())
        }
    },[])

    function addFiles(fileObjs) {
        let temp = []
        let tempSend = []
        fileObjs.map((item) => {
            if (item) {
                if (item.file) {
                    if ((item.file.type).includes('application/')) {
                        temp.push(item.file.name)
                    } else {
                        temp.push(URL.createObjectURL(item.file))
                    }
                    tempSend.push(item.file)
                }
            }
        })
        setFlag(!flag)
        setPhotos([...photos, ...temp]);
        setPhotosend([...photosend, ...tempSend]);
    }

    const changeCategory = (event) => {
        setTriggered(false)
        if (category == event.target.value){
            setCategory('')
            setIndicator('')
        } else{
            setCategory(event.target.value)
            setIndicator('')
        }
    }

    function deletePhoto(index) {
        let temp = photos
        temp.splice(index, 1)
        setPhotos(temp)
        let temp2 = photosend
        temp2.splice(index, 1)
        setPhotosend(temp2)
        setFlag(!flag)
    }

    function deletePhotoJunk(index, name) {
        let temp = photoDeleteRead
        temp.splice(index, 1)
        setPhotoDeleteRead(temp)
        let temp2 = photoDelete
        temp2.push(name)
        setPhotoDelete(temp2)
        setFlag(!flag)
    }

    function cancel() {
        history.goBack()
    }

    const Category = (id) => {
        let title
        switch (id) {
            case 1:
                title="NETWORKING & PARTNERSHIP"
                break;
            case 2:
                title="MARKET INTELLIGENCE"
                break;
            // case 3:
            //     title="PROMOTIONAL SUPPORT"
            //     break;
            default:
                break;
        }
        return title
    }

    const onEditorStateChange = (event) => {
        let editorState = event; 
        let editorSourceHTML = JSON.stringify(convertToRaw(event.getCurrentContent()))
        setRead(editorState)
        setSend(editorSourceHTML)
    }

    const saving = () => {
        if (!category) {
            setTriggered(true)
            goOnTop()
        } else {
            setSavingTriggered(true)
            const dataSend = new FormData()
            photosend.map((item) => {
                dataSend.append('photo', item)
            })
            photoDeleteRead.map((item) => {
                dataSend.append('imageOld', item)
            })
            photoDelete.map((item) => {
                dataSend.append('imageDelete', item)
            })
            dataSend.set('report', sendText)
            dataSend.set('category', category)
            dataSend.set('credit', credit)
            dataSend.set('indicator', indicator)
            Axios({
                method: 'PATCH',
                url: `${SERVER}/report/weekly/edit-only/${data.id}`,
                data: dataSend,
                headers: {
                    token : localStorage.getItem('token'),
                }
            })
            .then(({data})=>{
                cancel()
            })
            .catch(err=>{
            })
        }
    }

    const goOnTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    const changeCredit = (event) => {
        if (credit == event.target.value){
            setCredit(false)
            setIndicator('')
        } else{
            setCredit(event.target.value) 
        }
    }

    const changeIndicator = (event) => {
        if (indicator == event.target.value){
            setIndicator('')
        } else{
            setIndicator(event.target.value) 
        }
    }

    return (
        <>
            <Box className={classes.root}>
                <Box>
                    <Typography variant="h6" style={{ fontWeight: "500" }}>
                        UPDATE REPORT {Category(data && data.CategoryId)} Week {week && week.week}, {year && year}
                    </Typography>
                </Box>
                <Box marginTop="1vw">
                    <FormControl component="fieldset" style={{ width: "100%", justifyContent: "space-between" }}>
                        <RadioGroup style={{ justifyContent: "space-between" }} value={category} onChange={changeCategory}>
                            {
                                options &&
                                options.map((option,i) => {
                                    return (
                                        <FormControlLabel
                                            key={i}
                                            value={option}
                                            label={option ? option.includes('Partnership with Airline') ? 'Partnership with Airlines' : option : option}
                                            disabled={ data && (data.credit !== 'disetujui' && data.credit !== 'ditolak') ? false : true }
                                            style={{ borderColor: (Triggered && !category) && 'red' }}
                                            className={category == option ? classes.boxRadioActive : classes.boxRadio} 
                                            control={
                                                <Checkbox
                                                    checked={category == option ? true : false}
                                                    onChange={changeCategory}
                                                    value={option}
                                                />
                                            }
                                        />
                                    )
                                })
                            }
                        </RadioGroup>
                    </FormControl>
                </Box>
                <Box border="1px solid #DDDDDD" marginTop="1em" minHeight="38vh">
                    <Editor
                        editorState={readText}
                        editorClassName={classes.editor}
                        placeholder="Create report here.."
                        onEditorStateChange={onEditorStateChange}
                    />
                </Box>
                <Box display="flex" position="relative" flexDirection="column" justifyContent="center" alignItems="center" border="1px solid #DDDDDD" marginTop="1em" minHeight="30vh">
                    <Box position="absolute" width="100%" height="100%" zIndex="99" style={{ opacity: "0" }}><DropzoneAreaBase
                        onAdd={addFiles}
                        acceptedFiles={['image/jpeg', 'image/png', 'image/jpg', 'application/pdf', '.doc','.docx']}
                        // onDelete={(fileObj) => console.log('Removed File:', fileObj)}
                        showAlerts={false}
                        dropzoneText=""
                        dropzoneClass={classes.dropZone}
                    />
                    </Box>
                    <Box marginY="1rem"><img src={UploadImg} style={{ width: "4rem", height: "4rem" }} /></Box>
                    <Typography style={{ color: '#999999' }}>Upload Here (JPG/PNG/PDF/WORD | Max File Size 3 MB)</Typography>
                    <Typography style={{ color: "#E23F81" }}>Choose</Typography>
                    <Box display="flex" marginTop="1em">
                        {
                            photoDeleteRead &&
                            photoDeleteRead.map((photo, index) => {
                                return (
                                    <Box key={index} width="15rem" marginRight="3rem" marginBottom="1rem" position="relative"
                                        style={{
                                            border: (photo && (photo.includes('.pdf') || photo.includes('.doc'))) &&'1px solid #e35981',
                                            borderRadius: (photo && (photo.includes('.pdf') || photo.includes('.doc'))) && '4px',
                                            maxHeight: (photo && (photo.includes('.pdf') || photo.includes('.doc'))) && '105px'
                                        }}
                                    >
                                        <Box className={classes.boxDelete}>
                                            <Button onClick={() => deletePhotoJunk(index, photo)}>
                                                <CancelSharpIcon style={{ fontSize: "2rem", color: "#E23F81" }} />
                                            </Button>
                                        </Box>
                                        {
                                            photo &&
                                            photo.includes('.pdf') ?
                                                <div
                                                    style={{
                                                        padding: 5,
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <i className="fas fa-file-pdf"
                                                        style={{ fontSize: "80px", color: '#E23F81' }}
                                                    />
                                                    <div
                                                        style={{
                                                            wordBreak: 'break-all',
                                                            padding: 5,
                                                            marginTop: 'auto',
                                                            boxSizing: 'border-box'
                                                        }}
                                                    >{ photo && photo.split('-IMAGE-')[1] }</div>
                                                </div>
                                            :
                                            photo &&
                                            photo.includes('.doc') ?
                                                <div
                                                    style={{
                                                        padding: 5,
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <i className="fas fa-file-word"
                                                        style={{ fontSize: "80px", color: '#E23F81' }}
                                                    />
                                                    <div
                                                        style={{
                                                            wordBreak: 'break-all',
                                                            padding: 5,
                                                            marginTop: 'auto',
                                                            boxSizing: 'border-box'
                                                        }}
                                                    >{ photo && photo.split('-IMAGE-')[1] }</div>
                                                </div>
                                            :
                                            <img src={photo} alt="images" className="image-upload-content"
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "4px"
                                                }}
                                            />
                                        }
                                    </Box>
                                )
                            })
                        }
                        {
                            photos.map((photo, index) => {
                                return (
                                    <Box key={index} width="15rem" marginRight="3rem" marginBottom="1rem" position="relative"
                                        style={{
                                            border: (photo && (photo.includes('.pdf') || photo.includes('.doc'))) &&'1px solid #e35981',
                                            borderRadius: (photo && (photo.includes('.pdf') || photo.includes('.doc'))) && '4px',
                                            maxHeight: (photo && (photo.includes('.pdf') || photo.includes('.doc'))) && '105px'
                                        }}
                                    >
                                        <Box className={classes.boxDelete}>
                                            <Button onClick={() => deletePhoto(index)}>
                                                <CancelSharpIcon style={{ fontSize: "2rem", color: "#E23F81" }} />
                                            </Button>
                                        </Box>
                                        {
                                            photo &&
                                            photo.includes('.pdf') ?
                                                <div
                                                    style={{
                                                        padding: 5,
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <i className="fas fa-file-pdf"
                                                        style={{ fontSize: "80px", color: '#E23F81' }}
                                                    />
                                                    <div
                                                        style={{
                                                            wordBreak: 'break-all',
                                                            padding: 5,
                                                            marginTop: 'auto',
                                                            boxSizing: 'border-box'
                                                        }}
                                                    >{ photo }</div>
                                                </div>
                                            :
                                            photo &&
                                            photo.includes('.doc') ?
                                                <div
                                                    style={{
                                                        padding: 5,
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <i className="fas fa-file-word"
                                                        style={{ fontSize: "80px", color: '#E23F81' }}
                                                    />
                                                    <div
                                                        style={{
                                                            wordBreak: 'break-all',
                                                            padding: 5,
                                                            marginTop: 'auto',
                                                            boxSizing: 'border-box'
                                                        }}
                                                    >{ photo }</div>
                                                </div>
                                            :
                                            <img src={photo} alt="images" className="image-upload-content"
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "4px"
                                                }}
                                            />
                                        }
                                    </Box>
                                )
                            })
                        }
                    </Box>
                </Box>
                {
                    !category ?
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <FormControlLabel
                            value={true}
                            disabled
                            label={'I want to submit this report as my credit'}
                            control={
                                <Checkbox
                                    checked={credit ? true : false}
                                    onChange={changeCredit}
                                    value={true}
                                />
                            }
                        />
                        <span style={{ color: 'red', marginTop: -5 }} >
                            please select your category first!
                        </span>
                    </div>:
                    vito ?
                    <FormControlLabel
                        value={'I want to submit this report as my credit'}
                        label={'I want to submit this report as my credit'}
                        disabled={ data && (data.credit !== 'disetujui' && data.credit !== 'ditolak') ? false : true }
                        control={
                            <Checkbox
                                checked={credit ? true : false}
                                onChange={changeCredit}
                                value={'I want to submit this report as my credit'}
                            />
                        }
                    />
                    :
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <FormControlLabel
                            value={true}
                            disabled
                            label={'I want to submit this report as my credit'}
                            control={
                                <Checkbox
                                    checked={credit ? true : false}
                                    onChange={changeCredit}
                                    value={true}
                                />
                            }
                        />
                        <span style={{ color: 'red', marginTop: -5 }} >
                            please contact admin to create your form evaluation, to open your credit!
                        </span>
                    </div>
                }

                {
                    credit && data && data.CategoryId == '1' &&
                    category && (category).includes('Partnership with Academics') ?
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1vw' }} >
                            <span className="txt-indi-ctr" > Indicators </span>
                            <Box marginTop="0.5vw">
                                <FormControl component="fieldset" style={{ width: "100%", justifyContent: "space-between" }}>
                                    <RadioGroup style={{ justifyContent: "space-between" }} value={indicator} onChange={changeIndicator}>
                                        <FormControlLabel
                                            disabled={ data && (data.credit !== 'disetujui' && data.credit !== 'ditolak') ? false : true }
                                            value={'Collaboration Activities with Academics Partners'}
                                            label={`Collaboration Activities with Academics Partners 
                                                ${
                                                    category && (category).includes('Partnership with Academics') ? vito && `(${vito.A1a} Target)` : `(${0} Target)`
                                                }
                                            `}
                                            className={indicator == 'Collaboration Activities with Academics Partners' ? classes.boxRadioActive : classes.boxRadio} 
                                            control={
                                                <Checkbox
                                                    checked={indicator == 'Collaboration Activities with Academics Partners' ? true : false}
                                                    onChange={changeIndicator}
                                                    value={'Collaboration Activities with Academics Partners'}
                                                />
                                            }
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </div>
                    :
                    credit && data && data.CategoryId == '1' &&
                    category && (category).includes('Partnership with Business (TA/TO, Airlines, Accommodation, and others)') ?
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1vw' }} >
                            <span className="txt-indi-ctr" > Indicators </span>
                            <Box marginTop="0.5vw">
                                <FormControl component="fieldset" style={{ width: "100%", justifyContent: "space-between" }}>
                                    <RadioGroup style={{ justifyContent: "space-between" }} value={indicator} onChange={changeIndicator}>
                                        <FormControlLabel
                                            disabled={ data && (data.credit !== 'disetujui' && data.credit !== 'ditolak') ? false : true }
                                            value={'Collaboration Activities with Bussiness/Industry Partners'}
                                            label={`Collaboration Activities with Bussiness/Industry Partners 
                                                ${
                                                    category && (category).includes('Partnership with Business (TA/TO, Airlines, Accommodation, and others)') ? vito && `(${vito.A2a} Target)` : `(${0} Target)`
                                                }
                                            `}
                                            className={indicator == 'Collaboration Activities with Bussiness/Industry Partners' ? classes.boxRadioActive : classes.boxRadio} 
                                            control={
                                                <Checkbox
                                                    checked={indicator == 'Collaboration Activities with Bussiness/Industry Partners' ? true : false}
                                                    onChange={changeIndicator}
                                                    value={'Collaboration Activities with Bussiness/Industry Partners'}
                                                />
                                            }
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </div>
                    :
                    credit && data && data.CategoryId == '1' &&
                    category && (category).includes('Partnership with Communities') ?
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1vw' }} >
                            <span className="txt-indi-ctr" > Indicators </span>
                            <Box marginTop="0.5vw">
                                <FormControl component="fieldset" style={{ width: "100%", justifyContent: "space-between" }}>
                                    <RadioGroup style={{ justifyContent: "space-between" }} value={indicator} onChange={changeIndicator}>
                                        <FormControlLabel
                                            disabled={ data && (data.credit !== 'disetujui' && data.credit !== 'ditolak') ? false : true }
                                            value={'Collaboration Activities with Communities Partners'}
                                            label={`Collaboration Activities with Communities Partners 
                                                ${
                                                    category && (category).includes('Partnership with Communities') ? vito && `(${vito.A3a} Target)` : `(${0} Target)`
                                                }
                                            `}
                                            className={indicator == 'Collaboration Activities with Communities Partners' ? classes.boxRadioActive : classes.boxRadio} 
                                            control={
                                                <Checkbox
                                                    checked={indicator == 'Collaboration Activities with Communities Partners' ? true : false}
                                                    onChange={changeIndicator}
                                                    value={'Collaboration Activities with Communities Partners'}
                                                />
                                            }
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </div>
                    :
                    credit && data && data.CategoryId == '1' &&
                    category && (category).includes('Partnership with Governments') ?
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1vw' }} >
                            <span className="txt-indi-ctr" > Indicators </span>
                            <Box marginTop="0.5vw">
                                <FormControl component="fieldset" style={{ width: "100%", justifyContent: "space-between" }}>
                                    <RadioGroup style={{ justifyContent: "space-between" }} value={indicator} onChange={changeIndicator}>
                                        <FormControlLabel
                                            disabled={ data && (data.credit !== 'disetujui' && data.credit !== 'ditolak') ? false : true }
                                            value={'Collaboration Activities with Governments Partners'}
                                            label={`Collaboration Activities with Governments Partners 
                                                ${
                                                    category && (category).includes('Partnership with Governments') ? vito && `(${vito.A4a} Target)` : `(${0} Target)`
                                                }
                                            `}
                                            className={indicator == 'Collaboration Activities with Governments Partners' ? classes.boxRadioActive : classes.boxRadio} 
                                            control={
                                                <Checkbox
                                                    checked={indicator == 'Collaboration Activities with Governments Partners' ? true : false}
                                                    onChange={changeIndicator}
                                                    value={'Collaboration Activities with Governments Partners'}
                                                />
                                            }
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </div>
                    :
                    credit && data && data.CategoryId == '1' &&
                        category && (category).includes('Partnership with Media') ?
                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1vw' }} >
                                <span className="txt-indi-ctr" > Indicators </span>
                                <Box marginTop="0.5vw">
                                    <FormControl component="fieldset" style={{ width: "100%", justifyContent: "space-between" }}>
                                        <RadioGroup style={{ justifyContent: "space-between" }} value={indicator} onChange={changeIndicator}>
                                            <FormControlLabel
                                                disabled={ data && (data.credit !== 'disetujui' && data.credit !== 'ditolak') ? false : true }
                                                value={'Collaboration Activities with Media Partners'}
                                                label={`Collaboration Activities with Media Partners 
                                                    ${
                                                        category && (category).includes('Partnership with Media') ? vito && `(${vito.A5a} Target)` : `(${0} Target)`
                                                    }
                                                `}
                                                className={indicator == 'Collaboration Activities with Media Partners' ? classes.boxRadioActive : classes.boxRadio} 
                                                control={
                                                    <Checkbox
                                                        checked={indicator == 'Collaboration Activities with Media Partners' ? true : false}
                                                        onChange={changeIndicator}
                                                        value={'Collaboration Activities with Media Partners'}
                                                    />
                                                }
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                            </div>
                        :
                    credit && data && data.CategoryId == '2' &&
                    category && (category).includes('Market Update') ?
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1vw' }} >
                            <span className="txt-indi-ctr" > Indicators </span>
                            <Box marginTop="0.5vw">
                                <FormControl component="fieldset" style={{ width: "100%", justifyContent: "space-between" }}>
                                    <RadioGroup style={{ justifyContent: "space-between" }} value={indicator} onChange={changeIndicator}>
                                        <FormControlLabel
                                            disabled={ data && (data.credit !== 'disetujui' && data.credit !== 'ditolak') ? false : true }
                                            value={'Number of Market Strategic Review'}
                                            label={`Number of Market Strategic Review 
                                                ${
                                                    category && (category).includes('Market Update') ? vito && `(${vito.B1a} Target)` : `(${0} Target)`
                                                }
                                            `}
                                            className={indicator == 'Number of Market Strategic Review' ? classes.boxRadioActive : classes.boxRadio} 
                                            control={
                                                <Checkbox
                                                    checked={indicator == 'Number of Market Strategic Review' ? true : false}
                                                    onChange={changeIndicator}
                                                    value={'Number of Market Strategic Review'}
                                                />
                                            }
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </div>
                    :
                    credit && data && data.CategoryId == '2' &&
                    category && (category).includes('Competitor Update') &&
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1vw' }} >
                            <span className="txt-indi-ctr" > Indicators </span>
                            <Box marginTop="0.5vw">
                                <FormControl component="fieldset" style={{ width: "100%", justifyContent: "space-between" }}>
                                    <RadioGroup style={{ justifyContent: "space-between" }} value={indicator} onChange={changeIndicator}>
                                        <FormControlLabel
                                            value={'Number of Analysis of Competitor Activities'}
                                            label={`Number of Analysis of Competitor Activities 
                                                ${
                                                    category && (category).includes('Competitor Update') ? vito && `(${vito.B2a} Target)` : `(${0} Target)`
                                                }
                                            `}
                                            className={indicator == 'Number of Analysis of Competitor Activities' ? classes.boxRadioActive : classes.boxRadio} 
                                            disabled={ data && (data.credit !== 'disetujui' && data.credit !== 'ditolak') ? false : true }
                                            control={
                                                <Checkbox
                                                    checked={indicator == 'Number of Analysis of Competitor Activities' ? true : false}
                                                    onChange={changeIndicator}
                                                    value={'Number of Analysis of Competitor Activities'}
                                                />
                                            }
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </div>
                }

                <Box display="flex" alignItems="center" marginTop="2em">
                    {
                        savingTriggered ? 
                        <Button type="submit" variant="contained" color="secondary" style={{ backgroundColor: "#E23F81", textTransform: "none", boxShadow: "none", padding: "0.5em 3em" }}>Loading</Button>
                        :
                        <Button disabled={credit ? indicator ? false : true : false}   onClick={saving} type="submit" variant="contained" color="secondary" style={{ backgroundColor: "#E23F81", textTransform: "none", boxShadow: "none", padding: "0.5em 3em" }}>Submit</Button>
                    }
                    <Button variant="outlined" onClick={cancel} color="secondary" style={{ textTransform: "none", padding: "0.5em 3em", marginLeft: "2em", }} >Cancel</Button>
                </Box>
            </Box>
        </>
    )
}