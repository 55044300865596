import axios from 'axios'
import Swal from 'sweetalert2'
import { SERVER } from '../../server'
import {
    IS_LOGOUT,
    GET_COUNTRY
} from "../constanta";

export const getCountry = () => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            const { data } = await axios({
                method: 'GET',
                url: `${SERVER}/country`,
                headers: { token }
            })
            dispatch({
                type: GET_COUNTRY,
                data: data
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}