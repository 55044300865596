import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import waIcon from '../../../../images/wa.png'
import fbIcon from '../../../../images/facebook.png'
import tweetIcon from '../../../../images/twitter.png'
import ShareIcon from '@material-ui/icons/Share';
import EmailIcon from '@material-ui/icons/Email';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const useStyles = makeStyles({
    buttonCopy: {
        color: "white",
        background: "#E23F81",
        borderRadius: "4px",
        width: "100px",
        marginLeft: "1em",
        border: "0.5px solid #DDDDDD",
        textTransform: "none",
        boxShadow: "none"
    },
    text: {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#999999"
    },
    boxIcon: {
        width: "100px",
        height: "100px",
        borderRadius: "50%",
        boxShadow: "0px 2px 4px rgba(117, 117, 117, 0.1), 0px -2px 4px rgba(117, 117, 117, 0.1)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "1em"
    },
    urlBox: {
        margin: "1em",
        background: "#F2F3F9",
        border: "1px solid #DDDDDD",
        borderRadius: "4px",
        padding: "1em",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    content: {
        borderTop: "0.5px solid #DDDDDD"
    }
})

export default function CustomizedDialogs() {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles()

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Box borderRadius="50%" padding="0.25rem" style={{ background: "#FAFAFA" }}>
                <IconButton size="small" onClick={handleClickOpen} aria-label="delete">

                    <ShareIcon size="small" color="disabled" />
                </IconButton>
            </Box>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Share
                </DialogTitle>
                <Box className={classes.content}>
                    <Box display="flex" padding="1em">
                        <Box display="flex" flexDirection="column" justifyContent="center" textAlign="center" marginX="1em">
                            <Box className={classes.boxIcon}>
                                <img width="70px" height="70px" src={waIcon} alt="WhatsApp" />
                            </Box>

                            <Typography className={classes.text}>WhatsApp</Typography>
                        </Box>
                        <Box display="flex" flexDirection="column" justifyContent="center" textAlign="center" marginX="1em">
                            <Box className={classes.boxIcon}>
                                <EmailIcon style={{ color: "#2C304D", fontSize: "60px" }} />
                            </Box>

                            <Typography className={classes.text}>Email</Typography>
                        </Box>
                        <Box display="flex" flexDirection="column" justifyContent="center" textAlign="center" marginX="1em">
                            <Box className={classes.boxIcon}>
                                <img width="50px" height="50px" src={fbIcon} alt="Facebook" />
                            </Box>

                            <Typography className={classes.text}>Facebook</Typography>
                        </Box>
                        <Box display="flex" flexDirection="column" justifyContent="center" textAlign="center" marginX="1em">
                            <Box className={classes.boxIcon}>
                                <img width="70px" height="70px" src={tweetIcon} alt="Twitter" />
                            </Box>

                            <Typography className={classes.text}>Twitter</Typography>
                        </Box>
                    </Box>
                    <Box className={classes.urlBox}>
                        <Typography variant="h6" noWrap style={{ color: "#999999" }}>http://vitokemenpar.com/vor_weekly</Typography>
                        <Button variant="contained" color="secondary" className={classes.buttonCopy}>Copy</Button>

                    </Box>
                </Box>
            </Dialog>
        </div>
    );
}