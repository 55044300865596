import React, { useState, useEffect } from "react";
import { TextField, Paper, makeStyles, Button, Box } from "@material-ui/core";
import { Typography } from "@material-ui/core"
import logoWI from '../../../../images/bird.png'
import Axios from 'axios'
import { SERVER } from '../../../../../../server'
import socketClient from "socket.io-client";
import { useSelector } from 'react-redux'

const useStyle = makeStyles({
    commentBox: {
        background: "#FAFAFA",
        padding: "2em",
        paddingTop: 2,
        overflowY: 'auto',
    },
    buttonComment: {
        background: "#E23F81",
        color: "white",
        padding: "1em 2em",
        marginTop: "1em",
        width: "fit-content",
        textTransform: "none"
    },
    nameComment: {
        color: "#E23F81",
        fontSize: "16px"
    }
})

export default function SimplePopper(props) {
    const { photoProfile, data } = props
    const classes = useStyle()
    const [comments, setComments] = useState([])
    const [text, setText] = useState('')
    const socket = socketClient(SERVER, {transports: ['websocket'], secure: true});

    const emailProfile = useSelector(state=> state.profileState.id)

    useEffect(()=>{
        if (data) {
            getComment(data)
            socket.on('message', (send) => {
                if (send.id == data.id) {
                    getComment(data)
                }
            })
        }
    },[data])

    useEffect(()=>{
        if (comments) {
            const messages = document.getElementById('message-bx-097');
            messages.scrollTop = messages.scrollHeight;
        }
    },[comments])

    const getComment = (data) => {
        if (data) {
            Axios({
                method: 'GET',
                url: `${SERVER}/comment/${data.id}`,
                headers: {
                    token : localStorage.getItem('token'),
                }
            })
            .then(({data})=>{
                setComments(data)
            })
            .catch(err=>{
            })
        }
    };

    const addComment = (e) => {
        e.preventDefault()
        if (text, data) {
            Axios({
                method: 'POST',
                url: `${SERVER}/comment/created`,
                data: {
                    message: text,
                    reportId: data.id,
                    userId : data.UserId
                },
                headers: {
                    token : localStorage.getItem('token'),
                }
            })
            .then((dataNew)=>{
                setText('')
            })
            .catch(err=>{
            })
        }
    };

    const showDate = (dateParams) => {
        if (dateParams) {
            let start = new Date(dateParams).getDate()
            let startMonth = new Date(dateParams).getMonth()
            let monthStart = ''
            let yearResult = new Date(dateParams).getFullYear()
            switch (startMonth) {
                case 0 :
                    monthStart = 'Januari'
                    break;
                case 1 :
                    monthStart = 'Februari'
                    break;
                case 2 :
                    monthStart = 'Maret'
                    break;
                case 3 :
                    monthStart = 'April'
                    break;
                case 4 :
                    monthStart = 'Mei'
                    break;
                case 5 :
                    monthStart = 'Juni'
                    break;
                case 6 :
                    monthStart = 'Juli'
                    break;
                case 7 :
                    monthStart = 'Agustus'
                    break;
                case 8 :
                    monthStart = 'September'
                    break;
                case 9 :
                    monthStart = 'Oktober'
                    break;
                case 10 :
                    monthStart = 'November'
                    break;
                case 11 :
                    monthStart = 'Desember'
                    break;
                default:
                    monthStart = ''
                    break;
            }
            let hour = new Date(dateParams).getHours()
            let minute = new Date(dateParams).getMinutes()
            let second = new Date(dateParams).getSeconds()
            
            return start + ' '+ monthStart +' '+ yearResult +' - '+ (hour < 10 ? '0'+hour : hour) +':'+(minute < 10 ? '0'+minute : minute)
        }
    }

    return (
        <>
            <Box className={classes.commentBox}>
                <Box maxHeight="19em" overflow="auto" id="message-bx-097">
                {
                    (comments && emailProfile) &&
                    comments.map((comment, i) => {
                        return (
                            emailProfile == (comment && comment.UserId) ?
                            <Box marginBottom="1.5em" key={i}>
                                <Box display="flex" justifyContent="flex-end" >
                                    <div style={{ backgroundColor: '#d8ecc1', display: 'flex', marginRight: 5, padding: '10px 10px', borderRadius: 20 }} >
                                        <Box display="flex" flexDirection="column" >
                                            <Box display="flex" alignItems="center">
                                                <Box marginRight="0.5em">
                                                    <Typography variant="caption">{ comment && comment.createdAt ? showDate(comment.createdAt) : '-' }</Typography>
                                                </Box>
                                                <Typography className={classes.nameComment} >{ comment && comment.User ? comment.User.name ? comment.User.name : '-' : '-' }</Typography>
                                            </Box>
                                            <Typography fontSize='16px' align="right"  >{comment && comment.message ? comment.message : '-'}</Typography>
                                        </Box>
                                        <Box height="3em" width="3em" border={1} borderRadius="50%" borderColor="grey.500" marginLeft="1em">
                                            <img width="100%" height="100%" style={{ borderRadius: '50%' }} src={comment && comment.User ? comment.User.photo ? comment.User.photo : logoWI : logoWI} />
                                        </Box>
                                    </div>
                                </Box>
                            </Box>
                            :
                            <Box marginBottom="1.5em" key={i}>
                                <Box display="flex">
                                    <div style={{ backgroundColor: '#c1d8ec', display: 'flex', marginRight: 5, padding: '10px 10px', borderRadius: 20 }} >
                                        <Box height="3em" width="3em" border={1} borderRadius="50%" borderColor="grey.500" marginRight="1em">
                                            <img width="100%" height="100%" style={{ borderRadius: '50%' }} src={comment && comment.User ? comment.User.photo ? comment.User.photo : logoWI : logoWI} />
                                        </Box>
                                        <Box display="flex" flexDirection="column">
                                            <Box display="flex" alignItems="center">
                                                <Typography className={classes.nameComment}>{ comment && comment.User ? comment.User.name ? comment.User.name : '-' : '-' }</Typography>
                                                <Box marginLeft="0.5em">
                                                    <Typography variant="caption">{ comment && comment.createdAt ? showDate(comment.createdAt) : '-' }</Typography>
                                                </Box>
                                            </Box>
                                            <Typography fontSize='16px'>{comment && comment.message ? comment.message : '-'}</Typography>
                                        </Box>
                                    </div>
                                </Box>
                            </Box>
                        )
                    })
                }
                </Box>

                <form onSubmit={addComment}>
                    <Box display="flex" flexDirection="column" width="100%">
                        <Box display="flex" flexDirection="row" width="100%" alignItems="center">
                            <img height="40em" width="40em" style={{ borderRadius: '50%' }} src={photoProfile ? photoProfile : logoWI} alt=""/>
                            <TextField value={text} width="100%" style={{ paddingLeft: 10 }} onChange={e=>setText(e.target.value)} id="outlined-basic" placeholder="Type comment here.." variant="outlined" color="secondary" fullWidth multiline rowsMax={4} />
                        </Box>
                        <Button size="small" onClick={addComment} variant="contained" className={classes.buttonComment} type="submit">
                            Post Comment
                        </Button>
                    </Box>
                </form>

            </Box>

        </>
    );
}
