import React, { useState, useEffect } from 'react'
import { Box, makeStyles, Button, TextField, IconButton } from '@material-ui/core'
import Typography from '@material-ui/core/Typography';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import NewAlbum from '../../../../../component/Report/Modal/NewAlbum'
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import UploadImg from '../../../../../images/uploadImg.png'
import { useHistory } from 'react-router-dom'
import Axios from 'axios'
import { SERVER } from '../../../../../../../server'
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        padding: "2em",
        width: '98%',
        minHeight: '98%',
        background: "white",
        boxShadow: "none"
    },
    editor: {
        fontSize: "16px",
        lineHeight: "19px",
        padding: "15px 21px",
        minHeight: "100%"
    },
    boxDelete: {
        background: "white",
        position: "absolute",
        width: "1.8rem",
        height: "1.8rem",
        top: "-1rem",
        left: "14rem",
        display: "flex",
        justifyContent: "center",
        borderRadius: "50%",
        zIndex: "99999999"
    },
    dropZone: {
        height: "100%"
    }
})

export default function PIN(props) {
    const classes = useStyles()
    const history = useHistory()
    const [images, setImages] = useState([])
    const [isGalleries, setIsGalleries] = useState(false)
    const [flag, setFlag] = useState(false)
    const [photos, setPhotos] = useState([])
    const [photosSend, setPhotosSend] = useState([])
    const [albumList, setAlbumList] = useState([])
    const [data, setData] = useState(null)
    const [readDesc, setReadDesc] = useState(null)
    const [sendDesc, setSendDesc] = useState(null)
    const [albumSelect, setAlbumSelect] = useState(null)
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [photoDeleteRead, setPhotoDeleteRead] = useState([])
    const [photoDelete, setPhotoDelete] = useState([])
    const [month, setMonth] = useState(null)

    const handleDateChange = (date) => {
      setSelectedDate(date);
    };

    function addFiles(fileObjs) {
        let temp = []
        let tempSend = []
        fileObjs.map((item) => {
            temp.push(URL.createObjectURL(item.file))
            tempSend.push(item.file)
        })
        setPhotos([...photos, ...temp]);
        setPhotosSend([...photosSend, ...tempSend]);
        setFlag(!flag)
    }

    function deletePhoto(index) {
        let temp = photos
        temp.splice(index, 1)
        setPhotos(temp)
        let temp2 = photosSend
        temp2.splice(index, 1)
        setPhotosSend(temp2)
        setFlag(!flag)
    }

    useEffect(()=>{
        if (history.location.state) {
            if (history.location.state.month) {
                setMonth(history.location.state.month)
            }
            if (history.location.state.file) {
                setData(history.location.state.file)
                readAlbum(history.location.state.file)
                if (history.location.state.file.created) {
                    setSelectedDate(history.location.state.file.created)
                }
                if (history.location.state.file && (history.location.state.file.description && history.location.state.file.description != 'null')) {
                    setReadDesc(EditorState.createWithContent(convertFromRaw(JSON.parse(history.location.state.file.description))))
                    setSendDesc(history.location.state.file.description)
                }
                if (history.location.state.file.photo) {
                    setPhotoDeleteRead(history.location.state.file.photo.split(','))
                }
            }
        }
    },[])

    useEffect(()=>{
        if (albumList) {
            albumList.map(album=>{
                if (album.name === data.name) {
                    setAlbumSelect(album)
                }
            })
        }
    },[albumList])

    const cancel = () => {
        history.goBack()
    }

    const saving = () => {
        if (data && albumSelect) {
            const dataSend = new FormData()
            photosSend.map((item) => {
                dataSend.append('photo', item)
            })
            photoDeleteRead.map((item) => {
                dataSend.append('imageOld', item)
            })
            photoDelete.map((item) => {
                dataSend.append('imageDelete', item)
            })
            dataSend.set('description', sendDesc)
            dataSend.set('name', albumSelect.name)
            dataSend.set('created', selectedDate)
            dataSend.set('month', month && month.month)
            Axios({
                method: 'PATCH',
                url: `${SERVER}/gallery/${data.id}`,
                data: dataSend,
                headers: {
                    token : localStorage.getItem('token'),
                }
            })
            .then(({data})=>{
                cancel()
            })
            .catch(err=>{
            })
        }
    }

    const readAlbum = (data) => {
        if (data) {
            Axios({
                method: 'GET',
                url: `${SERVER}/gallery/album/${data.id}`,
                headers: {
                    token : localStorage.getItem('token'),
                }
            })
            .then(({data})=>{
                setAlbumList(data)
            })
            .catch(err=>{
            })
        }
    }

    const onEditorStateChange = (event) => {
        let editorState = event; 
        let editorSourceHTML = JSON.stringify(convertToRaw(event.getCurrentContent()))
        setReadDesc(editorState)
        setSendDesc(editorSourceHTML)
    }

    const handlePlainText = (text) => {
        let flag = false
        if (text) {
            const currentContent = text.getCurrentContent();
            const currentContentLength = currentContent.getPlainText('').length;
            if (currentContentLength !== 0) {
                flag = true
            }
        }
        return flag
    }

    function deletePhotoJunk(index, name) {
        let temp = photoDeleteRead
        temp.splice(index, 1)
        setPhotoDeleteRead(temp)
        let temp2 = photoDelete
        temp2.push(name)
        setPhotoDelete(temp2)
        setFlag(!flag)
    }

    return (
        <>
            <Box className={classes.root}>
                <Box>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>ADD NEW GALLERY</Typography>
                </Box>
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Date"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                <Box display="flex" marginTop="2em">
                    <Box width={5 / 6} marginRight="1em">
                        <Autocomplete
                            id="combo-box-demo"
                            options={albumList}
                            size="small"
                            color="secondary"
                            fullWidth
                            value={albumSelect}
                            onChange={(event, newValue) => {
                                setAlbumSelect(newValue);
                            }}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} label="Select Album" color="secondary" variant="outlined" />}
                        />
                    </Box>
                    <Box width={1 / 6}>
                        <NewAlbum readAlbum={readAlbum} data={data}/>
                    </Box>
                </Box>
                <Box border="1px solid #DDDDDD" marginTop="1em" minHeight="38vh">
                    <Editor
                        editorState={readDesc}
                        editorClassName={classes.editor}
                        placeholder="Description"
                        onEditorStateChange={onEditorStateChange}
                    />
                </Box>
                <Box display="flex" position="relative" flexDirection="column" justifyContent="center" alignItems="center" border="1px solid #DDDDDD" marginTop="1em" minHeight="30vh">
                    <Box position="absolute" width="100%" height="100%" zIndex="99" style={{ opacity: "0" }}>
                        <DropzoneAreaBase
                            onAdd={(fileObjs) => addFiles(fileObjs)}
                            // onDelete={(fileObj) => console.log('Removed File:', fileObj)}
                            acceptedFiles={['image/jpeg', 'image/png', 'image/jpg']}
                            showAlerts={false}
                            dropzoneText=""
                            dropzoneClass={classes.dropZone}
                        />
                    </Box>
                    <Box marginY="1rem"><img src={UploadImg} style={{ width: "4rem", height: "4rem" }} /></Box>
                    <Typography style={{ color: '#999999' }}>Upload Image File Here (JPG/PNG)</Typography>

                    <Typography style={{ color: "#E23F81" }}>Choose Image</Typography>

                    <Box display="flex" alignItems="center" justifyContent="center" flexWrap="wrap" marginTop="1rem">
                        {
                            photoDeleteRead &&
                            photoDeleteRead.map((photo, index) => {
                                return (
                                    <Box key={index} width="15rem" height="15rem" marginLeft="1vw" marginRight="1vw" marginBottom="1vw" position="relative">
                                        <Box className={classes.boxDelete}><IconButton onClick={() => deletePhotoJunk(index, photo)}><CancelSharpIcon style={{ fontSize: "2rem", color: "#E23F81", cursor: "pointer" }} /></IconButton></Box>
                                        <img src={photo} alt="images" className="image-upload-content"
                                            style={{
                                                width: "100%",
                                                borderRadius: "4px"
                                            }} />
                                    </Box>
                                )
                            })

                        }
                        {
                            photos.map((photo, index) => {
                                return (
                                    <Box key={index} width="15rem" height="15rem" marginLeft="1vw" marginRight="1vw" marginBottom="1vw" position="relative">
                                        <Box className={classes.boxDelete}><IconButton onClick={() => deletePhoto(index)}><CancelSharpIcon style={{ fontSize: "2rem", color: "#E23F81", cursor: "pointer" }} /></IconButton></Box>
                                        <img src={photo} alt="images" className="image-upload-content"
                                            style={{
                                                width: "100%",
                                                borderRadius: "4px"
                                            }} />
                                    </Box>
                                )
                            })

                        }
                    </Box>
                </Box>
                <Box display="flex" alignItems="center" marginTop="1em">
                    <Button disabled={((photoDeleteRead.length !== 0 ? true : photos.length !== 0) && albumSelect && selectedDate && handlePlainText(readDesc)) ? false : true} onClick={saving} type="submit" variant="contained" color="secondary" style={{ backgroundColor: "#E23F81", textTransform: "none", boxShadow: "none", padding: "0.5em 3em" }}>Submit</Button>
                    <Button onClick={cancel} variant="outlined" color="secondary" style={{ textTransform: "none", padding: "0.5em 3em", marginLeft: "2em" }} >Cancel</Button>
                </Box>
            </Box>
        </>
    )
}