import {
    GET_COUNTRY
} from "../constanta";

const initialState = {
    countries : []
}

export default (state = initialState, action)=> {
    switch(action.type){
        case GET_COUNTRY:
            return {
                ...state,
                countries: action.data
            }
        default: {
            return state
        }
    }
}