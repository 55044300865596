import React, { useState, useEffect, Fragment } from 'react'
import { Box, Grid, makeStyles, FormControl, InputLabel, Select, MenuItem, withStyles, Button, IconButton } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import ListFile from '../../../../component/Report/Card/MontlyListFile'
import UploadBox from '../../../../component/Report/Card/MontlyUpload'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Axios from 'axios'
import { SERVER } from '../../../../../../server'
import Swal from 'sweetalert2'

const useStyles = makeStyles({
    root: {
        height: '100px',
        background: "white",
        padding: "1em 0em",
        boxShadow: "none"
    },
    content: {
        padding: "1em",
        marginTop: "2em",
        height: "85%",
        background: "white"
    },
    tabs: {
        textTransform: "none",
        fontSize: "1.5em"
    },

})

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export default function PIN(props) {
    const [value, setValue] = useState(0);
    const classes = useStyles();
    const [report, setReport] = useState([])
    const [monthNow, setMonthNow] = useState(null)
    const month = useSelector(state=> state.vitoState.monthly)
    const { year } = useParams()

    useEffect(()=>{
        if (month) {
            let monthNum
            switch (new Date().getMonth()) {
                case 0:
                    monthNum = 0
                    break;
                case 1:
                    monthNum = 1
                    break;
                case 2:
                    monthNum = 2
                    break;
                case 3:
                    monthNum = 3
                    break;
                case 4:
                    monthNum = 4
                    break;
                case 5:
                    monthNum = 5
                    break;
                case 6:
                    monthNum = 6
                    break;
                case 7:
                    monthNum = 7
                    break;
                case 8:
                    monthNum = 8
                    break;
                case 9:
                    monthNum = 9
                    break;
                case 10:
                    monthNum = 10
                    break;
                case 11:
                    monthNum = 11
                    break; 
                default:
                    break;
            }
            setValue(monthNum)
            month.map((mon, i)=>{
                if (i === monthNum) {
                    setMonthNow(mon)
                    getReport(mon)
                }
            })
        }
    },[month])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const changeMonth = (month) => {
        let monthNum
        switch (month.month) {
            case 'Januari':
                monthNum = 0
                break;
            case 'Februari':
                monthNum = 1
                break;
            case 'Maret':
                monthNum = 2
                break;
            case 'April':
                monthNum = 3
                break;
            case 'Mei':
                monthNum = 4
                break;
            case 'Juni':
                monthNum = 5
                break;
            case 'Juli':
                monthNum = 6
                break;
            case 'Agustus':
                monthNum = 7
                break;
            case 'September':
                monthNum = 8
                break;
            case 'Oktober':
                monthNum = 9
                break;
            case 'November':
                monthNum = 10
                break;
            case 'Desember':
                monthNum = 11
                break; 
            default:
                break;
        }
        if (monthNum != value) {
            setMonthNow(month)
            getReport(month)
        }
    }

    const getReport = (month) => {
        if (month) {
            Swal.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: () => {
                    Swal.showLoading()
                }
            })
            Axios({
                method: 'GET',
                url: `${SERVER}/report/monthly/${month.id}`,
                headers: {
                    token : localStorage.getItem('token'),
                }
            })
            .then(({data})=>{
                setReport(data)
                Swal.close()
            })
            .catch(err=>{
                Swal.close()
            })
        }
    }

    const changeMonthToEnglish = (month) => {
        let result
        switch(month){
            case 'Januari': result = "January";
                break;
            case 'Februari': result = "February";
                break;
            case 'Maret': result = "March";
                break;
            case 'April': result = "April";
                break;
            case 'Mei': result = "May";
                break;
            case 'Juni': result = "June"; 
                break;
            case 'Juli': result = "July";
                break;
            case 'Agustus': result = "August";
                break;
            case 'September': result = "September";
                break;
            case 'Oktober': result = "October";
                break;
            case 'November': result = "November";
                break;
            case 'Desember': result = "December";
                break;
        }
        return result
    }

    return (
        <Fragment>
            <Box style={{ backgroundColor: 'white', display: 'flex', justifyContent: 'center' }} className="appbar-WeeklyBox">
                {/* <Box height="15%" maxWidth="68vw"> */}
                    {/* <AppBar position="static" className={classes.root} color="default"> */}
                        <Tabs
                            className="appbar-WeeklyUptd"
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="on"
                            indicatorColor="secondary"
                            textColor="secondary"
                            aria-label="scrollable force tabs example"
                            fontSize="5rem"

                        >{
                            month &&
                                month.map((item,i) => (
                                <Tab key={i} onClick={e=>changeMonth(item)} label={
                                    <React.Fragment>
                                        <Box display="flex" flexDirection="column" alignItems="center" height="60px" justifyContent="center">
                                            <Typography style={{ fontSize: '20px' }} className={classes.tabs}>{item && changeMonthToEnglish(item.month)}</Typography>
                                            <Typography style={{ fontSize: '15px' }}>{year && year}</Typography>
                                        </Box>
                                    </React.Fragment>}>
                                </Tab>))
                            }
                        </Tabs>
                    {/* </AppBar> */}
                {/* </Box> */}
            </Box>
            <Box className="content-weekly" style={{ marginTop: '1em', padding: 0 }}>
                {
                    month &&
                    month.map((tab, index) => {
                        return (
                            <TabPanel value={value} key={index} index={index}>
                                {/* Item {tab} */}
                                {
                                    report &&
                                    report.length !== 0 ? <ListFile report={report} getReport={getReport} monthNow={monthNow}/> : <UploadBox monthNow={monthNow} getReport={getReport}/>
                                }
                            </TabPanel>)
                    })
                }
            </Box>
        </Fragment>
    )
}