import {
    GET_EVALUATION, GET_EVALUATIONONE, GET_EVALUATIONVITOONE
} from "../constanta";

const initialState = {
    userId: [],
    user: null,
    vito: null
}

export default (state = initialState, action)=> {
    switch(action.type){
        case GET_EVALUATION:
            return {
                ...state,
                userId: action.data
            }
        case GET_EVALUATIONONE:
            return {
                ...state,
                user: action.data
            }
        case GET_EVALUATIONVITOONE:
            return {
                ...state,
                vito: action.data
            }
        default: {
            return state
        }
    }
}