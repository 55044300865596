import React, { useState } from "react";
import "./styles.scss";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Swal from "sweetalert2";
import { useHistory } from 'react-router-dom'
import Divider from '@material-ui/core/Divider';
import { SERVER } from '../../../server'

const ButtonOk = withStyles((theme) => ({
  root: {
      width: '100%',
      height: '44px',
      color: '#ffffff',
      backgroundColor: '#E23F81',
      fontFamily: ['Roboto', 'sans-serif'],
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '18px',
      textAlign: 'center',
      lineHeight: '21px',
      boxSizing: 'border-box',
      borderRadius: '4px',
      boxShadow: 'none',
      textTransform: 'none',
      '&:hover': {
          backgroundColor: '#E23F81',
          boxShadow: 'none',
      },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: "18px",
      width: "539px",
      height: "44px",
    },
    buttonSend: {
      position: "absolute",
      width: "139px",
      height: "21px",
      left: "372px",
      top: "930px",

      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "18px",
      lineHeight: "21px",
      /* identical to box height */

      color: "#FFFFFF",
    },
    buttonExit: {
      position: "absolute",
      width: "42px",
      height: "21px",
      left: "613px",
      top: "930px",

      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "18px",
      lineHeight: "21px",
      /* identical to box height */

      color: "#E23F81",
    },
    iconInfo: {
      color: "#999999",
      boxSizing: "border-box",
    },
  },
}));

export default function ForgetPass() {
  const classes = useStyles();
  const History = useHistory();
  const [secret, setSecret] = useState(true)
  const [secretThree, setSecretThree] = useState(true)
  const [secretTwo, setSecretTwo] = useState(true)

  const sendPassword = () => {
    const SwalCustom = Swal.mixin({
      customClass: {
        confirmButton: 'btn-swal-wrong'
      },
      buttonsStyling: false
    })
    
    SwalCustom.fire({
      html:`
        <div class="modal-forget-wrapper" >
          <div class="modal-forget-wrapper-2" >
            <i class="fas fa-check fa-times-custom"></i>
          </div>
          <span class="modal-forget-wrapper-txt-header" >
          Password Berhasil Diganti
          </span>
          <span class="modal-forget-wrapper-txt" >
          Silahkan login dengan password yang baru
          </span>
        </div>
      `,
      confirmButtonColor: '#d33',
      confirmButtonColor: 'Login Sekarang',
      showCancelButton: false,
      showCloseButton: true,
      reverseButton: true
    }).then((result) => {
      if (result.value) {
      } else {
      }
    })
  }

  const sendFailedPassword = () => {
    const SwalCustom = Swal.mixin({
      customClass: {
        confirmButton: 'btn-swal-wrong'
      },
      buttonsStyling: false
    })
    
    SwalCustom.fire({
      html:`
        <div class="modal-forget-wrapper" >
          <div class="modal-forget-wrapper-2" >
            <i class="fas fa-times fa-times-custom"></i>
          </div>
          <span class="modal-forget-wrapper-txt-header" >
          Password Salah
          </span>
          <span class="modal-forget-wrapper-txt" >
          Jika lupa password, Anda bisa me-reset password melalui <a href="${SERVER}/forget-password" class="link-tautan">tautan ini.</a>
          </span>
        </div>
      `,
      confirmButtonColor: '#d33',
      confirmButtonColor: 'Coba Lagi',
      showCancelButton: false,
      showCloseButton: true,
      reverseButton: true
    }).then((result) => {
      if (result.value) {
      } else {
      }
    })
  }

  return (
    <div className="container-forgetpass-pages">
      <div className="container-forgetpass-box">
        <div className="container-white-forgotpass" >
          <div className="send-data-header-wrapper" >
            <span className="forgetpass-header-txt" >
            Ganti Password
            </span>
            <span className="forgetpass-header-txt-sm">
            Pastikan menginat password lama <br/>sebelum menggantinya
            </span>
          </div>
          <div className="input-newpass-wrapper" >
            <div className="input-send-data" style={{ marginTop: 30 }}>
              <input type={secret ? 'password' : 'text'} className="input-login-txt" style={{ padding: 0 }} placeholder="Password Saat ini"/>
              <div className="eye-secret-newpass" >
                {
                  secret ? 
                  <i className="fas fa-eye eye-custom-newpass-false " onClick={()=>setSecret(false)} />
                  :
                  <i className="fas fa-eye eye-custom-newpass-true " onClick={()=>setSecret(true)} />
                }
              </div>
            </div>
            <span className="forgetpass-txt-link" onClick={()=>History.push('/forget-password')}>Lupa Password?</span>
            <div className="input-send-data" style={{ marginTop: 20 }}>
              <input type={secretTwo ? 'password' : 'text'} className="input-login-txt" style={{ padding: 0 }} placeholder="Password Baru"/>
              <div className="eye-secret-newpass" >
                {
                  secretTwo ? 
                  <i className="fas fa-eye eye-custom-newpass-false " onClick={()=>setSecretTwo(false)} />
                  :
                  <i className="fas fa-eye eye-custom-newpass-true " onClick={()=>setSecretTwo(true)} />
                }
              </div>
            </div>
            <div className="input-send-data" style={{ marginTop: 30 }}>
              <input type={secretThree ? 'password' : 'text'} className="input-login-txt" style={{ padding: 0 }} placeholder="Ulangi Password Baru"/>
              <div className="eye-secret-newpass" >
                {
                  secretThree ? 
                  <i className="fas fa-eye eye-custom-newpass-false " onClick={()=>setSecretThree(false)} />
                  :
                  <i className="fas fa-eye eye-custom-newpass-true " onClick={()=>setSecretThree(true)} />
                }
              </div>
            </div>
          </div>
        </div>
        <div className="footer-send-data" >
          <ButtonOk onClick={()=>sendFailedPassword()} >Buat Password Baru</ButtonOk>
        </div>
      </div>
    </div>
  );
}
