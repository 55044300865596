import axios from 'axios'
import { SERVER } from '../../server'
import {
    GET_REQUESTDATA,
    IS_LOGOUT
} from "../constanta";

export const getRequestData = () => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            const { data } = await axios({
                method: 'GET',
                url: `${SERVER}/request-data`,
                headers: { token }
            })
            dispatch({
                type: GET_REQUESTDATA,
                data : data
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const updateStatusRequestData = (id, status) => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            await axios({
                method: 'patch',
                url: `${SERVER}/request-data/${id}`,
                data: { status },
                headers: { token }
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}