import { Box, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, makeStyles, MenuItem, OutlinedInput, Select, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import InfoIcon from '@material-ui/icons/Info';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SERVER } from '../../../../../../server'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useDispatch } from 'react-redux'
import { getVito } from '../../../../../../store/actions/vitoAction'

const imageSize = 140

const useStyle = makeStyles({
    root: {
        paddingTop: 50,
        paddingBottom: 50,
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "#F2F3F9",
    },
    profile: {
        background: "white",
        padding: "2em",
        borderRadius: "4px"
    },
    buttonUpload: {
        color: "#E23F81",
        border: "1px solid #E23F81",
        borderRadius: "4px",
        textTransform: "none"
    },
    icon: {
        fontSize: "10em",
        color: "#F2F3F9",
        borderRadius: imageSize/2,
        width: imageSize,
        height: imageSize,
        backgroundSize: 'cover',
        boxSizing: 'border-box'
    },
    buttonCancel: {
        marginLeft: "2em",
        color: "#E23F81",
        border: "1px solid #E23F81",
        borderRadius: "4px",
        textTransform: "none"
    },
    formControl: {
        marginTop: "1em"
    },
    inputFile: {
        display: "none"
    }
})

export default function Add() {
    const classes = useStyle()
    const Dispatch = useDispatch()
    const history = useHistory()
    const filterRole = useSelector(state=> state.filterState.roleVito)
    const countryArea = useSelector(state=> state.filterState.vitoCountryArea)
    const [showPassword, setShowPassword] = useState(false)
    const [vitoImg, setVitoImg] = useState(null)
    const [showUlangiPassword, setShowUlangiPassword] = useState(false)
    const [photo, setPhoto] = useState(null)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [companyAddress, setCompanyAddress] = useState('')
    const [phone, setPhone] = useState('')
    const [role, setRole] = useState('')
    const [country, setCountry] = useState('')
    const [area, setArea] = useState('')
    const [password, setPassword] = useState('')
    const [passwordRepeat, setPasswordRepeat] = useState('')

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    };

    const handleClickShowUlangiPassword = () => {
        setShowUlangiPassword(!showUlangiPassword)
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    function back() {
        history.push('/admin/vito')
    }

    const imagesHandler = (e) => {
        let image = e.target.files[0]
        setPhoto(e.target.files[0])
        let reader = new FileReader()
        if (image && image.type.match('image.*')) {
            reader.readAsDataURL(image)
            reader.onloadend = () => {
                setVitoImg(reader.result)
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            }
        }
    }

    const submit = (e) => {
        e.preventDefault()
        const formData = new FormData();
        formData.append('photo', photo)
        formData.set('name', name);
        formData.set('email', email);
        formData.set('phone', phone);
        formData.set('companyName', companyName);
        formData.set('companyAddress', companyAddress);
        formData.set('role', role);
        formData.set('country', country);
        formData.set('area', area);
        formData.set('password', password);
        formData.set('passwordRepeat', passwordRepeat);
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Swal.showLoading()
        axios({
            method: 'post',
            url: `${SERVER}/vito/created-vito`,
            data: formData,
            headers: {
                token : localStorage.getItem('token'),
            }
        })
        .then(({data})=>{
            back()
            // Dispatch(getVito())
            Swal.close()
        })
        .catch(err=>{
            if (err.response) {
                if (err.response.data) {
                    if (err.response.data.message) {
                        Toast.fire({
                            icon: 'error',
                            title: (err.response.data.message).includes('password kurang dari 8') ? 'password kurang dari 8 karakter' : err.response.data.message
                        })
                    }
                }
            }
        })
    }

    const deletePhos =()=>{
        setPhoto(null)
        setVitoImg(null)
    }

    return (
        <Box spacing={3} className={classes.root} >
            <Typography variant="h4"><b>Tambah VITO</b></Typography>
            <Box width="50%">
                <Box>
                    <Button style={{ color: "#E23F81" }} startIcon={<ArrowBackIcon />} onClick={back} >Kembali</Button>
                </Box>
                <Box className={classes.profile}>
                    <Box display="flex">
                        {!vitoImg?<AccountCircleIcon className={classes.icon}/>:<img src={vitoImg} className={classes.icon}/>}
                        <Box display="flex" flexDirection="column" padding="1em" justifyContent="center">
                            <Typography variant="h6" style={{ color: "#999999" }}>Cover/Photo</Typography>
                            <Box marginY="1em">
                                <input
                                    accept="image/*"
                                    id="contained-button-file"
                                    onChange={e=>imagesHandler(e)}
                                    type="file"
                                    className={classes.inputFile}
                                />
                                <div style={{ display: 'flex', flexDirection: 'row' }} >
                                    <label htmlFor="contained-button-file">
                                        <Button variant="outlined" size="small" className={classes.buttonUpload} component="span">Upload Foto</Button>
                                    </label>
                                    <div className="btn-dlete-phos-897" onClick={deletePhos} >
                                        <i className="fas fa-trash"></i>
                                    </div>
                                </div>
                            </Box>
                            <Typography variant="caption" style={{ color: "#999999" }}>Kosongkan jika tidak ada foto</Typography>
                        </Box>
                    </Box>
                    <Box marginX="1em">
                        <form>
                            <Box>
                                <Box marginTop="1em"><TextField name="nama" value={name} onChange={e=>setName(e.target.value)} id="outlined-basic" color="secondary" size="small" label="Nama Lengkap" variant="outlined" fullWidth /></Box>
                                <Box marginTop="1em"><TextField name="email" value={email} onChange={e=>setEmail(e.target.value)} id="outlined-basic" color="secondary" size="small" label="Email" variant="outlined" fullWidth />
                                    <Box display="flex" alignItems="center" marginTop="0.25em"><InfoIcon fontSize="small" style={{ color: "#999999" }} /><Box display="flex" alignItems="center" marginLeft="0.5em"><Typography variant="caption" style={{ color: "#999999" }}>Pastikan email yang didaftarkan aktif, karena akan menerima berbagai email penting</Typography></Box></Box></Box>
                                <Box marginTop="1em"><TextField name="noHP" value={phone} onChange={e=>setPhone(e.target.value)} id="outlined-basic" color="secondary" size="small" label="No. HP" variant="outlined" fullWidth /></Box>
                                <Box marginTop="1em"><TextField name="namaKantor" value={companyName} onChange={e=>setCompanyName(e.target.value)} id="outlined-basic" color="secondary" size="small" label="Nama Kantor" variant="outlined" fullWidth /></Box>
                                <Box marginTop="1em"><TextField name="alamatKantor" value={companyAddress} onChange={e=>setCompanyAddress(e.target.value)} id="outlined-basic" color="secondary" size="small" label="Alamat Kantor" variant="outlined" fullWidth /></Box>
                                <FormControl color="secondary" variant="outlined" fullWidth size="small" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Peran</InputLabel>
                                    <Select
                                        name="role"
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        label="Role"
                                        value={role}
                                        onChange={e=> setRole(e.target.value)}
                                    >
                                        {
                                            filterRole &&
                                            filterRole.map((role,i)=>(
                                                <MenuItem key={i} value={role.initial}>{ role.role }</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl color="secondary" variant="outlined" fullWidth size="small" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Negara</InputLabel>
                                    <Select
                                        name="negara"
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        label="Negara"
                                        value={country}
                                        onChange={e=> setCountry(e.target.value)}
                                    >
                                        {
                                            countryArea &&
                                            countryArea.map((area,i)=>(
                                                <MenuItem key={i} style={{ textTransform: 'uppercase' }} value={area.country}>{ area.country }</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl color="secondary" variant="outlined" fullWidth size="small" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">{!country ? "Pilih Negara" : "Vito Area"}</InputLabel>
                                    <Select
                                        name="vitoArea"
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        label={!country ? "Pilih Negara" : "Vito Area"}
                                        disabled={!country}
                                        value={area}
                                        onChange={e=> setArea(e.target.value)}
                                    >
                                        {
                                            (countryArea && country) &&
                                            countryArea
                                            .filter(area=> area.country === country )
                                            .map((area,i)=>(
                                                area.area &&
                                                area.area.length === 0 ?
                                                <MenuItem key={i} style={{ textTransform: 'uppercase' }} value={'Tidak Ada Area'}> Tidak Ada Area </MenuItem>
                                                :
                                                area.area &&
                                                area.area.map(value=>(
                                                    value ?
                                                    <MenuItem key={i} style={{ textTransform: 'uppercase' }} value={value}>{ value }</MenuItem>
                                                    :
                                                    <MenuItem key={i} style={{ textTransform: 'uppercase' }} value={'Tidak Ada Area'}> Tidak Ada Area </MenuItem>
                                                ))
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                <Box marginTop="1em"><FormControl fullWidth variant="outlined" size="small">
                                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                    <OutlinedInput
                                        name="password"
                                        fullWidth
                                        color="secondary"
                                        id="outlined-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        onChange={e=> setPassword(e.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        labelWidth={70}
                                    />
                                </FormControl></Box>
                                <Box marginTop="1em"><FormControl fullWidth variant="outlined" size="small">
                                    <InputLabel htmlFor="outlined-adornment-password">Ulangi Password</InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        name="ulangiPassword"
                                        color="secondary"
                                        id="outlined-adornment-password"
                                        type={showUlangiPassword ? 'text' : 'password'}
                                        value={passwordRepeat}
                                        onChange={e=> setPasswordRepeat(e.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowUlangiPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showUlangiPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        labelWidth={120}
                                    />
                                </FormControl></Box>
                            </Box>
                            <Box display="flex" alignItems="center" marginTop="1.5em">
                                <Button type="submit" variant="contained" onClick={e=>submit(e)} color="secondary" size="large" style={{ backgroundColor: "#E23F81", textTransform: "none" }}>Buat Akun</Button>
                                <Button variant="outlined" className={classes.buttonCancel} size="large" onClick={back}>Batal</Button>
                            </Box>

                        </form>
                    </Box>
                </Box>
            </Box>

        </Box>
    )
}
