import { Box, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, makeStyles, MenuItem, OutlinedInput, Select, TextField, Typography } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import InfoIcon from '@material-ui/icons/Info';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import logoWI from '../../../../images/bird.png'
import {useHistory} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { SERVER } from '../../../../../../server'
import axios from 'axios'
import Swal from 'sweetalert2'
import { getVito } from '../../../../../../store/actions/vitoAction'

const useStyle = makeStyles({
    root: {
        paddingTop: 50,
        paddingBottom: 50,
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "#F2F3F9",
    },
    profile: {
        background: "white",
        padding: "2em",
        borderRadius: "4px"
    },
    buttonUpload: {
        color: "#E23F81",
        border: "1px solid #E23F81",
        borderRadius: "4px",
        textTransform: "none"
    },
    buttonCancel: {
        marginLeft: "2em",
        color: "#E23F81",
        border: "1px solid #E23F81",
        borderRadius: "4px",
        textTransform: "none"
    },
    formControl: {
        marginTop: "1em"
    },
    circleIcon: {
        borderRadius: "50%",
        width: "10em",
        height: "10em",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "white",
        border: "2px solid #F1F1F1"
    },
    inputFile: {
        display: "none"
    }
})

export default function Edit({ match }) {
    const history = useHistory()
    const classes = useStyle()
    const Dispatch = useDispatch()
    const [photo, setPhoto] = useState(null)
    const [vitoImg, setVitoImg] = useState(null)
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [companyAddress, setCompanyAddress] = useState("")
    const [phone, setPhone] = useState("")
    const [role, setRole] = useState("")
    const [country, setCountry] = useState("")
    const [area, setArea] = useState("")
    const filterRole = useSelector(state=> state.filterState.roleVito)
    const countryArea = useSelector(state=> state.filterState.vitoCountryArea)
    const [deletePhoto, setDeletePhoto] = useState(false)
    
    function back () {
        history.goBack()
    }
    
    useEffect(()=>{
        Swal.showLoading()
        axios({
            method: 'GET',
            url: `${SERVER}/vito/${match.params.id}`,
            headers: { token : localStorage.getItem('token') }
        })
        .then(({ data })=>{
            setVitoImg(data.photo)
            setName(data.name)
            setEmail(data.email)
            setCompanyName(data.companyName)
            setCompanyAddress(data.companyAddress)
            setPhone(data.phone)
            setRole(data.role)
            setCountry(data.country)
            if (data.area) {
                setArea(data.area)
            } else {
                setArea('tidak ada area')
            }
            Swal.close()
        })
        .catch(err=>{
            console.log(err);
        })
    },[])

    const imagesHandler = (e) => {
        let image = e.target.files[0]
        setPhoto(e.target.files[0])
        let reader = new FileReader()
        if (image && image.type.match('image.*')) {
            reader.readAsDataURL(image)
            reader.onloadend = () => {
                setVitoImg(reader.result)
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            }
        }
    }

    const submit = (e) => {
        e.preventDefault()
        const formData = new FormData();
        formData.append('photo', photo)
        formData.set('name', name);
        formData.set('email', email);
        formData.set('phone', phone);
        formData.set('companyName', companyName);
        formData.set('companyAddress', companyAddress);
        formData.set('role', role);
        formData.set('country', country);
        formData.set('area', area);
        formData.set('deletePhoto', deletePhoto);
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        Swal.showLoading()
        axios({
            method: 'put',
            url: `${SERVER}/vito/${match.params.id}`,
            data: formData,
            headers: {
                token : localStorage.getItem('token'),
            }
        })
        .then(({data})=>{
            history.replace('/admin/vito')
            // Dispatch(getVito())
            Swal.close()
        })
        .catch(err=>{
            if (err.response) {
                if (err.response.data) {
                    if (err.response.data.message) {
                        Toast.fire({
                            icon: 'error',
                            title: err.response.data.message
                        })
                    }
                }
            }
        })
    }

    const deletePhos =()=>{
        setDeletePhoto(true)
        setPhoto(null)
        setVitoImg(null)
    }

    return (
        <Box spacing={3} className={classes.root} >
            <Typography variant="h4"><b>Edit Profil VITO</b></Typography>
            <Box width="50%">
                <Box>
                    <Button style={{ color: "#E23F81" }} startIcon={<ArrowBackIcon />} onClick={back} >Kembali</Button>
                </Box>
                <Box className={classes.profile}>
                    <Box display="flex">
                        <Box className={classes.circleIcon}>
                            {
                                vitoImg ?
                                <img style={{ borderRadius: '50%' }} src={vitoImg} width="100%" height="100%"/>
                                :
                                <img src={logoWI} width="100%" height="100%" />
                            }
                        </Box>
                        <Box display="flex" flexDirection="column" padding="1em" justifyContent="center">
                            <Typography variant="h6" style={{ color: "#999999" }}>Cover/Photo</Typography>
                            <Box marginY="1em">
                                <input
                                    accept="image/*"
                                    id="contained-button-file"
                                    multiple
                                    type="file"
                                    className={classes.inputFile}
                                    onChange={e=>imagesHandler(e)}
                                />
                                <div style={{ display: 'flex', flexDirection: 'row' }} >
                                    <label htmlFor="contained-button-file">
                                        <Button variant="outlined" size="small" className={classes.buttonUpload} component="span">Upload Foto</Button>
                                    </label>
                                    <div className="btn-dlete-phos-897" onClick={deletePhos} >
                                        <i className="fas fa-trash"></i>
                                    </div>
                                </div>
                            </Box>
                            <Typography variant="caption" style={{ color: "#999999" }}>Kosongkan jika tidak ada foto</Typography>
                        </Box>
                    </Box>

                    <Box marginX="1em">
                        <form>
                            <Box>
                                <Box marginTop="1em">
                                    <TextField id="outlined-basic" label="Nama Lengkap" style={{ marginTop: 2 }} value={name} onChange={e=>setName(e.target.value)} color="secondary" size="small"  variant="outlined" fullWidth />
                                </Box>
                                <Box marginTop="1em">
                                    <TextField id="outlined-basic" label="Email" style={{ marginTop: 2 }} value={email} onChange={e=>setEmail(e.target.value)} color="secondary" size="small" variant="outlined" fullWidth />
                                    <Box display="flex" alignItems="center" marginTop="0.25em">
                                        <InfoIcon fontSize="small" style={{ color: "#999999" }} />
                                        <Box display="flex" alignItems="center" marginLeft="0.5em">
                                            <Typography variant="caption" style={{ color: "#999999" }}>Pastikan email yang didaftarkan aktif, karena akan menerima berbagai email penting</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box marginTop="1em">
                                    <TextField id="outlined-basic" label="No. HP" style={{ marginTop: 2 }} value={phone} onChange={e=>setPhone(e.target.value)} color="secondary" size="small" variant="outlined" fullWidth />
                                </Box>
                                <Box marginTop="1em">
                                    <TextField id="outlined-basic" label="Nama Kantor" style={{ marginTop: 2 }} value={companyName} onChange={e=>setCompanyName(e.target.value)} color="secondary" size="small" variant="outlined" fullWidth />
                                </Box>
                                <Box marginTop="1em">
                                    <TextField id="outlined-basic" label="Alamat Kantor" style={{ marginTop: 2 }} value={companyAddress} onChange={e=>setCompanyAddress(e.target.value)} color="secondary" size="small"  variant="outlined" fullWidth />
                                </Box>
                                <FormControl color="secondary" variant="outlined" fullWidth size="small" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Peran</InputLabel>
                                    <Select
                                        style={{ marginTop: 2 }}
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        label="Role"
                                        value={role}
                                        onChange={e=> setRole(e.target.value)}
                                    >
                                        {
                                            filterRole &&
                                            filterRole.map((role,i)=>(
                                                <MenuItem key={i} value={role.initial}>{ role.role }</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl color="secondary" variant="outlined" fullWidth size="small" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Negara</InputLabel>
                                    <Select
                                        style={{ marginTop: 2, textTransform: 'uppercase' }}
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={country}
                                        label="Negara"
                                        onChange={e=> setCountry(e.target.value)}
                                    >
                                        {
                                            countryArea &&
                                            countryArea.map((area,i)=>(
                                                <MenuItem key={i} style={{ textTransform: 'uppercase' }} value={area.country}>{ area.country }</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl color="secondary" variant="outlined" fullWidth size="small" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">{!country ? "Pilih Negara" : "Area VITO"}</InputLabel>
                                    <Select
                                        style={{ marginTop: 2, textTransform: 'uppercase' }}
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        disabled={!country}
                                        label={!country ? "Pilih Negara" : "Vito Area"}
                                        value={area}
                                        onChange={e=> setArea(e.target.value)}
                                    >
                                        {
                                            (countryArea && country) &&
                                            countryArea
                                            .filter(area=> area.country === country )
                                            .map((area,i)=>(
                                                area.area &&
                                                area.area.length === 0 ?
                                                    <MenuItem key={i} style={{ textTransform: 'uppercase' }} value={'tidak ada area'}> Tidak Ada Area </MenuItem>
                                                :
                                                area.area &&
                                                area.area.map(value=>(
                                                    <MenuItem key={i} style={{ textTransform: 'uppercase' }} value={value ? (value).toLowerCase() : 'tidak ada area' }>{ value ? value : 'Tidak Ada Area' }</MenuItem>
                                                ))
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box display="flex" alignItems="center" marginTop="1.5em">
                                <Button type="submit" variant="contained" onClick={e=>submit(e)} color="secondary" size="large" style={{ backgroundColor: "#E23F81", textTransform: "none" }}>Simpan</Button>
                                <Button variant="outlined" className={classes.buttonCancel} onClick={back} size="large">Batal</Button>
                            </Box>

                        </form>
                    </Box>
                </Box>
            </Box>

        </Box>
    )
}
