import { Box, Button, IconButton, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

export default function Index(props) {

    const changeMonthToEnglish = (month) => {
        let result
        switch(month){
            case 'Januari': result = "January";
                break;
            case 'Februari': result = "February";
                break;
            case 'Maret': result = "March";
                break;
            case 'April': result = "April";
                break;
            case 'Mei': result = "May";
                break;
            case 'Juni': result = "June"; 
                break;
            case 'Juli': result = "July";
                break;
            case 'Agustus': result = "August";
                break;
            case 'September': result = "September";
                break;
            case 'Oktober': result = "October";
                break;
            case 'November': result = "November";
                break;
            case 'Desember': result = "December";
                break;
        }
        return result
    }

    return (
        <>
            <Box width="22rem" position="absolute" top="3rem" paddingTop="0.5rem" zIndex="999" style={{background: "white"}} boxShadow={1}>
                <Box paddingX="2rem" display="flex" justifyContent="space-between" alignItems="center">
                    <IconButton disabled={props.inputYear == props.years[0]?true:false} display="flex" alignItems="center" justifyContent="center" size="small" onClick={props.beforeYear}>
                        <ArrowBackIosIcon />
                    </IconButton>
                    <Typography style={{ fontWeight: 'bolder', color: '#e35981' }} >{props.inputYear}</Typography>
                    <IconButton disabled={props.inputYear == props.years[props.years.length-1]?true:false} size="small" onClick={props.nextYear}>
                        <ArrowForwardIosIcon />
                    </IconButton>
                </Box>
                <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent="center">
                    {
                        props &&
                        props.months &&
                        props.months.map((month, index) => {
                            return (
                                <Box key={index} padding="0.5rem">
                                    {props.inputMonth == month? <Button color="secondary" variant="contained" style={{background: "#E23F81"}}>{changeMonthToEnglish(month)}</Button>:<Button onClick={(e)=>props.changeMonth(e, month)}>{changeMonthToEnglish(month)}</Button>}
                                </Box>
                            )
                        })
                    }
                </Box>
            </Box>
        </>
    )
}
