import { Box, FormControl, FormControlLabel, makeStyles, Radio, RadioGroup, Typography, TextField, Button, Select, MenuItem, InputLabel } from '@material-ui/core';
import React, { useState } from 'react'


const useStyles = makeStyles({
    box: {
        padding: "1em 2em",
        borderBottom: "0.5px solid #DDDDDD"
    },
    buttonRead: {
        background: "#E23F81",
        borderRadius: "4px",
        border: "none",
        padding: "1em 2em"
    },
});

export default function PIN(props) {
    const classes = useStyles();
    const [flag, setFlag] = useState(false)
    const [indikator, setIndikator] = useState([""])
    const [kategori, setKategori] = useState([''])
    const [nama, setNama] = useState('')

    function changeNama (event) {
        setNama(event.target.value)
    }
    
    function changeKategori (event, index) {
        let kat = kategori
        kat[index] = event.target.value
        setFlag(!flag)
        setKategori(kat)
    }

    function changeIndikator (event, index) {
        let ind = indikator
        ind[index] = event.target.value
        setFlag(!flag)
        setIndikator(ind)
    }

    function deleteKategori(index) {
        let newKategori = kategori
        newKategori.splice(index, 1)
        setFlag(!flag)
        setKategori(newKategori)
    }

    function deleteIndikator(index) {
        let newIndikator = indikator
        newIndikator.splice(index, 1)
        setFlag(!flag)
        setIndikator(newIndikator)
    }

    function addKategori() {
        let newKategori = kategori
        newKategori.push('')
        setFlag(!flag)
        setKategori(newKategori)
    }

    function addIndikator() {
        let newIndikator = indikator
        newIndikator.push("")
        setFlag(!flag)
        setIndikator(newIndikator)
    }

    return (
        <>
            <Box className={classes.box}>
                <Typography variant="h6">Nama VITO</Typography>
                {/* <Box marginTop="1em"><TextField name="nama" value={nama} onChange={changeNama} color="secondary" label="Pilih VITO" variant="outlined" fullWidth /></Box> */}
                <FormControl variant="outlined" color="secondary" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">Pilih VITO</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={nama}
                        onChange={changeNama}
                        label="Pilih VITO"
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box className={classes.box}>
                <Typography variant="h6">Kategori</Typography>
                {
                    kategori.map((item, index) => {
                        return (
                            <Box marginTop="1em" display="flex" key={index}><Box width={5 / 6}>
                                <TextField name="kategori" value={item} onChange={(event) => changeKategori(event, index)} id="outlined-basic" color="secondary" label="Tulis Kategori" variant="outlined" fullWidth />
                            </Box><Box marginLeft="1em" width={1 / 6}>{index == (kategori.length - 1) ? <Button onClick={() => addKategori()} variant="outlined" size="large" color="secondary" style={{ background: "#FCECF2", height: "100%", textTransform: "none" }} fullWidth>Tambah Kategori</Button> : <Button variant="outlined" color="secondary" size="large" style={{ height: "100%", textTransform: "none" }} fullWidth onClick={() => deleteKategori(index)}>Hapus</Button>}</Box></Box>
                        )
                    })
                }

            </Box>
            <Box className={classes.box}>
                <Typography variant="h6">Indikator</Typography>
                {
                    indikator.map((item, index) => {
                        return (
                            <Box marginTop="1em" display="flex" key={index}><Box width={5 / 6}>
                                <TextField name="kategori" value={item} onChange={(event) => changeIndikator(event, index)} id="outlined-basic" color="secondary" label="Tulis Indikator" variant="outlined" fullWidth />
                            </Box><Box marginLeft="1em" width={1 / 6}>{index == (indikator.length - 1) ? <Button onClick={() => addIndikator()} variant="outlined" size="large" color="secondary" style={{ background: "#FCECF2", height: "100%", textTransform: "none" }} fullWidth>Tambah Indikator</Button> : <Button variant="outlined" color="secondary" size="large" style={{ height: "100%", textTransform: "none" }} fullWidth onClick={() => deleteIndikator(index)}>Hapus</Button>}</Box></Box>
                        )
                    })
                }

            </Box>
           
            <Box padding="1em 2em">
                <Button variant="contained" color="secondary" className={classes.buttonRead} >Simpan</Button>
            </Box>

        </>
    )
}