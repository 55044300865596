const initialState = {
    roleAdmin : [
        { role : 'Penanggung Jawab Pasar', initial : 'picpasar' },
        { role : 'Super Admin', initial : 'admin' },
        { role : 'Markom', initial : 'markom' },
        { role : 'Deputi', initial : 'deputi' },
        { role : 'Direktorat', initial : 'direktorat' },
        { role : 'Tim Koordinator VITO', initial : 'koordinatorvito' },
        { role : 'Tamu', initial : 'guest' },
    ],
    vitoCountryArea:[
        { country: 'singapore', area: ['Wonderful Indonesia Tourism Office', ''] },
        { country: 'malaysia', area: [] },
        { country: 'thailand', area: [] },
        { country: 'australia', area: ['Executive VITO', 'SYDNEY', 'MELBOURNE', ''] },
        { country: 'china', area: ['Guangzhou', 'Hong Kong & Macau', 'Beijing', 'Shanghai', ''] },
        { country: 'south korea', area: [] },
        { country: 'japan', area: [] },
        { country: 'india', area: ['New Delhi', 'Mumbai', ''] },
        { country: 'saudi arabia', area: [] },
        { country: 'united arab emirates', area: ['Middle East','Halal Tourism',''] },
        { country: 'united kingdom', area: [] },
        { country: 'france', area: [] },
        { country: 'netherlands', area: [] },
        { country: 'germany', area: [] },
        { country: 'russia', area: [] },
        { country: 'cruise tourism', area: ['Cruise Tourism',''] },
    ],
    roleVito:[
        { role : 'VITO', initial : 'vito' }
    ],
    years: [
        {
            id : 4,
            year: 2022
        }
    ],
    coordinatorWeekly:[
        {
            id : 2022,
            year: 2022,
            month: [
                {
                    id: 20224,
                    month: 'april',
                    weekId: [
                        { id: 202241 },
                        { id: 202242 },
                    ]
                },
                {
                    id: 20225,
                    month: 'mei',
                    weekId: [
                        { id: 202251 },
                        { id: 202252 },
                    ]
                },
                {
                    id: 20226,
                    month: 'juni',
                    weekId: [
                        { id: 202261 },
                        { id: 202262 },
                    ]
                },
                {
                    id: 20227,
                    month: 'juli',
                    weekId: [
                        { id: 202271 },
                        { id: 202272 },
                    ]
                },
                {
                    id: 20228,
                    month: 'agustus',
                    weekId: [
                        { id: 202281 },
                        { id: 202282 },
                    ]
                },
                {
                    id: 20229,
                    month: 'september',
                    weekId: [
                        { id: 202291 },
                        { id: 202292 },
                    ]
                },
                {
                    id: 202210,
                    month: 'oktober',
                    weekId: [
                        { id: 2022101 },
                        { id: 2022102 },
                    ]
                },
                {
                    id: 202211,
                    month: 'november',
                    weekId: [
                        { id: 2022111 },
                        { id: 2022112 },
                    ]
                },
                {
                    id: 202212,
                    month: 'desember',
                    weekId: [
                        { id: 2022121 },
                        { id: 2022122 },
                    ]
                }
            ]
        }
    ],
    coordinatorQuarterly:[
        {
            id : 2022,
            year: 2022,
            month: [
                {
                    id: 20226,
                    month: 'juni',
                },
                {
                    id: 20229,
                    month: 'september',
                },
                {
                    id: 202212,
                    month: 'desember',
                }
            ]
        }
    ]
}

export default (state = initialState, action)=> {
    switch(action.type){
        default: {
            return state
        }
    }
}