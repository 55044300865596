import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import './styles.scss'
import Axios from 'axios'
import { SERVER } from '../../../../server'
import { getWeekly } from '../../../../store/actions/vitoAction'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw, ContentState, convertFromHTML } from 'draft-js';

function useQuery(){
    return new URLSearchParams(useLocation().search)
}

export default function Reader (){
    const history = useHistory()
    const dispatch = useDispatch()
    const query = useQuery()
    const [week, setWeek] = useState(null)
    const [weekId, setWeekId] = useState(null)
    const [data, setData] = useState(null)
    const [user, setUser] = useState(null)
    const weeklyList = useSelector(state=> state.vitoState.weekly)
    const filterYears = useSelector(state=> state.filterState.years)

    useEffect(()=>{
        setWeekId(query.get('identity'));        
    },[])

    const handleWeek = () => {
        let weekFix
        if (weeklyList) {
            weeklyList.forEach((week)=>{
                if (week.id == weekId) {
                    weekFix = week
                    return
                }
            })
        }
        setWeek(weekFix)
    }

    useEffect(()=>{
        let year
        if (filterYears) {
            if (query.get('year') > 2025) {
                year = filterYears.filter(e=> e.year === 2025)[0].id
            }
            if (query.get('year') < 2019) {
                year = filterYears.filter(e=> e.year === 2019)[0].id
            }
            if (query.get('year') <= 2025 && query.get('year') >= 2019) {
                year = filterYears.filter(e=> e.year == query.get('year'))[0].id
            }
        }
        dispatch(getWeekly(year))
    },[filterYears])

    useEffect(()=>{
        if (weekId) {
            Axios({
                method: 'GET',
                url: `${SERVER}/report/document/${weekId}/${query.get('vito')}`,
                headers: {
                    token : localStorage.getItem('token'),
                }
            })
            .then(({data})=>{
                if (data) {
                    setUser(data.user)
                    let category1 = []
                    let category2 = []
                    let category3 = []
                    data.data
                    .sort((a, b) => a.createdAt < b.createdAt ? -1 : 1)
                    .map(dt=>{
                        if (dt.CategoryId == '1') {
                            category1.push(dt)
                        } else if (dt.CategoryId == '2') {
                            category2.push(dt)
                        } else if (dt.CategoryId == '3') {
                            category3.push(dt)
                        }
                    })
                    setData([category1, category2, category3])
                    window.print()
                }
            })
            .catch(err=>{
            })
        }
    },[weekId])

    useEffect(()=>{
        handleWeek()
    },[weeklyList])
    
    const showDateWeek = (dateParams) => {
        if (dateParams) {
            const [day, month, year] = dateParams.split("/")
            let date = new Date(year, month - 1, day).getDate() 
            let monthNumber = new Date(year, month - 1, day).getMonth()
            let monthResult = ''
            let yearResult = new Date(year, month - 1, day).getFullYear()
            switch (monthNumber) {
                case 0 :
                    monthResult = 'January'
                    break;
                case 1 :
                    monthResult = 'February'
                    break;
                case 2 :
                    monthResult = 'March'
                    break;
                case 3 :
                    monthResult = 'April'
                    break;
                case 4 :
                    monthResult = 'May'
                    break;
                case 5 :
                    monthResult = 'June'
                    break;
                case 6 :
                    monthResult = 'July'
                    break;
                case 7 :
                    monthResult = 'August'
                    break;
                case 8 :
                    monthResult = 'September'
                    break;
                case 9 :
                    monthResult = 'October'
                    break;
                case 10 :
                    monthResult = 'November'
                    break;
                case 11 :
                    monthResult = 'December'
                    break;
                default:
                    monthResult = ''
                    break;
            }
            return date + ' ' + monthResult  + ' ' + yearResult
        }
    }

    const showDate = (dateParams) => {
        if (dateParams) {
            let start = new Date(dateParams).getDate()
            let startMonth = new Date(dateParams).getMonth()
            let monthStart = ''
            let yearResult = new Date(dateParams).getFullYear()
            switch (startMonth) {
                case 0 :
                    monthStart = 'January'
                    break;
                case 1 :
                    monthStart = 'February'
                    break;
                case 2 :
                    monthStart = 'March'
                    break;
                case 3 :
                    monthStart = 'April'
                    break;
                case 4 :
                    monthStart = 'May'
                    break;
                case 5 :
                    monthStart = 'June'
                    break;
                case 6 :
                    monthStart = 'July'
                    break;
                case 7 :
                    monthStart = 'August'
                    break;
                case 8 :
                    monthStart = 'September'
                    break;
                case 9 :
                    monthStart = 'October'
                    break;
                case 10 :
                    monthStart = 'November'
                    break;
                case 11 :
                    monthStart = 'December'
                    break;
                default:
                    monthStart = ''
                    break;
            }
            let hour = new Date(dateParams).getHours()
            let minute = new Date(dateParams).getMinutes()
            let second = new Date(dateParams).getSeconds()
            
            return start + ' '+ monthStart +' '+ yearResult +' '+ (hour < 10 ? '0'+hour : hour) +':'+(minute < 10 ? '0'+minute : minute)+':'+(second < 10 ? '0'+second : second)
        }
    }

    return(
        <div className="container-pdf" >
            <span style={{ textTransform: 'uppercase' }} >{user && user.country} - {user && user.area ? user.area === 'tidak ada area' ? 'No Area' : user.area : '-'} </span> | {user && user.name} | Week {week && week.week} ({showDateWeek(week && week.start)} - {showDateWeek(week && week.end)})
            <h2 className="category-pdf-txt" > 1. NETWORKING & PARTNERSHIP </h2>
            {
                data && data[0] &&
                data[0]
                .filter(a=> (!a.category || a.category == 'null') )
                .map((a, i)=>(
                    (a.status === 'workplan' || a.status === 'report') ?
                    <div key={i} className="box-indicator-txt boxdesign" >
                        <h4 className="header-styl-pdf" >
                            <strong> a {i+=1}. Workplan: </strong> {a && a.description ? a.description : '-'}
                        </h4>
                        <div className="contain-styl-pdf" >
                            <span > Report: </span>
                            <Editor
                                editorState={a && ((a.report != 'null') && (a.report)) ?
                                    EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                    :
                                    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                }
                                toolbarHidden
                                readOnly
                                toolbar={false}
                            />
                            <span style={{ fontWeight: 'normal', fontSize: 12, marginTop: 5 }} >
                                created: { showDate(a && a.createdAt)}
                            </span>
                        </div>
                    </div>
                    :
                    a.status === "onlyreport" &&
                    <div key={i} className="box-indicator-txt boxdesign" style={{ marginTop: 10 }} >
                        <h4 className="header-styl-pdf" >
                            <strong> a {i+=1}. Report without Workplan: </strong>
                        </h4>
                        <div style={{ marginTop: 0 }} className="contain-styl-pdf" >
                            <Editor
                                editorState={a && ((a.report != 'null') && (a.report)) ?
                                    EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                    :
                                    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                }
                                toolbarHidden
                                readOnly
                                toolbar={false}
                                editorClassName="box-content-txt-1231"
                            />
                            <span style={{ fontWeight: 'normal', fontSize: 12, marginTop: 5 }} >
                                created: { showDate(a && a.createdAt)}
                            </span>
                        </div>
                    </div>
                ))
            }

            <h3> A. Partnership with Academics </h3>
            {
                data && data[0] &&
                data[0]
                .filter(a=> (a.category).includes('Partnership with Academics') )
                .map((a, i)=>(
                    (a.status === 'workplan' || a.status === 'report') ?
                    <div key={i} className="box-indicator-txt boxdesign" >
                        <h4 className="header-styl-pdf" >
                            <strong> a {i+=1}. Workplan: </strong> {a && a.description ? a.description : '-'}
                        </h4>
                        <div className="contain-styl-pdf" >
                            <span > Report: </span>
                            <Editor
                                editorState={a && ((a.report != 'null') && (a.report)) ?
                                    EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                    :
                                    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                }
                                toolbarHidden
                                readOnly
                                toolbar={false}
                            />
                            <span style={{ fontWeight: 'normal', fontSize: 12, marginTop: 5 }} >
                                created: { showDate(a && a.createdAt)}
                            </span>
                        </div>
                    </div>
                    :
                    a.status === "onlyreport" &&
                    <div key={i} className="box-indicator-txt boxdesign" style={{ marginTop: 10 }} >
                        <h4 className="header-styl-pdf" >
                            <strong> a {i+=1}. Report without Workplan: </strong>
                        </h4>
                        <div style={{ marginTop: 0 }} className="contain-styl-pdf" >
                            <Editor
                                editorState={a && ((a.report != 'null') && (a.report)) ?
                                    EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                    :
                                    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                }
                                toolbarHidden
                                readOnly
                                toolbar={false}
                                editorClassName="box-content-txt-1231"
                            />
                            <span style={{ fontWeight: 'normal', fontSize: 12, marginTop: 5 }} >
                                created: { showDate(a && a.createdAt)}
                            </span>
                        </div>
                    </div>
                ))
            }

            <h3> B. Partnership with Business (TA/TO, Airlines, Accommodation, and others) </h3>
            {
                data && data[0] &&
                data[0]
                .filter(a=> (a.category).includes('Partnership with Business (TA/TO, Airlines, Accommodation, and others)') )
                .map((a, i)=>(
                    (a.status === 'workplan' || a.status === 'report') ?
                    <div key={i} className="box-indicator-txt boxdesign" >
                        <h4 className="header-styl-pdf" >
                            <strong> b {i+=1}. Workplan: </strong> {a && a.description ? a.description : '-'}
                        </h4>
                        <div className="contain-styl-pdf" >
                            <span > Report: </span>
                            <Editor
                                editorState={a && ((a.report != 'null') && (a.report)) ?
                                    EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                    :
                                    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                }
                                toolbarHidden
                                readOnly
                                toolbar={false}
                            />
                            <span style={{ fontWeight: 'normal', fontSize: 12, marginTop: 5 }} >
                                created: { showDate(a && a.createdAt)}
                            </span>
                        </div>
                    </div>
                    :
                    a.status === "onlyreport" &&
                    <div key={i} className="box-indicator-txt boxdesign" style={{ marginTop: 10 }} >
                        <h4 className="header-styl-pdf" >
                            <strong> b {i+=1}. Report without Workplan: </strong>
                        </h4>
                        <div style={{ marginTop: 0 }} className="contain-styl-pdf" >
                            <Editor
                                editorState={a && ((a.report != 'null') && (a.report)) ?
                                    EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                    :
                                    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                }
                                toolbarHidden
                                readOnly
                                toolbar={false}
                                editorClassName="box-content-txt-1231"
                            />
                            <span style={{ fontWeight: 'normal', fontSize: 12, marginTop: 5 }} >
                                created: { showDate(a && a.createdAt)}
                            </span>
                        </div>
                    </div>
                ))
            }

            <h3> C. Partnership with Communities </h3>
            {
                data && data[0] &&
                data[0]
                .filter(a=> (a.category).includes('Partnership with Communities') )
                .map((a, i)=>(
                    (a.status === 'workplan' || a.status === 'report') ?
                    <div key={i} className="box-indicator-txt boxdesign" >
                        <h4 className="header-styl-pdf" >
                            <strong> c {i+=1}. Workplan: </strong> {a && a.description ? a.description : '-'}
                        </h4>
                        <div className="contain-styl-pdf" >
                            <span > Report: </span>
                            <Editor
                                editorState={a && ((a.report != 'null') && (a.report)) ?
                                    EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                    :
                                    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                }
                                toolbarHidden
                                readOnly
                                toolbar={false}
                            />
                            <span style={{ fontWeight: 'normal', fontSize: 12, marginTop: 5 }} >
                                created: { showDate(a && a.createdAt)}
                            </span>
                        </div>
                    </div>
                    :
                    a.status === "onlyreport" &&
                    <div key={i} className="box-indicator-txt boxdesign" style={{ marginTop: 10 }} >
                        <h4 className="header-styl-pdf" >
                            <strong> c {i+=1}. Report without Workplan: </strong>
                        </h4>
                        <div style={{ marginTop: 0 }} className="contain-styl-pdf" >
                            <Editor
                                editorState={a && ((a.report != 'null') && (a.report)) ?
                                    EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                    :
                                    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                }
                                toolbarHidden
                                readOnly
                                toolbar={false}
                                editorClassName="box-content-txt-1231"
                            />
                            <span style={{ fontWeight: 'normal', fontSize: 12, marginTop: 5 }} >
                                created: { showDate(a && a.createdAt)}
                            </span>
                        </div>
                    </div>
                ))
            }

            <h3> D. Partnership with Governments </h3>
            {
                data && data[0] &&
                data[0]
                .filter(a=> (a.category).includes('Partnership with Governments') )
                .map((a, i)=>(
                    (a.status === 'workplan' || a.status === 'report') ?
                    <div key={i} className="box-indicator-txt boxdesign" >
                        <h4 className="header-styl-pdf" >
                            <strong> d {i+=1}. Workplan: </strong> {a && a.description ? a.description : '-'}
                        </h4>
                        <div className="contain-styl-pdf" >
                            <span > Report: </span>
                            <Editor
                                editorState={a && ((a.report != 'null') && (a.report)) ?
                                    EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                    :
                                    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                }
                                toolbarHidden
                                readOnly
                                toolbar={false}
                            />
                            <span style={{ fontWeight: 'normal', fontSize: 12, marginTop: 5 }} >
                                created: { showDate(a && a.createdAt)}
                            </span>
                        </div>
                    </div>
                    :
                    a.status === "onlyreport" &&
                    <div key={i} className="box-indicator-txt boxdesign" style={{ marginTop: 10 }} >
                        <h4 className="header-styl-pdf" >
                            <strong> d {i+=1}. Report without Workplan: </strong>
                        </h4>
                        <div style={{ marginTop: 0 }} className="contain-styl-pdf" >
                            <Editor
                                editorState={a && ((a.report != 'null') && (a.report)) ?
                                    EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                    :
                                    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                }
                                toolbarHidden
                                readOnly
                                toolbar={false}
                                editorClassName="box-content-txt-1231"
                            />
                            <span style={{ fontWeight: 'normal', fontSize: 12, marginTop: 5 }} >
                                created: { showDate(a && a.createdAt)}
                            </span>
                        </div>
                    </div>
                ))
            }

            <h3> E. Partnership with Media </h3>
            {
                data && data[0] &&
                data[0]
                .filter(a=> (a.category).includes('Partnership with Media') )
                .map((a, i)=>(
                    (a.status === 'workplan' || a.status === 'report') ?
                    <div key={i} className="box-indicator-txt boxdesign" >
                        <h4 className="header-styl-pdf" >
                            <strong> d {i+=1}. Workplan: </strong> {a && a.description ? a.description : '-'}
                        </h4>
                        <div className="contain-styl-pdf" >
                            <span > Report: </span>
                            <Editor
                                editorState={a && ((a.report != 'null') && (a.report)) ?
                                    EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                    :
                                    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                }
                                toolbarHidden
                                readOnly
                                toolbar={false}
                            />
                            <span style={{ fontWeight: 'normal', fontSize: 12, marginTop: 5 }} >
                                created: { showDate(a && a.createdAt)}
                            </span>
                        </div>
                    </div>
                    :
                    a.status === "onlyreport" &&
                    <div key={i} className="box-indicator-txt boxdesign" style={{ marginTop: 10 }} >
                        <h4 className="header-styl-pdf" >
                            <strong> d {i+=1}. Report without Workplan: </strong>
                        </h4>
                        <div style={{ marginTop: 0 }} className="contain-styl-pdf" >
                            <Editor
                                editorState={a && ((a.report != 'null') && (a.report)) ?
                                    EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                    :
                                    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                }
                                toolbarHidden
                                readOnly
                                toolbar={false}
                                editorClassName="box-content-txt-1231"
                            />
                            <span style={{ fontWeight: 'normal', fontSize: 12, marginTop: 5 }} >
                                created: { showDate(a && a.createdAt)}
                            </span>
                        </div>
                    </div>
                ))
            }

            <h2 className="category-pdf-txt" > 2. MARKET INTELLIGENCE </h2>
            {
                data && data[1] &&
                data[1]
                .filter(a=> (!a.category || a.category == 'null') )
                .map((a, i)=>(
                    (a.status === 'workplan' || a.status === 'report') ?
                    <div key={i} className="box-indicator-txt boxdesign" >
                        <h4 className="header-styl-pdf" >
                            <strong> a {i+=1}. Workplan: </strong> {a && a.description ? a.description : '-'}
                        </h4>
                        <div className="contain-styl-pdf" >
                            <span > Report: </span>
                            <Editor
                                editorState={a && ((a.report != 'null') && (a.report)) ?
                                    EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                    :
                                    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                }
                                toolbarHidden
                                readOnly
                                toolbar={false}
                            />
                            <span style={{ fontWeight: 'normal', fontSize: 12, marginTop: 5 }} >
                                created: { showDate(a && a.createdAt)}
                            </span>
                        </div>
                    </div>
                    :
                    a.status === "onlyreport" &&
                    <div key={i} className="box-indicator-txt boxdesign" style={{ marginTop: 10 }} >
                        <h4 className="header-styl-pdf" >
                            <strong> a {i+=1}. Report without Workplan: </strong>
                        </h4>
                        <div style={{ marginTop: 0 }} className="contain-styl-pdf" >
                            <Editor
                                editorState={a && ((a.report != 'null') && (a.report)) ?
                                    EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                    :
                                    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                }
                                toolbarHidden
                                readOnly
                                toolbar={false}
                                editorClassName="box-content-txt-1231"
                            />
                            <span style={{ fontWeight: 'normal', fontSize: 12, marginTop: 5 }} >
                                created: { showDate(a && a.createdAt)}
                            </span>
                        </div>
                    </div>
                ))
            }

            <h3> A. Market Update </h3>
            {
                data && data[1] &&
                data[1]
                .filter(a=> (a.category).includes('Market Update') )
                .map((a, i)=>(
                    (a.status === 'workplan' || a.status === 'report') ?
                    <div key={i} className="box-indicator-txt boxdesign" >
                        <h4 className="header-styl-pdf" >
                            <strong> a {i+=1}. Workplan: </strong> {a && a.description ? a.description : '-'}
                        </h4>
                        <div className="contain-styl-pdf" >
                            <span > Report: </span>
                            <Editor
                                editorState={a && ((a.report != 'null') && (a.report)) ?
                                    EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                    :
                                    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                }
                                toolbarHidden
                                readOnly
                                toolbar={false}
                            />
                            <span style={{ fontWeight: 'normal', fontSize: 12, marginTop: 5 }} >
                                created: { showDate(a && a.createdAt)}
                            </span>
                        </div>
                    </div>
                    :
                    a.status === "onlyreport" &&
                    <div key={i} className="box-indicator-txt boxdesign" style={{ marginTop: 10 }} >
                        <h4 className="header-styl-pdf" >
                            <strong> a {i+=1}. Report without Workplan: </strong>
                        </h4>
                        <div style={{ marginTop: 0 }} className="contain-styl-pdf" >
                            <Editor
                                editorState={a && ((a.report != 'null') && (a.report)) ?
                                    EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                    :
                                    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                }
                                toolbarHidden
                                readOnly
                                toolbar={false}
                                editorClassName="box-content-txt-1231"
                            />
                            <span style={{ fontWeight: 'normal', fontSize: 12, marginTop: 5 }} >
                                created: { showDate(a && a.createdAt)}
                            </span>
                        </div>
                    </div>
                ))
            }

            <h3> B. Competitor Update </h3>
            {
                data && data[1] &&
                data[1]
                .filter(a=> (a.category).includes('Competitor Update') )
                .map((a, i)=>(
                    (a.status === 'workplan' || a.status === 'report') ?
                    <div key={i} className="box-indicator-txt boxdesign" >
                        <h4 className="header-styl-pdf" >
                            <strong> b {i+=1}. Workplan: </strong> {a && a.description ? a.description : '-'}
                        </h4>
                        <div className="contain-styl-pdf" >
                            <span > Report: </span>
                            <Editor
                                editorState={a && ((a.report != 'null') && (a.report)) ?
                                    EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                    :
                                    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                }
                                toolbarHidden
                                readOnly
                                toolbar={false}
                            />
                            <span style={{ fontWeight: 'normal', fontSize: 12, marginTop: 5 }} >
                                created: { showDate(a && a.createdAt)}
                            </span>
                        </div>
                    </div>
                    :
                    a.status === "onlyreport" &&
                    <div key={i} className="box-indicator-txt boxdesign" style={{ marginTop: 10 }} >
                        <h4 className="header-styl-pdf" >
                            <strong> b {i+=1}. Report without Workplan: </strong>
                        </h4>
                        <div style={{ marginTop: 0 }} className="contain-styl-pdf" >
                            <Editor
                                editorState={a && ((a.report != 'null') && (a.report)) ?
                                    EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                    :
                                    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                }
                                toolbarHidden
                                readOnly
                                toolbar={false}
                                editorClassName="box-content-txt-1231"
                            />
                            <span style={{ fontWeight: 'normal', fontSize: 12, marginTop: 5 }} >
                                created: { showDate(a && a.createdAt)}
                            </span>
                        </div>
                    </div>
                ))
            }

            <h3> C. Global Trend </h3>
            {
                data && data[1] &&
                data[1]
                .filter(a=> (a.category).includes('Global Trend') )
                .map((a, i)=>(
                    (a.status === 'workplan' || a.status === 'report') ?
                    <div key={i} className="box-indicator-txt boxdesign" >
                        <h4 className="header-styl-pdf" >
                            <strong> c {i+=1}. Workplan: </strong> {a && a.description ? a.description : '-'}
                        </h4>
                        <div className="contain-styl-pdf" >
                            <span > Report: </span>
                            <Editor
                                editorState={a && ((a.report != 'null') && (a.report)) ?
                                    EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                    :
                                    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                }
                                toolbarHidden
                                readOnly
                                toolbar={false}
                            />
                            <span style={{ fontWeight: 'normal', fontSize: 12, marginTop: 5 }} >
                                created: { showDate(a && a.createdAt)}
                            </span>
                        </div>
                    </div>
                    :
                    a.status === "onlyreport" &&
                    <div key={i} className="box-indicator-txt boxdesign" style={{ marginTop: 10 }} >
                        <h4 className="header-styl-pdf" >
                            <strong> c {i+=1}. Report without Workplan: </strong>
                        </h4>
                        <div style={{ marginTop: 0 }} className="contain-styl-pdf" >
                            <Editor
                                editorState={a && ((a.report != 'null') && (a.report)) ?
                                    EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                    :
                                    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                }
                                toolbarHidden
                                readOnly
                                toolbar={false}
                                editorClassName="box-content-txt-1231"
                            />
                            <span style={{ fontWeight: 'normal', fontSize: 12, marginTop: 5 }} >
                                created: { showDate(a && a.createdAt)}
                            </span>
                        </div>
                    </div>
                ))
            }

            {/* <h2 className="category-pdf-txt" > 3. PROMOTIONAL SUPPORT </h2>
            {
                data && data[2] &&
                data[2]
                .filter(a=> (!a.category || a.category == 'null') )
                .map((a, i)=>(
                    (a.status === 'workplan' || a.status === 'report') ?
                    <div key={i} className="box-indicator-txt boxdesign" >
                        <h4 className="header-styl-pdf" >
                            <strong> a {i+=1}. Workplan: </strong> {a && a.description ? a.description : '-'}
                        </h4>
                        <div className="contain-styl-pdf" >
                            <span > Report: </span>
                            <Editor
                                editorState={a && ((a.report != 'null') && (a.report)) ?
                                    EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                    :
                                    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                }
                                toolbarHidden
                                readOnly
                                toolbar={false}
                            />
                            <span style={{ fontWeight: 'normal', fontSize: 12, marginTop: 5 }} >
                                created: { showDate(a && a.createdAt)}
                            </span>
                        </div>
                    </div>
                    :
                    a.status === "onlyreport" &&
                    <div key={i} className="box-indicator-txt boxdesign" style={{ marginTop: 10 }} >
                        <h4 className="header-styl-pdf" >
                            <strong> a {i+=1}. Report without Workplan: </strong>
                        </h4>
                        <div style={{ marginTop: 0 }} className="contain-styl-pdf" >
                            <Editor
                                editorState={a && ((a.report != 'null') && (a.report)) ?
                                    EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                    :
                                    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                }
                                toolbarHidden
                                readOnly
                                toolbar={false}
                                editorClassName="box-content-txt-1231"
                            />
                            <span style={{ fontWeight: 'normal', fontSize: 12, marginTop: 5 }} >
                                created: { showDate(a && a.createdAt)}
                            </span>
                        </div>
                    </div>
                ))
            }

            <h3> A. Regional Promotion (SM, TM, FT, Festival) </h3>
            {
                data && data[2] &&
                data[2]
                .filter(a=> (a.category).includes('Regional Promotion') )
                .map((a, i)=>(
                    (a.status === 'workplan' || a.status === 'report') ?
                    <div key={i} className="box-indicator-txt boxdesign" >
                        <h4 className="header-styl-pdf" >
                            <strong> a {i+=1}. Workplan: </strong> {a && a.description ? a.description : '-'}
                        </h4>
                        <div className="contain-styl-pdf" >
                            <span > Report: </span>
                            <Editor
                                editorState={a && ((a.report != 'null') && (a.report)) ?
                                    EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                    :
                                    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                }
                                toolbarHidden
                                readOnly
                                toolbar={false}
                            />
                            <span style={{ fontWeight: 'normal', fontSize: 12, marginTop: 5 }} >
                                created: { showDate(a && a.createdAt)}
                            </span>
                        </div>
                    </div>
                    :
                    a.status === "onlyreport" &&
                    <div key={i} className="box-indicator-txt boxdesign" style={{ marginTop: 10 }} >
                        <h4 className="header-styl-pdf" >
                            <strong> a {i+=1}. Report without Workplan: </strong>
                        </h4>
                        <div style={{ marginTop: 0 }} className="contain-styl-pdf" >
                            <Editor
                                editorState={a && ((a.report != 'null') && (a.report)) ?
                                    EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                    :
                                    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                }
                                toolbarHidden
                                readOnly
                                toolbar={false}
                                editorClassName="box-content-txt-1231"
                            />
                            <span style={{ fontWeight: 'normal', fontSize: 12, marginTop: 5 }} >
                                created: { showDate(a && a.createdAt)}
                            </span>
                        </div>
                    </div>
                ))
            }

            <h3> B. Online Promotion (Website & Social Media) </h3>
            {
                data && data[2] &&
                data[2]
                .filter(a=> (a.category).includes('Online Promotion') )
                .map((a, i)=>(
                    (a.status === 'workplan' || a.status === 'report') ?
                    <div key={i} className="box-indicator-txt boxdesign" >
                        <h4 className="header-styl-pdf" >
                            <strong> b {i+=1}. Workplan: </strong> {a && a.description ? a.description : '-'}
                        </h4>
                        <div className="contain-styl-pdf" >
                            <span > Report: </span>
                            <Editor
                                editorState={a && ((a.report != 'null') && (a.report)) ?
                                    EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                    :
                                    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                }
                                toolbarHidden
                                readOnly
                                toolbar={false}
                            />
                            <span style={{ fontWeight: 'normal', fontSize: 12, marginTop: 5 }} >
                                created: { showDate(a && a.createdAt)}
                            </span>
                        </div>
                    </div>
                    :
                    a.status === "onlyreport" &&
                    <div key={i} className="box-indicator-txt boxdesign" style={{ marginTop: 10 }} >
                        <h4 className="header-styl-pdf" >
                            <strong> b {i+=1}. Report without Workplan: </strong>
                        </h4>
                        <div style={{ marginTop: 0 }} className="contain-styl-pdf" >
                            <Editor
                                editorState={a && ((a.report != 'null') && (a.report)) ?
                                    EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                    :
                                    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                }
                                toolbarHidden
                                readOnly
                                toolbar={false}
                                editorClassName="box-content-txt-1231"
                            />
                            <span style={{ fontWeight: 'normal', fontSize: 12, marginTop: 5 }} >
                                created: { showDate(a && a.createdAt)}
                            </span>
                        </div>
                    </div>
                ))
            }

            <h3> C. PR-Ing and Customer Service </h3>
            {
                data && data[2] &&
                data[2]
                .filter(a=> (a.category).includes('Customer Service') )
                .map((a, i)=>(
                    (a.status === 'workplan' || a.status === 'report') ?
                    <div key={i} className="box-indicator-txt boxdesign" >
                        <h4 className="header-styl-pdf" >
                            <strong> c {i+=1}. Workplan: </strong> {a && a.description ? a.description : '-'}
                        </h4>
                        <div className="contain-styl-pdf" >
                            <span > Report: </span>
                            <Editor
                                editorState={a && ((a.report != 'null') && (a.report)) ?
                                    EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                    :
                                    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                }
                                toolbarHidden
                                readOnly
                                toolbar={false}
                            />
                            <span style={{ fontWeight: 'normal', fontSize: 12, marginTop: 5 }} >
                                created: { showDate(a && a.createdAt)}
                            </span>
                        </div>
                    </div>
                    :
                    a.status === "onlyreport" &&
                    <div key={i} className="box-indicator-txt boxdesign" style={{ marginTop: 10 }} >
                        <h4 className="header-styl-pdf" >
                            <strong> c {i+=1}. Report without Workplan: </strong>
                        </h4>
                        <div style={{ marginTop: 0 }} className="contain-styl-pdf" >
                            <Editor
                                editorState={a && ((a.report != 'null') && (a.report)) ?
                                    EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                    :
                                    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                }
                                toolbarHidden
                                readOnly
                                toolbar={false}
                                editorClassName="box-content-txt-1231"
                            />
                            <span style={{ fontWeight: 'normal', fontSize: 12, marginTop: 5 }} >
                                created: { showDate(a && a.createdAt)}
                            </span>
                        </div>
                    </div>
                ))
            } */}
        </div>
    )
}