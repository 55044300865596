import {
    IS_LOGIN,
    IS_LOGOUT
} from "../constanta";

const initialState = {
    access: null,
    root: null,
    email: null,
    name: null,
    photo: null,
    role: null,
    country: null,
    area: null,
    id: null
}

export default (state = initialState, action)=> {
    switch(action.type){
        case IS_LOGIN:
            return {
                ...state,
                access: action.profile.access,
                name: action.profile.name,
                email: action.profile.email,
                photo: action.profile.photo,
                root: action.profile.root,
                role: action.profile.role,
                country: action.profile.country,
                area: action.profile.area,
                id: action.profile.id,
            }
        case IS_LOGOUT:
            return {
                ...state,
                access: null,
                root : null,
                email : null,
                name: null,
                role: null,
                id: null
            }
        default: {
            return state
        }
    }
}