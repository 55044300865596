import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Box, makeStyles, Typography, Button, CardActions } from '@material-ui/core'
import WorkplanReport from '../Card/WorkplanReport'
import Workplan from '../Card/Workplan'
import ReportWhitoutWorkplan from '../Card/ReportWithoutWorkplan'
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import Axios from 'axios'
import Swal from 'sweetalert2'
import { SERVER } from '../../../../../server'
import RefreshIcon from '@material-ui/icons/Refresh';

const useStyle = makeStyles({
    root: {
        width: '100%',
        background: "#F2F3F9",
        borderRadius: "5px",
    },
    title: {
        fontWeight: "500",
        lineHeight: "16px",
        color: "#2C304D"
    },
    cards: {
        minHeight: "calc( 72vh - 60px)",
        maxHeight: "calc( 72vh - 60px)",
        overflowX: "hidden",
        overflowY: "scroll",
        padding: "1em",
        paddingTop: '0em'
    }

})

export default function PIN(props) {
    const classes = useStyle()
    const history = useHistory()
    const [report, setReport] = useState([])

    const {
        options,
        CategoryId,
        weekId,
        weeklyList,
        getDataFirst
    } = props

    const { year } = useParams()

    function addReport() {
        history.push({
            pathname: `/vito/report/weekly/${year}/add-report`,
            state: { weeklyList, weekId, CategoryId, options }
        })
    }

    function addWorkPlan() {
        history.push({
            pathname: `/vito/workplan`,
            state: { weeklyList, weekId, CategoryId, options }
        })
    }

    const dataRead = (CategoryId, weekId) =>{
        if (CategoryId && weekId) {
            Swal.showLoading()
            Axios({
                method: 'GET',
                url: `${SERVER}/report/${CategoryId}/${weekId}`,
                headers: {
                    token : localStorage.getItem('token'),
                }
            })
            .then(({data})=>{
                Swal.close()
                getDataFirst(data)
                setReport(data)
            })
            .catch(err=>{
                Swal.close()
            })
        }
    }

    useEffect(()=>{
        dataRead(CategoryId, weekId)
    },[CategoryId, weekId])

    const refresh = () => {
        dataRead(CategoryId, weekId)
    }

    return (
        <Box className={classes.root}>
            <Box style={{ minHeight: 30, maxHeight: 30,  padding: '1em', boxSizing: 'content-box' }}>
                <span style={{ fontWeight: 'bold', fontSize: '1.2vw' }} >
                    {props.title}
                </span>
            </Box>
            <Box className={classes.cards}>
                {
                    report &&
                    report.length === 0 ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '50vh' }}>
                        <AssignmentLateIcon style={{ fontSize: '12vw', color: '#e35981' }} />
                        <span style={{ fontSize: 15 }} >
                            Report Not Found
                        </span>
                    </div>
                    :
                    report &&
                    report.map((data,i)=>(
                        data.status === "workplan" ?
                        <Workplan key={i} data={data} weeklyList={weeklyList} /> :
                        data.status === "report" ?
                        <WorkplanReport key={i} data={data} weeklyList={weeklyList} dataRead={dataRead} CategoryId={CategoryId} weekId={weekId} /> :
                        data.status === "onlyreport" &&
                        <ReportWhitoutWorkplan key={i} data={data} weeklyList={weeklyList} options={options} dataRead={dataRead} CategoryId={CategoryId} weekId={weekId}/>
                    ))
                }
            </Box>
            {/* <Box padding="1em" paddingBottom="0"><Button variant="outlined" color="secondary" fullWidth style={{ textTransform: "none" }} onClick={addReport}>Add Other New Weekly Report</Button></Box> */}
            <Box padding="1em" paddingBottom="0"><Button variant="outlined" color="secondary" fullWidth style={{ textTransform: "none" }} onClick={addWorkPlan}>Add More Work Plan</Button></Box>
            <Button variant="contained" color="default" onClick={refresh} fullWidth style={{ backgroundColor: '#E23F81', color: "#ffffff", marginTop: 5 }} >
                <RefreshIcon />
            </Button>
        </Box>
    )
}
