import React, { useEffect, useState } from "react";
import { csv } from "d3-fetch";
import {
    ComposableMap,
    Geographies,
    Geography,
    Marker,
    ZoomableGroup,
    Annotation
} from "react-simple-maps";

const geoUrl = "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json";

const MapChart = ({ modalUp, setUserCountry }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        csv(`/nations.csv`).then((data) => {
            setData(data);
        });
    }, []);

    // const [coordinate] = useState([
    //     { nation : 'Singapore', long: 103.751959, lat : 0.8270 },
    //     { nation : 'Monaco', long: 7.41667-0.5, lat : 43.33333-0.5 },
    //     { nation : 'France', long: 1.7191036, lat : 46.71109 },
    //     { nation : 'Malaysia', long: 101.693207, lat : 	3.140853 },
    //     { nation : 'Thailand', long: 100.523186, lat : 13.736717 },
    //     { nation : 'China', long: 104.1361, lat : 35.8593 },
    //     { nation : 'South Korea', long: 126.724612, lat : 34.932600 },
    //     { nation : 'Japan', long: 136.44302150, lat : 34.75149249 },
    //     { nation : 'India', long: 75.7792201, lat : 19.1289956 },
    //     { nation : 'Saudi Arabia', long: 40.107849, lat : 24.266906 },
    //     { nation : 'United Arab Emirates', long: 55.0396418, lat : 24.5505839 },
    //     { nation : 'Netherlands', long: 5.2793703, lat : 53.0129919 },
    //     { nation : 'Germany', long: 12.5541194, lat : 49.1642292 },
    //     { nation : 'Russia', long: 103.0, lat : 55.0 },
    //     { nation : 'United Kingdom', long: -3, lat : 53.0 },
    //     { nation : 'Australia', long: 145.4, lat : -24.0 },
    // ])

    const [coordinate] = useState([
        { nation : 'Singapore', long: 110.751959, lat : -0.5 },
        { nation : 'Cruise Tourism', long: 45.41667-0.5, lat : 41.33333-0.5 },
        { nation : 'France', long: 11.7191036, lat : 46.71109 },
        { nation : 'Malaysia', long: 118.693207, lat : 	3.540853 },
        { nation : 'Thailand', long: 110.523186, lat : 13.736717 },
        { nation : 'China', long: 113.1361, lat : 33.8593 },
        { nation : 'South Korea', long: 150.724612, lat : 34.932600 },
        { nation : 'Japan', long: 150.44302150, lat : 39.75149249 },
        { nation : 'India', long: 80.2792201, lat : 21.1289956 },
        { nation : 'Saudi Arabia', long: 60.107849, lat : 20.266906 },
        { nation : 'United Arab Emirates', long: 40.0396418, lat : 24.5505839 },
        { nation : 'Netherlands', long: 39.2793703, lat : 56.0129919 },
        { nation : 'Germany', long: 7.5541194, lat : 50.8642292 },
        { nation : 'Russia', long: 110.0, lat : 60.0 },
        { nation : 'United Kingdom', long: 2, lat : 53.0 },
        { nation : 'Australia', long: 145.4, lat : -24.0 },
    ])

    return (
        <ComposableMap data-tip="" projectionConfig={{ scale: 200, rotate: [0, 0, 0] }}>
            <ZoomableGroup center={[60,165]} minZoom={1} zoom={1.5} >
            {data.length > 0 && (
                <>
                <Geographies geography={geoUrl}>
                    {({ geographies }) =>
                        geographies.map(geo => {
                            const d = data.find((s) => s.ISO3 === geo.properties.ISO_A3);
                            return (
                                <Geography
                                    key={geo.rsmKey}
                                    geography={geo}
                                    onClick={()=>d ? modalUp(geo.properties.NAME) : ''}
                                    onMouseEnter={() => {
                                        const { NAME, POP_EST } = geo.properties;
                                        setUserCountry(`${d ? NAME : ''}`);
                                    }}
                                    onMouseLeave={() => {
                                        setUserCountry("");
                                    }}
                                    style={{
                                        default: {
                                            cursor: !d ? 'grab' : 'default',
                                            fill: d ? '#048FD4': "#C4C4C4",
                                            outline: "none"
                                        },
                                        hover: {
                                            cursor: !d ? 'grab' : 'default',
                                            fill: d ? '#026494': "#C4C4C4",
                                            outline: "none"
                                        },
                                        pressed: {
                                            cursor: !d ? 'grab' : 'default',
                                            fill: "#026494",
                                            outline: "none"
                                        }
                                    }}
                                />
                            )
                        })
                    }
                </Geographies>
                {
                    coordinate.map((nat, i)=>(
                        <Annotation
                            key={i}
                            subject={[nat.long,nat.lat]}
                            dx={
                                0
                                // nat.nation === "South Korea" ? 40 :
                                // nat.nation === "Japan" ? 25 :
                                // nat.nation === "United Arab Emirates" ? 10 :
                                // nat.nation === "Netherlands" ? 10 :
                                // nat.nation === "Germany" ? 10 :
                                // nat.nation === "Russia" ? 0 :
                                // nat.nation === "China" ? 0 :
                                // nat.nation === "India" ? 10 :
                                // nat.nation === "Australia" ? 0
                                // : -15 
                            }
                            dy={0
                                // nat.nation === "South Korea" ? 50 :
                                // nat.nation === "Japan" ? 20 :
                                // nat.nation === "United Arab Emirates" ? -20 :
                                // nat.nation === "Netherlands" ? -30 :
                                // nat.nation === "Germany" ? 0 :
                                // nat.nation === "Russia" ? -20:
                                // nat.nation === "China" ? 0 :
                                // nat.nation === "India" ? -10 :
                                // nat.nation === "Australia" ? 0
                                // : 10
                            }
                            connectorProps={{
                                stroke: (nat.nation !== "China" && nat.nation !== "Russia" && nat.nation !== "India" && nat.nation !== "Australia") && "black",
                                strokeWidth: (nat.nation !== "China" && nat.nation !== "Russia" && nat.nation !== "India" && nat.nation !== "Australia") && 1,
                                strokeLinecap: (nat.nation !== "China" && nat.nation !== "Russia" && nat.nation !== "India" && nat.nation !== "Australia") && "round"
                            }}
                        >
                            <text
                                onClick={()=>modalUp(nat.nation)}
                                onMouseEnter={() => {
                                    setUserCountry(nat.nation)
                                }}
                                onMouseLeave={() => {
                                    setUserCountry("");
                                }}
                                x={
                                    nat.nation === "Germany" ? "3"
                                    : "-3"
                                }
                                y={
                                    nat.nation === "United Arab Emirates" ? "-5" :
                                    nat.nation === "India" ? "-5" 
                                    : 0
                                }
                                textAnchor={
                                    nat.nation === "Germany" ? "start" :
                                    nat.nation === "United Arab Emirates" ? "middle" :
                                    nat.nation === "India" ? "middle"
                                    : "end"
                                }
                                alignmentBaseline="middle"
                                style={{
                                    fontSize: 
                                    nat.nation === "United Kingdom" ? 15 :
                                    nat.nation === "Netherlands" ? 15 :
                                    nat.nation === "Germany" ? 15 :
                                    nat.nation === "France" ? 15 :
                                    nat.nation === "Cruise Tourism" ? 15 :
                                    nat.nation === "Russia" ? 20 :
                                    nat.nation === "Singapore" ? 15 :
                                    nat.nation === "South Korea" ? 15 :
                                    nat.nation === "Japan" ? 15 :
                                    nat.nation === "Thailand" ? 15 :
                                    nat.nation === "Malaysia" ? 15 :
                                    nat.nation === "China" ? 20 :
                                    nat.nation === "Australia" ? 15 :
                                    nat.nation === "India" ? 15 :
                                    nat.nation === "Saudi Arabia" ? 15 :
                                    nat.nation === "United Arab Emirates" ? 15 :
                                    8,
                                    zIndex: 1, cursor: 'default',
                                    textShadow: '-1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white'
                                }}>
                            {nat.nation}
                            </text>
                        </Annotation>
                    ))
                }
                <Marker coordinates={[103.951959,1.100270]}>
                    <circle r={2} fill="#048FD4" stroke="#fff" strokeWidth={0.2}
                        onClick={e=>modalUp('Singapore')}
                        onMouseEnter={() => {
                            setUserCountry(`${'Singapore'}`);
                        }}
                        onMouseLeave={() => {
                            setUserCountry("");
                        }}
                        style={{
                            default: {
                                fill: '#048FD4',
                                outline: "none"
                            },
                            hover: {
                                fill: '#026494',
                                outline: "none"
                            },
                            pressed: {
                                fill: "#026494",
                                outline: "none"
                            }
                        }}
                    />
                </Marker>
                <Marker coordinates={[7.41667, 43.33333]}>
                    <circle r={2} fill="#048FD4" stroke="#fff" strokeWidth={0.2}
                        onClick={e=>modalUp('Monaco')}
                        onMouseEnter={() => {
                            setUserCountry(`${'Monaco'}`);
                        }}
                        onMouseLeave={() => {
                            setUserCountry("");
                        }}
                        style={{
                            default: {
                                fill: '#048FD4',
                                outline: "none"
                            },
                            hover: {
                                fill: '#026494',
                                outline: "none"
                            },
                            pressed: {
                                fill: "#026494",
                                outline: "none"
                            }
                        }}
                    />
                </Marker>
            </>
            )}
            </ZoomableGroup>
        </ComposableMap>
    );
};

export default MapChart;