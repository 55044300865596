import { combineReducers } from 'redux'
import User from './userReducer'
import Filter from './filter'
import Profile from './profileReducer'
import Vito from './vitoReducer'
import WorkPlan from './workplanReducer'
import Report from './reportReducer'
import Dashboard from './dashboardReducer'
import Evaluation from './evaluationReducer'
import RequestData from './requestDataReducer'
import CoordinatorData from './coordinatorDataReducer'
import Notification from './notificationReducer'
import Partner from './partnerReducer'
import Country from './countryReducer'

const reducers = combineReducers({
    userState : User,
    filterState : Filter,
    profileState : Profile,
    vitoState : Vito,
    workplanState : WorkPlan,
    reportState : Report,
    dashboardState : Dashboard,
    evaluationState : Evaluation,
    requestDataState : RequestData,
    coordinatorDataState : CoordinatorData,
    notificationState : Notification,
    partnerState : Partner,
    countryState : Country
})

export default reducers