import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const useStyles = makeStyles({
    buttonRead: {
        border: "none",
        textTransform: "none",
        textAlign: "left"
    },
    content: {
        width: "50em",
        padding: "1.5em 3em",
        background: "white",
        marginBottom: "3em"
    },
    text: {
        marginTop: "1.5em"
    },
    borderDialog: {
        borderRadius: "10px"
    }
})

export default function Index(props) {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles()

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button className={classes.buttonRead} onClick={handleClickOpen}>{props.index+1}. {props.title}</Button>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" maxWidth="lg"  open={open} paper={classes.borderDialog}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Indikator Kualitatif
                </DialogTitle>
                    <Box paddingX="15%" borderTop="0.5px solid #DDDDDD" paddingTop="1em"><Typography variant="h6" align="center" style={{textTransform:"capitalize"}}> {props.title} </Typography></Box>
                    <Box className={classes.content}>
                        <Typography className={classes.text}>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Obcaecati perferendis, ipsam incidunt veniam cum, voluptatibus quae sint asperiores facere a iusto? Quaerat cum consectetur neque nam necessitatibus voluptates laborum dolorum. <br/> Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta quo quisquam non autem placeat at tempore dolore, nesciunt distinctio aliquam quia illum. Repellat odio sapiente at minus soluta, veniam pariatur!</Typography>
                    </Box>
            </Dialog>
        </div>
    );
}