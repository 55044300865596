import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, withStyles, IconButton, Box, Typography, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import DeleteIcon from '@material-ui/icons/Delete';
import Axios from 'axios'
import { SERVER } from '../../../../../../server'

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
})

const useStyles = makeStyles({
    buttonOK: {
        background: "#E23F81",
        padding: "0.5em",
        textTransform: "none",
        marginRight: "1rem"
    },
    boxCircle: {
        borderRadius: "50%",
        height: "10em",
        width: "10em",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(226, 63, 129, 0.2)"
    },
    buttonBatal: {
        textTransform: 'none',
        padding: "0.5em",
        border: "1px solid #E23F81",
        color: "#E23F81",
        marginLeft: "1rem"
    }
})


export default function Index(props) {
    const classes = useStyles()
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const {
        data,
        read,
        CategoryId,
        weekId
    } = props

    const saving = () => {
        Axios({
            method: 'DELETE',
            url: `${SERVER}/report/delete/${data.id}`,
            data : { data },
            headers: {
                token : localStorage.getItem('token'),
            }
        })
        .then(({data})=>{
            read(CategoryId, weekId)
            handleClose()
        })
        .catch(err=>{
        })
    }

    return (
        <>
            <Box borderRadius="50%" padding="0.25rem" style={{ background: "#FAFAFA" }}>
                <IconButton size="small" aria-label="delete" onClick={handleClickOpen} ><DeleteIcon size="small" color="disabled" /></IconButton>
            </Box>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                </DialogTitle>
                <Box align="center">
                    <Box className={classes.boxCircle}><ErrorIcon color="secondary" style={{ color: "#E23F81", fontSize: "10em" }} /></Box>
                </Box>
                <Box paddingX="3rem" paddingTop="1rem"><Typography variant="h5" style={{fontWeight: "500"}} align="center"> Are you sure want to delete this {props.categoryReport}? </Typography></Box>
                <Box paddingX="20%" paddingTop="1rem"><Typography style={{ color: "#999999" }} align="center"> {props.categoryReport} that has been deleted, can not be restored. </Typography></Box>
                <Box display="flex" justifyContent="center" marginY="2em">
                    <Box width={1 / 3}><Button variant="contained" fullWidth onClick={saving} color="secondary" className={classes.buttonOK}>Yes</Button></Box>
                    <Box width={1 / 3}><Button variant="outlined" fullWidth color="secondary" onClick={handleClose} className={classes.buttonBatal}>Cancel</Button></Box>
                </Box>
            </Dialog>
        </>
    )
}
