import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import Images from './Images'
import { SERVER } from '../../../../../../../server'
import axios from 'axios'

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Box minHeight="3em">{children}</Box>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const useStyles = makeStyles({
    buttonView: {
        color: "white",
        background: "#E23F81",
        borderRadius: "20px",
        width: "100px",
        textTransform: "none",
        boxShadow: "none",
    },
    groupButton:{
        display: 'flex',
        flexDirection: 'column',
    }
})

export default function CustomizedDialogs(props) {
    const { photos, photosAll } = props
    const [open, setOpen] = React.useState(false);
    const classes = useStyles()

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleDownload = (e, uri) => {
        if (e) e.preventDefault()
        axios({
            url: uri,
            method: 'GET',
            responseType: 'blob'
        })
        .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${(uri).split(`${SERVER}/public/uploads/image/`).join('')}`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
        })
    }

    const openNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    return (
        <div>
            <div className={classes.groupButton} >
            {
                photos && (photos.includes('.pdf') && !photos.includes('.doc')) ?
                <Button variant="contained" size="small" className={classes.buttonView} onClick={e=>openNewTab(photos)}>{props.text}</Button>
                :
                photos && (!photos.includes('.pdf') && !photos.includes('.doc')) &&
                <Button variant="contained" size="small" className={classes.buttonView} onClick={handleClickOpen}>{props.text}</Button>
            }
            <Button variant="contained" size="small" className={classes.buttonView} style={{ marginTop: 5 }} onClick={e=>handleDownload(e, photos)}>{props.text2}</Button>
            </div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" maxWidth="lg"  open={open} paper={classes.borderDialog}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                </DialogTitle>
                <Images photos={photos} photosAll={photosAll}/>
            </Dialog>
        </div>
    );
}