import React,{ useState, Fragment, useEffect } from 'react'
import { Box, Typography, TextField, withStyles, makeStyles, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Paper, requirePropFactory, Button } from '@material-ui/core'
import './styles.scss'
import {useHistory} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { KeyboardDatePicker } from "@material-ui/pickers";
import { getTabulasiDashboard } from '../../../../../store/actions/reportAction'

// COMPONENT

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#F3F3F3",
        color: "#999999",
        fontWeight: 500,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "#FAFAFA",
        },

    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 700,
        boxShadow: "none"
    },
    buttonViewProfile: {
        background: "#FCECF2",
        color: "#E23F81",
        boxShadow: "none"
    },
    titleTable: {
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor: "#DDDDDD",
        paddingTop:"1em",
        paddingBottom:"1em",
        paddingLeft:"1.5em",
        backgroundColor: 'white'
    },
    tableContainer: {
        boxShadow: "none",
        border: "1px solid #CCCCCC",
        borderRadius: "4px"
    }
});

function Tabulasi() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [search, setSearch] = useState('')
    const vitos = useSelector(state=> state.vitoState.vitos)
    const tabulasi = useSelector(state=> state.reportState.tabulasi)
    const [selectedDate, handleDateChange] = useState(new Date());

    const getCount = (idVito, idMonth) => {
        let result = 0
        if (tabulasi) {
            if (tabulasi.monthlyReport) {
                if (idVito) {
                    if (tabulasi.monthlyReport[idVito]) {
                        tabulasi.monthlyReport[idVito].map(data=>{
                            if (data.MonthId == idMonth) {
                                result = result + Number(data.count)
                            }
                        })
                    }
                }
            }
            if (tabulasi.weeklyReport) {
                if (idVito) {
                    if (tabulasi.weeklyReport[idVito]) {
                        tabulasi.weeklyReport[idVito].map(data=>{
                            if (data.MonthId == idMonth) {
                                result = result + Number(data.count)
                            }
                        })
                    }
                }
            }
        }
        return result
    }

    const getTabulasi = (date) => {
        handleDateChange(date)
        const newYear = new Date(date).getFullYear()
        if (newYear === 2019) {
            dispatch(getTabulasiDashboard(1))
        } else if (newYear === 2020) {
            dispatch(getTabulasiDashboard(2))
        } else if (newYear === 2021) {
            dispatch(getTabulasiDashboard(3))
        } else if (newYear === 2022) {
            dispatch(getTabulasiDashboard(4))
        } else if (newYear === 2023) {
            dispatch(getTabulasiDashboard(5))
        } else if (newYear === 2024) {
            dispatch(getTabulasiDashboard(6))
        } else if (newYear >= 2025) {
            dispatch(getTabulasiDashboard(7))
        }
    }

    const ToDetail = (userId, monthId, yearId) => {
        history.push(`/admin/tabulasi/${userId}/${monthId}/${monthId && (monthId).split('')[0]}`)
    }

    return (
        <Fragment>
            <div className="dashboard" >
                <span className="breadcrumb-txt-black" >
                    Tabulasi
            </span>
                <span className="breadcrumb-slash-black" >
                    /
            </span>
            </div>
            <div className="vito-table">
                <Box width='100%'>
                    <Box className={classes.titleTable}>
                        <Typography variant="h5"><b>Daftar Jumlah Report VITO Tahun {new Date(selectedDate).getFullYear()}</b></Typography>
                    </Box>
                    <Box paddingLeft="1.5em" paddingRight="1em" paddingTop="2em" style={{ backgroundColor: 'white', paddingBottom: '20px' }} >
                        <div className="report-filter-container" >
                            <TextField label="Cari VITO" size="small" variant="outlined" borderColor="#C4C4C4"
                                InputProps={{
                                    style: {
                                        width: "248px",
                                        height: "36px",
                                    }
                                }}
                                onChange={e=>setSearch(e.target.value)}
                            />
                            <KeyboardDatePicker
                                style={{
                                    marginLeft: 10
                                }}
                                views={["year"]}
                                // minDate={new Date("2019-01-01")}
                                // maxDate={new Date("2025-01-01")}
                                minDate={new Date("2022-01-01")}
                                maxDate={new Date("2022-12-01")}
                                label="Pilih Tahun"
                                size="small"
                                clearable
                                inputVariant="outlined"
                                value={selectedDate}
                                onChange={date => getTabulasi(date)}
                                format="yyyy"
                                InputProps={{
                                    style: {
                                        width: "152px",
                                        height: "36px",
                                        paddingRight: 5
                                    }
                                }}
                                keyboardIcon={<i className="fas fa-calendar-day" style={{ color: '#999999', fontSize: 15 }} />}
                            />
                        </div>
                        <Box paddingTop="1em">
                            <TableContainer component={Paper} className={classes.tableContainer}>
                                <Table className={classes.table} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell style={{ width: 20 }} >NO</StyledTableCell>
                                            <StyledTableCell align="left">NAMA & NEGARA</StyledTableCell>
                                            <StyledTableCell align="left">BULAN & JUMLAH REPORT</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                        vitos &&
                                        vitos
                                        .filter(a=> a && a.id !== 111 )
                                        .filter(a=>search ? ((a.name).toLowerCase()).includes((search).toLowerCase()) : a)
                                        .map((row,i) => (
                                            <StyledTableRow key={i}>
                                                <StyledTableCell align="left">{i+=1}</StyledTableCell>
                                                <StyledTableCell align="left">
                                                    <Box display="flex" alignItems="center">
                                                        {row && row.name ? row.name : '-'}
                                                    </Box>
                                                    <Box display="flex" alignItems="center" style={{
                                                        textTransform: 'uppercase',
                                                        color: '#999999'
                                                    }} >
                                                        {row && row.country ? row.country : '-'}
                                                    </Box>
                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexWrap: 'wrap'
                                                        }}
                                                    >
                                                        {
                                                            tabulasi &&
                                                            tabulasi.yearData &&
                                                            tabulasi.yearData.map((mont, i)=>(
                                                                <div key={i} className="btn-month-tabulasi"
                                                                    onClick={_=>ToDetail(row.id, mont.id, )}
                                                                >
                                                                    <div className="btn-month-tabulasi-title">
                                                                        {mont && mont.month}
                                                                    </div>
                                                                    <div className="btn-month-tabulasi-count">
                                                                        { getCount(row && row.id, mont && mont.id) }
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
            </div>
        </Fragment>
    )
}

export default Tabulasi