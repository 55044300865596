import React,{ useState, Fragment, useEffect } from 'react'
import './styles.scss'
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Box, Typography, TextField, withStyles, makeStyles, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Collapse, Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { SERVER } from '../../../../../server'
import axios from 'axios'

// COMPONENT
const useStyles = makeStyles((theme) => ({
    tableContainer:{
        backgroundColor: "#F3F3F3",
        border: '0.5px solid #CCCCCC',
        boxSizing: 'border-box',
        borderRadius: '4px',
        marginBottom : 20,
        boxShadow: 'none'
    },
    tableRow:{
        backgroundColor: "#F3F3F3",
    },
    tableRowTxt:{
        fontfamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '16px',
        color: '#999999'
    },
    tableCellDouble:{
        padding: 10
    }
}));

const MonthButtonDeactive = withStyles((theme) => ({
    root: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "16px",
        textAlign: "center",
        color: "#2C304D",
        transition: '0.5s',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: '#f0a0c0',
        },
    },
}))(Button);

const MonthButtonActive = withStyles((theme) => ({
    root: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "16px",
        textAlign: "center",
        color: "white",
        backgroundColor:'#E23F81',
        transition: '0.5s',
        textTransform: 'capitalize',
        '&:hover': {
            color: "white",
            backgroundColor:'#E23F81'
        },
    },
}))(Button);

export default function Report(){
    const classes = useStyles();
    const history = useHistory()
    const [selectedDate, handleDateChange] = useState(new Date());
    const filterYears = useSelector(state=> state.filterState.years)
    const vitos = useSelector(state=> state.vitoState.vitos)
    const [weekly, setWeekly] = useState(null)

    const [data] = useState([
        {
            nama : "Ms. Nattamon Limthanachai",
            negara : "THAILAND",
        },
        {
            nama : "Ms. Anna Kiseleva",
            negara : "RUSSIA",
        },
        {
            nama : "Mr. Mostafa El Enany",
            negara : "SAUDI ARABIA",
        },
        {
            nama : "Mr. Kelvin Ong",
            negara : "MALAYSIA",
        }
    ])

    function viewProfile (name) {
        history.push(`/vito/${name}/weekly`)
    }

    useEffect(()=>{
        if (filterYears) {
            let year
            if (selectedDate.getFullYear() > 2025) {
                handleDateChange(2026)
                year = filterYears.filter(e=> e.year === 2025)[0].id
            }
            if (selectedDate.getFullYear() < 2019) {
                handleDateChange(2019)
                year = filterYears.filter(e=> e.year === 2019)[0].id
            }
            if (selectedDate.getFullYear() <= 2025 && selectedDate.getFullYear() >= 2019) {
                year = filterYears.filter(e=> e.year === selectedDate.getFullYear())[0].id
            }
            axios({
                method: 'get',
                url: `${SERVER}/database/${year}`,
                headers: {
                    token : localStorage.getItem('token'),
                }
            })
            .then(({data})=>{
                setWeekly(data)
            })
            .catch(err=>{
            })
        }
    },[filterYears, selectedDate])

    return(
        <div className="dashboard" >
            <span className="breadcrumb-txt-black" >
                Galeri
            </span>
            <span className="breadcrumb-slash-black" >
                /
            </span>
            <div className="report-vito-container" >
                <div className="report-tab-container" >
                    <span className="tab-span-report-disabled" onClick={()=>history.push('/admin/report')}>
                        Laporan
                    </span>
                    <span className="tab-span-report-active">
                        Galeri
                    </span>
                </div>
                <div className="report-filter-container" >
                    <TextField label="Cari VITO" size="small" variant="outlined" borderColor="#C4C4C4"
                        InputProps={{
                            style:{
                                width: "248px",
                                height: "36px",
                            }
                        }}
                    />
                    <KeyboardDatePicker
                        style={{
                            marginLeft: 10
                        }}
                        views={["year"]}
                        minDate={new Date("2019-01-01")}
                        maxDate={new Date("2025-01-01")}
                        label="Pilih Tahun"
                        size="small"
                        clearable
                        inputVariant="outlined"
                        value={selectedDate}
                        onChange={date => handleDateChange(date)}
                        format="yyyy"
                        InputProps={{
                            style:{
                                width: "152px",
                                height: "36px",
                                paddingRight: 5
                            }
                        }}
                        keyboardIcon={<i className="fas fa-calendar-day" style={{ color: '#999999', fontSize: 15 }} />}
                    />
                </div>
                <div className="report-table-container" >
                <TableContainer className={classes.tableContainer}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead className={classes.tableRow}>
                            <TableRow>
                                <TableCell className={classes.tableRowTxt} style={{ width: 10 }} align="left">NO</TableCell>
                                <TableCell className={classes.tableRowTxt} style={{ minWidth: 100 }} align="left">NAMA & NEGARA</TableCell>
                                <TableCell className={classes.tableRowTxt} align="left">BULAN</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                vitos &&
                                vitos.map((user, i) => (    
                                    <RowColappse key={i} user={user} i={i} weekly={weekly} year={selectedDate}/>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                </div>
            </div>
        </div>
    )
}

const ButtonDeactive = withStyles((theme) => ({
    root: {
        minWidth: '135px',
        height: '34px',
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "16px",
        color: "#E23F81",
        transition: '0.5s',
        textTransform: 'capitalize',
        border: '1px solid #DDDDDD',
        '&:hover': {
            backgroundColor: '#E23F81',
            color: 'white',
        },
    },
}))(Button);

const RowColappse = (props) => {
    const history = useHistory()
    let {
        i,
        user,
        weekly,
        year
    } = props
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [monthSelect, setMonthSelect] = useState(false);
    const [weeklySelect, setWeeklySelect] = useState(false);
    
    function toGallery(year, month ) {
        history.push(`/admin/report/galleries/${user.id}?year=${year.getFullYear()}&month=${(month).toLowerCase()}`)
    }

    useEffect(()=>{
        setMonthSelect(false)
        setOpen(false)
    },[weekly])

    useEffect(()=>{
        if (weekly) {
            const week = weekly.filter(e=> e.month === monthSelect )[0]
            if (week) {
                setWeeklySelect(week.weekly)
            }
        }
    },[monthSelect])

    return(
        <Fragment>
            <TableRow style={{ backgroundColor: i % 2 === 0 ? '#FAFAFA' : '#FFFFFF' }}>
                <TableCell align="left" >{ i+=1 }</TableCell>
                <TableCell align="left" className={classes.tableCellDouble} >
                    <div className="tableCellDoubleReport" >
                        <span className="report-tablecellName" >
                            { user.name }
                        </span>
                        <span className="report-tablecellNation" >
                            {user.country} { user.area && ' - ' + user.area }
                        </span>
                    </div>
                </TableCell>
                <TableCell align="left">
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: "space-between"
                    }}  >
                        {
                            weekly &&
                            weekly.map((data, i) => (
                            monthSelect === data.month ?
                                <MonthButtonActive key={i}
                                    style={{
                                        // marginRight: i !== (data.month).length - 1 && 5
                                        marginTop: 2,
                                        marginBottom: 2
                                    }}
                                >
                                    {data.month}
                                </MonthButtonActive>
                                :
                                <MonthButtonDeactive key={i}
                                    style={{
                                        // marginRight: i !== (data.month).length - 1 && 5
                                        marginTop: 2,
                                        marginBottom: 2
                                    }}
                                    onClick={() => toGallery(year, data.month)}
                                >
                                    { data.month }
                                </MonthButtonDeactive>
                            ))
                        }
                    </div>
                </TableCell>
            </TableRow>
        </Fragment>
    )
}