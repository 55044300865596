import React from 'react'
import { Box, makeStyles, Typography, Button, IconButton } from '@material-ui/core'

import MessageIcon from '@material-ui/icons/Message';
import EditIcon from '@material-ui/icons/Edit';
import ShareReport from '../../Modal/ShareReport'
import DetailReportWeekly from '../../Modal/DetailReportWeekly'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw, ContentState, convertFromHTML } from 'draft-js';
import { useSelector } from 'react-redux'

const useStyle = makeStyles({
    buttonRead: {
        color: "#E23F81",
        background: "rgba(226, 63, 129, 0.1)",
        borderRadius: "2px",
        width: "100px",
        border: "none"
    },
    root: {
        padding: "1em",
        width: "95%",
        maxWidth: "95%",
        height: "264",
        backgroundColor: "white",
        marginTop: "12px",
        borderRadius: "3px"

    },
    caption: {
        color: "#999999",
    },
    reportBox: {
        marginBottom: "0.5em",
        padding: '0.2em 0.5em',
        width: "fit-content",
        background: "rgba(20, 0, 145, 0.1)",
        borderRadius: "2px",
    },
    reportText: {
        fontWeight: "normal",
        fontSize: "10px",
        lineHeight: "9px",
        color: "#140090",
    },
    partnership: {
        color: "#DA9D02",
        lineHeight: "19px",
        marginTop: "0.5em",
    }

})

export default function PIN(props) {
    const nameProfile = useSelector(state=> state.profileState.name)
    const photoProfile = useSelector(state=> state.profileState.photo)
    const {
        data,
        weeklyList,
        CategoryId,
        dataRead,
        vito,
        Week
    } = props
    const classes = useStyle()

    const showDate = (dateParams) => {
        if (dateParams) {
            let start = new Date(dateParams).getDate()
            let startMonth = new Date(dateParams).getMonth()
            let monthStart = ''
            let yearResult = new Date(dateParams).getFullYear()
            switch (startMonth) {
                case 0 :
                    monthStart = 'Januari'
                    break;
                case 1 :
                    monthStart = 'Februari'
                    break;
                case 2 :
                    monthStart = 'Maret'
                    break;
                case 3 :
                    monthStart = 'April'
                    break;
                case 4 :
                    monthStart = 'Mei'
                    break;
                case 5 :
                    monthStart = 'Juni'
                    break;
                case 6 :
                    monthStart = 'Juli'
                    break;
                case 7 :
                    monthStart = 'Agustus'
                    break;
                case 8 :
                    monthStart = 'September'
                    break;
                case 9 :
                    monthStart = 'Oktober'
                    break;
                case 10 :
                    monthStart = 'November'
                    break;
                case 11 :
                    monthStart = 'Desember'
                    break;
                default:
                    monthStart = ''
                    break;
            }
            let hour = new Date(dateParams).getHours()
            let minute = new Date(dateParams).getMinutes()
            let second = new Date(dateParams).getSeconds()
            
            return start + ' '+ monthStart +' '+ yearResult +' '+ (hour < 10 ? '0'+hour : hour) +':'+(minute < 10 ? '0'+minute : minute)+':'+(second < 10 ? '0'+second : second)+' WIB'
        }
    }

    return (
        <Box className={classes.root} boxShadow={1}>

            <Box className={classes.textBox}>
                <div style={{ display: 'flex' }}  >
                    <Box className={classes.reportBox}>
                        <Typography className={classes.reportText}>Report Without Workplan</Typography>
                    </Box>
                    {
                        data &&
                        data.credit != 'false' &&
                        <Box className={classes.reportBox} style={{ marginLeft: 10, backgroundColor: '#f9efd6' }} >
                            <Typography className={classes.reportText} style={{ color: '#ae7d01' }}>
                                {
                                    data &&
                                    data.credit === 'meminta' ? 'Credit' :
                                    data &&
                                    data.credit === 'disetujui' ? 'Credit Approved' :
                                    data &&
                                    data.credit === 'ditolak' ? 'Credit Rejected' :
                                    ''
                                }
                            </Typography>
                        </Box>
                    }
                </div>
                <Typography className={classes.partnership} variant="subtitle2">{data ? data.category ? data.category.includes('Partnership with Airline') ? 'Partnership with Airlines' : data.category : data.category : '-'}</Typography>
                <Typography variant="caption" className={classes.caption}>Dibuat : { showDate(data && data.createdAt) }</Typography>

                <Typography marginTop="0.5em">
                    <Editor
                        editorState={data && (data.report && data.report != 'null') ?
                            EditorState.createWithContent(convertFromRaw(JSON.parse(data.report)))
                            :
                            EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                        }
                        toolbarHidden
                        readOnly
                        toolbar={false}
                        editorClassName={classes.editor}
                    />
                </Typography>

                <Box display="flex" marginTop="1em">
                    <Box width={1 / 2}>
                        <DetailReportWeekly
                            nameProfile={nameProfile}
                            photoProfile={photoProfile}
                            data={data}
                            weeklyList={weeklyList}
                            dataRead={dataRead}
                            CategoryId={CategoryId}
                            vito={vito}
                            Week={Week}
                        />
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" paddingX="12px" width={1 / 2}>
                        {/* <IconButton size="small"  aria-label="delete">
                            <ShareIcon size="small" color="disabled" />
                        </IconButton> */}
                        {/* <ShareReport/> */}
                        {/* <IconButton size="small" aria-label="delete">
                            <MessageIcon size="small" color="disabled" />
                        </IconButton>
                        <IconButton size="small" aria-label="delete">
                            <EditIcon size="small" color="disabled" />
                        </IconButton> */}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
