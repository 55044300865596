import React,{ useEffect } from 'react'
import { Box, makeStyles, Typography, Button, IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DetailReportWeekly from '../../Modal/DetailReportWeekly';
import DetailReportWeeklyEdit from '../../Modal/DetailReportWeekly/indexEdit';
import ShareReport from '../../Modal/ShareReport'
import { useHistory, useParams } from 'react-router-dom'
import DeleteAlert from '../../Modal/DeleteAlert/downgrade'
import DeleteReportCredit from '../../Modal/DeleteAlert/deleteReportCreditCard'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw, ContentState, convertFromHTML } from 'draft-js';
import { useSelector } from 'react-redux'

const useStyle = makeStyles({
    root: {
        width: "95%",
        padding: "1em",
        height: "264",
        backgroundColor: "white",
        marginTop: "12px",
        borderRadius: "3px"
    },
    textBox: {
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor: "#DDDDDD",
        marginTop: "0.5em",
        paddingBottom: "0.5em"
    },
    caption: {
        color: "#999999",
        marginTop: "0.5em",
        paddingBottom: "0.5em"
    },
    workplanBox: {
        padding: '0.2em 0.5em',
        marginBottom: "0.5em",
        width: "fit-content",
        background: "rgba(226, 63, 129, 0.1)",
        borderRadius: "2px",
    },
    workplanText: {
        fontWeight: "normal",
        fontSize: "10px",
        lineHeight: "9px",
        color: "#E23F81",
    },
    reportBox: {
        marginTop: "0.5em",
        marginBottom: "0.5em",
        padding: '0.2em 0.5em',
        width: "fit-content",
        background: "rgba(20, 0, 145, 0.1)",
        borderRadius: "2px",
    },
    reportText: {
        fontWeight: "normal",
        fontSize: "10px",
        lineHeight: "9px",
        color: "#140090",
    },
    partnership: {
        color: "#DA9D02",
        lineHeight: "19px"
    },
    editor:{
        maxHeight: 80,
        overflow: 'hidden'
    }
})

export default function PIN(props) {
    const classes = useStyle()
    const history = useHistory()
    const { year } = useParams()
    const nameProfile = useSelector(state=> state.profileState.name)
    const photoProfile = useSelector(state=> state.profileState.photo)
    const {
        dataRead,
        weeklyList,
        data,
        CategoryId,
        weekId,
        key
    } = props

    const showDate = (dateParams) => {
        if (dateParams) {
            let start = new Date(dateParams).getDate()
            let startMonth = new Date(dateParams).getMonth()
            let monthStart = ''
            let yearResult = new Date(dateParams).getFullYear()
            switch (startMonth) {
                case 0 :
                    monthStart = 'January'
                    break;
                case 1 :
                    monthStart = 'February'
                    break;
                case 2 :
                    monthStart = 'March'
                    break;
                case 3 :
                    monthStart = 'April'
                    break;
                case 4 :
                    monthStart = 'May'
                    break;
                case 5 :
                    monthStart = 'June'
                    break;
                case 6 :
                    monthStart = 'July'
                    break;
                case 7 :
                    monthStart = 'August'
                    break;
                case 8 :
                    monthStart = 'September'
                    break;
                case 9 :
                    monthStart = 'October'
                    break;
                case 10 :
                    monthStart = 'November'
                    break;
                case 11 :
                    monthStart = 'December'
                    break;
                default:
                    monthStart = ''
                    break;
            }
            let hour = new Date(dateParams).getHours()
            let minute = new Date(dateParams).getMinutes()
            let second = new Date(dateParams).getSeconds()
            
            return start + ' '+ monthStart +' '+ yearResult +' '+ (hour < 10 ? '0'+hour : hour) +':'+(minute < 10 ? '0'+minute : minute)+':'+(second < 10 ? '0'+second : second)+' WIB'
        }
    }

    function editReport() {
        history.push(`/vito/report/weekly/${year}/edit/${data.id}`,{ data, weeklyList })
    }

    return (
        <Box key={key} className={classes.root} boxShadow={1}>

            <Box className={classes.textBox}>
                <Box className={classes.workplanBox}>
                    <Typography className={classes.workplanText}>Work Plan</Typography>
                </Box>
                <Typography className={classes.partnership} variant="subtitle2">{data ? data.category ? data.category.includes('Partnership with Airline') ? 'Partnership with Airlines' : data.category : data.category : '-'}</Typography>
                <Typography variant="caption" className={classes.caption}>Created : { showDate(data && data.createdAt)}</Typography>
                <Box>
                    <Typography>{data ? data.description : '-'}</Typography>
                </Box>
            </Box>

            <Box >
                <div style={{ display: 'flex' }}  >
                    <Box className={classes.reportBox}>
                        <Typography className={classes.reportText}>Report</Typography>
                    </Box>
                    {
                        data &&
                        data.credit != 'false' &&
                        <Box className={classes.reportBox} style={{ marginLeft: 10, backgroundColor: '#f9efd6' }} >
                            <Typography className={classes.reportText} style={{ color: '#ae7d01' }}>
                                {
                                    data &&
                                    data.credit === 'meminta' ? 'Credit' :
                                    data &&
                                    data.credit === 'disetujui' ? 'Credit Approved' :
                                    data &&
                                    data.credit === 'ditolak' ? 'Credit Rejected' :
                                    ''
                                }
                            </Typography>
                        </Box>
                    }
                    {
                        data && data.tagMarkom === true &&
                        <Box className={classes.reportBox} style={{ marginLeft: 10, backgroundColor: '#ADDFFF' }} >
                            <Typography className={classes.reportText} style={{ color: '#0000FF' }}>Markom</Typography>
                        </Box>
                    }
                    {
                        data && data.tagRegional === true &&
                        <Box className={classes.reportBox} style={{ marginLeft: 10, backgroundColor: '#C3FDB8' }} >
                            <Typography className={classes.reportText} style={{ color: '#00A36C' }}>Regional</Typography>
                        </Box>
                    }
                    {
                        data && data.tagEkraf === true &&
                        <Box className={classes.reportBox} style={{ marginLeft: 10, backgroundColor: '#FAF884' }} >
                            <Typography className={classes.reportText} style={{ color: '#EE9A4D' }}>Ekraf</Typography>
                        </Box>
                    }
                    {
                        data && data.tagPartner !== '' && data.tagPartner !== null &&
                        <Box className={classes.reportBox} style={{ marginLeft: 10, backgroundColor: '#34495E' }} >
                            <Typography className={classes.reportText} style={{ color: '#EBEDEF' }}>{ data.countPartner } Partner</Typography>
                        </Box>
                    }
                </div>
                <Typography variant="caption" className={classes.caption}>Created : { showDate(data && data.reportCreatedAt)}</Typography>
                <Box>
                    <Editor
                        editorState={data && ((data.report != 'null') && (data.report)) ?
                            EditorState.createWithContent(convertFromRaw(JSON.parse(data.report)))
                            :
                            EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                        }
                        toolbarHidden
                        readOnly
                        toolbar={false}
                        editorClassName={classes.editor}
                    />
                </Box>
                <Box display="flex" marginTop="1em">
                    <Box width={1 / 2}>
                        <DetailReportWeekly
                            nameProfile={nameProfile}
                            photoProfile={photoProfile}
                            data={data}
                            weeklyList={weeklyList}
                        />
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" paddingX="12px" width={1 / 2}>
                        {
                            data && 
                            // (data.credit === 'meminta' || data.credit === 'disetujui' || data.credit === 'ditolak') ?
                            (data.credit === 'disetujui' || data.credit === 'ditolak') ?
                            <DeleteReportCredit categoryReport="Weekly Report" CategoryId={CategoryId} weekId={weekId} read={dataRead} data={data} />
                            :
                            <DeleteAlert categoryReport="Weekly Report" CategoryId={CategoryId} weekId={weekId} read={dataRead} data={data} />
                        }
                        {/* <ShareReport /> */}
                        {/* <Box borderRadius="50%" padding="0.25rem" style={{ background: "#FAFAFA" }}>
                            <IconButton size="small" aria-label="delete">
                                <MessageIcon size="small" color="disabled" />
                            </IconButton>
                        </Box> */}
                        <DetailReportWeeklyEdit
                            nameProfile={nameProfile}
                            photoProfile={photoProfile}
                            data={data}
                            weeklyList={weeklyList}
                        />
                        <Box borderRadius="50%" padding="0.25rem" style={{ background: "#FAFAFA" }}>
                            <IconButton onClick={() => editReport()} size="small" aria-label="delete">
                                <EditIcon size="small" color="disabled" />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>

            </Box>

        </Box>
    )
}
