import React from 'react'

import {
    AlignmentType,
    Document,
    HeadingLevel,
    Packer,
    Paragraph,
    TabStopPosition,
    TabStopType,
    TextRun
} from 'docx';

import { convertToRaw, EditorState, convertFromRaw } from 'draft-js';

export class createDocVito {

    create(props, user, country, area, weeklyList, weekId){
        let weekFix
        if (weeklyList) {
            weeklyList.forEach((week)=>{
                if (week.id == weekId) {
                    weekFix = week
                    return
                }
            })
        }
        const document = new Document({
            styles:{
                paragraphStyles: [
                    {
                        id: 'reportStyle-date',
                        name: "Report Style Date",
                        basedOn: "Normal",
                        next: "Normal",
                        quickFormat: true,
                        run: {
                            size: 20,
                            color: "black",
                        },
                        paragraph: {
                            indent: { left: 650, hanging: 0 },
                        },
                    },
                    {
                        id: "reportStyle",
                        name: "Report Style",
                        next: "Normal",
                        basedOn: "Normal",
                        quickFormat: true,
                        run: {
                            size: 20,
                            color: "black",
                        },
                        paragraph: {
                            indent: { left: 650, hanging: 0 },
                        },
                    },
                    {
                        id: "Heading1",
                        name: "Heading 1",
                        basedOn: "Normal",
                        next: "Normal",
                        quickFormat: true,
                        run: {
                            size: 24,
                            bold: true,
                            underline: true,
                            color: "black",
                        },
                        // paragraph: {
                        //     spacing: {
                        //         after: 120,
                        //     },
                        // },
                    },
                    {
                        id: "Heading3",
                        name: "Heading 3",
                        basedOn: "Normal",
                        next: "Normal",
                        quickFormat: true,
                        run: {
                            size: 20,
                            bold: true,
                            color: "black",
                        },
                        // paragraph: {
                        //     spacing: {
                        //         after: 120,
                        //     },
                        // },
                    },
                    {
                        id: "Heading5",
                        name: "Heading 5",
                        basedOn: "Normal",
                        next: "Normal",
                        quickFormat: true,
                        run: {
                            size: 18,
                            bold: true,
                            color: "black",
                        },
                        paragraph: {
                            indent: { left: 650, hanging: 0 },
                        },
                    },
                ]
            },
            numbering: {
                config: [
                    {
                        reference: "H1point",
                        levels: [
                            {
                                level: 0,
                                format: "decimal",
                                text: "1.",
                                alignment: AlignmentType.LEFT,
                                style: {
                                    paragraph: {
                                        indent: { left: 0, hanging: 0 },
                                    },
                                },
                            },
                            {
                                level: 1,
                                format: "decimal",
                                text: "2.",
                                alignment: AlignmentType.LEFT,
                                style: {
                                    paragraph: {
                                        indent: { left: 0, hanging: 0 },
                                    },
                                },
                            },
                            {
                                level: 2,
                                format: "decimal",
                                text: "3.",
                                alignment: AlignmentType.LEFT,
                                style: {
                                    paragraph: {
                                        indent: { left: 0, hanging: 0 },
                                    },
                                },
                            },
                        ],
                    },
                    {
                        reference: "H3Char",
                        levels: [
                            {
                                level: 0,
                                format: "upperLetter",
                                text: "A.",
                                alignment: AlignmentType.LEFT,
                                style: {
                                    paragraph: {
                                        indent: { left: 150, hanging: 0 },
                                    },
                                },
                            },
                            {
                                level: 1,
                                format: "upperLetter",
                                text: "B.",
                                alignment: AlignmentType.LEFT,
                                style: {
                                    paragraph: {
                                        indent: { left: 150, hanging: 0 },
                                    },
                                },
                            },
                            {
                                level: 2,
                                format: "upperLetter",
                                text: "C.",
                                alignment: AlignmentType.LEFT,
                                style: {
                                    paragraph: {
                                        indent: { left: 150, hanging: 0 },
                                    },
                                },
                            },
                            {
                                level: 3,
                                format: "upperLetter",
                                text: "D.",
                                alignment: AlignmentType.LEFT,
                                style: {
                                    paragraph: {
                                        indent: { left: 150, hanging: 0 },
                                    },
                                },
                            },
                        ],
                    }
                ],
            },
            sections: [{
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: `${country ? (country).toUpperCase() : '-'} - ${area ? area === 'tidak ada area' ? 'NO AREA' : (area).toUpperCase() : '-' } | ${user ? user : '-' } | Week ${weekFix ? weekFix.week ? weekFix.week : '-' : '-' } (${this.showDateWeek(weekFix && weekFix.start)} - ${this.showDateWeek(weekFix && weekFix.end)})`,
                                italics: true,
                            })
                        ],
                    }),
// ------------------------------------------------------------------------------------ A
                    new Paragraph({
                        text: "NETWORKING & PARTNERSHIP",
                        heading: HeadingLevel.HEADING_1,
                        numbering: {
                            reference: "H1point",
                            level: 0,
                        },
                    }),
                        ...props[0]
                            .filter(a=> (!a.category || a.category == 'null') )
                            .map((dataA,i)=>{
                                const arr = [];
                                arr.push(this.createHeader(dataA,i))
                                arr.push(this.createContent(dataA))
                                arr.push(this.createCreateDate(dataA))
                                return arr
                            })
                            .reduce((prev, curr) => prev.concat(curr), []),
                        new Paragraph({
                            text: "Partnership with Airlines",
                            heading: HeadingLevel.HEADING_3,
                            numbering: {
                                reference: "H3Char",
                                level: 0,
                            },
                        }),
                        ...props[0]
                            .filter(a=> (a && a.category) && (a.category).includes('Partnership with Airline') )
                            .map((dataA,i)=>{
                                const arr = [];
                                arr.push(this.createHeader(dataA,i))
                                arr.push(this.createContent(dataA))
                                arr.push(this.createCreateDate(dataA))
                                return arr
                            })
                            .reduce((prev, curr) => prev.concat(curr), []),
                        new Paragraph({
                            text: "Partnership with Online Travel Agent (OTA) or Travel Agent/Tour Operator",
                            heading: HeadingLevel.HEADING_3,
                            numbering: {
                                reference: "H3Char",
                                level: 1,
                            },
                        }),
                        ...props[0]
                            .filter(a=> (a && a.category) && (a.category).includes('Partnership with Online') )
                            .map((dataA,i)=>{
                                const arr = [];
                                arr.push(this.createHeader(dataA,i))
                                arr.push(this.createContent(dataA))
                                arr.push(this.createCreateDate(dataA))
                                return arr
                            })
                            .reduce((prev, curr) => prev.concat(curr), []),
                        new Paragraph({
                            text: "Partnership with Association",
                            heading: HeadingLevel.HEADING_3,
                            numbering: {
                                reference: "H3Char",
                                level: 2,
                            },
                        }),
                        ...props[0]
                            .filter(a=> (a && a.category) && (a.category).includes('Partnership with Association') )
                            .map((dataA,i)=>{
                                const arr = [];
                                arr.push(this.createHeader(dataA,i))
                                arr.push(this.createContent(dataA))
                                arr.push(this.createCreateDate(dataA))
                                return arr
                            })
                            .reduce((prev, curr) => prev.concat(curr), []),
                        new Paragraph({
                            text: "Partnership with Related Travel Industry",
                            heading: HeadingLevel.HEADING_3,
                            numbering: {
                                reference: "H3Char",
                                level: 3,
                            },
                        }),
                        ...props[0]
                            .filter(a=> (a && a.category) && (a.category).includes('Partnership with Related') )
                            .map((dataA,i)=>{
                                const arr = [];
                                arr.push(this.createHeader(dataA,i))
                                arr.push(this.createContent(dataA))
                                arr.push(this.createCreateDate(dataA))
                                return arr
                            })
                            .reduce((prev, curr) => prev.concat(curr), []),
// ------------------------------------------------------------------------------------ B
                    new Paragraph({
                        text: "MARKET INTELLIGENCE",
                        heading: HeadingLevel.HEADING_1,
                        numbering: {
                            reference: "H1point",
                            level: 1,
                        },
                    }),
                    ...props[1]
                        .filter(b=> (!b.category || b.category == 'null') )
                        .map((dataB,i)=>{
                            const arr = [];
                            arr.push(this.createHeader(dataB,i))
                            arr.push(this.createContent(dataB))
                            arr.push(this.createCreateDate(dataB))
                            return arr
                        })
                        .reduce((prev, curr) => prev.concat(curr), []),
                    new Paragraph({
                        text: "Market Update (Strategy and trend issues)",
                        heading: HeadingLevel.HEADING_3,
                        numbering: {
                            reference: "H3Char",
                            level: 0,
                        },
                    }),
                    ...props[1]
                        .filter(b=> (b.category).includes('Market Update') )
                        .map((dataB,i)=>{
                            const arr = [];
                            arr.push(this.createHeader(dataB,i))
                            arr.push(this.createContent(dataB))
                            arr.push(this.createCreateDate(dataB))
                            return arr
                        })
                        .reduce((prev, curr) => prev.concat(curr), []),
                    new Paragraph({
                        text: "Outbound Update (Number of outbound and market share)",
                        heading: HeadingLevel.HEADING_3,
                        numbering: {
                            reference: "H3Char",
                            level: 1,
                        },
                    }),
                    ...props[1]
                        .filter(b=> (b.category).includes('Outbound Update') )
                        .map((dataB,i)=>{
                            const arr = [];
                            arr.push(this.createHeader(dataB,i))
                            arr.push(this.createContent(dataB))
                            arr.push(this.createCreateDate(dataB))
                            return arr
                        })
                        .reduce((prev, curr) => prev.concat(curr), []),
                    new Paragraph({
                        text: "Competitor Update",
                        heading: HeadingLevel.HEADING_3,
                        numbering: {
                            reference: "H3Char",
                            level: 2,
                        },
                    }),
                    ...props[1]
                        .filter(b=> (b.category).includes('Competitor Update') )
                        .map((dataB,i)=>{
                            const arr = [];
                            arr.push(this.createHeader(dataB,i))
                            arr.push(this.createContent(dataB))
                            arr.push(this.createCreateDate(dataB))
                            return arr
                        })
                        .reduce((prev, curr) => prev.concat(curr), []),
// ------------------------------------------------------------------------------------ C
                new Paragraph({
                    text: "PROMOTIONAL SUPPORT",
                    heading: HeadingLevel.HEADING_1,
                    numbering: {
                        reference: "H1point",
                        level: 2,
                    },
                }),
                ...props[2]
                    .filter(c=> (!c.category || c.category == 'null') )
                    .map((dataC,i)=>{
                        const arr = [];
                        arr.push(this.createHeader(dataC,i))
                        arr.push(this.createContent(dataC))
                        arr.push(this.createCreateDate(dataC))
                        return arr
                    })
                    .reduce((prev, curr) => prev.concat(curr), []),
                    new Paragraph({
                        text: "Regional Promotion (SM, TM, FT, Festival)",
                        heading: HeadingLevel.HEADING_3,
                        numbering: {
                            reference: "H3Char",
                            level: 0,
                        },
                    }),
                    ...props[2]
                        .filter(c=> (c.category).includes('Regional Promotion') )
                        .map((dataC,i)=>{
                            const arr = [];
                            arr.push(this.createHeader(dataC,i))
                            arr.push(this.createContent(dataC))
                            arr.push(this.createCreateDate(dataC))
                            return arr
                        })
                        .reduce((prev, curr) => prev.concat(curr), []),
                    new Paragraph({
                        text: "Online Promotion (Website & Social Media)",
                        heading: HeadingLevel.HEADING_3,
                        numbering: {
                            reference: "H3Char",
                            level: 1,
                        },
                    }),
                    ...props[2]
                        .filter(c=> (c.category).includes('Online Promotion') )
                        .map((dataC,i)=>{
                            const arr = [];
                            arr.push(this.createHeader(dataC,i))
                            arr.push(this.createContent(dataC))
                            arr.push(this.createCreateDate(dataC))
                            return arr
                        })
                        .reduce((prev, curr) => prev.concat(curr), []),
                    new Paragraph({
                        text: "PR-Ing and Customer Service",
                        heading: HeadingLevel.HEADING_3,
                        numbering: {
                            reference: "H3Char",
                            level: 2,
                        },
                    }),
                    ...props[2]
                        .filter(c=> (c.category).includes('Customer Service') )
                        .map((dataC,i)=>{
                            const arr = [];
                            arr.push(this.createHeader(dataC,i))
                            arr.push(this.createContent(dataC))
                            arr.push(this.createCreateDate(dataC))
                            return arr
                        })
                        .reduce((prev, curr) => prev.concat(curr), []),
                ]
            }]
        })
        return document;
    }

    createHeader(props,i){
        if (props.status === 'workplan' || props.status === 'report') {
            return new Paragraph({
                text: `a ${i+=1}. Workplan: ${props && props.description ? props.description : '-'}`,
                heading: HeadingLevel.HEADING_5,
            })
        } else if (props.status === "onlyreport"){
            return new Paragraph({
                text: `a ${i+=1}. Report without Workplan: ${props && props.description ? props.description : '-'}`,
                heading: HeadingLevel.HEADING_5,
            })
        }
    }

    createContent(props){
        let data;
        let blocks;
        let value;
        if (props && ((props.report != 'null') && (props.report))) {
            data = EditorState.createWithContent(convertFromRaw(JSON.parse(props.report)))
            blocks = convertToRaw(data.getCurrentContent()).blocks;
            value  = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
        } else {
            value = '-'
        }
        return new Paragraph({
            text: value,
            style: "reportStyle",
        })
    }

    createCreateDate(props){
        return  new Paragraph({
            text: `created: ${this.showDate(props && props.createdAt)}`,
            style: "reportStyle-date",
        })
    }
    
    showDate(dateParams){
        if (dateParams) {
            let start = new Date(dateParams).getDate()
            let startMonth = new Date(dateParams).getMonth()
            let monthStart = ''
            let yearResult = new Date(dateParams).getFullYear()
            switch (startMonth) {
                case 0 :
                    monthStart = 'January'
                    break;
                case 1 :
                    monthStart = 'February'
                    break;
                case 2 :
                    monthStart = 'March'
                    break;
                case 3 :
                    monthStart = 'April'
                    break;
                case 4 :
                    monthStart = 'May'
                    break;
                case 5 :
                    monthStart = 'June'
                    break;
                case 6 :
                    monthStart = 'July'
                    break;
                case 7 :
                    monthStart = 'August'
                    break;
                case 8 :
                    monthStart = 'September'
                    break;
                case 9 :
                    monthStart = 'October'
                    break;
                case 10 :
                    monthStart = 'November'
                    break;
                case 11 :
                    monthStart = 'December'
                    break;
                default:
                    monthStart = ''
                    break;
            }
            let hour = new Date(dateParams).getHours()
            let minute = new Date(dateParams).getMinutes()
            let second = new Date(dateParams).getSeconds()
            
            return start + ' '+ monthStart +' '+ yearResult +' '+ (hour < 10 ? '0'+hour : hour) +':'+(minute < 10 ? '0'+minute : minute)+':'+(second < 10 ? '0'+second : second)+' WIB'
        }
    }

    showDateWeek(dateParams){
        if (dateParams) {
            let [day, month, year] = dateParams.split("/")
            let date = new Date(year, month - 1, day).getDate() 
            let monthNumber = new Date(year, month - 1, day).getMonth()
            let monthResult = ''
            let yearResult = new Date(year, month - 1, day).getFullYear()
            switch (monthNumber) {
                case 0 :
                    monthResult = 'January'
                    break;
                case 1 :
                    monthResult = 'February'
                    break;
                case 2 :
                    monthResult = 'March'
                    break;
                case 3 :
                    monthResult = 'April'
                    break;
                case 4 :
                    monthResult = 'May'
                    break;
                case 5 :
                    monthResult = 'June'
                    break;
                case 6 :
                    monthResult = 'July'
                    break;
                case 7 :
                    monthResult = 'August'
                    break;
                case 8 :
                    monthResult = 'September'
                    break;
                case 9 :
                    monthResult = 'October'
                    break;
                case 10 :
                    monthResult = 'November'
                    break;
                case 11 :
                    monthResult = 'December'
                    break;
                default:
                    monthResult = ''
                    break;
            }
            return date + ' ' + monthResult  + ' ' + yearResult
        }
    }
}