import axios from 'axios'
import { SERVER } from '../../server'
import {
    IS_LOGOUT,
    GET_DASHBOARDWORKPLAN,
    GET_DASHBOARDREPORT,
    GET_DASHBOARDMONTHLY,
    GET_MONTHLYDASH,
    GET_WEEKLYDASH,
    GET_BAGANMONTHDASH,
    GET_WEEKLYCHARTDASH,
    GET_BAGANWEEKDASH
} from "../constanta";

export const getWorkplan = () => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            const { data } = await axios({
                method: 'GET',
                url: `${SERVER}/report/workplan/dashboard`,
                headers: { token }
            })

            dispatch({
                type: GET_DASHBOARDWORKPLAN,
                data : data
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const getReport = (month) => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            const { data } = await axios({
                method: 'GET',
                url: `${SERVER}/report/dashboard/${month}`,
                headers: { token }
            })

            dispatch({
                type: GET_DASHBOARDREPORT,
                data : data
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const getMonthly = ( year ) => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            const { data } = await axios({
                method: 'GET',
                url: `${SERVER}/report/monthly/dashboard/${year}`,
                headers: { token }
            })

            dispatch({
                type: GET_DASHBOARDMONTHLY,
                data : data
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const getMonthlyDash = ( year ) => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            const { data } = await axios({
                method: 'GET',
                url: `${SERVER}/database/${year}`,
                headers: { token }
            })
            dispatch({
                type: GET_MONTHLYDASH,
                data : data
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const getWeeklyDash = ( year ) => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            const { data } = await axios({
                method: 'GET',
                url: `${SERVER}/database/list/${year}`,
                headers: { token }
            })
            dispatch({
                type: GET_WEEKLYDASH,
                data : data
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const getReportMonthChartDashboard = ( month, year ) => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            const { data } = await axios({
                method: 'GET',
                url: `${SERVER}/report/bagan-month/${month}/${year}`,
                headers: { token }
            })
            dispatch({
                type: GET_BAGANMONTHDASH,
                data : data
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const getWeeklyChartDash = ( year ) => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            const { data } = await axios({
                method: 'GET',
                url: `${SERVER}/database/${year}`,
                headers: { token }
            })
            dispatch({
                type: GET_WEEKLYCHARTDASH,
                data : data
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const getReportRightChartDashboard = ( month, year, week ) => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            const { data } = await axios({
                method: 'GET',
                url: `${SERVER}/report/bagan-week/${month}/${year}/${week}`,
                headers: { token }
            })
            dispatch({
                type: GET_BAGANWEEKDASH,
                data : data
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}
