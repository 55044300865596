import {
    GET_NOTIFICATION,
    GET_NOTIFICATIONCOUNT,
    GET_NOTIFICATIONRESTART,
    GET_NOTIFICATIONVITOCOUNT,
    GET_NOTIFICATIONPICCOUNT,
    GET_NOTIFICATIONPOCOUNT
} from "../constanta";

const initialState = {
    notification: [],
    count : 0,
    countVito : 0,
    countPIC : 0,
    countPO: 0
}

export default (state = initialState, action)=> {
    switch(action.type){
        case GET_NOTIFICATION:
            return {
                ...state,
                notification: [...state.notification , ...action.data]
            }
        case GET_NOTIFICATIONRESTART:
            return {
                ...state,
                notification: []
            }
        case GET_NOTIFICATIONCOUNT:
            return {
                ...state,
                count: action.data
            }
        case GET_NOTIFICATIONPOCOUNT:
            return {
                ...state,
                countPO: action.data
            }
        case GET_NOTIFICATIONVITOCOUNT:
            return {
                ...state,
                countVito: action.data
            }
        case GET_NOTIFICATIONPICCOUNT:
            return {
                ...state,
                countPIC: action.data
            }
        default: {
            return state
        }
    }
}