import { Box, IconButton, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import Gallery from '../Modal/Gallery'
import EditIcon from '@material-ui/icons/Edit';
import DeleteAlert from '../Modal/DeleteAlert/deleteGallery'
import DetailGallery from '../../Report/Modal/DetailGallery';
import BoxImg from '../Modal/DetailGallery/openImage'
import { datePickerDefaultProps } from '@material-ui/pickers/constants/prop-types';
import { useHistory } from 'react-router-dom'

export default function Index(props) {
    const [display, setDisplay] = useState('none')
    const { file, getReport, monthObj, weeklyDate } = props
    const history = useHistory()
    function hoverBox() {
        setDisplay("block")
    }

    function noHoverBox() {
        setDisplay("none")
    }

    function editGalleries() {
        history.push('/vito/report/galleries/edit',{ file : file, month: monthObj })
    }

    const getDate = (time) => {
        let date = new Date(time).getDate()
        let month = new Date(time).getMonth()+1
        let year = new Date(time).getFullYear()

        return `${year}-${month < 10 ? '0' + month : month}-${date < 10 ? '0' + date : date }`
    }

    const getWeek = (id) => {
        let find = '-'
        if (weeklyDate) {
            weeklyDate.map(data=>{
                if (data.id == id) {
                    find = data.week
                }
            })
        }
        return 'Week '+find
    }

    return (
        <>
            <Box onMouseEnter={hoverBox} display="flex" justifyContent="center" position="relative" onMouseLeave={noHoverBox} borderRadius="1em 1em 0em 0em" minWidth="100%" >
                <img src={props.image} style={{ width: "100%", height: '207px' }} />
                <Box display={display} position="absolute" width="100%" height="100%"  >
                    <Box display="flex" alignItems="center" justifyContent="center" borderRadius="1em 1em 0em 0em" width="100%" height="100%" style={{backgroundColor: "rgba(0, 0, 0, 0.4)"}}>
                        <DetailGallery data={file} weeklyDate={weeklyDate} text="See Detail" />
                    </Box>
                </Box>
            </Box>

            <Box paddingX="1em" marginTop="1em">
                <Typography variant="subtitle1" style={{ wordWrap: "break-word" }}> { file && file.name ? file.name : file.WeekId && getWeek(file.WeekId) }</Typography>
                <Typography variant="caption">{file && file.created ? getDate(file.created) : file.reportCreatedAt ? getDate(file.reportCreatedAt) : file.createdAt && getDate(file.createdAt) }</Typography>
            </Box>
            <Box padding="1em" display="flex">
                <Box width={1 / 2}>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" paddingRigth="1em" width={1 / 2}>
                    {
                        file && file.photo ?
                        <>
                            <IconButton size="small" aria-label="delete">
                                <BoxImg photosAll={file && file.photo ? file.photo : file.photos && file.photos} />
                            </IconButton>
                            <IconButton size="small" aria-label="delete" onClick={editGalleries}>
                                <EditIcon size="small" color="disabled"/>
                            </IconButton>
                            <DeleteAlert categoryReport="Gallery" file={file} getReport={getReport} monthObj={monthObj}/>
                        </>
                        :
                        <IconButton size="small" aria-label="delete" style={{ marginLeft: 'auto' }} >
                            <BoxImg photosAll={file && file.photo ? file.photo : file.photos && file.photos} />
                        </IconButton>
                    }
                </Box>
            </Box>
        </>
    )
}
