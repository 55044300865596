import React, { useEffect, useState } from 'react'
import { Box, Typography, TextField, withStyles, makeStyles, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Paper, requirePropFactory, Button } from '@material-ui/core'
// import DetailRekomendasi from '../../../../../component/LihatKPI/Modal/DetailRekomendasi'
// import DetailIndikator from '../../../../../component/LihatKPI/Modal/DetailIndikator'
import logoWI from '../../../../../images/bird.png'
import { useSelector, useDispatch } from 'react-redux'
// import { getOneVito } from '../../../../../../../store/actions/evaluationActions'
// COMPONENT

const StyledTableCell = withStyles((theme) => ({
    root: {
        border: "1px solid rgba(224, 224, 224, 1)"
    },
    head: {
        backgroundColor: "#F3F3F3",
        color: "#999999",
        fontWeight: 500,
        padding: "0.5em 1em",
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "#FAFAFA",
        },

    },
}))(TableRow);

function createData(no, komponen, indikator, target, realisasi, nilai) {
    return { no, komponen, indikator, target, realisasi, nilai };
}

const data3 = [
    { nama: "Mr. A", nilaiKualitatif: "65.0", nilaiKuantitatif: "18.0", skor: "83.0", huruf: "A", ket: "Excellent", rekomendasi: "To be re-appointed next year" },
]

const dataPassingGrade = [
    {rentangNIlai: "80 - 100", nilaiHuruf: "A", keterangan: "Excellent", rekomendasi: "To be re-appointed next year"},
    {rentangNIlai: "70 - 79", nilaiHuruf: "B", keterangan: "Good", rekomendasi: "To be re-appointed next year"},
    {rentangNIlai: "60 - 69", nilaiHuruf: "C", keterangan: "Fair", rekomendasi: "To look for comparison(s)"},
    {rentangNIlai: "50 - 59", nilaiHuruf: "D", keterangan: "Poor", rekomendasi: "To be replaced next year"}
]

const useStyles = makeStyles({
    boxProfile: {
        padding: "2em",
        background: "white",
    },
    caption: {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#999999"

    },
    tableroot: {
        marginTop: "1em",
        background: "white",
    },
    table: {
        minWidth: 700,
        boxShadow: "none"
    },
    buttonViewProfile: {
        background: "#FCECF2",
        color: "#E23F81",
        boxShadow: "none"
    },
    titleTable: {
        padding: "1em 2em",
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor: "#DDDDDD",
    },
    tableContainer: {
        boxShadow: "none",
        border: "1px solid #CCCCCC",
        borderRadius: "4px"
    },
    boxKeterangan: {
        display: "flex",
        marginLeft: "1em",
        width: "40%",
        padding: "2em",
        background: "white",
        justifyContent: "space-around"
    }

});

export default function PIN(props) {
    const classes = useStyles();
    // const Dispatch = useDispatch()
    const user = useSelector(state=> state.evaluationState.vito)
    const name = useSelector(state=> state.profileState.name)
    const country = useSelector(state=> state.profileState.country)
    const area = useSelector(state=> state.profileState.area)

    // useEffect(()=>{
    //     Dispatch(getOneVito(null,3))
    // },[])

    const getSQuantitative = (target, realitation) => {
        let total = Math.round((realitation/target)*100)
        if (total > 100) total = 100
        return total
    }

    // const getATQuantitative = (nilai1, nilai2, nilai3, nilai4) => {
    //     let total = Math.round((nilai1 + nilai2 + nilai3 + nilai4)/4)
    //     return total
    // }
    const getATQuantitative = (nilai1, nilai2, nilai3, nilai4, nilai5) => {
        let total = Math.round((nilai1 + nilai2 + nilai3 + nilai4 + nilai5)/5)
        if (total > 100) total = 100
        return total
    }

    const getBJQuantitative = (nilai1, nilai2, nilai3) => {
        let total = Math.round(nilai1 + nilai2 + nilai3)
        return total
    }

    // const getBTQuantitative = (nilai1, nilai2, nilai3) => {
    //     let total = Math.round((nilai1 + nilai2 + nilai3)/3)
    //     return total
    // }
    const getBTQuantitative = (nilai1, nilai2) => {
        let total = Math.round((nilai1 + nilai2)/2)
        return total
    }

    const getCTQuantitative = (nilai1, nilai2, nilai3, nilai4, nilai5) => {
        let total = Math.round((nilai1 + nilai2 + nilai3 + nilai4 + nilai5)/5)
        return total
    }

    return (
        <>
            <div className="dashboard" >
                <span className="breadcrumb-txt-active" >
                    Evaluation VITO
            </span>
                <span className="breadcrumb-slash-active" >
                    /
            </span>
                <span className="breadcrumb-txt-black" >
                    Detail Evaluation
            </span>
                <span className="breadcrumb-slash-black" >
                    /
            </span>
            </div>
            <div>
                <Box margin="1em" style={{ paddingBottom: 20 }} >
                    <Box height="7em" width="100%" display="flex">
                        <Box className={classes.boxProfile} display="flex" width="60%">
                            <Box height="3.5em" width="3.5em" border={1} borderRadius="50%" borderColor="grey.500" marginRight="2em" display="flex" justifyContent="center" alignItems="center">
                                <img width="100%" height="100%" src={logoWI} />
                            </Box>
                            <Box display="flex" flexDirection="column">
                                <Typography variant="h6">{ name ? name : '-' }</Typography>
                                <Typography variant="caption" className={classes.caption} style={{ textTransform: 'uppercase' }} >{ country ? country : '' } { (area && area !== 'tidak ada area') ? '- '+area : '' }</Typography>
                            </Box>
                        </Box>
                        <Box className={classes.boxKeterangan}>
                            <Typography style={{ color: "#666666" }}>Remarks</Typography>
                            <Box>
                                <Typography>I = Indicator</Typography>
                                <Typography>T = Target</Typography>
                            </Box>
                            <Box>
                                <Typography>R = Realisation</Typography>
                                <Typography>S = Score</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.tableroot}>
                        <Box className={classes.titleTable}>
                            {
                                user ? 
                                <Typography variant="h6">I. QUANTITATIVE PERFORMANCE SCORING </Typography>
                                :
                                <Typography variant="h6" style={{ color: 'red' }} > Please Contact Admin To Create Your Form Evaluation </Typography>
                            }
                        </Box>

                        <Box padding="1em 2em" style={{ opacity: !user && '0.3' }} >
                            <Box marginY="1em"><Typography color="primary">A. Networking & Partnership</Typography></Box>
                            <TableContainer component={Paper} className={classes.tableContainer}>
                                <Table className={classes.table} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell rowSpan={2}>NO</StyledTableCell>
                                            <StyledTableCell rowSpan={2} align="left">COMPONENT</StyledTableCell>
                                            <StyledTableCell rowSpan={2} align="left">INDICATOR (I)</StyledTableCell>
                                            <StyledTableCell align="center">TARGET (T)</StyledTableCell>
                                            <StyledTableCell align="center">REALISATION (R)</StyledTableCell>
                                            <StyledTableCell rowSpan={2} align="center">SCORE (S)</StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell align="center">2022</StyledTableCell>
                                            <StyledTableCell align="center">Q2 - Q4</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow>
                                            <StyledTableCell align="left">
                                                1
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                Partnership with Academics
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                Collaboration Activities with Academics Partners
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {user && user.A1a ? user.A1a : 0}
                                            </StyledTableCell>
                                            <StyledTableCell align="center" style={{ color: '#E23F81' }} >
                                                {user && user.A1Ra ? user.A1Ra : 0}
                                            </StyledTableCell>
                                            <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>
                                                {
                                                    user && user.A1a ?
                                                    getSQuantitative(user && user.A1a ? user.A1a : 0, user && user.A1Ra ? user.A1Ra : 0)
                                                    :
                                                    0
                                                }
                                            </StyledTableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <StyledTableCell align="left">
                                                2
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                Partnership with Business (TA/TO, Airlines, Accommodation, and others)
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                Collaboration Activities with Bussiness/Industry Partners
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                { user && user.A2a ? user.A2a : 0 }
                                            </StyledTableCell>
                                            <StyledTableCell align="center" style={{ color: '#E23F81' }} >
                                                {user && user.A2Ra ? user.A2Ra : 0}
                                            </StyledTableCell>
                                            <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>
                                                {
                                                    user && user.A2a ?
                                                    getSQuantitative(user && user.A2a ? user.A2a : 0, user && user.A2Ra ? user.A2Ra : 0)
                                                    :
                                                    0
                                                }
                                            </StyledTableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <StyledTableCell align="left">
                                                3
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                Partnership with Communities
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                Collaboration Activities with Communities Partners
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {user && user.A3a ? user.A3a : 0}
                                            </StyledTableCell>
                                            <StyledTableCell align="center" style={{ color: '#E23F81' }} >
                                                {user && user.A3Ra ? user.A3Ra : 0}
                                            </StyledTableCell>
                                            <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>
                                                {
                                                    user && user.A3a ?
                                                    getSQuantitative(user && user.A3a ? user.A3a : 0, user && user.A3Ra ? user.A3Ra : 0)
                                                    :
                                                    0
                                                }
                                            </StyledTableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <StyledTableCell align="left">
                                                4
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                Partnership with Governments
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                Collaboration Activities with Governments Partners
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {user && user.A4a ? user.A4a : 0}
                                            </StyledTableCell>
                                            <StyledTableCell align="center" style={{ color: '#E23F81' }} >
                                                {user && user.A4Ra ? user.A4Ra : 0}
                                            </StyledTableCell>
                                            <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>
                                                {
                                                    user && user.A4a ?
                                                    getSQuantitative(user && user.A4a ? user.A4a : 0, user && user.A4Ra ? user.A4Ra : 0)
                                                    :
                                                    0
                                                }
                                            </StyledTableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <StyledTableCell align="left">
                                                5
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                Partnership with Media
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                Collaboration Activities with Media Partners
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {user && user.A5a ? user.A5a : 0}
                                            </StyledTableCell>
                                            <StyledTableCell align="center" style={{ color: '#E23F81' }} >
                                                {user && user.A5Ra ? user.A5Ra : 0}
                                            </StyledTableCell>
                                            <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>
                                                {
                                                    user && user.A5a ?
                                                    getSQuantitative(user && user.A5a ? user.A5a : 0, user && user.A5Ra ? user.A5Ra : 0)
                                                    :
                                                    0
                                                }
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell colSpan={5}>
                                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                                    <Box maxWidth="75%">
                                                    <p>• Target for each VITO region must be based on data baseline/information on potential pentahelix’s partners in the region.</p>
                                                    <p>• All the Score are NO MORE than 100.</p>
                                                    <p>• If the Realisation is bigger than the Target, we will put the score in 100.</p>
                                                    </Box>
                                                    <Typography variant="subtitle2">TOTAL</Typography>
                                                </Box>
                                            </StyledTableCell>
                                                <StyledTableCell align="center">{
                                                    getATQuantitative(
                                                        user && user.A1a ?
                                                        getSQuantitative(user && user.A1a ? user.A1a : 0, user && user.A1Ra ? user.A1Ra : 0)
                                                        :
                                                        0,
                                                        user && user.A2a ?
                                                        getSQuantitative(user && user.A2a ? user.A2a : 0, user && user.A2Ra ? user.A2Ra : 0)
                                                        :
                                                        0,
                                                        user && user.A3a ?
                                                        getSQuantitative(user && user.A3a ? user.A3a : 0, user && user.A3Ra ? user.A3Ra : 0)
                                                        :
                                                        0,
                                                        user && user.A4a ?
                                                        getSQuantitative(user && user.A4a ? user.A4a : 0, user && user.A4Ra ? user.A4Ra : 0)
                                                        :
                                                        0,
                                                        user && user.A5a ?
                                                        getSQuantitative(user && user.A5a ? user.A5a : 0, user && user.A5Ra ? user.A5Ra : 0)
                                                        :
                                                        0
                                                    )
                                                }</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </Box>

                        <Box padding="1em 2em" style={{ opacity: !user && '0.3' }}>
                            <Box marginY="1em"><Typography color="primary">B. MARKET INTELLIGENCE</Typography></Box>
                            <TableContainer component={Paper} className={classes.tableContainer}>
                                <Table className={classes.table} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell rowSpan={2}>NO</StyledTableCell>
                                            <StyledTableCell rowSpan={2} align="left">COMPONENT</StyledTableCell>
                                            <StyledTableCell rowSpan={2} align="left">INDICATOR (I)</StyledTableCell>
                                            <StyledTableCell align="center">TARGET (T)</StyledTableCell>
                                            <StyledTableCell align="center">REALISATION (R)</StyledTableCell>
                                            <StyledTableCell rowSpan={2} align="center">SCORE (S)</StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell align="center">2022</StyledTableCell>
                                            <StyledTableCell align="center">Q2 - Q4</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow>
                                            <StyledTableCell align="center">
                                                1
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                Market Update
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                Number of Market Strategic Review
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {user && user.B1a ? user.B1a : 0}
                                            </StyledTableCell>
                                            <StyledTableCell align="center" style={{ color: '#E23F81' }} >
                                                {user && user.B1Ra ? user.B1Ra : 0}
                                            </StyledTableCell>
                                            <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>
                                                {
                                                    user && user.B1a ?
                                                    getSQuantitative(user && user.B1a ? user.B1a : 0, user && user.B1Ra ? user.B1Ra : 0)
                                                    :
                                                    0
                                                }
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell align="center">
                                                2
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                Competitor Update
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                Number of Analysis of Competitor Activities
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {user && user.B2a ? user.B2a : 0}
                                            </StyledTableCell>
                                            <StyledTableCell align="center" style={{ color: '#E23F81' }} >
                                                {user && user.B2Ra ? user.B2Ra : 0}
                                            </StyledTableCell>
                                            <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>
                                                {
                                                    user && user.B2a ?
                                                    getSQuantitative(user && user.B2a ? user.B2a : 0, user && user.B2Ra ? user.B2Ra : 0)
                                                    :
                                                    0
                                                }
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell colSpan={5}>
                                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                                    <Box maxWidth="75%">
                                                        <p>• Target for each VITO region must be based on monthly data baseline/information about the market situation in the region.</p>
                                                        <p>• All the Score are NO MORE than 100.</p>
                                                        <p>• If the Realisation is bigger than the Target, we will put the score in 100.</p> 
                                                    </Box>
                                                    <Typography variant="subtitle2">TOTAL</Typography>
                                                </Box>
                                            </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {
                                                        getBTQuantitative(
                                                            user && user.B1a ?
                                                            getSQuantitative(user && user.B1a ? user.B1a : 0, user && user.B1Ra ? user.B1Ra : 0)
                                                            :
                                                            0,
                                                            user && user.B2a ?
                                                            getSQuantitative(user && user.B2a ? user.B2a : 0, user && user.B2Ra ? user.B2Ra : 0)
                                                            :
                                                            0
                                                        )
                                                    }
                                                </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </Box>

                    </Box>

                </Box>  

            </div>
        </>

    )
}