import axios from 'axios'
import Swal from 'sweetalert2'
import { SERVER } from '../../server'
import {
    IS_LOGOUT,
    GET_EVALUATION,
    GET_EVALUATIONONE,
    GET_EVALUATIONVITOONE
} from "../constanta";

export const getEvaluationId = ( sendToken, id ) => async dispatch => {
    let tokenLocal = localStorage.getItem('token')
    if( tokenLocal || sendToken ) {
        try {
            let token = sendToken ? sendToken : tokenLocal
            Swal.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: () => {
                    Swal.showLoading()
                }
            })
            const { data } = await axios({
                method: 'get',
                url: `${SERVER}/evaluation/${id}`,
                headers: { token }
            })
            dispatch({
                type: GET_EVALUATION,
                data : data
            })
            Swal.close()
        } catch (err) {
        }
    } else {
        Swal.close()
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const getOne = ( sendToken, year, id ) => async dispatch => {
    let tokenLocal = localStorage.getItem('token')
    if( tokenLocal || sendToken ) {
        try {
            let token = sendToken ? sendToken : tokenLocal
            Swal.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: () => {
                    Swal.showLoading()
                }
            })
            const { data } = await axios({
                method: 'get',
                url: `${SERVER}/evaluation/${year}/${id}`,
                headers: { token }
            })
            dispatch({
                type: GET_EVALUATIONONE,
                data : data
            })
            Swal.close()
        } catch (err) {
        }
    } else {
        Swal.close()
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const updateScore = ( sendToken, id, code, score ) => async dispatch => {
    let tokenLocal = localStorage.getItem('token')
    if( tokenLocal || sendToken ) {
        try {
            let token = sendToken ? sendToken : tokenLocal
            const { data } = await axios({
                method: 'PATCH',
                url: `${SERVER}/evaluation/${code}/${id}`,
                data: { score },
                headers: { token }
            })
            dispatch(getOne(null, 4, id))
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const createdEvaluation = ( sendToken, id ) => async dispatch => {
    let tokenLocal = localStorage.getItem('token')
    if( tokenLocal || sendToken ) {
        try {
            let token = sendToken ? sendToken : tokenLocal
            const { data } = await axios({
                method: 'post',
                url: `${SERVER}/evaluation/${id}`,
                headers: { token }
            })
            dispatch(getEvaluationId(null, 4))
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const getOneVito = ( sendToken, year ) => async dispatch => {
    let tokenLocal = localStorage.getItem('token')
    if( tokenLocal || sendToken ) {
        try {
            let token = sendToken ? sendToken : tokenLocal
            const { data } = await axios({
                method: 'get',
                url: `${SERVER}/evaluation/vito/${year}`,
                headers: { token }
            })
            dispatch({
                type: GET_EVALUATIONVITOONE,
                data : data
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}