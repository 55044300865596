import { Box, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, makeStyles, MenuItem, OutlinedInput, Select, TextField, Typography } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import InfoIcon from '@material-ui/icons/Info';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SERVER } from '../../../../../../server'
import axios from 'axios'
import Swal from 'sweetalert2'
import { getPartner } from '../../../../../../store/actions/partnerAction'
import { getCountry } from '../../../../../../store/actions/countryAction'
import { Autocomplete } from '@material-ui/lab';

const useStyle = makeStyles({
    root: {
        paddingTop: 50,
        paddingBottom: 50,
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "#F2F3F9",
    },
    profile: {
        background: "white",
        padding: "2em",
        borderRadius: "4px"
    },
    buttonUpload: {
        color: "#E23F81",
        border: "1px solid #E23F81",
        borderRadius: "4px",
        textTransform: "none"
    },
    buttonCancel: {
        marginLeft: "2em",
        color: "#E23F81",
        border: "1px solid #E23F81",
        borderRadius: "4px",
        textTransform: "none"
    },
    formControl: {
        marginTop: "1em"
    },
    circleIcon: {
        borderRadius: "50%",
        width: "10em",
        height: "10em",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "white",
        border: "2px solid #F1F1F1"
    },
    inputFile: {
        display: "none"
    }
})

export default function Edit({ match }) {
    const classes = useStyle()
    const history = useHistory()
    const Dispatch = useDispatch()
    const countries = useSelector(state=> state.countryState.countries)
    const [name, setName] = useState('')
    const [contactPerson, setContactPerson] = useState('')
    const [title, setTitle] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [type, setType] = useState('')
    const [countryOrigin, setCountryOrigin] = useState('')
    // const [status, setStatus] = useState('')
    // const [activity, setActivity] = useState('')
    const [targetMarket, setTargetMarket] = useState([])
    // const [countriesId, setCountriesId] = useState([])
    // const [countryId, setCountryId] = useState('')
    
    function back () {
        history.goBack()
    }
    
    useEffect(()=>{
        Swal.showLoading()
        axios({
            method: 'GET',
            url: `${SERVER}/partner/${match.params.id}`,
            headers: { token : localStorage.getItem('token') }
        })
        .then(({ data })=>{
            setName(data.name)
            setContactPerson(data.contactPerson)
            setTitle(data.title)
            setEmail(data.email)
            setPhone(data.phone)
            setType(data.type)
            setCountryOrigin(data.countryOrigin)
            setTargetMarket((data.targetMarket).split(','))
            // setStatus(data.status)
            // setActivity(data.activity)
            // if (data.targetMarket) {
            //     setCountriesId((data.targetMarket).split(',').slice(0,((data.targetMarket).split(',')).length -1))
            // }
            // setCountryId((data.targetMarket).split(',')[(data.targetMarket).split(',').length-1])
            Swal.close()
        })
        .catch(err=>{
            console.log(err);
        })
    },[])

    // const addTargetMarket = () => {
    //     if (countryId) {
    //         let arr = [...countriesId]
    //         arr.push(countryId)
    //         setCountriesId(arr)
    //         setCountryId('')
    //     }
    // }

    // const removeTargetMarket = (i) => {
    //     let arr = []
    //     countriesId.map((n, index)=>{
    //         if (index !== i) {
    //             arr.push(n)
    //         }
    //     })
    //     setCountriesId(arr)
    // }

    // const checkingId = (id) => {
    //     let flag = false
    //     countriesId.map(di=>{
    //         if (di === id) {
    //             flag = true
    //         }
    //     })
    //     return flag
    // }

    // const getCountryName = (id) => {
    //     if (countries){
    //         let name
    //         countries.map(country=>{
    //             if (country.id == id) {
    //                 name = country.name
    //             }
    //         })
    //         return name
    //     }
    // }

    const getSelectedCountry = (ids) => {
        if (countries) {
            let selectedCountry = []
            for (let i = 0; i < ids.length; i++) {
                let id = parseInt(ids[i])
                countries.map(country=>{
                    if (country.id === id) {
                        selectedCountry.push(country)
                    }
                })
            }
            return selectedCountry;
        }
    }

    const getId = (object) => {
        let id = []
        object.map(obj=>{
            id.push(obj.id)
        })
        return id
    }

    const submit = (e) => {
        e.preventDefault()

        // let CouId = [...countriesId]
        // if (countryId) {
        //     CouId.push(countryId)
        // }

        let formData = {
            name: name,
            contactPerson: contactPerson,
            title: title,
            email: email,
            phone: phone,
            type: type,
            countryOrigin: countryOrigin,
            // targetMarket: CouId,
            targetMarket: targetMarket,
            // status: status,
            // activity: activity
        }

        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Swal.showLoading()
        axios({
            method: 'put',
            url: `${SERVER}/partner/update/${match.params.id}`,
            data: formData,
            headers: {
                token : localStorage.getItem('token'),
            }
        })
        .then(({data})=>{
            history.replace('/vito/partner')
            Swal.close()
            Dispatch(getPartner())
            Dispatch(getCountry())
        })
        .catch(err=>{
            if (err.response) {
                if (err.response.data) {
                    if (err.response.data.message) {
                        Toast.fire({
                            icon: 'error',
                            title: err.response.data.message
                        })
                    }
                }
            }
        })
    }

    return (
        <Box spacing={3} className={classes.root} >
            <Typography variant="h4"><b>Edit Partner</b></Typography>
            <Box width="50%">
                <Box>
                    <Button style={{ color: "#E23F81" }} startIcon={<ArrowBackIcon />} onClick={back} >Back</Button>
                </Box>
                <Box className={classes.profile}>
                        
                        <Box marginX="1em">
                            <form>
                                <Box>
                                    <Box marginTop="1em"><TextField required name="name" value={name} onChange={e=> setName(e.target.value)} id="outlined-basic" color="secondary" size="small" label="Company" variant="outlined" fullWidth /></Box>
                                    <Box marginTop="1em"><TextField required name="contactPerson" value={contactPerson} onChange={e=> setContactPerson(e.target.value)} id="outlined-basic" color="secondary" size="small" label="Contact Person" variant="outlined" fullWidth /></Box>
                                    <Box marginTop="1em"><TextField required name="title" value={title} onChange={e=> setTitle(e.target.value)} id="outlined-basic" color="secondary" size="small" label="Title/Position" variant="outlined" fullWidth /></Box>
                                    <Box marginTop="1em"><TextField required name="email" value={email} onChange={e=> setEmail(e.target.value)} id="outlined-basic" color="secondary" size="small" label="Personal/Corporate Email" variant="outlined" fullWidth /></Box>
                                    <Box marginTop="1em"><TextField required type="number" name="phone" value={phone} onChange={e=> setPhone(e.target.value)} id="outlined-basic" color="secondary" size="small" label="Personal/Corporate Phone (ex: 081xxxxxxxxx)" variant="outlined" fullWidth /></Box>
                                    <FormControl color="secondary" variant="outlined" fullWidth size="small" className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
                                        <Select
                                            name="type"
                                            value={type}
                                            onChange={e=> setType(e.target.value)}
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            label="Type"
                                        >
                                            <MenuItem value="Academics/Institution">Academics/Institution</MenuItem>
                                            <MenuItem value="Airlines">Airlines</MenuItem>
                                            <MenuItem value="Association">Association</MenuItem>
                                            <MenuItem value="TA/TO">TA/TO</MenuItem>
                                            <MenuItem value="Accommodation">Accommodation</MenuItem>
                                            <MenuItem value="Other Industries">Other Industries</MenuItem>
                                            <MenuItem value="Diaspora">Diaspora</MenuItem>
                                            <MenuItem value="Community">Community</MenuItem>
                                            <MenuItem value="KBRI/KJRI">KBRI/KJRI</MenuItem>
                                            <MenuItem value="Tourism Authority">Tourism Authority</MenuItem>
                                            <MenuItem value="Creative Economy Authority">Creative Economy Authority</MenuItem>
                                            <MenuItem value="MoTCE Indonesia">MoTCE Indonesia</MenuItem>
                                            <MenuItem value="Media">Media</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl color="secondary" variant="outlined" fullWidth size="small" className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-outlined-label">Country Origin</InputLabel>
                                        <Select
                                            name="countryOrigin"
                                            value={countryOrigin}
                                            onChange={e=> setCountryOrigin(e.target.value)}
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            label="Country Origin"
                                        >
                                            <MenuItem value="Indonesia">Indonesia</MenuItem>
                                            <MenuItem value="Singapore">Singapore</MenuItem>
                                            <MenuItem value="New Delhi">New Delhi</MenuItem>
                                            <MenuItem value="Mumbai">Mumbai</MenuItem>
                                            <MenuItem value="Malaysia">Malaysia</MenuItem>
                                            <MenuItem value="Thailand">Thailand</MenuItem>
                                            <MenuItem value="Australia">Australia</MenuItem>
                                            <MenuItem value="Beijing">Beijing</MenuItem>
                                            <MenuItem value="Guangzhou">Guangzhou</MenuItem>
                                            <MenuItem value="Hong Kong">Hong Kong</MenuItem>
                                            <MenuItem value="Shanghai">Shanghai</MenuItem>
                                            <MenuItem value="South Korea">South Korea</MenuItem>
                                            <MenuItem value="Japan">Japan</MenuItem>
                                            <MenuItem value="Saudi Arabia">Saudi Arabia</MenuItem>
                                            <MenuItem value="United Arab Emirates">United Arab Emirates</MenuItem>
                                            <MenuItem value="United Kingdom">United Kingdom</MenuItem>
                                            <MenuItem value="France">France</MenuItem>
                                            <MenuItem value="Netherlands">Netherlands</MenuItem>
                                            <MenuItem value="Germany">Germany</MenuItem>
                                            <MenuItem value="Russia">Russia</MenuItem>
                                        </Select>
                                    </FormControl>

                                    {/* {
                                        countriesId.length !== 0 &&
                                        <div style={{ marginTop: '0.5em' }} >
                                            <span style={{ color: '#e35981', fontSize: '12px', paddingLeft: 7 }} >
                                                Target Market
                                            </span>
                                        </div>
                                    }
                                    {
                                        countriesId.length !== 0 &&
                                            countriesId &&
                                            countriesId.map((countryId,i)=>(
                                            <div key={i} style={{ display: 'flex', alignItems: 'center', marginTop: i!==0&&'0.5em'  }} >
                                                <span style={{
                                                    border: '1px solid #cdcdcd',
                                                    borderRadius: '4px',
                                                    width: '100%',
                                                    padding: '10px',
                                                    paddingLeft: '12px'
                                                }} >
                                                    { getCountryName(countryId) }
                                                </span>
                                                <i className="fas fa-minus-square" onClick={e=>removeTargetMarket(i)} style={{ fontSize: 30, marginLeft: 10, color: '#e35981', cursor: 'pointer' }} />
                                            </div>
                                        ))  
                                    }
                                    {
                                        <div style={{ display: 'flex', alignItems: 'center' }} >
                                            <FormControl color="secondary" variant="outlined" fullWidth size="small" className={classes.formControl}>
                                                <InputLabel id="demo-simple-select-outlined-label">Target Market</InputLabel>
                                                <Select
                                                    name="targetMarket"
                                                    value={countryId}
                                                    onChange={e=> setCountryId(e.target.value)}
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    label="Target Market"
                                                >
                                                    <MenuItem value="" >  </MenuItem>
                                                    {
                                                        countries &&
                                                        countries
                                                        .filter(a=> !(checkingId(a.id)))
                                                        .map((country,i)=>(
                                                            <MenuItem style={{ textTransform: 'capitalize' }} key={i} value={country.id}>{ `${country.name}` }</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                            <i className="fas fa-plus-square" onClick={e=>addTargetMarket()} style={{ fontSize: 30, marginTop: 19, marginLeft: 10, color: '#e35981', cursor: 'pointer' }} />
                                        </div>
                                    }
                                    
                                    <FormControl color="secondary" variant="outlined" fullWidth size="small" className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                                        <Select
                                            name="status"
                                            value={status}
                                            onChange={e=> setStatus(e.target.value)}
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            label="status"
                                        >
                                            <MenuItem value="Planning Stage">Planning Stage</MenuItem>
                                            <MenuItem value="Initiated/Started">Initiated/Started</MenuItem>
                                            <MenuItem value="On-going">On-going</MenuItem>
                                            <MenuItem value="Completed">Completed</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <Box marginTop="1em"><TextField name="activity" value={activity} onChange={e=> setActivity(e.target.value)} id="outlined-basic" color="secondary" size="small" label="Activity" variant="outlined" fullWidth multiline rows={4} /></Box> */}

                                    <Box marginTop="1em">
                                        <Autocomplete
                                            multiple
                                            id="tags-outlined"
                                            options={countries}
                                            getOptionLabel={(option) => option.name}
                                            value={getSelectedCountry(targetMarket)}
                                            filterSelectedOptions
                                            onChange={(event, value) => setTargetMarket(getId(value))}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Target Market"
                                                    placeholder="Select"
                                                    name='targetMarket'
                                                    variant='outlined'
                                                />
                                            )}
                                        />
                                    </Box>
                                </Box>

                                <Box display="flex" alignItems="center" marginTop="1.5em">
                                    <Button type="submit" onClick={e=>submit(e)} variant="contained" color="secondary" size="large" style={{ backgroundColor: "#E23F81", textTransform: "none" }}>Submit</Button>
                                    <Button variant="outlined" className={classes.buttonCancel} size="large" onClick={back}>Cancel</Button>
                                </Box>
                            </form>
                        </Box>
                    </Box>
            </Box>

        </Box>
    )
}
