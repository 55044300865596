import React, { useState, useRef, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, Box, Typography, IconButton, Fade, Paper, Popper } from '@material-ui/core';
import logoWI from '../../../../images/bird.png'
import Comment from '../../Card/Comment'
import BoxImg from './BoxImg'
import './styles.scss'
import CloseIcon from '@material-ui/icons/Close';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw, ContentState, convertFromHTML } from 'draft-js';
import Axios from 'axios'
import { SERVER } from '../../../../../../server'
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useSelector } from 'react-redux'

const useStyles = makeStyles({
    buttonRead: {
        color: "#E23F81",
        background: "rgba(226, 63, 129, 0.1)",
        borderRadius: "2px",
        width: "100px",
        border: "none"
    },
    boxProfile: {
        borderTop: "0.5px solid #DDDDDD",
        borderBottom: "0.5px solid #DDDDDD",
        padding: "1em 2.5em",
        background: "#FAFAFA",
    },
    titleWorkplan: {
        color: "#E23F81"
    },
    buttonKPI: {
        marginLeft: "2em",
        color: "#E23F81",
        border: "1px solid #E23F81",
        background: "rgba(226, 63, 129, 0.1)",
        borderRadius: "2px",
        textTransform: "none"
    }
})

export default function ScrollDialog(props) {
    const {
        photoProfile,
        data,
        weeklyList,
        dataRead,
        CategoryId,
        vito,
        Week
    } = props
    const classes = useStyles()
    const [open, setOpen] = useState(false);
    const [week, setWeek] = useState(null)
    const [scroll, setScroll] = useState('paper');
    const roleProfile = useSelector(state=> state.profileState.role)
    const myid = useSelector(state=> state.profileState.id)
    const [listIdApproved] = useState([112, 28, 99, 100, 98, 97])

    useEffect(()=>{
        if (weeklyList) {
            let weekFix
            weeklyList.forEach((week)=>{
                if (week.id == data.WeekId) {
                    weekFix = week
                    return
                }
            })
            setWeek(weekFix)
        }
    },[weeklyList])

    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = useRef(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    const getYear = (id) => {
        let find = '-'
        switch (id) {
            case 1:
                find = 2019
                break;
            case 2:
                find = 2020
                break;
            case 3:
                find = 2021
                break;
            case 4:
                find = 2022
                break;
            case 5:
                find = 2023
                break;
            case 6:
                find = 2024
                break;
            case 7:
                find = 2025
                break;
            default:
                break;
        }
        return find
    }
    
    const showDate = (dateParams) => {
        if (dateParams) {
            let start = new Date(dateParams).getDate()
            let startMonth = new Date(dateParams).getMonth()
            let monthStart = ''
            let yearResult = new Date(dateParams).getFullYear()
            switch (startMonth) {
                case 0 :
                    monthStart = 'Januari'
                    break;
                case 1 :
                    monthStart = 'Februari'
                    break;
                case 2 :
                    monthStart = 'Maret'
                    break;
                case 3 :
                    monthStart = 'April'
                    break;
                case 4 :
                    monthStart = 'Mei'
                    break;
                case 5 :
                    monthStart = 'Juni'
                    break;
                case 6 :
                    monthStart = 'Juli'
                    break;
                case 7 :
                    monthStart = 'Agustus'
                    break;
                case 8 :
                    monthStart = 'September'
                    break;
                case 9 :
                    monthStart = 'Oktober'
                    break;
                case 10 :
                    monthStart = 'November'
                    break;
                case 11 :
                    monthStart = 'Desember'
                    break;
                default:
                    monthStart = ''
                    break;
            }
            let hour = new Date(dateParams).getHours()
            let minute = new Date(dateParams).getMinutes()
            let second = new Date(dateParams).getSeconds()
            
            return start + ' '+ monthStart +' '+ yearResult +' '+ (hour < 10 ? '0'+hour : hour) +':'+(minute < 10 ? '0'+minute : minute)+':'+(second < 10 ? '0'+second : second)
        }
    }

    const showDateWeek = (dateParams) => {
        if (dateParams) {
            const [day, month, year] = dateParams.split("/")
            let date = new Date(year, month - 1, day).getDate() 
            let monthNumber = new Date(year, month - 1, day).getMonth()
            let monthResult = ''
            let yearResult = new Date(year, month - 1, day).getFullYear()
            switch (monthNumber) {
                case 0 :
                    monthResult = 'Januari'
                    break;
                case 1 :
                    monthResult = 'Februari'
                    break;
                case 2 :
                    monthResult = 'Maret'
                    break;
                case 3 :
                    monthResult = 'April'
                    break;
                case 4 :
                    monthResult = 'Mei'
                    break;
                case 5 :
                    monthResult = 'Juni'
                    break;
                case 6 :
                    monthResult = 'Juli'
                    break;
                case 7 :
                    monthResult = 'Agustus'
                    break;
                case 8 :
                    monthResult = 'September'
                    break;
                case 9 :
                    monthResult = 'Oktober'
                    break;
                case 10 :
                    monthResult = 'November'
                    break;
                case 11 :
                    monthResult = 'Desember'
                    break;
                default:
                    monthResult = ''
                    break;
            }
            return date + ' ' + monthResult  + ' ' + yearResult
        }
    }

    const changeCredit = status => {
        if (data) {
            Axios({
                method: 'PATCH',
                url: `${SERVER}/report/credit/${data.id}`,
                headers: {
                    token : localStorage.getItem('token'),
                },
                data: {
                    status,
                    week
                }
            })
            .then(({data})=>{
                dataRead(CategoryId, Week.id, vito.id)
            })
            .catch(err=>{
            })
        }
    }

    return (
        <div>
            <Button variant="outlined" size="small" style={{ textTransform: 'capitalize' }} className={classes.buttonRead} onClick={handleClickOpen('body')}>Lihat</Button>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth="lg"
                disableEnforceFocus
            >
                <DialogTitle id="scroll-dialog-title">
                    <Box paddingX="1em" display="flex" justifyContent="space-between">
                        <Typography variant="h6">View Report {data && data.category} Week {week && week.week}, {data && getYear(data.YearId)}</Typography>
                        <IconButton size="small" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <Box className={classes.boxProfile} display="flex">
                    <Box display="flex" alignItems="center" width="100%">
                        <Box height="3.5rem" width="3.5rem" border={1} borderRadius="50%" borderColor="grey.500" marginRight="1rem">
                            <img width="100%" height="100%" style={{ borderRadius: '50%' }} src={data && data.User ? data.User.photo ? data.User.photo : logoWI : logoWI} />
                        </Box>
                        <Box display="flex" flexDirection="column" width="100%">
                            <Box display="flex" alignItems="center">
                                <Typography variant="h6">{data && data.User ? data.User.name ? data.User.name : '-' : '-'}</Typography>
                                <Box borderLeft="0.5px solid #DDDDDD" marginLeft="1.5em" paddingX="1.5rem">
                                    <Typography
                                        style={{
                                            fontFamily: "Roboto",
                                            fontStyle: "normal",
                                            fontWeight: "normal",
                                            fontSize: "12px",
                                            lineHeight: "14px",
                                            color: "#999999",
                                        }}
                                    >Week {week && week.week} ({showDateWeek(week && week.start)} - {showDateWeek(week && week.end)})</Typography>
                                </Box>
                            </Box>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }} >
                                    <Typography>{data && data.category}</Typography>
                                    <Typography variant="caption" className={classes.caption}>Created : { showDate(data && data.createdAt)}</Typography>
                                </div>
                                { myid }
                                {
                                    data && data.credit === 'meminta' ?
                                    (
                                        roleProfile === 'admin' ||
                                        roleProfile === 'picpasar' ||
                                        listIdApproved.includes(myid)
                                    ) &&
                                    <div style={{ display: 'flex', flexDirection: 'column' }} >
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} >
                                            <div className="btn-kpi-987812" style={{ height: 35 }} onClick={e=>changeCredit('disetujui')}>
                                                Approve KPI
                                            </div>
                                            <div className="btn-kpi-98781" style={{ marginLeft: '1vw', height: 35 }} onClick={e=>changeCredit('ditolak')}>
                                                Reject KPI
                                            </div>
                                        </div>
                                        <span style={{ color: '#E23F81', marginTop: 6 }} >
                                            <b>Indicator</b> : { data && data.indicator }
                                        </span>
                                    </div>
                                    :
                                    data && data.credit === 'disetujui' ?
                                    (
                                        roleProfile === 'admin' ||
                                        roleProfile === 'picpasar' ||
                                        listIdApproved.includes(myid)
                                    ) &&
                                    <div style={{ display: 'flex', flexDirection: 'column' }} >
                                        <PopupState variant="popper" popupId="demo-popup-popper">
                                            {(popupState) => (
                                                <div>
                                                    <div className="btn-kpi-987812" style={{ height: 40 }} {...bindToggle(popupState)}>
                                                        KPI Approved
                                                        <div style={{ borderLeft: '1px solid white', marginLeft: '0.5vw', marginRight: '0.3vw', height: '100%' }} />
                                                        <ArrowDropDownIcon />
                                                    </div>
                                                    
                                                    <Popper {...bindPopper(popupState)} transition style={{ zIndex: 99999999999 }} >
                                                        {({ TransitionProps }) => (
                                                        <Fade {...TransitionProps} timeout={350}>
                                                            <Paper {...bindToggle(popupState)} >
                                                                <div onClick={e=>{changeCredit('ditolak')}} className="btn-kpi-98781" style={{ marginTop: '0.3vw', height: '30px' }} >
                                                                    Reject KPI
                                                                </div>
                                                            </Paper>
                                                        </Fade>
                                                        )}
                                                    </Popper>
                                                </div>
                                            )}
                                        </PopupState>
                                        <span style={{ color: '#E23F81', marginTop: 6 }} >
                                            <b>Indicator</b> : { data && data.indicator }
                                        </span>
                                    </div>
                                    :
                                    data && data.credit === 'ditolak' &&
                                    (
                                        roleProfile === 'admin' ||
                                        roleProfile === 'picpasar' ||
                                        listIdApproved.includes(myid)
                                    ) &&
                                    <div style={{ display: 'flex', flexDirection: 'column' }} >
                                        <PopupState variant="popper" popupId="demo-popup-popper">
                                            {(popupState) => (
                                                <div>
                                                    <div className="btn-kpi-987812" style={{ height: 40 }} {...bindToggle(popupState)}>
                                                        KPI Rejected
                                                        <div style={{ borderLeft: '1px solid white', marginLeft: '0.5vw', marginRight: '0.3vw', height: '100%' }} />
                                                        <ArrowDropDownIcon />
                                                    </div>
                                                    <Popper {...bindPopper(popupState)} transition style={{ zIndex: 99999999999 }} >
                                                        {({ TransitionProps }) => (
                                                        <Fade {...TransitionProps} timeout={350}>
                                                            <Paper {...bindToggle(popupState)}>
                                                                <div onClick={e=>{changeCredit('disetujui')}} className="btn-kpi-98781" style={{ marginTop: '0.3vw', height: '30px' }}>
                                                                    Approve KPI
                                                                </div>
                                                            </Paper>
                                                        </Fade>
                                                        )}
                                                    </Popper>
                                                </div>
                                            )}
                                        </PopupState>
                                        <span style={{ color: '#E23F81', marginTop: 6 }} >
                                            <b>Indicator</b> : { data && data.indicator }
                                        </span>
                                    </div>
                                }
                            </div>
                        </Box>
                    </Box>
                </Box>
                <DialogContent dividers={scroll === 'paper'}>
                    <Box>
                        <Typography style={{color: "#DA9D02"}}>{data && data.category} </Typography>
                    </Box>
                    <Box style={{ marginTop: '0.5em' }} >
                        {
                            data && data.credit != 'false' &&
                            <span style={{color: "rgb(174, 125, 1)", background: 'rgb(249, 239, 214)', borderRadius: 2, padding: '5px 10px' }}>
                                {
                                    data && data.credit === 'meminta' ? 'I submit this report as my credit' :
                                    data && data.credit === 'ditolak' ? 'Your Credit is Rejected' :
                                    data && data.credit === 'disetujui' && 'Your Credit is Approved'
                                }
                            </span>
                        }
                    </Box>
                    <Box style={{ marginTop: '1em', marginBottom: '1vw' }} >
                        {
                            data && (data.indicator != 'false' && data.indicator) &&
                            <span style={{color: "#2C304D", background: 'rgba(44, 48, 77, 0.08)', borderRadius: 2, padding: '5px 10px' }}>
                                    { data && data.indicator }
                            </span>
                        }
                    </Box>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <Editor
                            editorState={data && (data.report && data.report != 'null') ?
                                EditorState.createWithContent(convertFromRaw(JSON.parse(data.report)))
                                :
                                EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                            }
                            toolbarHidden
                            readOnly
                            toolbar={false}
                            editorClassName={classes.editor}
                        />

                    </DialogContentText>
                    <Box display="flex" >
                        {
                            data &&
                            data.photos &&
                            data.photos.split(',').map((item, i)=> {
                                return (
                                    <BoxImg key={i} photos={item} photosAll={data && data.photos}/>
                                )
                            })
                        }
                    </Box>
                </DialogContent>
                <Comment data={data} handleClose={handleClose} photoProfile={photoProfile}/>
            </Dialog>
        </div>
    );
}
