import React, { useState } from "react";
import "./styles.scss";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Swal from "sweetalert2";
import { useHistory } from 'react-router-dom'
import Divider from '@material-ui/core/Divider';

const ButtonOk = withStyles((theme) => ({
  root: {
      width: '100%',
      height: '44px',
      color: '#ffffff',
      backgroundColor: '#E23F81',
      fontFamily: ['Roboto', 'sans-serif'],
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '18px',
      textAlign: 'center',
      lineHeight: '21px',
      boxSizing: 'border-box',
      borderRadius: '4px',
      boxShadow: 'none',
      textTransform: 'none',
      '&:hover': {
          backgroundColor: '#E23F81',
          boxShadow: 'none',
      },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: "18px",
      width: "539px",
      height: "44px",
    },
    buttonSend: {
      position: "absolute",
      width: "139px",
      height: "21px",
      left: "372px",
      top: "930px",

      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "18px",
      lineHeight: "21px",
      /* identical to box height */

      color: "#FFFFFF",
    },
    buttonExit: {
      position: "absolute",
      width: "42px",
      height: "21px",
      left: "613px",
      top: "930px",

      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "18px",
      lineHeight: "21px",
      /* identical to box height */

      color: "#E23F81",
    },
    iconInfo: {
      color: "#999999",
      boxSizing: "border-box",
    },
  },
}));

export default function ForgetPass() {
  const classes = useStyles();
  const History = useHistory();
  const [secret, setSecret] = useState(true)
  const [secretTwo, setSecretTwo] = useState(true)

  return (
    <div className="container-forgetpass-pages">
      <div className="container-forgetpass-box">
        <div className="container-white-forgotpass" >
          <div className="send-data-header-wrapper" >
            <span className="forgetpass-header-txt" >
            Buat Password Baru
            </span>
            <span className="forgetpass-header-txt-sm">
            Silahkan buat password baru Anda
            </span>
          </div>
          <div className="input-newpass-wrapper" >
            <div className="input-send-data" style={{ marginTop: 30 }}>
              <input type={secret ? 'password' : 'text'} className="input-login-txt" style={{ padding: 0 }} placeholder="Password"/>
              <div className="eye-secret-newpass" >
                {
                  secret ? 
                  <i className="fas fa-eye eye-custom-newpass-false " onClick={()=>setSecret(false)} />
                  :
                  <i className="fas fa-eye eye-custom-newpass-true " onClick={()=>setSecret(true)} />
                }
              </div>
            </div>
            <div className="input-send-data" style={{ marginTop: 30 }}>
              <input type={secretTwo ? 'password' : 'text'} className="input-login-txt" style={{ padding: 0 }} placeholder="Ulangi Password Baru"/>
              <div className="eye-secret-newpass" >
                {
                  secretTwo ? 
                  <i className="fas fa-eye eye-custom-newpass-false " onClick={()=>setSecretTwo(false)} />
                  :
                  <i className="fas fa-eye eye-custom-newpass-true " onClick={()=>setSecretTwo(true)} />
                }
              </div>
            </div>
          </div>
        </div>
        <div className="footer-send-data" >
          <ButtonOk >Buat Password Baru</ButtonOk>
        </div>
      </div>
    </div>
  );
}
