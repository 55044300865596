import {
    GET_USER,
} from "../constanta";

const initialState = {
    users: []
}

export default (state = initialState, action)=> {
    switch(action.type){
        case GET_USER:
            return {
                ...state,
                users: action.data
            }
        default: {
            return state
        }
    }
}