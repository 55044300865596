import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import logoWI from '../../../../images/logo-WI.png'

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const useStyles = makeStyles({
    buttonRead: {
        color: "#E23F81",
        background: "rgba(226, 63, 129, 0.1)",
        borderRadius: "2px",
        width: "100px",
        border: "none"
    },
    boxProfile: {
        width: "50em",
        borderTop: "1px solid #DDDDDD",
        padding: "1em 3em",
        background: "#FAFAFA",
    },
    subtitle: {
        color: "#DA9D02",
        lineHeight: "19px",
    },
    content: {
        width: "50em",
        padding: "1.5em 3em",
        background: "white"
    },
    text: {
        marginTop: "1.5em"
    },
    borderDialog: {
        
        borderRadius: "10px"
        
    }
})

export default function CustomizedDialogs() {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles()

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button variant="outlined" size="small" className={classes.buttonRead} onClick={handleClickOpen}>Read More</Button>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" maxWidth="lg"  open={open} paper={classes.borderDialog}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Detail Work Plan
                </DialogTitle>
                    <Box className={classes.boxProfile} display="flex">
                        <Box height="3.5em" width="3.5em" border={1} borderRadius="50%" borderColor="grey.500" marginRight="2em">
                            <img width="90%" height="80%" src={logoWI}/>
                        </Box>
                        <Box display="flex" flexDirection="column">
                            <Typography variant="h6">Ms. Nattamon Limthanachai</Typography>
                            <Typography variant="caption" className={classes.caption}>Created : 12 June 2020 00:19:47</Typography>
                        </Box>
                    </Box>
                    <Box className={classes.content}>
                        <Typography variant="h6">Market Intelligence</Typography>
                        <Typography className={classes.subtitle} variant="subtitle1">Partnership with Airlines</Typography>
                        <Typography className={classes.text}>Menghadiri peresmian pesawat baru Japan Airlines, kamis, 13 Juni 2020. Penyambutan armada dengan nomor registrasi PK-GHE tersebut diselenggarakan di Hanggar 2 Garuda. Penyambutan armada dengan nomor registrasi PK-GHE tersebut diselenggarakan di Hanggar 2 Garuda. Penyambutan armada dengan nomor registrasi PK-GHE tersebut diselenggarakan di Hanggar 2 Garuda</Typography>
                    </Box>
            </Dialog>
        </div>
    );
}