import React, { useEffect, useState } from 'react'
import { Box, Typography, Tooltip, ClickAwayListener, withStyles, makeStyles, TextField, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Paper, requirePropFactory, Button, FormControl, InputLabel, Select, MenuItem, Menu } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import InfoIcon from '@material-ui/icons/Info';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import Pagination from '@material-ui/lab/Pagination';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useDispatch, useSelector } from 'react-redux'
import { getGalleryIndi, getMonthlyIndi } from '../../../../../../store/actions/reportAction'
import DetailReportWeekly from '../../../../component/Report/Modal/DetailReportWeekly/indexIndicator';
import DetailGallery from '../../../../component/Report/Modal/DetailGallery/indexIndicator';

const useStylesIndicator = makeStyles((theme) => ({
    arrow: {
        color: "#2C304D",
    },
    tooltip: {
        backgroundColor: "#2C304D",
    },
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#F3F3F3",
        color: "#999999",
        fontWeight: 500,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "#FAFAFA",
        },
    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 700,
        boxShadow: "none"
    },
    tableContainer: {
        boxShadow: "none",
        border: "1px solid #CCCCCC",
        borderRadius: "4px"
    },
    itemMenu: {
        fontSize: "12px",
        padding: "0.5rem 1rem",
        color: "#999999"
    },
    iconMenu: {
        fontSize: "16px",
        marginRight: "0.5rem",
        color: "#999999"
    }
});

export default function PIN(props) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [month, setMonth] = useState('')
    const [year, setYear] = useState('')
    const [name, setName] = useState('')
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const indicatorGallery = useSelector(state=> state.reportState.indicatorGallery)
    const monthly = useSelector(state=> state.reportState.monthly)
    const filterYears = useSelector(state=> state.filterState.years)

    function onChangeMonth(e) {
        setMonth(e.target.value)
    }

    function onChangeYear(e) {
        setYear(e.target.value)
        if (filterYears) {
            filterYears.map(year=>{
                if (year.year == e.target.value) {
                    dispatch(getGalleryIndi(year.id))
                    dispatch(getMonthlyIndi(year.id))
                }
            })
        }
    }

    function addReport() {
        history.push('/vito/report/monthly/'+year)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    useEffect(()=>{
        if (filterYears) {
            filterYears.map(year=>{
                if (year.year == new Date().getFullYear()) {
                    setYear(year.year)
                    dispatch(getGalleryIndi(year.id))
                    dispatch(getMonthlyIndi(year.id))
                }
            })
        }
    },[filterYears])

    const getDate = (dateParams) => {
        if (dateParams) {
            let start = new Date(dateParams).getDate()
            let startMonth = new Date(dateParams).getMonth()
            let monthStart = ''
            let yearResult = new Date(dateParams).getFullYear()
            switch (startMonth) {
                case 0 :
                    monthStart = '01'
                    break;
                case 1 :
                    monthStart = '02'
                    break;
                case 2 :
                    monthStart = '03'
                    break;
                case 3 :
                    monthStart = '04'
                    break;
                case 4 :
                    monthStart = '05'
                    break;
                case 5 :
                    monthStart = '06'
                    break;
                case 6 :
                    monthStart = '07'
                    break;
                case 7 :
                    monthStart = '08'
                    break;
                case 8 :
                    monthStart = '09'
                    break;
                case 9 :
                    monthStart = '10'
                    break;
                case 10 :
                    monthStart = '11'
                    break;
                case 11 :
                    monthStart = '12'
                    break;
                default:
                    monthStart = ''
                    break;
            }
            let hour = new Date(dateParams).getHours()
            let minute = new Date(dateParams).getMinutes()
            let second = new Date(dateParams).getSeconds()
            
            return yearResult+'-'+ monthStart +'-'+ start

            // +' '+ (hour < 10 ? '0'+hour : hour) +':'+(minute < 10 ? '0'+minute : minute)+':'+(second < 10 ? '0'+second : second)
        }
    }

    const getTime = (dateParams) => {
        if (dateParams) {
            let hour = new Date(dateParams).getHours()
            let minute = new Date(dateParams).getMinutes()
            let second = new Date(dateParams).getSeconds()
            
            return (hour < 10 ? '0'+hour : hour) +':'+(minute < 10 ? '0'+minute : minute)+':'+(second < 10 ? '0'+second : second)
        }
    }

    const changeMonthToEnglish = (month) => {
        let result
        switch(month){
            case 'Januari': result = "January";
                break;
            case 'Februari': result = "February";
                break;
            case 'Maret': result = "March";
                break;
            case 'April': result = "April";
                break;
            case 'Mei': result = "May";
                break;
            case 'Juni': result = "June"; 
                break;
            case 'Juli': result = "July";
                break;
            case 'Agustus': result = "August";
                break;
            case 'September': result = "September";
                break;
            case 'Oktober': result = "October";
                break;
            case 'November': result = "November";
                break;
            case 'Desember': result = "December";
                break;
        }
        return result
    }

    return (
        <>
            <div className="dashboard" >
                <span className="breadcrumb-txt-black" >
                    Gallery Report
            </span>
                <span className="breadcrumb-slash-black" >
                    /
            </span>
            </div>
            <div className="vito-table">
                <Box width='100%'>
                    <Box padding="1em 1.5em">
                        <Typography variant="h5" style={{ fontWeight: "500" }}>GALLERY REPORT</Typography>
                    </Box>
                    <Box paddingLeft="1.5em" paddingRight="1em" paddingTop="2em">
                        <Box display="flex" justifyContent="space-between">
                            <Box width={1 / 2} display="flex">
                                <Box width={1 / 2} ><TextField label="Search" onChange={e=>setName(e.target.value)} color="secondary" size="small" variant="outlined" borderColor="#C4C4C4" fullWidth /></Box>
                                <Box width={1 / 4} style={{ marginLeft: '1vw' }} >
                                    <FormControl color="secondary" variant="outlined" fullWidth size="small" >
                                    <InputLabel id="demo-simple-select-outlined-label">Choose Year</InputLabel>
                                        <Select
                                            value={year}
                                            onChange={onChangeYear}
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            label="Choose Year"
                                        >
                                            {
                                                filterYears &&
                                                filterYears.map(({year}, i)=>(
                                                    <MenuItem key={i} value={year}>{year}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box width={1 / 4} style={{ marginLeft: '1vw' }} >
                                    <FormControl color="secondary" variant="outlined" fullWidth size="small" >
                                    <InputLabel id="demo-simple-select-outlined-label">Choose Month</InputLabel>
                                        <Select
                                            value={month}
                                            onChange={onChangeMonth}
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            label="Choose Month"
                                        >
                                            <MenuItem value=""> Semua </MenuItem>
                                            {
                                                monthly &&
                                                monthly.map((mon, i)=>(
                                                    <MenuItem key={i} value={mon.id}>{changeMonthToEnglish(mon.month)}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                            <Box>
                                <Button variant="contained" size="large" color="secondary" style={{ backgroundColor: "#E23F81", textTransform: "none" }} onClick={addReport}>Add New Gallery</Button>
                            </Box>
                        </Box>
                        <Box paddingTop="1em">
                            <TableContainer component={Paper} className={classes.tableContainer}>
                                <Table className={classes.table} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">NO</StyledTableCell>
                                            <StyledTableCell align="left">ALBUM NAME</StyledTableCell>
                                            <StyledTableCell align="left">COUNTRY</StyledTableCell>
                                            <StyledTableCell align="left">CREATED</StyledTableCell>
                                            <StyledTableCell align="center">MODIFIED</StyledTableCell>
                                            {/* <StyledTableCell align="center">ACTION</StyledTableCell> */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        
                                        {
                                        indicatorGallery
                                        .filter(a=> name ? ((a.name).toLowerCase()).includes((name).toLowerCase()): a )
                                        .filter(a=> month ? month == a.MonthId : a )
                                        .slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
                                        .map((row, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell align="center">{((page - 1) * rowsPerPage) + index + 1}</StyledTableCell>
                                                <StyledTableCell align="left">{row.name ? row.name : '-'}</StyledTableCell>
                                                <StyledTableCell align="left" style={{ textTransform: 'uppercase' }}>{row.User && row.User.country ? row.User.country : '-'}</StyledTableCell>
                                                <StyledTableCell align="left">
                                                    <div>
                                                        <span>
                                                            {row.createdAt ? getDate(row.createdAt) : '-'}
                                                        </span>
                                                        <span style={{ marginLeft: 5, color : '#8e8e8e', fontSize: 13 }} >
                                                            {row.createdAt && getTime(row.createdAt)}
                                                        </span>
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <div>
                                                        <span>
                                                            {(row.updatedAt !== row.createdAt)  ? getDate(row.updatedAt) : '-'}
                                                        </span>
                                                        <span style={{ marginLeft: 5, color : '#8e8e8e', fontSize: 13 }} >
                                                            {(row.updatedAt !== row.createdAt) && row.updatedAt && getTime(row.updatedAt)}
                                                        </span>
                                                    </div>
                                                </StyledTableCell>
                                                {/* <StyledTableCell align="center">
                                                    <ClickAway data={row}/>
                                                </StyledTableCell> */}
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>

                                </Table>
                            </TableContainer>
                            <Box display="flex" marginTop="2rem">
                                <Box display="flex" alignItems="center" width={1 / 3}>
                                    <Box >
                                        <Typography style={{ color: "#999999" }}>Show</Typography>
                                    </Box>
                                    <Box marginX="0.5rem">
                                        <FormControl variant="outlined" color="secondary" size="small" >
                                            <Select
                                                style={{ color: "#999999" }}
                                                value={rowsPerPage}
                                                onChange={handleChangeRowsPerPage}
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                            >
                                                <MenuItem value={10}>10</MenuItem>
                                                <MenuItem value={20}>20</MenuItem>
                                                <MenuItem value={0}>All</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box >
                                        <Typography style={{ color: "#999999" }}>From {indicatorGallery
                                        .filter(a=> month ? month == a.MonthId : a )
                                        .length} Data</Typography>
                                    </Box>
                                </Box>
                                <Box width={1 / 3} display="flex" alignItems="center" justifyContent="center">
                                    <Pagination page={page} onChange={handleChangePage} count={Math.ceil(indicatorGallery
                                        .filter(a=> month ? month == a.MonthId : a )
                                        .length / rowsPerPage)} variant="outlined" shape="rounded" />
                                </Box>
                            </Box>
                        </Box>

                    </Box>
                </Box>
            </div>
        </>
    )
}

const useStylesBtn = makeStyles((theme) => ({
    root: {
        position: 'absolute',
    },
    dropdown: {
        justifyContent: 'center',
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        width: '100px',
        position: 'absolute',
        left: '2vw',
        zIndex: 99,
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        boxShadow: '6px 5px 33px -14px rgba(0,0,0,0.75)'
    },
    boxLast:{
        width: '100px',
        height: 35,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: 15,
        paddingRight: 15,
        color: '#999999',
        borderRadius: 4,
        cursor: 'pointer',
        '&:hover':{
            width: '70px',
            color: 'white',
            backgroundColor: '#E23F81'
        }
    }
}));

function ClickAway(props) {
    let { data } = props
    const classes = useStylesBtn();
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway} >
            <div >
                <div className="rqs-dta-btn-option" style={{ backgroundColor:'#FFFFFF', margin: '0 auto' }} onClick={handleClick} >
                    <MoreHorizIcon />
                </div>
                <div className={classes.root}>
                    { open ? (
                    <div className={classes.dropdown} >
                        <div className={classes.boxLast}>
                            <DetailGallery data={data} handleClickAway={handleClickAway}/>
                        </div>
                    </div>
                    ) : null}
                </div>
            </div>
        </ClickAwayListener>
    );
}