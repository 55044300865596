import React, { useEffect, useState } from 'react'
import ImageGallery from 'react-image-gallery';
import './styles.scss'

export default function Gallery (props) {
  const { photos, photosAll } = props
  const [images, setImages] = useState([])
  
  useEffect(()=>{
    if (photosAll) {
      let temp = []
      temp.push({ original: photos, thumbnail: photos })
      photosAll.split(',').forEach(photo=>{
        if (photo.includes('.png') || photo.includes('.jpg') || photo.includes('.jpeg')) {
          if (photo !== photos) {
            temp.push({ original: photo, thumbnail: photo })
          }
        }
      })
      setImages(temp)
    }
  },[photosAll])

  return (
    <>
      {/* <ImageGallery items={[{ original: photos, thumbnail: photos }]} /> */}
      <ImageGallery items={images} />
    </>
  ) 
  
}
