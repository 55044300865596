import React, { useState, useEffect } from 'react'
import { Box, Grid, makeStyles, ClickAwayListener, InputLabel, Select, MenuItem, withStyles, Button, IconButton } from '@material-ui/core'
import Typography from '@material-ui/core/Typography';
import { useHistory, useParams } from 'react-router-dom'
import empBox from '../../../../images/box.png'
import './styles.scss'
import ImagesGalleries from '../../../../component/Report/ImagesGalleries'
import Gambar1 from '../../../../images/gambar-1.png'
import SelectYearMonth from '../../../../component/SelectYearMonth'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSelector, useDispatch } from 'react-redux'
import { getMonthlyGallery, getWeekly } from '../../../../../../store/actions/vitoAction'
import Axios from 'axios'
import { SERVER } from '../../../../../../server'

const useStyles = makeStyles({
    root: {
        padding: "2em",
        boxSizing: 'border-box',
        width: 'calc( 100vw - 490px)',
        background: "white",
        boxShadow: "none",
    },
    title: {
        fontWeight: "500",
        lineHeight: "16px",
        color: "#2C304D"
    },
    selectBox: {
        width: "14em",
        padding: "1em"
    },
    image: {
        height: "30%",
        border: "1px solid #DDDDDD",
        boxSizing: "border-box",
        borderRadius: "1em 1em 0.5em 0.5em",
        margin: "0.75em",
        display: "flex",
        flexDirection: "column",
    },
    boxImages: {
        display: "flex",
        flexWrap: "wrap",
        overflowY: "scroll",
        minHeight: 'calc( 85vh )',
        maxHeight: 'calc( 85vh )',
    },
    boxImages2:{
        display: "flex",
        justifyContent: 'center',
        minHeight: 'calc( 85vh )',
        maxHeight: 'calc( 85vh )',
    },
    buttonReport: {
        marginTop: "1em",
        color: "#E23F81",
        borderColor: "#E23F81",
        borderRadius: "4px",
        textTransform: "none",
        marginLeft: "1em"
    },
})

export default function PIN(props) {
    const [years] = useState([2019, 2020, 2021, 2022, 2023, 2024, 2025])
    const [months] = useState(['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'])
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()
    const [reportGalleries, setReportGalleries] = useState([])
    const [showSelect, setShowSelect] = useState(false)
    const [inputYear, setInputYear] = useState(new Date().getFullYear())
    const [flag, setFlag] = useState(true)
    const [inputMonth, setInputMonth] = useState("")
    const { year } = useParams()
    const month = useSelector(state=> state.vitoState.monthGallery)
    const weeklyDate = useSelector(state=> state.vitoState.weekly)
    const filterYears = useSelector(state=> state.filterState.years)
    const [yearObj, setYearObj] = useState(null)
    const [monthObj, setMonthObj] = useState(null)

    function addGalleries() {
        history.push('/vito/report/galleries/add',{ month : monthObj })
    }

    function changeMonth(e, monthly) {
        e.preventDefault()
        setInputMonth(monthly)
        if (month) {
            month.map(mon=>{
                if (mon.month === monthly) {
                    getReport(mon)
                    setMonthObj(mon)
                }
            })
        }

    }

    function nextYear() {
        let temp = ''
        for (let i = 0; i < years.length; i++) {
            if (years[i] == inputYear) {
                temp = years[i + 1]
            }
        }
        if (filterYears) {
            filterYears.map((data)=>{
                if (temp === data.year) {
                    setYearObj(data)
                    dispatch(getMonthlyGallery(data.id))
                    dispatch(getWeekly(data.id))
                }
            })
        }
        setInputYear(temp)
        setFlag(!flag)
    }

    function beforeYear() {
        let temp = ''
        for (let i = 0; i < years.length; i++) {
            if (years[i] == inputYear) {
                temp = years[i - 1]
            }
        }
        if (filterYears) {
            filterYears.map((data)=>{
                if (temp === data.year) {
                    setYearObj(data)
                    dispatch(getMonthlyGallery(data.id))
                    dispatch(getWeekly(data.id))
                }
            })
        }
        setInputYear(temp)
        setFlag(!flag)
    }

    function changeShowSelect() {
        setShowSelect(!showSelect)
    }

    function closeSelect() {
        setShowSelect(false)
    }

    useEffect(()=>{
        setInputYear(year)
        switch (new Date().getMonth()) {
            case 0:
                setInputMonth('Januari')
                break;
            case 1:
                setInputMonth('Februari')
                break;
            case 2:
                setInputMonth('Maret')
                break;
            case 3:
                setInputMonth('April')
                break;
            case 4:
                setInputMonth('Mei')
                break;
            case 5:
                setInputMonth('Juni')
                break;
            case 6:
                setInputMonth('Juli')
                break;
            case 7:
                setInputMonth('Agustus')
                break;
            case 8:
                setInputMonth('September')
                break;
            case 9:
                setInputMonth('Oktober')
                break;
            case 10:
                setInputMonth('November')
                break;
            case 11:
                setInputMonth('Desember')
                break; 
            default:
                break;
        }
        if (filterYears) {
            filterYears.map((data)=>{
                if (year == data.year) {
                    setYearObj(data)
                    dispatch(getMonthlyGallery(data.id))
                    dispatch(getWeekly(data.id))
                }
            })
        }
    },[filterYears])

    useEffect(()=>{
        if (month) {
            month.map(mon=>{
                if (mon.month === inputMonth) {
                    getReport(mon)
                    setMonthObj(mon)
                }
            })
        }
    },[month])

    const getReport = (month) => {
        if (month) {
            Axios({
                method: 'GET',
                url: `${SERVER}/gallery/${month.id}`,
                headers: {
                    token : localStorage.getItem('token'),
                }
            })
            .then(({data})=>{
                let dataTemp = []
                if (data) {
                    data.map((dataA,i)=>{
                        if (dataA) {
                            let dataBank = dataA
                            if (dataA.photos) {
                                let temp = []
                                dataA.photos.split(',').map(img=>{
                                    if (img.includes('.png') || img.includes('.jpg') || img.includes('.jpeg')) {
                                        temp.push(img)
                                    }
                                })
                                if (temp.length > 0) {
                                    dataBank.photos = String(temp)
                                    dataTemp.push(dataBank) 
                                }
                            }
                        }
                    })
                    setReportGalleries(dataTemp)
                }
            })
            .catch(err=>{
            })
        }
    }

    const changeMonthToEnglish = (month) => {
        let result
        switch(month){
            case 'Januari': result = "January";
                break;
            case 'Februari': result = "February";
                break;
            case 'Maret': result = "March";
                break;
            case 'April': result = "April";
                break;
            case 'Mei': result = "May";
                break;
            case 'Juni': result = "June"; 
                break;
            case 'Juli': result = "July";
                break;
            case 'Agustus': result = "August";
                break;
            case 'September': result = "September";
                break;
            case 'Oktober': result = "October";
                break;
            case 'November': result = "November";
                break;
            case 'Desember': result = "December";
                break;
        }
        return result
    }

    return (
        <Box className={classes.root} >
            <Box paddingLeft="1em">
                <Typography className={classes.title} variant="h6" >Galleries</Typography>
            </Box>
            <Box margin="0.5rem 0.5rem">
                <ClickAwayListener onClickAway={closeSelect}>
                    <Box position="relative" width={1/4} marginTop="1rem" >
                        <Button display="flex" variant="outlined" style={{ height: "100%", width: "100%", textTransform: "none" }} onClick={changeShowSelect}>
                            <Box display="flex" width="100%" alignItems="center" justifyContent="space-between">
                                <Typography style={inputYear && inputMonth ? { color: "black" } : { color: "#C4C4C4" }}>{inputYear && inputMonth ? `${changeMonthToEnglish(inputMonth)}, ${inputYear}` : 'Choose Year & Month'}</Typography>
                                <ExpandMoreIcon style={{ color: "#C4C4C4" }} />
                            </Box>
                        </Button>
                        {showSelect ?
                        <SelectYearMonth years={years} inputYear={inputYear} beforeYear={beforeYear} nextYear={nextYear} changeMonth={changeMonth} inputMonth={inputMonth} months={months} />
                            : null}

                    </Box>
                </ClickAwayListener>
            </Box>
            {
                reportGalleries &&
                reportGalleries.length !== 0 ?
                <div>
                    <Box className={classes.boxImages}>
                        {
                            reportGalleries &&
                            reportGalleries.map((file, index) => {
                                return (
                                    <Box width="23%" key={index} className={classes.image}>
                                        <ImagesGalleries getReport={getReport} weeklyDate={weeklyDate} file={file} monthObj={monthObj} image={file && file.photo ? file.photo.split(',')[0] : file.photos && file.photos.split(',')[0]} />
                                    </Box>
                                )
                            })
                        }
                    </Box>

                    <Box>
                        <Button onClick={addGalleries} variant="outlined" size="small" className={classes.buttonReport}>Add New Gallery</Button>
                    </Box>
                </div>
                :
                <Box className={classes.boxImages2}>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
                        <Box><img src={empBox} style={{ width: "15em" }} /></Box>
                        <Typography variant="h6" style={{ color: "#999999" }}>No Gallery</Typography>
                        <Button onClick={addGalleries} variant="contained" color="secondary" size="large" style={{ textTransform: 'none', padding: "0.5em 3em", background: "#E23F81", marginTop: "2em" }}>Add New Gallery</Button>
                    </Box>
                </Box>
            }
        </Box>
    )
}