import React, { useEffect, useState } from "react";
import './styles.scss'
import Logo from '../../images/logo.png'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { SERVER } from '../../server'
import { profileToken } from '../../store/actions/profileAction'
import { getUser } from '../../store/actions/userAction'
import { getVito } from '../../store/actions/vitoAction'
import { useDispatch } from 'react-redux'
// import socketClient from "socket.io-client";

const useStyles = makeStyles((theme) => ({
  iconUser:{
    color: '#999999',
    margin: '0 auto',
    fontSize: 28
  },
  divider: {
    height: '75%',
    margin: 'auto 0',
    backgroundColor: '#999999',
    width: 0.5
  },
  checkbox: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#EAEAEA'
  },
  buttonLogin:{
    height: '44px',
    background: '#E23F81',
    borderRadius: '4px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '21px',
    color: '#FFFFFF',
    marginTop: 15,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#b43267',
    },
  }
}));

const CheckboxCustom = withStyles({
  root: {
    color: '#999999',
    '&$checked': {
      color: '#E23F81'
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function LoginPage() {
  const classes = useStyles();
  const History = useHistory();
  const Dispatch = useDispatch();
  const [remember, setRemember] = useState(null)
  const [secret, setSecret] = useState(true)
  const [email, setEmail] = useState(null)
  const [password, setPassword] = useState(null)

  useEffect(()=>{
    if (localStorage.getItem('remember')) {
      const token = localStorage.getItem('token')
      Dispatch(profileToken(token, History, Swal))
      Dispatch(getUser(token))
      Dispatch(getVito(token))
    }
  },[])

  const handleChange = (event) => {
    remember ? setRemember(null) : setRemember(event.target.value)
  };

  const handleLogin = (e) => {
    if (e) e.preventDefault()
    const SwalCustom = Swal.mixin({
      customClass: {
          cancelButton: 'btn-swal-wrong'
      },
      buttonsStyling: false
    })
    if (remember) {
      localStorage.setItem('remember', true)
    }
    if (email && password) {
      axios({
        method: 'post',
        url: `${SERVER}/user/login`,
        data:{
          email,
          password
        }
      })
      .then(({data})=>{
        localStorage.setItem('token', data.accessToken)
        SwalCustom.fire({
          html:`
            <div class="modal-forget-wrapper" >
              <div class="modal-forget-wrapper-2" >
                <i class="fas fa-check fa-times-custom"></i>
              </div>
              <span class="modal-forget-wrapper-txt-header" >
                Login Successful 
              </span>
            </div>
          `,
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000
        })
        setTimeout(() => {
          Dispatch(profileToken(data.token, History, Swal))
          Dispatch(getUser(data.token))
          Dispatch(getVito(data.token))
        }, 1000);
      })
      .catch(err=>{
        SwalCustom.fire({
          html:`
            <div class="modal-forget-wrapper" >
              <div class="modal-forget-wrapper-2" >
                <i class="fas fa-times fa-times-custom"></i>
              </div>
              <span class="modal-forget-wrapper-txt-header" >
                Incorrect Email/Password
              </span>
              <span class="modal-forget-wrapper-txt" >
                Try Again email/password
              </span>
            </div>
          `,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: 'Try Again',
          showConfirmButton: false,
          showCloseButton: true,
          reverseButton: true
        })
      })
    } else {
      SwalCustom.fire({
        html:`
          <div class="modal-forget-wrapper" >
            <div class="modal-forget-wrapper-2" >
              <i class="fas fa-times fa-times-custom"></i>
            </div>
            <span class="modal-forget-wrapper-txt-header" >
              Complete Your Email And Password
            </span>
            <span class="modal-forget-wrapper-txt" >
              Try Again email/password
            </span>
          </div>
        `,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Try Again',
        showConfirmButton: false,
        showCloseButton: true,
        reverseButton: true
      })
    }
  }

  const forgetPassword = () => {
    const SwalCustom = Swal.mixin({
      customClass: {
          cancelButton: 'btn-swal-wrong'
      },
      buttonsStyling: true
    })
    
    SwalCustom.fire({
      html:`
        <div class="modal-forgetPassword" >
          <div class="modal-forgetPassword-2" >
            <i class="fa fa-exclamation fa-forget-custom"></i>
          </div>
          <span class="modal-forget-wrapper-txt-header" >
            Ask Admin in MoTCE to Reset your Password
          </span>
        
        </div>
      `,
      showCancelButton: true,
      confirmButtonColor: '#3f86e2',
      cancelButtonColor: '#3f86e2',
      cancelButtonText: 'Close',
      showConfirmButton: false,
      showCloseButton: true,
      reverseButton: true
    }).then((result) => {
      if (result.value) {
      } else {
      }
    })
  }

  const handleRegister = () => {
    History.push('/request-user')
  }

  return (
    <div className="container-login-pages" >
      <div className="contain-login-page" >
        <span className="txt-login-page" >
          Welcome to VITO Online Report
        </span>
        <span className="txt-login-page-small" >
          Ministry of Tourism and Creative Economy of the Republic of Indonesia
        </span>
        <img src={Logo} alt="logo" className="logo-wi-login" />
        {/* <span className="txt-login-input" >
          Masuk Akun Anda
        </span> */}

        <form>
          <div className="container-input-login" >
            <div className="input-styles" >
              <div className="input-icons" >
                <AccountCircleIcon className={classes.iconUser} />
              </div>
              <Divider orientation="vertical" flexItem className={classes.divider} />
              <input type="text" className="input-login-txt" placeholder="Email" style={{ color: email && "black" }} onChange={e=>setEmail(e.target.value)} />
            </div>
            <div className="input-styles" style={{ marginTop: 30 }}>
              <div className="input-icons" >
                <VpnKeyIcon className={classes.iconUser} />
              </div>
              <Divider orientation="vertical" flexItem className={classes.divider} />
              <input type={secret ? 'password' : 'text'} style={{ color: password && "black" }} className="input-login-txt" placeholder="Password" onChange={e=>setPassword(e.target.value)} />
              <div className="eye-secret" >
                {
                  secret ? 
                  <i className="fas fa-eye eye-custom " onClick={()=>setSecret(false)} />
                  :
                  <i className="fas fa-eye eye-custom " style={{ color: '#e23f80' }} onClick={()=>setSecret(true)} />
                }
              </div>
            </div>
            <div className="remember-me-login" >
              <FormControlLabel
                control={<CheckboxCustom checked={remember === 'yes' ? true : false} onChange={handleChange} value="yes" />}
                label="Remember me"
                className={classes.checkbox}
              />
              <span className="txt-forget-password" onClick={()=>forgetPassword()}>Forgot Password?</span>
            </div>
            <Button type="submit" variant="contained" className={classes.buttonLogin} onClick={(e)=>handleLogin(e)}>
              Log in
            </Button>
          </div>
        </form>

        <div className="container-footer-login" >
          <span className="login-txt-footer" onClick={()=>handleRegister()}>
            Click Here To Request Data
          </span>
        </div>
      </div>
    </div>
  );
}
