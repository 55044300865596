import React from "react";
import "./styles.scss";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Swal from "sweetalert2";
import { useHistory } from 'react-router-dom'

const ButtonOk = withStyles((theme) => ({
  root: {
      width: '180px',
      height: '44px',
      color: '#ffffff',
      backgroundColor: '#E23F81',
      fontFamily: ['Roboto', 'sans-serif'],
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '18px',
      textAlign: 'center',
      lineHeight: '21px',
      boxSizing: 'border-box',
      borderRadius: '4px',
      boxShadow: 'none',
      textTransform: 'none',
      marginRight: 20,
      '&:hover': {
          backgroundColor: '#E23F81',
          boxShadow: 'none',
      },
  },
}))(Button);

const ButtonCancel = withStyles((theme) => ({
  root: {
      width: '180px',
      height: '44px',
      color: '#E23F81',
      backgroundColor: 'none',
      fontFamily: ['Roboto', 'sans-serif'],
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '18px',
      textAlign: 'center',
      lineHeight: '21px',
      boxSizing: 'border-box',
      borderRadius: '4px',
      boxShadow: 'none',
      textTransform: 'none',
      border: '1px solid #E23F81',
      '&:hover': {
          backgroundColor: 'none',
          boxShadow: 'none',
      },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: "18px",
      width: "539px",
      height: "44px",
    },
    buttonSend: {
      position: "absolute",
      width: "139px",
      height: "21px",
      left: "372px",
      top: "930px",

      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "18px",
      lineHeight: "21px",
      /* identical to box height */

      color: "#FFFFFF",
    },
    buttonExit: {
      position: "absolute",
      width: "42px",
      height: "21px",
      left: "613px",
      top: "930px",

      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "18px",
      lineHeight: "21px",
      /* identical to box height */

      color: "#E23F81",
    },
    iconInfo: {
      color: "#999999",
      boxSizing: "border-box",
    },
  },
}));

export default function ForgetPass() {
  const classes = useStyles();
  const History = useHistory();

  const sendVerification = () => {
    const SwalCustom = Swal.mixin({
      customClass: {
        confirmButton: 'btn-swal-wrong'
      },
      buttonsStyling: false
    })
    
    SwalCustom.fire({
      html:`
        <div class="modal-forget-wrapper" >
          <div class="modal-forget-wrapper-2" >
            <i class="fas fa-times fa-times-custom"></i>
          </div>
          <span class="modal-forget-wrapper-txt-header" >
          Email Berhasil Terkirim
          </span>
          <span class="modal-forget-wrapper-txt" >
          Silahkan cek email Anda untuk membuat password baru
          </span>
        </div>
      `,
      confirmButtonColor: '#d33',
      confirmButtonColor: 'Ok',
      showCancelButton: false,
      showCloseButton: true,
      reverseButton: true
    }).then((result) => {
      if (result.value) {
      } else {
      }
    })
  }

  return (
    <div className="container-forgetpass-pages">
      <div className="container-forgetpass-box">
        <div className="container-white-forgotpass" >
          <div className="send-data-header-wrapper" >
            <span className="forgetpass-header-txt" >
              Lupa Password
            </span>
            <span className="forgetpass-header-txt-sm">
              Silahkan masukan email akun Anda untuk <br/> me-reset password
            </span>
          </div>
          <div className="input-send-data-wrapper" >
            <input type="email" className="input-send-data" placeholder="Email" />
            <div className="fotgetpass-info" >
              <i className="fas fa-info-circle info-forgetpass-icon"/>
              <span className="fotgetpass-info-txt" >
                Jika lupa email yang terdaftar, silahkan hubungi Admin
              </span>
            </div>
          </div>
        </div>
        <div className="footer-send-data" >
          <ButtonOk onClick={()=>sendVerification()} >Kirim Verifikasi</ButtonOk>
          <ButtonCancel onClick={()=>History.goBack()}  >Batal</ButtonCancel>
        </div>
      </div>
    </div>
  );
}
