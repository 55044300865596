import { Box, Button, Typography, withStyles, makeStyles, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import UploadFile from '../../../../images/ic_upload.png'
import empBox from '../../../../images/box.png';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import LinearProgress from '@material-ui/core/LinearProgress';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import Axios from 'axios'
import { SERVER } from '../../../../../../server'

const useStylesTooltip = makeStyles((theme) => ({
    arrow: {
        color: "#2C304D",
    },
    tooltip: {
        backgroundColor: "#2C304D",
    },
}));

function CustomTooltip(props) {
    const classes = useStylesTooltip();
    return <Tooltip arrow classes={classes} {...props} />;
}

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#b8b8b8',
    },
}))(LinearProgress);

const BorderLinearComplete = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#E23F81',
    },
}))(LinearProgress);

const useStyles = makeStyles({
    boxClose: {
        borderRadius: "50%",
        display: "flex",
        width: "1rem",
        height: "1rem",
        padding: "0.3rem",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(226, 63, 129, 0.1)",
        cursor: "pointer"
    },
    disableBox: {
        background: 'rgba(255, 255, 255, 0.5)',
        width: "100%",
        height: "100%",
        position: "absolute",
        zIndex: "999"
    },
    dropZone: {
        height: "100%"
    }
})

export default function Index(props) {
    const classes = useStyles()
    const [docs, setDocs] = useState([])
    const [docsName, setDocsName] = useState([])
    const [flag, setFlag] = useState(false)
    const [progress, setProgress] = useState(0);
    const { monthNow, getReport} = props

    function addFiles(fileObj) {
        console.log(fileObj);
        let temp = docs
        let tempName = docsName
        
        fileObj.map((item) => {
            temp.push(item.file)
            tempName.push(item.file.name)
        })

        setDocs(temp)
        setDocsName(tempName)
        setProgress(0)
        onProgress()
        setFlag(!flag)
    }

    function onProgress() {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 300);

        return () => {
            clearInterval(timer);
        };
    }

    function deleteDoc(index) {
        let temp = docs
        let tempName = docsName

        temp.splice(index, 1)
        tempName.splice(index, 1)

        setDocs(temp)
        setDocsName(tempName)
        setFlag(!flag)
    }

    const sendReport = () => {
        if (monthNow) {
            const dataSend = new FormData()
            docs.map(item=>{
                dataSend.append('file', item)
            })
            dataSend.set('month', monthNow.month)
            Axios({
                method: 'POST',
                url: `${SERVER}/report/monthly/${monthNow.id}`,
                data: dataSend,
                headers: {
                    token : localStorage.getItem('token'),
                }
            })
            .then(({data})=>{
                getReport(monthNow)
                if (props.fromListFiles) {
                    props.cancel()
                }
            })
            .catch(err=>{
            })
        }
    }

    return (
        <>
            <Box display="flex" padding="1rem" height="65vh">
                <Box position="relative" width={1 / 2} border="2px dashed #FFC5DC" borderRadius="0.5rem" display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginRight="1rem">
                    <Box position="absolute" width="100%" height="100%" zIndex="9999999" style={{ opacity: "0" }}>
                    <DropzoneAreaBase
                        onAdd={(fileObjs) => addFiles(fileObjs)}
                        acceptedFiles={[".pdf"]}
                        // onDelete={(fileObj) => console.log('Removed File:', fileObj)}
                        showAlerts={false}
                        maxFileSize={52428800}
                        dropzoneText=""
                        dropzoneClass={classes.dropZone}
                    />
                    </Box>
                    <img src={UploadFile} style={{width: "15rem"}} />
                    <Box marginTop="1rem">
                        <Typography variant="h6" style={{ color: "#999999" }}>Drag or <span style={{color: "#E23F81"}}>Tap Here</span> To Upload File</Typography>
                    </Box>
                    <Box marginTop="3rem"><Typography variant="h6" style={{ color: "#E23F81" }}>Upload PDF File (Max File Size : 50 MB)</Typography></Box>
                </Box>
                <Box width={1 / 2} border="1px solid #DDDDDD" borderRadius="0.5rem" marginLeft="1rem" >
                    <Box height={5 / 6} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        {
                            docs.length > 0 ?
                                <Box height="100%" width="100%" paddingY="2rem">
                                    {
                                        docsName.map((name, index) => {
                                            return (
                                                <Box key={index} display="flex" paddingX="2rem" alignItems="center" marginTop={index !== 0 && '1vw'}>
                                                    <Box width={3 / 20}>
                                                        <i className="fas fa-file-pdf" style={{ fontSize: "3.5vw", color: "#E23F81" }}/>
                                                        {/* <PictureAsPdfIcon style={{ fontSize: "5rem", color: "#E23F81" }} /> */}
                                                    </Box>
                                                    <Box width={4 / 5} display="flex" flexDirection="column" justifyContent="center">
                                                        <Box display="flex" justifyContent="space-between" marginBottom="0.5rem">
                                                            <Typography style={{ color: "#999999" }}>{name}</Typography>
                                                            <Box className={classes.boxClose}>
                                                                <CustomTooltip placement="top" title="Delete" arrow>
                                                                    <Button onClick={() => deleteDoc(index)}>
                                                                        <CloseRoundedIcon style={{ color: "#E23F81", cursor: "pointer" }} />
                                                                    </Button>
                                                                </CustomTooltip>
                                                            </Box>
                                                        </Box>
                                                        <Box>
                                                            {
                                                                !docsName.length - 1 ?
                                                                    progress != 100 ?
                                                                    <BorderLinearProgress variant="determinate"  value={index == docsName.length - 1 ? progress : 100} />
                                                                    :
                                                                    <BorderLinearComplete variant="determinate"  value={index == docsName.length - 1 ? progress : 100} />
                                                                :
                                                                <BorderLinearComplete variant="determinate"  value={100} />
                                                            }
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            )
                                        })
                                    }
                                </Box> :
                                <Box>
                                    <Box><img src={empBox} style={{ width: "15rem" }} /></Box>
                                    <Button style={{ textTransform: "none" }}><Typography variant="h6" style={{ color: "#999999" }}>Select File To Upload</Typography></Button>
                                </Box>
                        }

                    </Box>
                    <Box height={1 / 6} borderTop="0.5px solid #DDDDDD" display="flex" alignItems="center" paddingLeft="2rem" align="left">
                        <Box position="relative">
                            {
                                docs.length == 0 ?
                                <Button variant="contained" color="secondary" size="large" style={{ textTransform: 'none', padding: "0.5rem 3rem", background: "#d7d7d7" }}>Submit</Button> :
                                progress != 100 ?
                                <Button variant="contained" color="secondary" size="large" style={{ textTransform: 'none', padding: "0.5rem 3rem", background: "#d7d7d7" }}>Submit</Button> :
                                <Button onClick={sendReport} variant="contained" color="secondary" size="large" style={{ textTransform: 'none', padding: "0.5rem 3rem", background: "#E23F81" }}>Submit</Button>
                            }
                        </Box>
                        {props.fromListFiles ? <Button variant="outlined" color="secondary" onClick={props.cancel} style={{ textTransform: "none", padding: "0.5rem 3rem", marginLeft: "2rem" }} >Cancel</Button> : null}
                    </Box>
                </Box>
            </Box>
        </>
    )
}
