import React, { useState } from 'react'
import { Box, Typography, makeStyles, Button, IconButton } from '@material-ui/core'
import ShareIcon from '@material-ui/icons/Share';
import GetAppIcon from '@material-ui/icons/GetApp';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import UploadBox from '../MontlyUpload'
import DeleteAlert from '../../Modal/DeleteAlert/deleteMonthly'
import { SERVER } from '../../../../../../server'
import { useHistory, Link } from 'react-router-dom'

const useStyles = makeStyles({
    title: {
        fontWeight: "500",
        lineHeight: "1rem",
        color: "#2C304D"
    },
    file: {
        // height: "17rem",
        border: "1px solid #DDDDDD",
        boxSizing: "border-box",
        borderRadius: "8px",
        padding: "1rem",
        margin: "0.75rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    boxFiles: {
        display: "flex",
        flexWrap: "wrap",
        maxHeight: "57vh",
        overflowY: "scroll"
    },
    buttonRead: {
        color: "#E23F81",
        background: "rgba(226, 63, 129, 0.1)",
        borderRadius: "4px",
        width: "6rem",
        border: "none",
        textTransform: "none"
    },
    buttonReport: {
        marginTop: "1rem",
        color: "#E23F81",
        borderColor: "#E23F81",
        borderRadius: "4px",
        textTransform: "none",
        marginLeft: "1rem"
    },

})

export default function Index(props) {
    const classes = useStyles();
    const history = useHistory()
    const [toUpload, setToUpload] = useState(false)
    const { report, getReport, monthNow } = props

    function upload() {
        setToUpload(true)
    }

    function cancel() {
        setToUpload(false)
    }

    const showDate = (dateParams) => {
        if (dateParams) {
            let start = new Date(dateParams).getDate()
            let startMonth = new Date(dateParams).getMonth()
            let monthStart = ''
            let yearResult = new Date(dateParams).getFullYear()
            switch (startMonth) {
                case 0 :
                    monthStart = 'January'
                    break;
                case 1 :
                    monthStart = 'February'
                    break;
                case 2 :
                    monthStart = 'March'
                    break;
                case 3 :
                    monthStart = 'April'
                    break;
                case 4 :
                    monthStart = 'May'
                    break;
                case 5 :
                    monthStart = 'June'
                    break;
                case 6 :
                    monthStart = 'July'
                    break;
                case 7 :
                    monthStart = 'August'
                    break;
                case 8 :
                    monthStart = 'September'
                    break;
                case 9 :
                    monthStart = 'October'
                    break;
                case 10 :
                    monthStart = 'November'
                    break;
                case 11 :
                    monthStart = 'December'
                    break;
                default:
                    monthStart = ''
                    break;
            }
            let hour = new Date(dateParams).getHours()
            let minute = new Date(dateParams).getMinutes()
            let second = new Date(dateParams).getSeconds()
            
            return start + ' '+ monthStart +' '+ yearResult +' '+ (hour < 10 ? '0'+hour : hour) +':'+(minute < 10 ? '0'+minute : minute)+':'+(second < 10 ? '0'+second : second)
        }
    }

    const downloadFile = async ({file}) => {
        fetch(file, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/pdf',
            },
        })
        .then((response) => response.blob())
        .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
            'download',
            `${file.split('-FILE-')[1]}`,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        });
    }

    const shareWhatsapp = (file) => {
        window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(`${file}`)}`,'_blank');
    }

    return (
        <>
            {
                toUpload ?
                    <UploadBox monthNow={monthNow} getReport={getReport} fromListFiles={true} cancel={cancel} /> :
                    <div>
                        <Box padding="1rem" marginBottom="0.5rem">
                            <Typography className={classes.title} variant="h6" >Monthly Report</Typography>
                        </Box>
                        <Box className={classes.boxFiles}>
                            {
                                report &&
                                report.map((file, i) => {
                                    return (
                                        <Box key={i} width="250px" className={classes.file}>
                                            <Box marginTop="0.5rem" display="flex" justifyContent="center">
                                                <Box textAlign="center">
                                                    <i className="fas fa-file-pdf" style={{ fontSize: "70px", color: "#E23F81" }}/>
                                                    {/* <PictureAsPdfIcon style={{ color: "#E23F81", fontSize: "7rem" }} /> */}
                                                </Box>
                                            </Box>
                                            <Box maxHeight="100%" marginTop="1vw" >
                                                <Box maxHeight="3rem" overflow="hidden">
                                                    <Typography style={{ wordWrap: "break-word", }}>
                                                        { file && file.file && file.file.split('-FILE-')[1] }    
                                                    </Typography>
                                                </Box>
                                                <Typography variant="caption">Uploaded: {file && file.createdAt && showDate(file.createdAt) }</Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center" marginTop="1rem">
                                                <Box width={1 / 2}>
                                                    <a href={`${file && file.file}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }} >
                                                        <Button variant="outlined" size="small" className={classes.buttonRead}>View</Button>
                                                    </a>
                                                </Box>
                                                <Box display="flex" justifyContent="space-between" alignItems="center" paddingX="0.75rem" width={1 / 2}>
                                                    <IconButton size="small" aria-label="delete" onClick={e=>shareWhatsapp(file && file.file)}>
                                                        <i className="fas fa-share"/>
                                                    </IconButton>
                                                    <IconButton size="small" aria-label="delete" onClick={e=>downloadFile(file && file)} >
                                                        <GetAppIcon size="small" color="disabled" />
                                                    </IconButton>
                                                    <DeleteAlert categoryReport="Monthly Report" file={file} getReport={getReport} monthNow={monthNow}/>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )
                                })

                            }
                        </Box>
                        <Box>
                            <Button variant="outlined" size="small" onClick={upload} className={classes.buttonReport}>Upload Report</Button>
                        </Box>
                    </div>
            }


        </>
    )
}
