import axios from 'axios'
import { SERVER } from '../../server'
import {
    IS_LOGIN,
    IS_LOGOUT,
} from "../constanta";

export const profileToken = ( sendToken, history, swal ) => async dispatch => {
    let tokenLocal = localStorage.getItem('token')
    if( tokenLocal || sendToken ) {
        try {
            let token = sendToken ? sendToken : tokenLocal
            const { data } = await axios({
                method: 'get',
                url: `${SERVER}/user/token`,
                headers: { token }
            })
            if (!data) {
                dispatch({
                    type: IS_LOGOUT
                })
                if (history) {
                    history.replace('/')
                }
                localStorage.clear()
            } else {
                const root = data.access === 'vito' ? 'vito' : 'admin'
                dispatch({
                    type: IS_LOGIN,
                    profile :{
                        access : data.access,
                        email : data.email,
                        name : data.name,
                        photo : data.photo,
                        root,
                        role: data.role,
                        country: data.country,
                        area: data.area,
                        id: data.id,
                    }
                })
                if(history){
                    if (history.location.pathname === '/') {
                        if (data.role === 'guest') {
                            history.replace(`/admin/vito`)
                        } else {
                            history.replace(`/${root}`)
                        }
                    }
                }
            }
        } catch (err) {
            if (err.response) {
                if (err.response.status) {
                    if (err.response.status === 401) {
                        dispatch(logout(history))
                    }
                }
            }
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const logout = history => async dispatch => {
    try {
        localStorage.clear()
        dispatch({
            type: IS_LOGOUT
        })
        history.replace('/')
    } catch (error) {}
}
