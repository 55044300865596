import React, { useEffect, useState } from 'react'
import ImageGallery from 'react-image-gallery';
import './styles.scss'

export default function Gallery (props) {
  const { photosAll } = props
  const [images, setImages] = useState([])
  
  useEffect(()=>{
    if (photosAll) {
      let temp = []
      photosAll.split(',').forEach(photo=>{
        temp.push({ original: photo, thumbnail: photo })
      })
      setImages(temp)
    }
  },[photosAll])

  return (
    <>
      {/* <ImageGallery items={[{ original: photos, thumbnail: photos }]} /> */}
      <ImageGallery items={images} />
    </>
  ) 
  
}
