import {
    GET_REQUESTDATA
} from "../constanta";

const initialState = {
    request : []
}

export default (state = initialState, action)=> {
    switch(action.type){
        case GET_REQUESTDATA:
            return {
                ...state,
                request: action.data
            }
        default: {
            return state
        }
    }
}