import React,{ useState } from 'react'
import './styles.scss'
import { FormControlLabel, Checkbox, TextField, makeStyles, Typography, withStyles, ClickAwayListener } from '@material-ui/core'
import { useHistory } from 'react-router-dom';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useSelector, useDispatch } from 'react-redux'
import { updateStatusRequestData } from '../../../../../store/actions/requestDataAction'

// COMPONENT
const useStyles = makeStyles((theme) => ({
    root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
            borderColor: '#FDFDFD'
        },
        width: "272px",
        height: "34px",
        backgroundColor: '#FDFDFD',
        fontSize: 14,
    },
    disabled: {},
    focused: {
        "& $notchedOutline": {
            borderColor: "yellow"
        }
    },
    error: {},
    notchedOutline: {
        borderColor: '#FDFDFD !important',
    }
}));

const useStylesBtn = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    dropdown: {
        justifyContent: 'center',
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        width: '308px',
        position: 'absolute',
        top: 28,
        right: 0,
        left: -250,
        zIndex: 1,
        borderRadius: '4px',
        paddingLeft: theme.spacing(2),
        backgroundColor: '#FFFFFF',
        boxShadow: '6px 5px 33px -14px rgba(0,0,0,0.75)'
    },
    label:{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "16px",
        color: "#999999",
    },
    box:{
        borderBottom: '0.5px solid #DDDDDD',
        width: '100%'
    },
    boxLast:{
        width: '100%'
    }
}));

const GreenCheckbox = withStyles({
    root: {
        color: '#999999',
        '&$checked': {
            color: '#E23F81',
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

function ClickAway(props) {
    let { id, status, changeStatus } = props
    const classes = useStylesBtn();
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={classes.root}>
                <div className="rqs-dta-btn-option" onClick={handleClick} >
                    <MoreHorizIcon/>
                </div>
                {open ? (
                <div className={classes.dropdown}>
                    <FormControlLabel
                        className={classes.box}
                        control={<GreenCheckbox onChange={e=>changeStatus(id,e.target.value)} value="Sudah dikirim via email"
                        checked={status === 'Sudah dikirim via email' ? true : false}
                        />}
                        label={<Typography className={classes.label}>Sudah dikirim melalui Email</Typography>}
                    />
                    <FormControlLabel
                        className={classes.box}
                        control={<GreenCheckbox onChange={e=>changeStatus(id,e.target.value)} value="Sudah duhubungi (Telepon, WA, SMS)"
                        checked={status === 'Sudah duhubungi (Telepon, WA, SMS)' ? true : false}
                        />}
                        label={<Typography className={classes.label}>Sudah dihubungi (Telepon, WA, SMS)</Typography>}
                    />
                    <FormControlLabel
                        className={classes.boxLast}
                        control={<GreenCheckbox onChange={e=>changeStatus(id,e.target.value)} value="Tidak dapat dikirimkan"
                        checked={status === 'Tidak dapat dikirimkan' ? true : false}
                        />}
                        label={<Typography className={classes.label}>Tidak dapat dikirimkan</Typography>}
                    />
                </div>
                ) : null}
            </div>
        </ClickAwayListener>
    );
}

export default function Report(){
    const classes = useStyles();
    const Dispatch = useDispatch()
    const [search,setSearch] = useState('')
    const data = useSelector(state=> state.requestDataState.request)
    const changeStatus = (id,status) => {
        Dispatch(updateStatusRequestData(id,status))
    }

    return(
        <div className="dashboard" >
            <span className="breadcrumb-txt-black" >
                Permintaan Laporan
            </span>
            <span className="breadcrumb-slash-black" >
                /
            </span>
            <div className="request-data-vito-container" >
                <div className="report-filter-container" >
                    <form autoComplete="off">
                        <TextField label="Cari Nama" size="small" variant="outlined" color="secondary"
                            onChange={e=>setSearch(e.target.value)}
                            InputProps={{ classes: classes }}
                            InputLabelProps={{
                                style:{
                                    fontSize: 14
                                }
                            }}
                        />
                    </form>
                </div>
                <div className="request-data-wrapper-content" >
                    <div className='request-data-header-content' >
                        <div className="request-data-one" >
                            <span className="request-data-header" >
                                No
                            </span>
                        </div>
                        <div className="request-data-two" >
                        NAMA
                        </div>
                        <div className="request-data-three" >
                        JABATAN/INSTITUSI
                        </div>
                        <div className="request-data-four" >
                        UNTUK KEPERLUAN
                        </div>
                        <div className="request-data-five" >
                        LAPORAN YANG DIMINTA
                        </div>
                        <div className="request-data-six" >
                        AKSI
                        </div>
                    </div>
                    {
                        data &&
                        data
                        .filter(user=>((user.name).toLowerCase()).includes((search).toLowerCase()))
                        .sort((a,b) => a.createdAt < b.createdAt ? -1 : 1)
                        .map((data, i)=>(
                            <div key={i} className='request-data-body-content' style={{ height: 80, backgroundColor: 'white', borderRadius: 4 }} >
                                <div className="request-data-one" >
                                    <span className="txt-vertical" style={{
                                        backgroundColor:
                                        data.status === 'Sudah dikirim via email' ? '#E23F81' :
                                        data.status === 'Sudah duhubungi (Telepon, WA, SMS)' ? '#20AC20' :
                                        data.status === 'Tidak dapat dikirimkan' ? '#1D0689' : 'white'
                                    }}>
                                        {
                                            data.status === 'Sudah dikirim via email' ? 'Email Dikirim' :
                                            data.status === 'Sudah duhubungi (Telepon, WA, SMS)' ? 'Telp, WA, SMS' :
                                            data.status === 'Tidak dapat dikirimkan' ? 'Tidak Dikirim' : '-'
                                        }
                                    </span>
                                    <span className="rqs-dta-num" >
                                        {
                                            i+=1
                                        }
                                    </span>
                                </div>
                                <div className="request-data-two" >
                                    <span className="rqs-dta-black" >
                                        {data.name}
                                    </span>
                                    <span className="rqs-dta-grey" >
                                        {data.email}
                                    </span>
                                    <span className="rqs-dta-grey" >
                                        {data.phone}
                                    </span>
                                </div>
                                <div className="request-data-three" >
                                    <span className="rqs-dta-black" >
                                    {data.jabatan}
                                    </span>
                                    <span className="rqs-dta-grey" >
                                    {data.instansi}
                                    </span>
                                </div>
                                <div className="request-data-four" >
                                    <span className="rqs-dta-black" >
                                    {data.forWhat}
                                    </span>
                                </div>
                                <div className="request-data-five" >
                                    <span className="rqs-dta-black" >
                                    {data.request}
                                    </span>
                                </div>
                                <div className="request-data-six" >
                                    <ClickAway id={data.id} changeStatus={changeStatus} status={data.status} />
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
