import React, { useEffect, useState } from 'react'
import './styles.scss'
import ProfileUnknown from '../../../../../images/unknown.png'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getNotificationVito, restart, readNotification } from '../../../../../store/actions/notificationAction'
import socketClient from "socket.io-client";
import { SERVER } from '../../../../../server'

export default function Notification() {
    const History = useHistory()
    const Dispatch = useDispatch()
    const notification = useSelector(state=> state.notificationState.notification)
    const myEmail = useSelector(state=> state.profileState.email)
    let [counting, setCounting] = useState(1)
    const socket = socketClient(SERVER, {transports: ['websocket'], secure: true});

    const [refresh,SetRefresh] = useState(false)
    const user = useSelector(state=> state.evaluationState.vito)

    useEffect(()=>{
        Dispatch(restart())
        Dispatch(getNotificationVito())
    },[])

    useEffect(()=>{
        socket.on('notification-vito', (data) => {
            if (user) {
                if (user.UserId == data.id) {
                    SetRefresh(true)
                }
            }
        })
    })

    const muatUlang = () => {
        Dispatch(restart())
        Dispatch(getNotificationVito())
        SetRefresh(false)
    }

    function showDate(dateParams) {
        let date = new Date(dateParams).getDate()
        let day = new Date(dateParams).getDay()
        let monthNumber = new Date(dateParams).getMonth()
        let month = ''
        let year = new Date(dateParams).getFullYear()
        let days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
        switch (monthNumber) {
            case 0:
                month = 'January'
                break;
            case 1:
                month = 'February'
                break;
            case 2:
                month = 'March'
                break;
            case 3:
                month = 'April'
                break;
            case 4:
                month = 'May'
                break;
            case 5:
                month = 'June'
                break;
            case 6:
                month = 'July'
                break;
            case 7:
                month = 'August'
                break;
            case 8:
                month = 'September'
                break;
            case 9:
                month = 'October'
                break;
            case 10:
                month = 'November'
                break;
            case 11:
                month = 'December'
                break;
            default:
                month = '-'
                break;
        }
        let hour = new Date(dateParams).getHours()
        let minute = new Date(dateParams).getMinutes()
        let second = new Date(dateParams).getSeconds()
        return days[day]  + ', ' + date + ' ' + month + ' ' + year +' '+ (hour < 10 ? '0'+hour : hour) +':'+(minute < 10 ? '0'+minute : minute)+':'+(second < 10 ? '0'+second : second) +' WIB'
    }

    const readMore = () => {
        Dispatch(getNotificationVito(counting))
        setCounting(counting+=1)
    }

    const readNote = (id, users) => {
        if (users) {
            if (!users.split(',').filter(a=>a == myEmail)[0]) {
                Dispatch(readNotification(id, users.split(','), 'vito'))
            }
        } else {
            Dispatch(readNotification(id, ['test@email.id']))
        }
    }

    return(
        <div className="container-notification" >

            <div style={{ display: 'flex', alignItems: 'center', width: '50%', cursor: 'pointer' }} onClick={e=>History.goBack()} >
                <i className="fas fa-arrow-left" style={{ color: '#E23F81', marginRight: 10, fontSize: '23px' }} />
                <span style={{ color: '#E23F81', fontSize: '16px' }} >
                    Back
                </span>
            </div>
            <div className="notification-box" >
                <div className="notification-box-top" >
                    <span className="notification-notif-text" >
                        Notification
                    </span>
                    {
                        refresh &&
                        <span onClick={e=>muatUlang()} className="notification-text2" style={{ backgroundColor: '#d35273', color: 'white', padding: 10, borderRadius: 10 }} >
                            {/* Tandai semua sudah dibaca */}
                            <i className="fa fa-refresh fa-1x" style={{ fontSize: 15, marginRight: 5 }} />
                            <span>
                                New Notifikasi, Refresh
                            </span>
                        </span>
                    }
                </div>
                <div className="notification-box-mid" >

                    {
                        notification &&
                        notification.map((data, i)=>(
                        <div className="notification-card"
                            key={i}
                            style={{ backgroundColor: data && data.users && data.users.split(',').filter(a=>a == myEmail)[0] && 'white' }}
                            onClick={e=> data && data.link && [History.push(data && data.link), readNote(data.id, data.users)]}
                        >
                            <img src={ProfileUnknown} alt="photo" className="photo-notification" />
                            <div className="notification-box-small" >
                                <span className="notification-txt1" >
                                    <b> { data && data.User ? data.User ? data.User.name : '-' : '-' } </b>
                                    { data && data.note ? data.note : '-' }
                                </span>
                                <span className="notification-txt1small" >
                                    { showDate(data && data.createdAt) }
                                </span>
                            </div>
                        </div>
                        ))
                    }
                    
                </div>
                <div className="notification-box-bottom" >
                    <span className="notification-notif-text2" onClick={e=>readMore()} >
                        See More
                    </span>
                </div>
            </div>
        </div>
    )
}