import { Box, FormControl, FormControlLabel, makeStyles, Radio, RadioGroup, Typography, TextField, Button, Select, MenuItem, InputLabel } from '@material-ui/core';
import React, { useState } from 'react'
import './styles.scss'
import Kuantitatif from './Kuantitatif'
import Kualitatif from './Kualitatif'

const useStyles = makeStyles({
    titleTable: {
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor: "#DDDDDD",
        paddingTop: "1em",
        paddingBottom: "1em",
        paddingLeft: "1.5em"
    },
    box: {
        padding: "1em 2em",
        borderBottom: "0.5px solid #DDDDDD"
    },
});

export default function PIN(props) {
    const classes = useStyles();
    const [categoryInput, setCategoryInput] = useState('kuantitatif');
    
    const handleChangeCategoryInput = (event) => {
        setCategoryInput(event.target.value);
    };

    return (
        <>
            <div className="dashboard" >
                <span className="breadcrumb-txt-active" >
                    Evaluation
            </span>
                <span className="breadcrumb-slash-active" >
                    /
            </span>
                <span className="breadcrumb-txt-black" >
                    Input Evaluation
            </span>
                <span className="breadcrumb-slash-black" >
                    /
            </span>
            </div>
            <div className="vito-table">
                <Box>
                    <Box className={classes.box}>
                        <Typography variant="h5" className={classes.title}><b>Input Evaluation</b></Typography>
                    </Box>
                    <Box className={classes.box}>
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="categoryInput" row name="categoryInput" value={categoryInput} onChange={handleChangeCategoryInput}>
                                <FormControlLabel value="kuantitatif" control={<Radio />} label="Kuantitatif" />
                                <FormControlLabel value="kualitatif" control={<Radio />} label="Kualitatif" />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    {
                        categoryInput === "kuantitatif" ? <Kuantitatif/>: <Kualitatif/>
                    }
                </Box>
            </div>
        </>
    )
}