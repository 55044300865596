import React  from 'react';
import Backdrop from './backdrop';
import './styles.scss'
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const UseStyles = makeStyles((theme) => ({
    button:{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "0.9vw",
        color: "#333333",
        textTransform: 'capitalize',
        background: "#e35981",
        borderRadius: "8px",
        boxShadow: 'none',
        boxSizing: "border-box",
        paddingLeft: '2vw',
        paddingRight: '2vw',
        "&:hover":{
            boxShadow: 'none',
            background: "#e35981",
        }
    },
    buttonCancel:{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "0.9vw",
        color: "#333333",
        textTransform: 'capitalize',
        background: "white",
        border: "1px solid #BFBFBF",
        boxSizing: "border-box",
        borderRadius: "8px",
        boxShadow: 'none',
        paddingLeft: '2vw',
        marginLeft: '0.8vw',
        paddingRight: '2vw',
        "&:hover":{
            boxShadow: 'none',
        }
    }
}))

const modal = props => {
    const classes = UseStyles();
    let {
        name,
        passwordHandler,
        passwordHandlerRepeat,
        password,
        setPassword,
        Null,
        showPassword,
        passwordRepeat,
        NullRepeat,
        showPasswordRepeat,
        setPasswordRepeat,
        error,
        setNull,
        setNullRepeat
    } = props
    return (
        <>
            <Backdrop show={props.show} clicked={props.modalClosed} />
            <div
                className="ModalAlert"
                style={{
                    display : props.show ? 'block' : 'none',
                    opacity : props.show ? 1 : 0,
                }}
            >
                <div className="modal-contain-alert" >
                    <span className="modal-alert-txt" >
                        Change Password
                    </span>
                    <span className="modal-alert-txt" style={{ fontSize: '1vw', marginTop: '0vw' }} >
                        { name && name }
                    </span>
                    <div className="input-register-box" style={{ marginTop: '1vw', borderColor: (Null && !password) && 'red' }}>
                        <i className="fas fa-unlock-alt fa-lock-styles"/>
                        <input type={showPassword ? 'text' : 'password'} className="input-register" value={password} placeholder="Password" onChange={e=>[setPassword(e.target.value), setNull(null), setNullRepeat(null)]}/>
                        <i className="fas fa-eye fa-eye-styles" style={{ color: showPassword ? "#e35981": "#C6C6C6" }} onClick={passwordHandler} />
                    </div>
                    <div className="input-register-box" style={{ marginTop: '1vw', borderColor: ((Null && !passwordRepeat) || (NullRepeat)) && 'red' }}>
                        <i className="fas fa-unlock-alt fa-lock-styles"/>
                        <input type={showPasswordRepeat ? 'text' : 'password'} className="input-register" value={passwordRepeat} placeholder="Re-Type Password" onChange={e=>[setPasswordRepeat(e.target.value), setNull(null), setNullRepeat(null)]}/>
                        <i className="fas fa-eye fa-eye-styles" style={{ color: showPasswordRepeat ? "#e35981": "#C6C6C6" }} onClick={passwordHandlerRepeat} />
                    </div>
                    <div className="input-register-box" style={{ marginTop: error && '1vw',  border: 'none', height: 0}}>
                        <span style={{ color: 'red', marginRight: 'auto', marginTop: '0.5vw' }} >
                            { error }
                        </span>
                    </div>
                    <div className="btn-modal-alert-wrapper" >
                        <Button className={classes.button} onClick={props.handleChangePass}>
                            Save
                        </Button>
                        <Button className={classes.buttonCancel} onClick={props.modalClosed} >
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};
export default modal;