import React, {useEffect, useState, Fragment} from 'react'
import { useHistory, Switch, Route, useRouteMatch } from 'react-router-dom'
import { ClickAwayListener, Box, Typography, TextField, withStyles, makeStyles, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Paper, requirePropFactory, Button, TablePagination, TableFooter, useTheme } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { deletePartner } from '../../../../../store/actions/partnerAction'
import DeleteAlert from '../../../component/Modal/DeleteAlert/deletePartner'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#F3F3F3",
        color: "#999999",
        fontWeight: 500,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "#FAFAFA",
        },

    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 700,
        boxShadow: "none"
    },
    buttonViewProfile: {
        background: "#FCECF2",
        color: "#E23F81",
        boxShadow: "none"
    },
    titleTable: {
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor: "#DDDDDD",
        paddingTop:"1em",
        paddingBottom:"1em",
        paddingLeft:"1.5em",
        backgroundColor: 'white'
    },
    tableContainer: {
        boxShadow: "none",
        border: "1px solid #CCCCCC",
        borderRadius: "4px"
    }
});

const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5)
    },
  }));

  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
  
  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };
  
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  
  const headCells = [
    { id: 'name', disablePadding: false, label: 'COMPANY' },
    { id: 'contactPerson', numeric: false, disablePadding: false, label: 'CONTACT PERSON' },
    { id: 'title', numeric: false, disablePadding: false, label: 'TITLE / POSITION' },
    { id: 'emailPhone', disablePadding: false, label: 'EMAIL / PHONE' },
    { id: 'type', numeric: false, disablePadding: false, label: 'TYPE' },
    { id: 'countryOrigin', numeric: false, disablePadding: false, label: 'COUNTRY ORIGIN' },
    { id: 'action', disablePadding: false, label: 'ACTION' },
  ];
  
  function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, haveAction } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <StyledTableRow>
          {headCells.map((headCell) => (
            (haveAction !== true) ? (headCell.id !== 'action') &&
            <StyledTableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'center'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </StyledTableCell> :
            <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
          ))}
        </StyledTableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    haveAction: PropTypes.bool.isRequired
  };

  const useStyles3 = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }));

export default function PIN(props) {
    // const classes = useStyles();
    const history = useHistory();
    const [search, setSearch] = useState('')
    const myRole = useSelector(state=> state.profileState.role)
    const partners = useSelector(state=> (myRole === 'picpasar') ? state.partnerState.partnersFilter : state.partnerState.partners)

    function viewPartner (id) {
        history.push(`/admin/partner/${id}`)
    }

    function addPartner () {
        history.push("/admin/partner/add")
    }

    const classes = useStyles3();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
  
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
  
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = partners.filter(a=>search ? ((((a.name).toLowerCase()).includes((search).toLowerCase())) || (((a.contactPerson).toLowerCase()).includes((search).toLowerCase())) || (((a.countryOrigin).toLowerCase()).includes((search).toLowerCase())) || (((a.type).toLowerCase()).includes((search).toLowerCase()))) : a).map((n) => n.name);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
        };
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const handleSearch = (event) => {
        setSearch(event.target.value);
        setPage(0);
    };

    return (
        <>
            <div className="dashboard" >
                <span className="breadcrumb-txt-black" >
                    PARTNER
            </span>
                <span className="breadcrumb-slash-black" >
                    /
            </span>
            </div>
            <div className="partner-table">
                <Box width='100%'>
                    <Box className={classes.titleTable}>
                        <Typography variant="h5"><b>PARTNER LIST</b> <sup style={{color:'red'}}></sup></Typography>
                    </Box>
                    <Box paddingLeft="1.5em" paddingRight="1em" paddingTop="2em" style={{ backgroundColor: 'white', paddingBottom: '20px' }} >
                        <Box display="flex" justifyContent="space-between">
                            <form autoComplete="off">
                                <TextField onChange={handleSearch} label="Search Partner" size="small" variant="outlined" color="secondary" borderColor="#C4C4C4" />
                            </form>
                            {
                                (myRole === 'admin' || myRole === 'picpasar') &&
                                <Button variant="contained" size="large" color="secondary" style={{backgroundColor:"#E23F81", textTransform:"none"}} onClick={addPartner}>Add Partner</Button>
                            }
                        </Box>
                        <Box paddingTop="1em">
                            <div className={classes.root}>
                                <Paper className={classes.paper}>
                                    <TableContainer>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[25, 50, { label: 'All', value: -1 }]}
                                            colSpan={8}
                                            count={partners.filter(a=>search ? ((((a.name).toLowerCase()).includes((search).toLowerCase())) || (((a.contactPerson).toLowerCase()).includes((search).toLowerCase())) || (((a.countryOrigin).toLowerCase()).includes((search).toLowerCase())) || (((a.type).toLowerCase()).includes((search).toLowerCase()))) : a).length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: { 'aria-label': 'rows per page' },
                                                native: true,
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </TableRow>
                                    <Table
                                        className={classes.table}
                                        aria-labelledby="tableTitle"
                                        aria-label="enhanced table"
                                    >
                                        <EnhancedTableHead
                                        classes={classes}
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={partners.filter(a=>search ? ((((a.name).toLowerCase()).includes((search).toLowerCase())) || (((a.contactPerson).toLowerCase()).includes((search).toLowerCase())) || (((a.countryOrigin).toLowerCase()).includes((search).toLowerCase())) || (((a.type).toLowerCase()).includes((search).toLowerCase()))) : a).length}
                                        haveAction={(myRole === 'admin' || myRole === 'picpasar') ? true : false}
                                        />
                                        <TableBody>
                                        {stableSort(partners.filter(a=>search ? ((((a.name).toLowerCase()).includes((search).toLowerCase())) || (((a.contactPerson).toLowerCase()).includes((search).toLowerCase())) || (((a.countryOrigin).toLowerCase()).includes((search).toLowerCase())) || (((a.type).toLowerCase()).includes((search).toLowerCase()))) : a), getComparator(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((partner, index) => {;

                                            return (
                                                <StyledTableRow>
                                                <StyledTableCell align="left">{ partner && partner.name ? partner.name : '-' }</StyledTableCell>
                                                <StyledTableCell align="left">{ partner && partner.contactPerson ? partner.contactPerson : '-' }</StyledTableCell>
                                                <StyledTableCell align="left">{ partner && partner.title ? partner.title : '-' }</StyledTableCell>
                                                <StyledTableCell align="left">{ partner && partner.email ? partner.email : '-' } / { partner && partner.phone ? partner.phone : '-' }</StyledTableCell>
                                                <StyledTableCell align="left">{ partner && partner.type ? partner.type : '-' }</StyledTableCell>
                                                <StyledTableCell align="left">{ partner && partner.countryOrigin ? partner.countryOrigin : '-' }</StyledTableCell>
                                                {
                                                    (myRole === 'admin' || myRole === 'picpasar') && 
                                                    <StyledTableCell align="left">
                                                        {/* <ClickAway id={partner.id} history={history} name={partner.name} style={{ top: 0 }} /> */}
                                                        <ClickAway id={partner.id} history={history} name={partner.name} style={{ top: 0 }} data={partner} />
                                                    </StyledTableCell>
                                                }
                                                </StyledTableRow>
                                            );
                                            })}
                                        </TableBody>
                                    </Table>
                                    </TableContainer>
                                </Paper>
                            </div>
                        </Box>
                    </Box>
                </Box>
            </div>
        </>
    )
}

const useStylesBtn = makeStyles((theme) => ({
    root: {
        position: 'absolute',
    },
    dropdown: {
        justifyContent: 'center',
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        width: '155px',
        position: 'absolute',
        // top: 28,
        // right: 0,
        left: -60,
        zIndex: 99,
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        boxShadow: '6px 5px 33px -14px rgba(0,0,0,0.75)'
    },
    label:{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "16px",
        color: "#999999",
    },
    box:{
        borderBottom: '0.5px solid #DDDDDD',
        width: '100%',
        height: 35,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: 15,
        paddingRight: 15,
        color: '#999999',
        cursor: 'pointer',
        borderRadius: 4,
        '&:hover':{
            color: 'white',
            backgroundColor: '#E23F81',
            width: '-webkit-fill-available'
        }
    },
    boxLast:{
        width: '100%',
        height: 35,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: 15,
        paddingRight: 15,
        color: '#999999',
        borderRadius: 4,
        cursor: 'pointer',
        '&:hover':{
            color: 'white',
            backgroundColor: '#E23F81',
            width: '-webkit-fill-available'
        }
    }
}));

function ClickAway(props) {
    let { id, name, history, data } = props
    const classes = useStylesBtn();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch()

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    const deleteUserAction = () => {
        dispatch(deletePartner(id))
        setOpen(false);
    }

    const viewPartner = () => {
        history.push('/admin/partner/'+id)
    }

    const updatePartner = () => {
        history.push('/admin/partner/edit/'+id)
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div>
                <div className="rqs-dta-btn-option" style={{ backgroundColor:'#FFFFFF', marginTop: 0 }} onClick={handleClick} >
                    <MoreHorizIcon/>
                </div>
                <div className={classes.root}>
                    { open ? (
                    <div className={classes.dropdown} >
                        {/* <div className={classes.box} onClick={()=>viewPartner()}>
                            <i className="fas fa-user"/>
                            <span style={{ marginLeft: 10 }} >
                                View Partner
                            </span>
                        </div> */}
                        <div className={classes.box} onClick={(e)=>updatePartner(e)}>
                            <i className="fas fa-pencil-alt"/>
                            <span style={{ marginLeft: 10 }} >
                                Edit
                            </span>
                        </div>
                        {/* <div className={classes.boxLast} onClick={()=>deleteUserAction()}>
                            <i className="fas fa-trash"/>
                            <span style={{ marginLeft: 10 }}>
                                Delete
                            </span>
                        </div> */}
                        <div className={classes.boxLast}>
                        {
                            <DeleteAlert data={data} />
                        }
                        </div>
                    </div>
                    ) : null}
                </div>
            </div>
        </ClickAwayListener>
    );
}