import React from 'react'
import { Box, makeStyles, Typography, Button } from '@material-ui/core'
import DetailWorkplan from '../../Modal/DetailReportWorkplan';

const useStyle = makeStyles({
    root: {
        padding: "1em",
        width: "95%",
        height: "264",
        backgroundColor: "white",
        marginTop: "12px",
        borderRadius: "3px"
    },
    caption: {
        color: "#999999",
    },
    workplanBox: {
        marginBottom: "0.5em",
        padding: '0.2em 0.5em',
        width: "fit-content",
        background: "rgba(226, 63, 129, 0.1)",
        borderRadius: "2px",
    },
    workplanText: {
        fontWeight: "normal",
        fontSize: "10px",
        lineHeight: "9px",
        color: "#E23F81",
    },
    partnership: {
        color: "#DA9D02",
        lineHeight: "19px",
        marginTop: "0.5em",
    }
})

export default function PIN(props) {
    const classes = useStyle()

    const {
        weeklyList,
        data,
        key
    } = props

    const showDate = (dateParams) => {
        if (dateParams) {
            let start = new Date(dateParams).getDate()
            let startMonth = new Date(dateParams).getMonth()
            let monthStart = ''
            let yearResult = new Date(dateParams).getFullYear()
            switch (startMonth) {
                case 0 :
                    monthStart = 'Januari'
                    break;
                case 1 :
                    monthStart = 'Februari'
                    break;
                case 2 :
                    monthStart = 'Maret'
                    break;
                case 3 :
                    monthStart = 'April'
                    break;
                case 4 :
                    monthStart = 'Mei'
                    break;
                case 5 :
                    monthStart = 'Juni'
                    break;
                case 6 :
                    monthStart = 'Juli'
                    break;
                case 7 :
                    monthStart = 'Agustus'
                    break;
                case 8 :
                    monthStart = 'September'
                    break;
                case 9 :
                    monthStart = 'Oktober'
                    break;
                case 10 :
                    monthStart = 'November'
                    break;
                case 11 :
                    monthStart = 'Desember'
                    break;
                default:
                    monthStart = ''
                    break;
            }
            let hour = new Date(dateParams).getHours()
            let minute = new Date(dateParams).getMinutes()
            let second = new Date(dateParams).getSeconds()
            
            return start + ' '+ monthStart +' '+ yearResult +' '+ (hour < 10 ? '0'+hour : hour) +':'+(minute < 10 ? '0'+minute : minute)+':'+(second < 10 ? '0'+second : second)+' WIB'
        }
    }

    return (
        <Box key={key} className={classes.root} boxShadow={1}>
            
            <Box className={classes.textBox}>
                <Box className={classes.workplanBox}>
                    <Typography className={classes.workplanText}>Work Plan</Typography>
                </Box>
                    <Typography className={classes.partnership} variant="subtitle2">{data ? data.category ? data.category.includes('Partnership with Airline') ? 'Partnership with Airlines' : data.category : data.category : '-'}</Typography>
                    <Typography variant="caption" className={classes.caption}>Dibuat : { showDate(data && data.createdAt) }</Typography>
                
                    <Typography marginTop="0.5em">{data ? data.description : '-'}</Typography>
                
                <Box width={1 / 2} marginTop="1em">
                    <DetailWorkplan
                        data={data}
                        weeklyList={weeklyList}
                    />
                </Box>
            </Box>
        </Box>
    )
}
