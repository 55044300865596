import axios from 'axios'
import Swal from 'sweetalert2'
import { SERVER } from '../../server'
import {
    GET_COORDINATORDATAOUTLOOK,
    GET_COORDINATORDATAQUARTERLY,
    GET_COORDINATORDATAWEEKLY,
    GET_COORDINATORDATA,
    IS_LOGOUT
} from "../constanta";

export const getCoordinatorData = (year) => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            Swal.showLoading()
            const { data } = await axios({
                method: 'GET',
                url: `${SERVER}/coordinator-data/${year}`,
                headers: { token }
            })
            Swal.close()
            dispatch({
                type: GET_COORDINATORDATA,
                data : data
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const getCoordinatorDataWeekly = (year, month) => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            Swal.showLoading()
            const { data } = await axios({
                method: 'GET',
                url: `${SERVER}/coordinator-data/weekly/${year}/${month}`,
                headers: { token }
            })
            Swal.close()
            dispatch({
                type: GET_COORDINATORDATAWEEKLY,
                data : data
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const getCoordinatorDataQuarterly = (year, month) => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            Swal.showLoading()
            const { data } = await axios({
                method: 'GET',
                url: `${SERVER}/coordinator-data/quarterly/${year}`,
                headers: { token }
            })
            Swal.close()
            dispatch({
                type: GET_COORDINATORDATAQUARTERLY,
                data : data
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const getCoordinatorDataOutlook = (year) => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            Swal.showLoading()
            const { data } = await axios({
                method: 'GET',
                url: `${SERVER}/coordinator-data/outlook/${year}`,
                headers: { token }
            })
            Swal.close()
            dispatch({
                type: GET_COORDINATORDATAOUTLOOK,
                data : data
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}