import React, { useState, useRef, useEffect } from 'react'
import './styles.scss'
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import HomeIcon from '@material-ui/icons/Home';
import MenuItem from '@material-ui/core/MenuItem';
import Logo from '../../images/logo.png'
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Evaluation from './Evaluation/Vito/DetailEvaluation';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import Notification from './Notification';
import { Switch, Route, useHistory } from 'react-router-dom'
import ProfileUnknown from '../../../../images/unknown.png'
import DescriptionIcon from '@material-ui/icons/Description';
import ReorderIcon from '@material-ui/icons/Reorder';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { useSelector, useDispatch } from 'react-redux'

import Home from './Report'
import AddWeeklyReport from './Report/Weekly/AddReportWeekly'
import CreateReportOnCard from './Report/Weekly/CreateReportOnCard'
import EditReportOnCard from './Report/Weekly/EditReportOnCard'
import EditReportOnCardNew from './Report/Weekly/EditReportOnCardNew'

import ReportWeekly from './Indicator/Weekly'
import ReportMonthly from './Indicator/Monthly'
import ReportGallery from './Indicator/Gallery'
import AddReportMonthly from './Indicator/Monthly/Add'
import AddGalleryReport from './Indicator/Gallery/Add'

import Workplan from './Workplan'
import Dashboard from './Dashboard'

import Partner from './Partner'
import AddPartner from './Partner/AddPartner'
import EditPartner from './Partner/EditPartner'

import { logout } from '../../../../store/actions/profileAction'
import { getNotificationVitoCount } from '../../../../store/actions/notificationAction'
import { getWorkplan, getReport, getMonthly, getMonthlyDash, getWeeklyDash } from '../../../../store/actions/dashboardAction'
import { getOneVito } from '../../../../store/actions/evaluationActions'
import { getPartner } from '../../../../store/actions/partnerAction'

import ModalChangePassword from '../../component/Modal/changePassword'
import Axios from 'axios'
import { SERVER } from '../../../../server'
import Swal from 'sweetalert2'
import socketClient from "socket.io-client";

const drawerWidth = 253;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    height: 62,
    backgroundColor: '#F2F3F9',
    borderBottom: '1px solid #E1E1E1',
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    borderRight: 'none',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    ...theme.mixins.toolbar,
  },
  content: {
    padding: theme.spacing(3),
    paddingTop: 15,
    flexGrow: 1,
    boxSizing: 'content-box',
    height: '94.5vh',
    backgroundColor: '#F2F3F9'
  },
  popupTop: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '10px',
    lineHeight: '12px',
    color: '#666666',
    height: 34
  },
  paperCustom: {
    padding: 0,
    marginTop: 5,
    boxShadow: "0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
  },
  notifications: {
    color: '#999999',
    fontSize: 25
  },
  arrowProfile: {
    color: '#CCCCCC',
    fontSize: 25
  },
  popperProfile: {
    padding: 0
  },
  divider: {
    height: 0.5,
    backgroundColor: '#DDDDDD'
  },
  iconBtn: {
    marginRight: 10,
    fontSize: 25
  },
  btnLessIcon: {
    color: '#E23F81'
  }
}));

const MenuButton = withStyles((theme) => ({
  root: {
    width: '90%',
    height: '50px',
    backgroundColor: 'none',
    borderRadius: '4px',
    boxShadow: 'none',
    textTransform: 'none',
    transition: '0.5s',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '19px',
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "18px",
    color: "#999999",
    '&:hover': {
      backgroundColor: '#d8e0e5',
      color: '#E23F81',
      boxShadow: 'none',
    },
  },
}))(Button);

const MenuButtonSmall = withStyles((theme) => ({
  root: {
    marginTop: 1,
    width: '75%',
    height: '40px',
    backgroundColor: 'none',
    borderRadius: '4px',
    boxShadow: 'none',
    textTransform: 'none',
    transition: '0.5s',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '19px',
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "18px",
    color: "#999999",
    '&:hover': {
      backgroundColor: '#d8e0e5',
      color: '#E23F81',
      boxShadow: 'none',
    },
  },
}))(Button);

const MenuButtonActive = withStyles((theme) => ({
  root: {
    width: '90%',
    height: '50px',
    backgroundColor: '#E23F81',
    borderRadius: '4px',
    boxShadow: 'none',
    textTransform: 'none',
    transition: '0.5s',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '19px',
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "18px",
    color: "#FFFFFF",
    '&:hover': {
      backgroundColor: '#E23F81',
      color: '#FFFFFF',
      boxShadow: 'none',
    },
  },
}))(Button);

const MenuButtonSmallActive = withStyles((theme) => ({
  root: {
    marginTop: 1,
    width: '75%',
    height: '40px',
    backgroundColor: '#E23F81',
    borderRadius: '4px',
    boxShadow: 'none',
    textTransform: 'none',
    transition: '0.5s',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '19px',
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "18px",
    color: "#FFFFFF",
    '&:hover': {
      backgroundColor: '#E23F81',
      color: '#FFFFFF',
      boxShadow: 'none',
    },
  },
}))(Button);

export default function Menu() {
  const classes = useStyles();
  const History = useHistory();
  const anchorRef = useRef(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [open, setOpen] = useState(true);
  const [openReport, setOpenReport] = useState(false);
  const [title, setTitle] = useState('')
  const nameProfile = useSelector(state=> state.profileState.name)
  const photoProfile = useSelector(state=> state.profileState.photo)
  const filterYears = useSelector(state=> state.filterState.years)
  const Dispatch = useDispatch();
  const [modalToggle , setModalToggle] = useState(false);
  const [showPassword, setShowPassword] = useState(false)
  const [password, setPassword] = useState('')
  const [Null, setNull] = useState(false)
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false)
  const [passwordRepeat, setPasswordRepeat] = useState('')
  const [NullRepeat, setNullRepeat] = useState(false)
  const [error, setError] = useState(false)
  const notification = useSelector(state=> state.notificationState.countVito)
  const socket = socketClient(SERVER, {transports: ['websocket'], secure: true});
  const user = useSelector(state=> state.evaluationState.vito)

  useEffect(()=>{
    Dispatch(getNotificationVitoCount())
    Dispatch(getOneVito(null,4))
  },[])

  useEffect(()=>{
    if (user) {
      socket.on('notification-vito', (data) => {
        if (user.UserId == data.id) {
          Dispatch(getNotificationVitoCount())
        }
      });
      socket.on('evaluation', (data) => {
        if (user.UserId == data.id) {
          Dispatch(getOneVito(null,4))
        }
      });
    }
  },[user])

  useEffect(()=>{
    if (filterYears) {
      let year
      let month
      if (new Date().getFullYear() > 2025) {
          year = filterYears.filter(e=> e.year === 2025)[0].id
      }
      if (new Date().getFullYear() < 2019) {
          year = filterYears.filter(e=> e.year === 2019)[0].id
      }
      if (new Date().getFullYear() <= 2025 && new Date().getFullYear() >= 2019) {
          year = filterYears.filter(e=> e.year === new Date().getFullYear())[0].id
      }
      Dispatch(getWorkplan())
      Dispatch(getMonthly(year))
      Dispatch(getMonthlyDash(year))
      Dispatch(getWeeklyDash(year))
      switch (new Date().getMonth()) {
        case 0 :
          month = `${year}${1}`
          break;
        case 1 :
          month = `${year}${2}`
          break;
        case 2 :
          month = `${year}${3}`
          break;
        case 3 :
          month = `${year}${4}`
          break;
        case 4 :
          month = `${year}${5}`
          break;
        case 5 :
          month = `${year}${6}`
          break;
        case 6 :
          month = `${year}${7}`
          break;
        case 7 :
          month = `${year}${8}`
          break;
        case 8 :
          month = `${year}${9}`
          break;
        case 9 :
          month = `${year}${10}`
          break;
        case 10 :
          month = `${year}${11}`
          break;
        case 11 :
          month = `${year}${12}`
          break;
        default:
          month = '-'
          break;
      }
      Dispatch(getReport(month))
    }
  },[filterYears])

  useEffect(()=>{
    Dispatch(getPartner())
    socket.on('partner', (data) => {
      Dispatch(getPartner())
    })
  },[])

  useEffect(() => {
    let temp = ''
    switch (History.location.pathname) {
      case '/vito/report/monthly':
      case '/vito/report/galleries':
      case '/vito/report/weekly':
        temp = "Report"
        break;
      case '/vito/workplan':
        temp = "Workplan"
        break;
      case '/vito/report/weekly/add-report':
      case '/vito/report/weekly/create-report-card':
        temp = "Add New Weekly Report"
        break;
      case '/vito/report/galleries/add':
        temp = "Add New Gallery"
        break
      case '/vito/indicator/weekly':
        temp = "Weekly Report"
        break
      case '/vito/indicator/monthly':
        temp = "Monthly Report"
        break
      case '/vito/indicator/gallery':
        temp = "Gallery"
        break
      case '/vito/partner':
        temp = "Partner"
        break;
      case '/vito':
        temp = "Dashboard"
        break
    }
    setTitle(temp)
  }, [History.location.pathname])

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenPopup(false);
  };

  const handleToggle = () => {
    setOpenPopup((prevOpen) => !prevOpen);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleLogout = () => {
    Dispatch(logout(History))
  }

  const modalHandler = (e, data) => {
    if (e) {
        e.preventDefault();
    }
    setOpenPopup(false);
    setModalToggle(!modalToggle)
  }

  const passwordHandler = () => {
    setShowPassword(!showPassword)
  }

  const passwordHandlerRepeat = () => {
    setShowPasswordRepeat(!showPasswordRepeat)
  }

  const handleChangePass = () => {
    setNull(true)
    if(password && passwordRepeat) {
      Axios({
        method: 'PATCH',
        url: `${SERVER}/user/change-password`,
        data: { password, passwordRepeat },
        headers: { token: localStorage.getItem('token') }
      })
      .then(({data})=>{
        setNull(null)
        setNullRepeat(null)
        setError(null)
        modalHandler()
        setPasswordRepeat('')
        setPassword('')
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        Toast.fire({
          icon: 'success',
          title: 'Ganti Password Berhasil'
        })
        handleLogout()
      })
      .catch(error=>{
        if (error.response) {
          if (error.response.data) {
            if (error.response.data.message) {
              if (error.response.data.message === 'password tidak sama') {
                setNullRepeat(true)
              }
              setError(error.response.data.message)
            }
            if (error.response.data.errors) {
              setError(error.response.data.errors[0])
            }
          }
        }
      })
    }
  }

  const handleModalIN = (e) => {
    if (e) { e.preventDefault() }
    setModalToggle(!modalToggle)
    setShowPassword(null)
    setPassword('')
    setNull(null)
    setShowPasswordRepeat(null)
    setPasswordRepeat('')
    setNullRepeat(null)
    setError(null)
  }

  const seeProfile = () => {
    History.push('/vito/profil')
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar,
          [classes.appBarShift],
        )}
      >
        <Toolbar>
          <ReorderIcon style={{ fontSize: "2.5em", color: "black", marginRight: "8px" }} />
          <span className="dashboard-head-txt" >
            {title}
          </span>
          <div className="container-appbar-right-side" >
            <div className="notif-container" style={{ cursor: 'pointer' }} onClick={e=> History.push('/vito/notification')} >
              <NotificationsIcon className={classes.notifications} />
              <div className="notification-icons" >
                {notification}
              </div>
            </div>
            <div className="profile-navbar-btn"
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            >
              <img src={photoProfile ? photoProfile : ProfileUnknown} alt="photo profile" className="photo-profile-navbar" />
              <span className="profile-txt-name" > {nameProfile ? nameProfile : '-'} </span>
              {
                !openPopup ?
                  <ExpandMoreIcon className={classes.arrowProfile} />
                  :
                  <ExpandLessIcon className={classes.arrowProfile} />
              }
              <Popper open={openPopup} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center top' }}
                  >
                    <Paper className={classes.paperCustom} >
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList autoFocusItem={openPopup} id="menu-list-grow" className={classes.popperProfile} onKeyDown={handleListKeyDown}>
                          <MenuItem onClick={seeProfile} className="popup-list" >
                            <i className="fas fa-user-circle unlock-custom" />
                            <span className="text-menu-item-popup" > Profile </span>
                          </MenuItem>
                          <Divider className={classes.divider} />
                          <MenuItem onClick={handleModalIN} className="popup-list" >
                            <i className="fas fa-unlock unlock-custom" />
                            <span className="text-menu-item-popup" > Change Password </span>
                          </MenuItem>
                          <Divider className={classes.divider} />
                          <MenuItem onClick={handleLogout} className="popup-list">
                            <i className="fas fa-sign-out-alt unlock-custom" />
                            <span className="text-menu-item-popup"> Log Out </span>
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        style={{ display: ((History.location.pathname === '/vito/notification')) && 'none' }}
        variant="permanent"
        className={clsx(classes.drawer,
          [classes.drawerOpen]
        )}
        classes={{
          paper: clsx(
            [classes.drawerOpen]
          ),
        }}
      >
        <div className="container-img-sidebar" >
          <img src={Logo} className="logo-sidebar" alt="logo" />
        </div>
        <div className="button-sidebar-wrapper">
          {
            History.location.pathname === '/vito' ?
              <MenuButtonActive>
                <DashboardIcon className={classes.iconBtn} />
              Dashboard
            </MenuButtonActive>
              :
              <MenuButton onClick={e => [History.push('/vito'), setOpenReport(false)]} >
                <DashboardIcon className={classes.iconBtn} />
              Dashboard
            </MenuButton>
          }
          {
            History.location.pathname === '/vito/workplan' ?
              <MenuButtonActive>
                <AssignmentTurnedInIcon className={classes.iconBtn} />
              Work Plan
            </MenuButtonActive>
              :
              <MenuButton onClick={e => [History.push('/vito/workplan'), setOpenReport(false)]} >
                <AssignmentTurnedInIcon className={classes.iconBtn} />
              Work Plan
            </MenuButton>
          }
          {
            (History.location.pathname).includes('/vito/report') ?
              <MenuButtonActive>
                <DescriptionIcon className={classes.iconBtn} />
              Report
            </MenuButtonActive>
              :
              <MenuButton onClick={e => [History.push(`/vito/report/weekly/${new Date().getFullYear()}`), setOpenReport(false)]} >
                <DescriptionIcon className={classes.iconBtn} />
              Report
            </MenuButton>
          }
          {
            History.location.pathname === '/vito/evaluation' ?
              <MenuButtonActive>
                <i className="fas fa-vote-yea" style={{ marginRight: 10, fontSize: 20 }} />
                Evaluation
            </MenuButtonActive>
              :
              <MenuButton onClick={e => [History.push('/vito/evaluation'), setOpenReport(false)]} >
                <i className="fas fa-vote-yea" style={{ marginRight: 10, fontSize: 20 }} />
                Evaluation
            </MenuButton>
          }
          {
            History.location.pathname === '/vito/partner' || History.location.pathname === '/vito/partner/add' ?
              <MenuButtonActive>
                <i className="fas fa-handshake" style={{ marginRight: 10, fontSize: 20 }} />
                Partner
            </MenuButtonActive>
              :
              <MenuButton onClick={e => [History.push('/vito/partner'), setOpenReport(false)]} >
                <i className="fas fa-handshake" style={{ marginRight: 10, fontSize: 20 }} />
                Partner
            </MenuButton>
          }
          {/* {
            (openReport) ?
              <>
                <MenuButtonActive onClick={() => setOpenReport(!openReport)} >
                  <AssessmentIcon className={classes.iconBtn} />
                    History
                    <i className="fas fa-chevron-circle-up" style={{ fontSize: '15px', marginLeft: 15 }} />
                </MenuButtonActive>
                {
                  History.location.pathname === '/vito/indicator/weekly' ?
                    <MenuButtonSmallActive>
                      <i className="fas fa-wave-square" style={{ fontSize: '15px', marginRight: 5 }}/>
                      Weekly
                    </MenuButtonSmallActive>
                    :
                    <MenuButtonSmall onClick={() => History.push('/vito/indicator/weekly')} >
                      Weekly
                    </MenuButtonSmall>
                }
                {
                  History.location.pathname === '/vito/indicator/monthly' ?
                    <MenuButtonSmallActive>
                      <i className="fas fa-wave-square" style={{ fontSize: '15px', marginRight: 5 }}/>
                      Monthly
                    </MenuButtonSmallActive>
                    :
                    <MenuButtonSmall onClick={() => History.push('/vito/indicator/monthly')} >
                      Monthly
                    </MenuButtonSmall>
                }
                {
                  History.location.pathname === '/vito/indicator/gallery' ?
                    <MenuButtonSmallActive>
                      <i className="fas fa-wave-square" style={{ fontSize: '15px', marginRight: 5 }}/>
                      Gallery
                    </MenuButtonSmallActive>
                    :
                    <MenuButtonSmall onClick={() => History.push('/vito/indicator/gallery')} >
                      Gallery
                    </MenuButtonSmall>
                }
              </>
              :
              (!openReport && !(History.location.pathname).includes('/vito/indicator')) ?
                <MenuButton onClick={() => [History.push('/vito/indicator/weekly'), setOpenReport(!openReport)]} >
                  <AssessmentIcon className={classes.iconBtn} />
                    History
                    <i className="fas fa-chevron-circle-down" style={{ fontSize: '15px', marginLeft: 15 }} />
                </MenuButton>
                :
                <MenuButtonActive onClick={() => setOpenReport(!openReport)} >
                  <AssessmentIcon className={classes.iconBtn} />
                  History
                  <i className="fas fa-chevron-circle-down" style={{ fontSize: '15px', marginLeft: 15 }} />
                </MenuButtonActive>
          } */}
        </div>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
          <Route path="/vito/report/weekly/edit-report-card/:id" component={EditReportOnCard} />
          <Route path="/vito/report/weekly/:year/create/:id" component={CreateReportOnCard} />
          <Route path="/vito/report/weekly/:year/edit-card/:id" component={EditReportOnCardNew} />
          <Route path="/vito/report/weekly/:year/edit/:id" component={EditReportOnCard} />
          <Route path="/vito/report/weekly/:year/add-report" component={AddWeeklyReport} />
          <Route path="/vito/report" component={Home} />
          <Route path="/vito/indicator/weekly" component={ReportWeekly} />
          <Route path="/vito/indicator/monthly/add" component={AddReportMonthly} />
          <Route path="/vito/indicator/gallery/add" component={AddGalleryReport} />
          <Route path="/vito/indicator/monthly"monthly component={ReportMonthly} />
          <Route path="/vito/indicator/gallery" component={ReportGallery} />
          <Route path="/vito/workplan" component={Workplan} />
          <Route path="/vito/notification" component={Notification} />
          <Route path="/vito/evaluation" component={Evaluation} />
          <Route path="/vito/partner/add" component={AddPartner} />
          <Route path="/vito/partner/edit/:id" component={EditPartner} />
          <Route path="/vito/partner" component={Partner} />
          <Route path="/vito" component={Dashboard} />
        </Switch>
      </main>
      <ModalChangePassword 
          show={modalToggle} modalClosed={modalHandler} children={"modal"}
          passwordHandler={passwordHandler}
          passwordHandlerRepeat={passwordHandlerRepeat}
          password={password}
          setPassword={setPassword}
          Null={Null}
          showPassword={showPassword}
          passwordRepeat={passwordRepeat}
          NullRepeat={NullRepeat}
          showPasswordRepeat={showPasswordRepeat}
          setPasswordRepeat={setPasswordRepeat}
          error={error}
          handleChangePass={handleChangePass}
          setNull={setNull}
          setNullRepeat={setNullRepeat}
      />
    </div>
  );
}