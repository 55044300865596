import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Box, Typography, withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Axios from 'axios'
import { SERVER } from '../../../../../../server'
import Swal from 'sweetalert2'

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: "1em 2em",
        borderBottom: "1px solid #E1E1E1"
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export default function FormDialog(props) {
    const [open, setOpen] = useState(false);
    const [albumText, setAlbumText] = useState('')
    const { data, readAlbum } = props

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSaving = (data) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        if (data) {
            Axios({
                method: 'POST',
                url: `${SERVER}/gallery/album`,
                data: {
                    name : albumText
                },
                headers: {
                    token : localStorage.getItem('token'),
                }
            })
            .then((newData)=>{
                handleClose()
                readAlbum(data)
            })
            .catch(error=>{
                if (error.response) {
                    if (error.response.data) {
                      if (error.response.data.message) {
                          Toast.fire({
                              icon: 'error',
                              title: error.response.data.message
                          })
                      }
                    }
                }
            })
        }
    }

    return (
        <div>
            <Button variant="outlined" fullWidth color="secondary" onClick={handleClickOpen} style={{
                textTransform: 'none',
                padding: "0.5em",
                border: "1px solid #E23F81",
                color: "#E23F81",
            }}>Add New Album</Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="lg">
                <DialogTitle id="form-dialog-title" onClose={handleClose}>Create New Album</DialogTitle>
                <DialogContent>
                    <Box width="40vw">
                        <TextField
                            size="large"
                            variant="outlined"
                            color="secondary"
                            margin="dense"
                            label="Album Name"
                            fullWidth
                            onChange={e=>setAlbumText(e.target.value)}
                        />
                    </Box>
                </DialogContent>
                <DialogActions style={{ justifyContent: "flex-start", paddingLeft: "2em" }}>
                    <Button onClick={e=>handleSaving(data)} type="submit" variant="contained" color="secondary" style={{ backgroundColor: "#E23F81", textTransform: "none", boxShadow: "none", padding: "1em 2em" }}>Create Album</Button>
                    <Button onClick={handleClose} variant="outlined" color="secondary" style={{ textTransform: "none", padding: "1em 2em", marginLeft: "1em" }} >Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}