import { GET_DASHBOARDWORKPLAN, GET_DASHBOARDREPORT, GET_DASHBOARDMONTHLY, GET_MONTHLYDASH, GET_WEEKLYDASH } from "../constanta";

const initialState = {
    workplan: [],
    report: [],
    monthly : [],
    monthlyDate: [],
    weeklyDate: []
}

export default (state = initialState, action)=> {
    switch(action.type){
        case GET_DASHBOARDWORKPLAN:
            return {
                ...state,
                workplan: action.data
            }
        case GET_DASHBOARDREPORT:
            return {
                ...state,
                report: action.data
            }
        case GET_DASHBOARDMONTHLY:
            return {
                ...state,
                monthly: action.data
            }
        case GET_MONTHLYDASH:
            return {
                ...state,
                monthlyDate: action.data
            }
        case GET_WEEKLYDASH:
            return {
                ...state,
                weeklyDate: action.data
            }
        default: {
            return state
        }
    }
}