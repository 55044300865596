import React,{ useState, useEffect } from 'react'
import { Box, Typography, TextField, withStyles, makeStyles, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Paper, requirePropFactory, Button } from '@material-ui/core'
import './styles.scss'
import logoWI from '../../../../images/bird.png'
import {useHistory} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getEvaluationId, createdEvaluation } from '../../../../../../store/actions/evaluationActions'
import CircularProgress from '@material-ui/core/CircularProgress';

// COMPONENT

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#F3F3F3",
        color: "#999999",
        fontWeight: 500,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "#FAFAFA",
        },

    },
}))(TableRow);

function createData(no, name, country, email, phone) {
    return { no, name, country, email, phone };
}

// const rows = [
//     createData(1, "Ms. Nattamon Limthanachai", "Thailand", ["oh@asialife.co.th"], "+66 843 4374 437"),
//     createData(2, "Ms. Anna Kiseleva", "Rusia", ["kiseleva@effixcom.ru"], "+7 329 3827 7832"),
//     createData(3, "Mr. Mostafa El Enany", "Saudi Arabia", ["vitosaudiarabia@hotmail.com"], "+966 9845 8594 84"),
//     createData(4, "Mr. Kelvin Ong", "Malaysia", ["vito.my@aviareps.com"], "+60 8945 5948 5489"),
//     createData(5, "Ms. Susan van Egmond", "Netherland", ["susan@tourismmarketingconcepts.com"], "+31 4983 4893 43"),
//     createData(6, "Mr. Sulaiman Shedek", "Singapora", ["info@tourism-indonesia.sg", "vito-singapore@hotmail.com"], "+65 9458 5485 45"),
//     createData(7, "Ms. Janet Juang", "China - Guangzhou", ["vito-guangzhou@tlmchina.com"], "+86 598 548 548"),
//     createData(8, "Mr. Emil Hardy Ridwan", "Australia - Melbourne", ["info@vitomelbourne.com.au"], "+61 74 584 548"),
//     createData(9, "Ms. Susan van Egmond", "Netherland", ["susan@tourismmarketingconcepts.com"], "+31 4983 4893 43"),
//     createData(10, "Mr. Sulaiman Shedek", "Singapora", ["info@tourism-indonesia.sg", "vito-singapore@hotmail.com"], "+65 9458 5485 45"),
//     createData(11, "Ms. Janet Juang", "China - Guangzhou", ["vito-guangzhou@tlmchina.com"], "+86 598 548 548"),
//     createData(12, "Mr. Emil Hardy Ridwan", "Australia - Melbourne", ["info@vitomelbourne.com.au"], "+61 74 584 548"),
//     createData(13, "Ms. Susan van Egmond", "Netherland", ["susan@tourismmarketingconcepts.com"], "+31 4983 4893 43"),
//     createData(14, "Mr. Sulaiman Shedek", "Singapora", ["info@tourism-indonesia.sg", "vito-singapore@hotmail.com"], "+65 9458 5485 45"),
//     createData(15, "Ms. Janet Juang", "China - Guangzhou", ["vito-guangzhou@tlmchina.com"], "+86 598 548 548"),
//     createData(16, "Mr. Emil Hardy Ridwan", "Australia - Melbourne", ["info@vitomelbourne.com.au"], "+61 74 584 548"),
// ];

const useStyles = makeStyles({
    table: {
        minWidth: 700,
        boxShadow: "none"
    },
    buttonViewProfile: {
        background: "#FCECF2",
        color: "#E23F81",
        boxShadow: "none"
    },
    buttonViewProfile1:{
        background: "#b9e1fe",
        color: "#169cfc",
        boxShadow: "none"
    },
    buttonViewProfile2:{
        background: "#b9e1fe",
        color: "#169cfc",
        boxShadow: "none",
        fontSize: 12
    },
    titleTable: {
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor: "#DDDDDD",
        paddingTop:"1em",
        paddingBottom:"1em",
        paddingLeft:"1.5em",
        backgroundColor: 'white'
    },
    tableContainer: {
        boxShadow: "none",
        border: "1px solid #CCCCCC",
        borderRadius: "4px"
    }
});

export default function PIN(props){
    const classes = useStyles();
    const history = useHistory()
    const Dispatch = useDispatch()
    const vitos = useSelector(state=> state.vitoState.vitos)
    const evaluations = useSelector(state=> state.evaluationState.userId)
    const roleProfile = useSelector(state=> state.profileState.role)
    const [search, setSearch] = useState('')

    function viewEvaluation (id) {
        history.push(`/admin/evaluation/vito/detail/${id}`)
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    const addEvaluation = userId => {
        Dispatch(createdEvaluation(null,userId))
    }

    useEffect(()=>{
        Dispatch(getEvaluationId(null,4))
    },[])

    return(
        <>
        <div className="dashboard" >
            <span className="breadcrumb-txt-active" >
                Evaluasi
            </span>
            <span className="breadcrumb-slash-active" >
                /
            </span>
            <span className="breadcrumb-txt-black" >
                VITO
            </span>
            <span className="breadcrumb-slash-black" >
                /
            </span>
        </div>
        <div className="vito-table">
                <Box paddingRight="1em" >
                    <Box className={classes.titleTable}>
                        <Typography variant="h5"><b>Evaluasi VITO</b></Typography>
                    </Box>
                    <Box paddingLeft="1.5em" paddingRight="1em" paddingTop="2em" style={{ backgroundColor: 'white', paddingBottom: '20px' }} >
                        <Box display="flex" justifyContent="space-between">
                        <form autoComplete="off">
                            <TextField onChange={e=>setSearch(e.target.value)} label="Cari VITO" size="small" variant="outlined" borderColor="#C4C4C4" />
                        </form>
                        </Box>
                        <Box paddingTop="1em">
                            <TableContainer component={Paper} className={classes.tableContainer}>
                                <Table className={classes.table} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>NO</StyledTableCell>
                                            <StyledTableCell align="left">NAMA</StyledTableCell>
                                            <StyledTableCell align="left">NEGARA</StyledTableCell>
                                            <StyledTableCell align="left">AREA</StyledTableCell>
                                            <StyledTableCell align="left">EMAIL</StyledTableCell>
                                            {/* <StyledTableCell align="left">HASIL PENILAIAN</StyledTableCell> */}
                                            <StyledTableCell align="left">AKSI</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                        vitos &&
                                        vitos
                                        .filter(a=> a && a.id !== 111 )
                                        .filter(a=>search ? ((a.name).toLowerCase()).includes((search).toLowerCase()) : a)
                                        .map((row, i) => (
                                            <StyledTableRow key={row.name}>
                                                <StyledTableCell align="left">{i+=1}</StyledTableCell>
                                                <StyledTableCell align="left">
                                                    <Box display="flex" alignItems="center">
                                                        <Box height="2em" width="2em" border={1} borderRadius="50%" borderColor="grey.500" display="flex" justifyContent="cebter" alignItems="center" marginRight="1em"><img width="100%" height="100%" src={logoWI}/>
                                                        </Box>
                                                    {row && row.name ? row.name : '-'}
                                                    </Box>
                                                </StyledTableCell>
                                                <StyledTableCell align="left">{row && row.country ? row.country : '-'}</StyledTableCell>
                                                <StyledTableCell align="left">{row && row.area ? row.area !== 'tidak ada area' ? row.area : '-' : '-'}</StyledTableCell>
                                                <StyledTableCell align="left">{row && row.email ? row.email : '-'}</StyledTableCell>
                                                {/* <StyledTableCell align="left">{row && row.phone ? row.phone : '-'}</StyledTableCell> */}
                                                <StyledTableCell align="left">
                                                    {
                                                        (roleProfile !== 'markom' && roleProfile !== 'deputi' && roleProfile !== 'direktorat' && roleProfile !== 'koordinatorvito' && roleProfile !== 'guest') ?
                                                        evaluations &&
                                                            evaluations.length !== 0 ?
                                                                (evaluations.filter(a=> a.UserId == row.id ).join('') == false) ?
                                                                <Button onClick={()=>addEvaluation(row.id)} variant="contained" className={classes.buttonViewProfile1}>Buat Evaluasi</Button>
                                                                :
                                                                <Button onClick={()=>viewEvaluation(row.id)} variant="contained" className={classes.buttonViewProfile}>Lihat Evaluasi</Button>
                                                            :
                                                            <CircularProgress color="secondary" style={{ height: 20, width: 20 }}/>
                                                        :
                                                        evaluations &&
                                                        evaluations.length !== 0 ?
                                                            (evaluations.filter(a=> a.UserId == row.id ).join('')) ?
                                                            <Button onClick={()=>viewEvaluation(row.id)} variant="contained" className={classes.buttonViewProfile}>Lihat Evaluasi</Button>
                                                            : 
                                                            <Button disabled variant="contained" className={classes.buttonViewProfile2}>Evaluasi Belum Dibuat</Button>
                                                        :
                                                        <CircularProgress color="secondary" style={{ height: 20, width: 20 }}/>
                                                    }
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>

                    </Box>
                </Box>
            </div>
        </>
    )
}