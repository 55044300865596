import React, { useState, Fragment, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  withStyles,
  makeStyles,
  requirePropFactory,
  Button,
  Divider,
  Grid,
  TableHead,
} from "@material-ui/core";
import "./styles.scss";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { SERVER } from "../../../../../server";
import Axios from "axios";
import { getVito } from "../../../../../store/actions/vitoAction";

// COMPONENT

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F3F3F3",
    color: "#999999",
    fontWeight: 500,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#FAFAFA",
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    boxShadow: "none",
  },
  buttonViewProfile: {
    background: "#FCECF2",
    color: "#E23F81",
    boxShadow: "none",
  },
  titleTable: {
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: "#DDDDDD",
    paddingTop: "1em",
    paddingBottom: "1em",
    paddingLeft: "1.5em",
    backgroundColor: "white",
  },
  tableContainer: {
    boxShadow: "none",
    border: "1px solid #CCCCCC",
    borderRadius: "4px",
  },
});

function TabulasiDetail() {
  const [data, setData] = useState(null);
  const vitos = useSelector((state) => state.vitoState.vitos);
  const [vito, setVito] = useState(null);
  const classes = useStyles();
  const { userId, monthId, yearId } = useParams();
  const Dispatch = useDispatch();
  const categori = [
    {
      categori: "NETWORKING & PARTNERSHIP",
      value: [
        {
          label: "Partnership with Academics",
          key: ["Partnership with Academics"],
        },
        {
          label:
            "Partnership with Business (TA/TO, Airlines, Accommodation, and others)",
          key: [
            "Partnership with Business (TA/TO, Airlines, Accommodation, and others)",
          ],
        },
        {
          label: "Partnership with Communities",
          key: ["Partnership with Communities"],
        },
        {
          label: "Partnership with Governments",
          key: ["Partnership with Governments"],
        },
        { label: "Partnership with Media", key: ["Partnership with Media"] },
        /*{ label: 'Partnership with Airlines', key: ['Partnership with Airline', 'Partnership with Airlines'] },
                { label: 'Partnership with Online Travel Agent (OTA) or Travel Agent/Tour Operator', key: ['Partnership with Online Travel Agent (OTA) or Travel Agent/Tour Operator'] },
                { label: 'Partnership with Association', key: ["Partnership with Association"] },
                { label: 'Partnership with Related Travel Industry', key: ["Partnership with Related Travel Industry"] },*/
      ],
    },
    {
      categori: "MARKET INTELLIGENCE",
      value: [
        { label: "Market Update", key: ["Market Update"] },
        //{ label: 'Outbound Update (Data on Outbound and Market Share)', key: ['Outbound Update (Jumlah outbound dan market share)', 'Outbound Update (Data on Outbound and Market Share)'] },
        { label: "Competitor Update", key: ["Competitor Update"] },
        { label: "Global Trend", key: ["Global Trend"] },
        // { label: '', key: '' },
      ],
    },
    /*{
            categori: 'PROMOTIONAL SUPPORT',
            value: [
                { label: 'Regional Promotion (SM, TM, FT, Festival)', key: ['Regional Promotion (SM, TM, FT, Festival)','Offline Promotion (SM, TM, FT, Festival)'] },
                { label: 'Online Promotion (Website & Social Media)', key: ['Online Promotion (Website & Social Media)'] },
                { label: 'PR-Ing and Customer Service', key: ['PR-Ing and Customer Service'] }
                // { label: '', key: '' },
            ]
        },*/
  ];

  useEffect(() => {
    if (vitos.length === 0) Dispatch(getVito());
  }, []);

  useEffect(() => {
    if (vitos) {
      let vit = vitos.filter((a) => a.id == userId);
      setVito(vit[0]);
      // console.log(
      //   "test ",
      //   `${SERVER}/report/report-groped/${userId}/${monthId}`
      // );
      Axios({
        method: "GET",
        url: `${SERVER}/report/report-groped/${userId}/${monthId}`,
        headers: { token: localStorage.getItem("token") },
      })
        .then(({ data }) => {
          setData(data);
          console.log(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [vitos]);

  function getCategory(dat, key) {
    let res = 0;
    if (dat.length > 0) {
      dat.map((a) => {
        key.map((b) => {
          if (a.category === b) {
            res = res + Number(a.count);
          }
        });
      });
    } else {
      res = 0;
    }
    return res;
  }

  function getSumCategory(dat, i) {
    let res = 0;
    if (dat.length > 0) {
      dat.map((a) => {
        if (Number(a.CategoryId) === i) {
          res = res + Number(a.count);
        }
      });
    } else {
      res = null;
    }
    return res;
  }

  function getPerWeek(dat) {
    let res = 0;
    if (dat) {
      dat.map((a) => {
        res = res + Number(a.count);
      });
    } else {
      res = 0;
    }
    return res;
  }
  function getWeekNumber(dat, key) {
    let res = 0;

    if (dat) {
      dat.map((a) => {
        if (a.id == key) res = a.week;
        //res = a.id;
      });
    } else {
      res = 0;
    }
    return res;
  }

  return (
    <Fragment>
      <div className="dashboard">
        <span className="breadcrumb-txt-black">Tabulasi</span>
        <span className="breadcrumb-slash-black">/</span>
        <span className="breadcrumb-txt-grey">Detail Jumlah Report</span>
      </div>
      <div className="vito-table">
        <Box width="100%">
          <Box className={classes.titleTable}>
            <Typography variant="h5">
              <b>Detail Jumlah Report VITO</b>
            </Typography>
          </Box>
          <Box
            style={{ backgroundColor: "white", padding: "25px 22px 30px 20px" }}
          >
            <div className="txt-name-det capitalize">
              {vito && vito.name} - {vito && vito.country}
            </div>
            <div className="mb-20">
              <div className="div-det-tab">
                <div className="txt-det-tab-left">
                  Total report tahun {new Date().getFullYear()}
                </div>
                :<span></span>
                <div className="fw-500 ml-40">
                  {data && Number(data.reportMonth) + Number(data.reportWeekly)}
                </div>
              </div>
              <div className="div-det-tab mt-14">
                <div className="txt-det-tab-left">Monthly report</div>:
                <span></span>
                <div className="fw-500 ml-40">
                  {data && Number(data.reportMonth)}
                </div>
              </div>
              <div className="div-det-tab mt-14">
                <div className="txt-det-tab-left">Weekly report</div>:
                <span></span>
                <div className="fw-500 ml-40">
                  {data && Number(data.reportWeekly)}
                </div>
              </div>
            </div>
            {data &&
              Object.keys(data.reportComp).map((key, ind) => (
                <div key={key}>
                  <div className="con-label-week-det mt-30">
                    <div>
                      Week {/*ind + 1*/}
                      {getWeekNumber(data.weeklynumber, key)}
                    </div>
                    <Divider
                      orientation="vertical"
                      style={{ margin: "0px 10px", backgroundColor: "#FFFFFF" }}
                    />
                    <div>{getPerWeek(data.reportComp[key])} Report</div>
                  </div>
                  <div style={{ width: "100%", marginTop: "15px" }}>
                    <Grid container spacing={2}>
                      {categori.map((cate, i) => (
                        <Grid item md={6} lg={6} key={i}>
                          <Table className="table-det-tab">
                            <TableHead>
                              <TableCell
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>{cate.categori}</div>
                                {getSumCategory(
                                  data.reportComp[key],
                                  i + 1
                                ) && (
                                  <div className="value-cat-det">
                                    {getSumCategory(
                                      data.reportComp[key],
                                      i + 1
                                    )}{" "}
                                    Report
                                  </div>
                                )}
                              </TableCell>
                            </TableHead>
                            {cate.value.map((comp, index) => (
                              <TableRow key={index}>
                                <TableCell
                                  className={
                                    index == cate.value.length - 1
                                      ? "border-btm-none"
                                      : ""
                                  }
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "12px 14px",
                                  }}
                                >
                                  <div className="label-comp-det">
                                    {comp.label}
                                  </div>
                                  {getCategory(data.reportComp[key], comp.key) >
                                  0 ? (
                                    <div className="value-comp-det">
                                      {getCategory(
                                        data.reportComp[key],
                                        comp.key
                                      )}{" "}
                                      Report
                                    </div>
                                  ) : (
                                    <div>-</div>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                          </Table>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                </div>
              ))}
          </Box>
        </Box>
      </div>
    </Fragment>
  );
}

export default TabulasiDetail;
