import React, { useState ,useEffect, Fragment} from 'react'
import { Box, Grid, makeStyles, ClickAwayListener, Button, IconButton } from '@material-ui/core'
import Typography from '@material-ui/core/Typography';
import { useParams, useHistory, useLocation } from 'react-router-dom'
import './styles.scss'
import ImagesGalleries from '../../../../../component/Vito/ImagesGalleries'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SelectYearMonth from '../../../../../component/SelectYearMonth'
import { useSelector, useDispatch } from 'react-redux'
import Axios from 'axios'
import { SERVER } from '../../../../../../../server'
import { getMonthlyGallery, getWeekly } from '../../../../../../../store/actions/vitoAction'
import socketClient from "socket.io-client";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
    root: {
        padding: "2em",
        boxSizing: 'border-box',
        width: 'calc( 100vw - 490px)',
        background: "white",
        boxShadow: "none",
    },
    title: {
        fontWeight: "500",
        lineHeight: "16px",
        color: "#2C304D"
    },
    selectBox: {
        width: "14em",
        padding: "1em"
    },
    image: {
        height: "30%",
        border: "1px solid #DDDDDD",
        boxSizing: "border-box",
        borderRadius: "1em 1em 0.5em 0.5em",
        margin: "0.75em",
        display: "flex",
        flexDirection: "column",
    },
    boxImages: {
        display: "flex",
        flexWrap: "wrap",
        overflowY: "scroll",
        minHeight: 'calc( 85vh )',
        maxHeight: 'calc( 85vh )',
    },
    buttonReport: {
        marginTop: "1em",
        color: "#E23F81",
        borderColor: "#E23F81",
        borderRadius: "4px",
        textTransform: "none",
        marginLeft: "1em"
    },
})

function useQuery(){
    return new URLSearchParams(useLocation().search)
}

export default function PIN(props) {
    const [years] = useState([2019, 2020, 2021, 2022, 2023, 2024, 2025])
    const [months] = useState(['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'])
    const { id } = useParams()
    const dispatch = useDispatch()
    const [reportGalleries, setReportGalleries] = useState([])
    const [loading, setLoading] = useState(false)
    const classes = useStyles()
    const [showSelect, setShowSelect] = useState(false)
    const [inputYear, setInputYear] = useState(2020)
    const [flag, setFlag] = useState(true)    
    const [inputMonth, setInputMonth] = useState("")
    const filterYears = useSelector(state=> state.filterState.years)
    const query = useQuery()
    const vito = useSelector(state=> state.vitoState.vito)
    const [monthObj, setMonthObj] = useState(null)
    const [yearObj, setYearObj] = useState(null)
    const month = useSelector(state=> state.vitoState.monthGallery)
    const weeklyDate = useSelector(state=> state.vitoState.weekly)
    const socket = socketClient(SERVER, {transports: ['websocket'], secure: true});

    useEffect(()=>{
        if (query.get('year') && query.get('month')) {
            setInputYear((query.get('year')))
            setInputMonth((query.get('month')))
        } else if (query.get('year')) {
            setInputYear((query.get('year')))
            setInputMonth('Januari')
        }
    },[])

    function changeMonth(e, monthly) {
        e.preventDefault()
        setInputMonth(monthly)
        if (month) {
            month.map(mon=>{
                if (mon.month === monthly) {
                    getReport(mon)
                    setMonthObj(mon)
                }
            })
        }
    }

    function nextYear() {
        let temp = ''
        for (let i = 0; i < years.length; i++) {
            if (years[i] == inputYear) {
                temp = years[i + 1]
            }
        }
        setInputYear(temp)
        setFlag(!flag)
    }

    function beforeYear() {
        let temp = ''
        for (let i = 0; i < years.length; i++) {
            if (years[i] == inputYear) {
                temp = years[i - 1]
            }
        }
        setInputYear(temp)
        setFlag(!flag)
    }

    function changeShowSelect() {
        setShowSelect(!showSelect)
    }

    function closeSelect() {
        setShowSelect(false)
    }

    useEffect(()=>{
        let year = new Date().getFullYear()
        setInputYear(year)
        switch (new Date().getMonth()) {
            case 0:
                setInputMonth('Januari')
                break;
            case 1:
                setInputMonth('Februari')
                break;
            case 2:
                setInputMonth('Maret')
                break;
            case 3:
                setInputMonth('April')
                break;
            case 4:
                setInputMonth('Mei')
                break;
            case 5:
                setInputMonth('Juni')
                break;
            case 6:
                setInputMonth('Juli')
                break;
            case 7:
                setInputMonth('Agustus')
                break;
            case 8:
                setInputMonth('September')
                break;
            case 9:
                setInputMonth('Oktober')
                break;
            case 10:
                setInputMonth('November')
                break;
            case 11:
                setInputMonth('Desember')
                break; 
            default:
                break;
        }
        if (filterYears) {
            filterYears.map((data)=>{
                if (year == data.year) {
                    setYearObj(data)
                    dispatch(getMonthlyGallery(data.id))
                    dispatch(getWeekly(data.id))
                }
            })
        }
    },[filterYears])

    useEffect(()=>{
        if (month) {
            month.map(mon=>{
                if (mon.month === inputMonth) {
                    getReport(mon)
                    setMonthObj(mon)
                }
            })
        }
    },[month])

    useEffect(()=>{
        if (monthObj && vito) {
            socket.on('report-galery', (data) => {
                if ((monthObj.id == data.month) && (id == data.vitoId)) {
                    getReport(monthObj)
                }
            })
        }
    },[monthObj, vito])

    const getReport = (month) => {
        if (month) {
            Axios({
                method: 'GET',
                url: `${SERVER}/gallery/${month.id}/${id}`,
                headers: {
                    token : localStorage.getItem('token'),
                }
            })
            .then(({data})=>{
                let dataTemp = []
                if (data) {
                    data.map((dataA,i)=>{
                        if (dataA) {
                            let dataBank = dataA
                            if (dataA.photos) {
                                let temp = []
                                dataA.photos.split(',').map(img=>{
                                    if (img.includes('.png') || img.includes('.jpg') || img.includes('.jpeg')) {
                                        temp.push(img)
                                    }
                                })
                                if (temp.length > 0) {
                                    dataBank.photos = String(temp)
                                    dataTemp.push(dataBank) 
                                }
                            }
                        }
                    })
                    setReportGalleries(dataTemp)
                    setLoading(true)
                }
            })
            .catch(err=>{
            })
        }
    }

    return (
        <Fragment>
            <Box className={classes.root}>
                <Box paddingLeft="0.5rem">
                    <Typography className={classes.title} variant="h6" >Galeri - { vito && vito.name ? vito.name : '-' }</Typography>
                </Box>
                <Box margin="0.5rem 0.5rem">
                    <ClickAwayListener onClickAway={closeSelect}>
                        <Box position="relative" width={1/4} marginTop="1rem" >
                            <Button display="flex" variant="outlined" style={{ height: "100%", width: "100%", textTransform: "none" }} onClick={changeShowSelect}>
                                <Box display="flex" width="100%" alignItems="center" justifyContent="space-between">
                                    <Typography style={inputYear && inputMonth ? { color: "black" } : { color: "#C4C4C4" }}>{inputYear && inputMonth ? `${inputMonth}, ${inputYear}` : 'Choose Year & Month'}</Typography>
                                    <ExpandMoreIcon style={{ color: "#C4C4C4" }} />
                                </Box>
                            </Button>
                            {showSelect ?
                                <SelectYearMonth years={years} inputYear={inputYear} beforeYear={beforeYear} nextYear={nextYear} changeMonth={changeMonth} inputMonth={inputMonth} months={months} />
                                : null}
                        </Box>
                    </ClickAwayListener>
                </Box>
                <Box className={classes.boxImages}>
                    {   
                        loading ?
                        reportGalleries &&
                        reportGalleries.length !== 0 ?
                        reportGalleries.map((file, i) => {
                            return (
                                <Box key={i} width="23%" className={classes.image}>
                                    <ImagesGalleries getReport={getReport} weeklyDate={weeklyDate} file={file} monthObj={monthObj} image={file && file.photo ? file.photo.split(',')[0] : file.photos && file.photos.split(',')[0]}/>
                                </Box>
                            )
                        })
                        :
                        <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent:'center', marginTop: '9vw' }} >
                            <i className="fas fa-images" style={{ fontSize: '10vw', color: '#716a69' }} />
                            <span style={{ marginTop: 2 }} > Album Tidak Ditemukan </span>
                        </div>
                        :
                        <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent:'center', marginTop: '9vw' }} >
                            <CircularProgress color="secondary"/>
                            <span style={{ marginTop: 2 }} > </span>
                        </div>
                        
                    }
                </Box>

                {/* <Box>
                    <Button variant="outlined" size="small" className={classes.buttonReport} onClick={addGallery}>Add New Gallery</Button>
                </Box> */}
            </Box>
        </Fragment>
    )
}