import React, { useState, useEffect, Fragment} from 'react'
import { Box, Grid, makeStyles, FormControl, InputLabel, Select, MenuItem, withStyles, Button, IconButton } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { useParams, useHistory, useLocation } from 'react-router-dom'
import ShareIcon from '@material-ui/icons/Share';
import MessageIcon from '@material-ui/icons/Message';
import EditIcon from '@material-ui/icons/Edit';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import './styles.scss'
import { useSelector } from 'react-redux'
import Axios from 'axios'
import { SERVER } from '../../../../../../../server'
import ListFile from './List'
import Empty from './Empty'
import socketClient from "socket.io-client";
import CircularProgress from '@material-ui/core/CircularProgress';
import Swal from 'sweetalert2'

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        width: '100%',
        height: '100px',
        background: "white",
        padding: "1em 0em",
        boxShadow: "none"
    },
    content: {
        paddingTop: "1em",
        marginTop: "1em",
        height: "calc( 100vh - 60px)",
        background: "white"
    },
    tabs: {
        textTransform: "none",
        fontSize: "1.5em"
    },
    title: {
        fontWeight: "500",
        lineHeight: "16px",
        color: "#2C304D"
    },
    file: {
        height: "19em",
        border: "1px solid #DDDDDD",
        boxSizing: "border-box",
        borderRadius: "8px",
        padding: "1em",
        margin: "0.75em",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "1em"
    },
    boxFiles: {
        display: "flex",
        flexWrap: "wrap",
        maxHeight: "55vh",
        overflowY: "scroll"
    },
    buttonRead: {
        color: "#E23F81",
        background: "rgba(226, 63, 129, 0.1)",
        borderRadius: "4px",
        width: "100px",
        border: "none",
        textTransform: "none"
    },
    buttonReport: {
        marginTop: "2em",
        color: "#E23F81",
        borderColor: "#E23F81",
        borderRadius: "4px",
        textTransform: "none",
        marginLeft: "1em"
    },

})

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function useQuery(){
    return new URLSearchParams(useLocation().search)
}

export default function PIN(props) {
    const [value, setValue] = useState(0);
    const history = useHistory()
    const query = useQuery()
    const month = useSelector(state=> state.vitoState.monthly)
    const vito = useSelector(state=> state.vitoState.vito)
    const socket = socketClient(SERVER, {transports: ['websocket'], secure: true});
    
    const [monthNow, setMonthNow] = useState(null)
    const [loading, setLoading] = useState(false)
    const classes = useStyles();
    const [report, setReport] = useState([])

    const changeMonth = (month) => {
        let monthNum
        switch (month.month) {
            case 'Januari':
                monthNum = 0
                break;
            case 'Februari':
                monthNum = 1
                break;
            case 'Maret':
                monthNum = 2
                break;
            case 'April':
                monthNum = 3
                break;
            case 'Mei':
                monthNum = 4
                break;
            case 'Juni':
                monthNum = 5
                break;
            case 'Juli':
                monthNum = 6
                break;
            case 'Agustus':
                monthNum = 7
                break;
            case 'September':
                monthNum = 8
                break;
            case 'Oktober':
                monthNum = 9
                break;
            case 'November':
                monthNum = 10
                break;
            case 'Desember':
                monthNum = 11
                break; 
            default:
                break;
        }
        if (monthNum != value) {
            setMonthNow(month)
            getReport(month)
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(()=>{
        if (month) {
            let monthNum
            let queryMonth = query.get('month')
            switch (new Date().getMonth()) {
                case 0:
                    monthNum = 0
                    break;
                case 1:
                    monthNum = 1
                    break;
                case 2:
                    monthNum = 2
                    break;
                case 3:
                    monthNum = 3
                    break;
                case 4:
                    monthNum = 4
                    break;
                case 5:
                    monthNum = 5
                    break;
                case 6:
                    monthNum = 6
                    break;
                case 7:
                    monthNum = 7
                    break;
                case 8:
                    monthNum = 8
                    break;
                case 9:
                    monthNum = 9
                    break;
                case 10:
                    monthNum = 10
                    break;
                case 11:
                    monthNum = 11
                    break; 
                default:
                    break;
            }
            month.map((mon, i)=>{
                if (queryMonth) {
                    if (queryMonth === (mon.month).toLowerCase()) {
                        setMonthNow(mon)
                        setValue(i)
                    }
                } else {
                    if (i === monthNum) {
                        setMonthNow(mon)
                        setValue(i)
                    }
                }
            })
        }
    },[month])

    useEffect(()=>{
        if (vito && monthNow) {
            getReport(monthNow)
        }
    },[vito, monthNow])

    useEffect(()=>{
        if (monthNow && vito) {
            socket.on('report-monthly', (data) => {
                if ((monthNow.id == data.month) && (vito.id == data.vitoId)) {
                    getReport(monthNow)
                }
            })
        }
    },[monthNow, vito])

    const getReport = (month) => {
        if (month && vito) {
            Swal.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: () => {
                    Swal.showLoading()
                }
            })
            Axios({
                method: 'GET',
                url: `${SERVER}/report/monthly/${month.id}/${vito.id}`,
                headers: {
                    token : localStorage.getItem('token'),
                }
            })
            .then(({data})=>{
                setReport(data)
                setLoading(true)
                Swal.close()
            })
            .catch(err=>{
                Swal.close()
            })
        }
    }

    return (
        <Fragment>
            <Box style={{ backgroundColor: 'white', display: 'flex', justifyContent: 'center' }} className="appbar-WeeklyBox">
                {/* <Box height="15%" maxWidth="68vw"> */}
                    {/* <AppBar position="static" className={classes.root} color="default"> */}
                        <Tabs
                            className="appbar-WeeklyUptd"
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="on"
                            indicatorColor="secondary"
                            textColor="secondary"
                            aria-label="scrollable force tabs example"
                        >{
                            month &&
                                month.map((item, i) => (
                                <Tab key={i} onClick={e=>changeMonth(item)} label={
                                        <Box display="flex" flexDirection="column" alignItems="center" height="60px" justifyContent="center">
                                            <Typography style={{ fontSize: '20px' }} className={classes.tabs}>{item && item.month}</Typography>
                                            <Typography style={{ fontSize: '15px' }} >{item && (new Date(item.dates.start)).getFullYear()}</Typography>
                                        </Box>
                                    }>
                                </Tab>))
                            }
                        </Tabs>
                    {/* </AppBar> */}
                {/* </Box> */}
            </Box>
            <Box className="content-weekly" style={{ marginTop: '1em', padding: 0 }}>
                {
                    month &&
                    month.map((tab, index) => {
                        return (
                            <TabPanel value={value} index={index}>
                                <Box padding="1em" marginBottom="0.5em">
                                    <Typography className={classes.title} variant="h6" >Laporan Bulanan - { vito && vito.name ? vito.name : '-' }</Typography>
                                </Box>
                                <Box className={classes.boxFiles}>
                                    {
                                        loading ?
                                        report &&
                                        report.length !== 0 ? <ListFile report={report} getReport={getReport} monthNow={monthNow}/> : <Empty monthNow={monthNow} getReport={getReport}/>
                                        : <CircularProgress color="secondary"/>
                                    }
                                </Box>
                            </TabPanel>)
                    })
                }
            </Box>
        </Fragment>
    )
}