import {
    GET_PARTNER, GET_PARTNER_FILTER
} from "../constanta";

const initialState = {
    partners : [],
    partnersFilter : []
}

export default (state = initialState, action)=> {
    switch(action.type){
        case GET_PARTNER:
            return {
                ...state,
                partners: action.data
            }
        case GET_PARTNER_FILTER:
            return {
                ...state,
                partnersFilter: action.data
            }
        default: {
            return state
        }
    }
}