import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Box, Checkbox, FormControl, FormControlLabel, RadioGroup, TextField } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Axios from 'axios'
import { SERVER } from '../../../../../server'
import Swal from 'sweetalert2'

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: "1rem 2.5rem",
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const useStyles = makeStyles({
    boxRadioActive: {
        background: "white",
        border: "1px solid  #DDDDDD",
        width: "100%",
        margin: "0em",
        marginBottom: "0.5rem",
        borderRadius: "4px",
        color: "#E23F81"
    },
    boxRadio: {
        background: "white",
        border: "1px solid  #DDDDDD",
        width: "100%",
        margin: "0em",
        marginBottom: "0.5rem",
        borderRadius: "4px",
    },
})

export default function CustomizedDialogs(props) {
    const [open, setOpen] = useState(false);
    const classes = useStyles()
    const [category, setCategory] = useState("");
    const [options, setOptions] = useState(props.options)
    const [showTextfield, setShowTextfield] = useState(false)
    const [textfield, setTextfield] = useState('')
    const {
        data,
        read,
        CategoryId,
        weekId,
        weekFind
    } = props

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const changeCategory = (event) => {
        setCategory(event.target.value)
        setShowTextfield(true)
    }

    useEffect(()=>{
        if (data) {
            setTextfield(data.description)
            setCategory(data.category)
        }
    },[data])

    const saving = () => {
        let datanew = {
            category : category,
            description : textfield,
            weekFind
        }
        Swal.showLoading()
        Axios({
            method: 'PATCH',
            url: `${SERVER}/report/${data.id}`,
            data: datanew,
            headers: {
                token : localStorage.getItem('token'),
            }
        })
        .then(({data})=>{
            // sessionStorage.removeItem(`${CategoryId}${weekId}`)
            read(CategoryId, weekId)
            handleClose()
            Swal.close()
        })
        .catch(err=>{
            Swal.close()
        })
    }


    return (
        <div>
            <Box borderRadius="50%" marginLeft="1rem" padding="0.25rem" style={{ background: "#FAFAFA" }}>
                <IconButton size="small" onClick={handleClickOpen}>
                    <EditIcon size="small" color="disabled" />
                </IconButton>
            </Box>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" maxWidth="lg" open={open} paper={classes.borderDialog}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Edit Work Plan
                </DialogTitle>
                <Box width="50em" padding="1.5em 3em" borderTop="0.5px solid #DDDDDD">
                    <Box>
                        <FormControl component="fieldset" style={{ width: "100%", justifyContent: "space-between" }}>
                            <RadioGroup style={{ justifyContent: "space-between" }} value={category} onChange={changeCategory}>
                                {
                                options.map((option, i) => {
                                    return (
                                        <FormControlLabel key={i} className={category == option ? classes.boxRadioActive : classes.boxRadio} value={option} control={<Checkbox
                                            checked={category == option ? true : false}
                                            onChange={changeCategory}
                                            value={option}
                                            disabled={
                                                data &&
                                                (data.credit !== 'disetujui' && data.credit !== 'ditolak') ? false : true
                                            }
                                        />} label={option} />
                                    )
                                })
                                }
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    <Box>
                        <TextField value={textfield} onChange={e=>setTextfield(e.target.value)} color="secondary" placeholder="Type your report title (plan) here.. (max 140 char)" fullWidth style={{ background: "white" }} variant="outlined" multiline rows={2} inputProps={{ maxLength: 140 }} />
                    </Box>
                    <Box display="flex" alignItems="center" marginTop="1em">
                        <Button type="submit" onClick={saving} variant="contained" color="secondary" style={{ backgroundColor: "#E23F81", textTransform: "none", boxShadow: "none", padding: "0.5em 3em" }}>Save</Button>
                        <Button variant="outlined" color="secondary" style={{ textTransform: "none", padding: "0.5em 3em", marginLeft: "2em" }} onClick={handleClose}>Cancel</Button>
                    </Box>
                </Box>
            </Dialog>
        </div>
    );
}