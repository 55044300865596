import axios from 'axios'
import { SERVER } from '../../server'
import {
    IS_LOGOUT,
    GET_NOTIFICATION,
    GET_NOTIFICATIONCOUNT,
    GET_NOTIFICATIONRESTART,
    GET_NOTIFICATIONVITOCOUNT,
    GET_NOTIFICATIONPICCOUNT,
    GET_NOTIFICATIONPOCOUNT
} from "../constanta";

export const getNotification = (offset = 0) => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            const { data } = await axios({
                method: 'GET',
                url: `${SERVER}/notification/${offset}`,
                headers: { token }
            })
            dispatch({
                type: GET_NOTIFICATION,
                data : data
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const getNotificationPIC = (offset = 0) => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            const { data } = await axios({
                method: 'GET',
                url: `${SERVER}/notification/pic/${offset}`,
                headers: { token }
            })
            dispatch({
                type: GET_NOTIFICATION,
                data : data
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const getNotificationPO = (offset = 0) => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            const { data } = await axios({
                method: 'GET',
                url: `${SERVER}/notification/online-promotion/${offset}`,
                headers: { token }
            })
            dispatch({
                type: GET_NOTIFICATION,
                data : data
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const getNotificationVito = (offset = 0) => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            const { data } = await axios({
                method: 'GET',
                url: `${SERVER}/notification/vito/${offset}`,
                headers: { token }
            })
            dispatch({
                type: GET_NOTIFICATION,
                data : data
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const restart = () => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            dispatch({
                type: GET_NOTIFICATIONRESTART,
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const getNotificationCount = () => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            const { data } = await axios({
                method: 'GET',
                url: `${SERVER}/notification/count`,
                headers: { token }
            })
            dispatch({
                type: GET_NOTIFICATIONCOUNT,
                data : data
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const getNotificationVitoCount = () => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            const { data } = await axios({
                method: 'GET',
                url: `${SERVER}/notification/vito/count`,
                headers: { token }
            })
            dispatch({
                type: GET_NOTIFICATIONVITOCOUNT,
                data : data
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const getNotificationPICCount = () => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            const { data } = await axios({
                method: 'GET',
                url: `${SERVER}/notification/pic/count`,
                headers: { token }
            })
            dispatch({
                type: GET_NOTIFICATIONPICCOUNT,
                data : data
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const getOnlinePromotionCount = () => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            const { data } = await axios({
                method: 'GET',
                url: `${SERVER}/notification/online-promotion/count`,
                headers: { token }
            })
            dispatch({
                type: GET_NOTIFICATIONPOCOUNT,
                data : data
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const readNotification = (id, users, role) => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            await axios({
                method: 'PATCH',
                url: `${SERVER}/notification/${id}`,
                headers: { token },
                data:{ users }
            })
            if (role === 'picpasar') {
                dispatch(getNotificationPIC())
                dispatch(getNotificationPICCount())
            } else if (role === 'vito') {
                dispatch(getNotificationVito())
                dispatch(getNotificationVitoCount())
            } else {
                dispatch(getNotification())
                dispatch(getNotificationCount())
            }
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}