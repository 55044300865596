import React, {useState} from 'react'
import { Box } from '@material-ui/core'
import Gallery from '../BoxImg2'

export default function Index(props) {
    const { photos, photosAll } = props
    const [display, setDisplay] = useState("none")
    function hoverBox() {
        setDisplay("block")
    }

    function noHoverBox() {
        setDisplay("none")
    }

    return (
        <Box borderRadius="4px" color="primary" width="124px" height="106px" marginRight="1vw" marginBottom="1vw" onMouseEnter={hoverBox} display="flex" justifyContent="center" position="relative" onMouseLeave={noHoverBox} className="img">
            <img zIndex="modal" src={props.photos} className="image" style={{ borderRadius: 5 }}/>
            <Box display={display} position="absolute" width="100%" height="100%" >
                <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%" style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
                    <Gallery text="Lihat" text2="Unduh" photos={photos} photosAll={photosAll}/>
                </Box>
            </Box>
        </Box>
    )
}
