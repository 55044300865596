import {
    GET_GALLERYINDICATOR,
    GET_MONTHLYINDI,
    GET_REPORTMONTHLYINDICATOR,
    GET_REPORTWEEKLYINDICATOR,
    GET_WEEKLYINDI,
    GET_REPORTMONTHLYDASHBOARD,
    GET_REPORTWEEKLYDASHBOARD,
    GET_BAGANMONTHDASH,
    GET_WEEKLYCHARTDASH,
    GET_BAGANWEEKDASH,
    GET_TABULASI_DASHBOARD
} from "../constanta";

const initialState = {
    indicatorWeekly: [],
    weekly: [],
    indicatorMonthly: [],
    monthly: [],
    indicatorGallery: [],
    monthlyDashboard: [],
    weeklyDashboard: [],
    baganMonth: [],
    weekListBagan: [],
    baganWeek: [],
    tabulasi: []
}

export default (state = initialState, action)=> {
    switch(action.type){
        case GET_REPORTWEEKLYINDICATOR:
            return {
                ...state,
                indicatorWeekly: action.data
            }
        case GET_TABULASI_DASHBOARD:
            return {
                ...state,
                tabulasi: action.data
            }
        case GET_REPORTMONTHLYINDICATOR:
            return {
                ...state,
                indicatorMonthly: action.data
            }
        case GET_WEEKLYINDI:
            return {
                ...state,
                weekly: action.data
            }
        case GET_MONTHLYINDI:
            return {
                ...state,
                monthly: action.data
            }
        case GET_GALLERYINDICATOR:
            return {
                ...state,
                indicatorGallery: action.data
            }
        case GET_REPORTMONTHLYDASHBOARD:
            return {
                ...state,
                monthlyDashboard: action.data
            }
        case GET_REPORTWEEKLYDASHBOARD:
            return {
                ...state,
                weeklyDashboard: action.data
            }
        case GET_BAGANMONTHDASH:
            return {
                ...state,
                baganMonth: action.data
            }
        case GET_WEEKLYCHARTDASH:
            return {
                ...state,
                weekListBagan: action.data
            }
        case GET_BAGANWEEKDASH:
            return {
                ...state,
                baganWeek: action.data
            }
        default: {
            return state
        }
    }
}