import { Box, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, makeStyles, MenuItem, OutlinedInput, Select, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import InfoIcon from '@material-ui/icons/Info';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SERVER } from '../../../../../../server'
import axios from 'axios'
import Swal from 'sweetalert2'
import { getUser } from '../../../../../../store/actions/userAction'

const imageSize = 140

const useStyle = makeStyles({
    root: {
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "#F2F3F9",
        height: "100vh"
    },
    profile: {
        background: "white",
        padding: "2em",
        borderRadius: "4px"
    },
    buttonUpload: {
        color: "#E23F81",
        border: "1px solid #E23F81",
        borderRadius: "4px",
        textTransform: "none"
    },
    icon: {
        fontSize: "10em",
        color: "#F2F3F9",
        borderRadius: imageSize/2,
        width: imageSize,
        height: imageSize,
        backgroundSize: 'cover',
        boxSizing: 'border-box'
    },
    buttonCancel: {
        marginLeft: "2em",
        color: "#E23F81",
        border: "1px solid #E23F81",
        borderRadius: "4px",
        textTransform: "none"
    },
    formControl: {
        marginTop: "1em"
    },
    inputFile: {
        display: "none"
    }
})

export default function Add() {
    const classes = useStyle()
    const history = useHistory()
    const Dispatch = useDispatch()
    const vitos = useSelector(state=> state.vitoState.vitos)
    const filterRole = useSelector(state=> state.filterState.roleAdmin)
    const [showPassword, setShowPassword] = useState(false)
    const [vitoImg, setVitoImg] = useState(null)
    const [showUlangiPassword, setShowUlangiPassword] = useState(false)
    const [photo, setPhoto] = useState(null)
    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)
    const [phone, setPhone] = useState(null)
    const [role, setRole] = useState(null)
    const [vitosId, setVitosId] = useState([])
    const [vitoId, setVitoId] = useState('')
    const [position, setPosition] = useState('')
    const [password, setPassword] = useState(null)
    const [passwordRepeat, setPasswordRepeat] = useState(null)

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    };

    const handleClickShowUlangiPassword = () => {
        setShowUlangiPassword(!showUlangiPassword)
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    function back() {
        history.push('/admin/user')
    }

    const imagesHandler = (e) => {
        let image = e.target.files[0]
        setPhoto(e.target.files[0])
        let reader = new FileReader()
        if (image && image.type.match('image.*')) {
            reader.readAsDataURL(image)
            reader.onloadend = () => {
                setVitoImg(reader.result)
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            }
        }
    }

    const addVitoId = () => {
        if (vitoId) {
            let arr = [...vitosId]
            arr.push(vitoId)
            setVitosId(arr)
            setVitoId('')
        }
    }

    const removeVitoId = (i) => {
        let arr = []
        vitosId.map((n, index)=>{
            if (index !== i) {
                arr.push(n)
            }
        })
        setVitosId(arr)
    }

    const submit = (e) => {
        e.preventDefault()
        let VitId = [...vitosId]
        if (vitoId) {
            VitId.push(vitoId)
        }
        const formData = new FormData();
        formData.append('photo', photo)
        formData.set('name', name);
        formData.set('email', email);
        formData.set('phone', phone);
        formData.set('role', role);
        formData.set('password', password);
        formData.set('passwordRepeat', passwordRepeat);
        formData.set('VitosId', VitId);
        formData.set('position', position);

        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Swal.showLoading()
        axios({
            method: 'post',
            url: `${SERVER}/user/created-user`,
            data: formData,
            headers: {
                token : localStorage.getItem('token'),
            }
        })
        .then(({data})=>{
            Swal.close()
            history.goBack()
            // Dispatch(getUser())
        })
        .catch(err=>{
            if (err.response) {
                if (err.response.data) {
                    if (err.response.data.message) {
                        Toast.fire({
                            icon: 'error',
                            title: err.response.data.message
                        })
                    }
                }
            }
        })
    }

    const checkingId = (id) => {
        let flag = false
        vitosId.map(di=>{
            if (di === id) {
                flag = true
            }
        })
        return flag
    }

    const getNama = (id) => {
        if (vitos){
            let name
            vitos.map(na=>{
                if (na.id == id) {
                    name = na.name
                }
            })
            return name
        }
    }

    const deletePhos =()=>{
        setPhoto(null)
        setVitoImg(null)
    }

    return (
        <>
            <Box spacing={3} className={classes.root} >
                <Typography variant="h4"><b>Tambah Pengguna</b></Typography>
                <Box width="50%">
                    <Box>
                        <Button style={{ color: "#E23F81" }} startIcon={<ArrowBackIcon />} onClick={back} >Kembali</Button>
                    </Box>
                    <Box className={classes.profile}>
                        <Box display="flex">
                            {!vitoImg?<AccountCircleIcon className={classes.icon}/>:<img src={vitoImg} className={classes.icon} />}
                            <Box display="flex" flexDirection="column" padding="1em" justifyContent="center">
                                <Typography variant="h6" style={{ color: "#999999" }}>Cover/Photo</Typography>
                                <Box marginY="1em">
                                    <input
                                        accept="image/*"
                                        id="contained-button-file"
                                        multiple
                                        type="file"
                                        className={classes.inputFile}
                                        onChange={e=>imagesHandler(e)}
                                    />
                                    <div style={{ display: 'flex', flexDirection: 'row' }} >
                                        <label htmlFor="contained-button-file">
                                            <Button variant="outlined" size="small" className={classes.buttonUpload} component="span">Upload Foto</Button>
                                        </label>
                                        <div className="btn-dlete-phos-897" onClick={deletePhos} >
                                            <i className="fas fa-trash"></i>
                                        </div>
                                    </div>
                                </Box>
                                <Typography variant="caption" style={{ color: "#999999" }}>Kosongkan jika tidak ada foto</Typography>
                            </Box>
                        </Box>
                        <Box marginX="1em">
                            <form>
                                <Box>
                                    <Box marginTop="1em"><TextField name="nama" value={name} onChange={e=> setName(e.target.value)} id="outlined-basic" color="secondary" size="small" label="Nama Lengkap" variant="outlined" fullWidth /></Box>
                                    <Box marginTop="1em"><TextField name="email" value={email} onChange={e=> setEmail(e.target.value)} id="outlined-basic" color="secondary" size="small" label="Email" variant="outlined" fullWidth />
                                        <Box display="flex" alignItems="center" marginTop="0.25em"><InfoIcon fontSize="small" style={{ color: "#999999" }} /><Box display="flex" alignItems="center" marginLeft="0.5em"><Typography variant="caption" style={{ color: "#999999" }}>Pastikan email yang didaftarkan aktif, karena akan menerima berbagai email penting</Typography></Box></Box></Box>
                                    <Box marginTop="1em"><TextField name="noHP" value={phone} onChange={e=> setPhone(e.target.value)} id="outlined-basic" color="secondary" size="small" label="No. Telp" variant="outlined" fullWidth /></Box>
                                    <FormControl color="secondary" variant="outlined" fullWidth size="small" className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-outlined-label">Pengguna</InputLabel>
                                        <Select
                                            name="role"
                                            value={role}
                                            onChange={e=> setRole(e.target.value)}
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            label="Pengguna"
                                        >
                                            {
                                                filterRole &&
                                                filterRole.map((role,i)=>(
                                                    <MenuItem key={i} value={role.initial}>{ role.role }</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                    {
                                        role === 'picpasar' &&
                                        <FormControl color="secondary" variant="outlined" fullWidth size="small" className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-outlined-label">Posisi</InputLabel>
                                        <Select
                                            name="position"
                                            value={position}
                                            onChange={e=> setPosition(e.target.value)}
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            label="Posisi"
                                        >
                                            <MenuItem value="coordinator">Koordinator</MenuItem>
                                            <MenuItem value="subcoordinator">Sub Koordinator</MenuItem>
                                        </Select>
                                    </FormControl>
                                    }
                                    {
                                        (role === 'picpasar' && vitosId.length !== 0) &&
                                        <div style={{ marginTop: '0.5em' }} >
                                            <span style={{ color: '#e35981', fontSize: '12px', paddingLeft: 7 }} >
                                                Daftar VITO
                                            </span>
                                        </div>
                                    }
                                    {
                                        (role === 'picpasar' && vitosId.length !== 0) &&
                                            vitosId &&
                                            vitosId.map((vitoId,i)=>(
                                            <div key={i} style={{ display: 'flex', alignItems: 'center', marginTop: i!==0&&'0.5em'  }} >
                                                <span style={{
                                                    border: '1px solid #cdcdcd',
                                                    borderRadius: '4px',
                                                    width: '100%',
                                                    padding: '10px',
                                                    paddingLeft: '12px'
                                                }} >
                                                    { getNama(vitoId) }
                                                </span>
                                                <i className="fas fa-minus-square" onClick={e=>removeVitoId(i)} style={{ fontSize: 30, marginLeft: 10, color: '#e35981', cursor: 'pointer' }} />
                                            </div>
                                        ))  
                                    }
                                    {
                                        role === 'picpasar' &&
                                        <div style={{ display: 'flex', alignItems: 'center' }} >
                                            <FormControl color="secondary" variant="outlined" fullWidth size="small" className={classes.formControl}>
                                                <InputLabel id="demo-simple-select-outlined-label">Pilih VITO</InputLabel>
                                                <Select
                                                    name="role"
                                                    value={vitoId}
                                                    onChange={e=> setVitoId(e.target.value)}
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    label="Select Vito"
                                                >
                                                    <MenuItem value="" >  </MenuItem>
                                                    {
                                                        vitos &&
                                                        vitos
                                                        .filter(a=> !(checkingId(a.id)))
                                                        .map((vito,i)=>(
                                                            <MenuItem style={{ textTransform: 'capitalize' }} key={i} value={vito.id}>{ `${vito.country} - ${vito.area}` } &nbsp; <span style={{ textTransform: 'none' }} > ({vito.email}) </span> </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                            <i className="fas fa-plus-square" onClick={e=>addVitoId()} style={{ fontSize: 30, marginTop: 19, marginLeft: 10, color: '#e35981', cursor: 'pointer' }} />
                                        </div>
                                    }
                                    <Box marginTop="1em"><FormControl fullWidth variant="outlined" size="small" color="secondary">
                                        <InputLabel htmlFor="outlined-adornment-password" color="secondary">Password</InputLabel>
                                        <OutlinedInput
                                            name="password"
                                            value={password}
                                            onChange={e=> setPassword(e.target.value)}
                                            fullWidth
                                            color="secondary"
                                            id="outlined-adornment-password"
                                            type={showPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            labelWidth={70}
                                        />
                                    </FormControl></Box>
                                    <Box marginTop="1em"><FormControl fullWidth variant="outlined" size="small" color="secondary">
                                        <InputLabel htmlFor="outlined-adornment-password" color="secondary">Ulangi Password</InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            name="ulangiPassword"
                                            value={passwordRepeat}
                                            onChange={e=> setPasswordRepeat(e.target.value)}
                                            color="secondary"
                                            id="outlined-adornment-password"
                                            type={showUlangiPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowUlangiPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showUlangiPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            labelWidth={120}
                                        />
                                    </FormControl></Box>
                                </Box>
                                <Box display="flex" alignItems="center" marginTop="1.5em">
                                    <Button type="submit" onClick={e=>submit(e)} variant="contained" color="secondary" size="large" style={{ backgroundColor: "#E23F81", textTransform: "none" }}>Buat Akun</Button>
                                    <Button variant="outlined" className={classes.buttonCancel} size="large" onClick={back}>Batal</Button>
                                </Box>
                            </form>
                        </Box>
                    </Box>
                </Box>

            </Box>


        </>
    )
}
