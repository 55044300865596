import { Box, Button, makeStyles, TextField, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import './styles.scss'
import Pagination from '@material-ui/lab/Pagination';
import CardBox from '../../../../component/LihatKPI'

const useStyles = makeStyles({
    filter : {
        textTransform: "none",
        padding: "0.5em 2em",
        color: "#999999"
    }
})



const boxData = [{category: "ada"}, {category: "ada"}, {category: ""}, {category: "ada"}, {category: ""}, {category: "ada"}, {category: ""}, 8, {category: "ada"}, 10]


export default function PIN(props) {
    const classes = useStyles();
    const [allDatas, setAllDatas] = useState([]) 
    const [page, setPage] = useState(1)

    useEffect(() => {
        let newDatas = []
        let item = []
        for (let i = 0; i < boxData.length; i++) {
            item.push(boxData[i])
            if (item.length == 6 || i == (boxData.length - 1)) {
                newDatas.push(item)
                item = []
            }
        }
        setAllDatas(newDatas)
    }, [])

    const handleChangePage = (event, value) => {
        setPage(value);
    }

    return (
        <>
            <div className="dashboard" >
                <span className="breadcrumb-txt-active" >
                    Evaluation
            </span>
                <span className="breadcrumb-slash-active" >
                    /
            </span>
                <span className="breadcrumb-txt-black" >
                    Lihat KPI
            </span>
                <span className="breadcrumb-slash-black" >
                    /
            </span>
            </div>
            <div className="vito-table">
                <Box padding="2rem">
                    <Box >
                        <Typography variant="h6">DAFTAR KPI</Typography>
                    </Box>
                    <Box display="flex" marginY="1em">
                        <Box width="20%" marginRight="1em"><TextField label="Search Weekly Report" size="small" variant="outlined" borderColor="#C4C4C4" fullWidth /></Box>
                        <Button variant="outlined" className={classes.filter}>Filter</Button>
                    </Box>
                    <Box >
                        {
                            allDatas && allDatas.map((item, index) => {
                                return (
                                    <>
                                    {
                                        page == index+1?<CardBox datas={item} page={index+1} key={index} /> : null
                                    }
                                    </>
                                )
                            }) 
                        }
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <Pagination count={allDatas.length} page={page} onChange={handleChangePage} variant="outlined" shape="rounded" />
                    </Box>
                </Box>
            </div>
        </>
    )
}