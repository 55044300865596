import axios from "axios";
import { SERVER } from "../../server";
import {
  IS_LOGOUT,
  GET_REPORTWEEKLYINDICATOR,
  GET_WEEKLYINDI,
  GET_REPORTMONTHLYINDICATOR,
  GET_MONTHLYINDI,
  GET_GALLERYINDICATOR,
  GET_REPORTMONTHLYDASHBOARD,
  GET_REPORTWEEKLYDASHBOARD,
  GET_REPORTMONTHLYCOUNTDASHBOARD,
  GET_REPORTWEEKLYCOUNTDASHBOARD,
  GET_TABULASI_DASHBOARD,
} from "../constanta";

export const getReportWeeklyIndi = (year) => async (dispatch) => {
  let token = localStorage.getItem("token");
  if (token) {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${SERVER}/report/weekly/indicator/${year}`,
        headers: { token },
      });

      dispatch({
        type: GET_REPORTWEEKLYINDICATOR,
        data: data,
      });
    } catch (err) {}
  } else {
    dispatch({
      type: IS_LOGOUT,
    });
  }
};

export const getReportMonthIndi = (year) => async (dispatch) => {
  let token = localStorage.getItem("token");
  if (token) {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${SERVER}/report/monthly/indicator/${year}`,
        headers: { token },
      });

      dispatch({
        type: GET_REPORTMONTHLYINDICATOR,
        data: data,
      });
    } catch (err) {}
  } else {
    dispatch({
      type: IS_LOGOUT,
    });
  }
};

export const getGalleryIndi = (year) => async (dispatch) => {
  let token = localStorage.getItem("token");
  if (token) {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${SERVER}/gallery/indicator/${year}`,
        headers: { token },
      });

      dispatch({
        type: GET_GALLERYINDICATOR,
        data: data,
      });
    } catch (err) {}
  } else {
    dispatch({
      type: IS_LOGOUT,
    });
  }
};

export const getWeeklyIndi = (year) => async (dispatch) => {
  let token = localStorage.getItem("token");
  if (token) {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${SERVER}/database/list/${year}`,
        headers: { token },
      });
      dispatch({
        type: GET_WEEKLYINDI,
        data: data,
      });
    } catch (err) {}
  } else {
    dispatch({
      type: IS_LOGOUT,
    });
  }
};

export const getMonthlyIndi = (year) => async (dispatch) => {
  let token = localStorage.getItem("token");
  if (token) {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${SERVER}/database/${year}`,
        headers: { token },
      });
      dispatch({
        type: GET_MONTHLYINDI,
        data: data,
      });
    } catch (err) {}
  } else {
    dispatch({
      type: IS_LOGOUT,
    });
  }
};

export const getReportMonthDashboard = (month) => async (dispatch) => {
  let token = localStorage.getItem("token");
  if (token) {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${SERVER}/report/dashboard/monthly/${month}`,
        headers: { token },
      });

      dispatch({
        type: GET_REPORTMONTHLYDASHBOARD,
        data: data,
      });
    } catch (err) {}
  } else {
    dispatch({
      type: IS_LOGOUT,
    });
  }
};

export const getReportWeeklyDashboard = (id) => async (dispatch) => {
  let token = localStorage.getItem("token");
  if (token) {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${SERVER}/report/dashboard/weekly/${id}`,
        headers: { token },
      });

      dispatch({
        type: GET_REPORTWEEKLYDASHBOARD,
        data: data,
      });
    } catch (err) {}
  } else {
    dispatch({
      type: IS_LOGOUT,
    });
  }
};

export const getReportMonthCountDashboard = (year) => async (dispatch) => {
  let token = localStorage.getItem("token");
  if (token) {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${SERVER}/report/dashboard/count/monthly/${year}`,
        headers: { token },
      });

      dispatch({
        type: GET_REPORTMONTHLYCOUNTDASHBOARD,
        data: data,
      });
    } catch (err) {}
  } else {
    dispatch({
      type: IS_LOGOUT,
    });
  }
};

export const getReportWeekCountDashboard = (year) => async (dispatch) => {
  let token = localStorage.getItem("token");
  if (token) {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${SERVER}/report/dashboard/count/weekly/${year}`,
        headers: { token },
      });

      dispatch({
        type: GET_REPORTWEEKLYCOUNTDASHBOARD,
        data: data,
      });
    } catch (err) {}
  } else {
    dispatch({
      type: IS_LOGOUT,
    });
  }
};

export const getTabulasiDashboard = (id) => async (dispatch) => {
  let token = localStorage.getItem("token");
  //console.log(`${SERVER}/report/report-groped/${id}`);
  if (token) {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${SERVER}/report/report-groped/${id}`,
        headers: { token },
      });
      //console.log(data);
      dispatch({
        type: GET_TABULASI_DASHBOARD,
        data: data,
      });
    } catch (err) {}
  } else {
    dispatch({
      type: IS_LOGOUT,
    });
  }
};
