import React, {useEffect, useState, Fragment} from 'react'
import { Box, makeStyles, InputAdornment, InputLabel, Select, MenuItem, withStyles, Button } from '@material-ui/core'
import { useHistory, Switch, Route, useRouteMatch } from 'react-router-dom'
import Weekly from './Weekly'
import Monthly from './Monthly'
import Galleries from './Galleries'
import AddGalleries from './Galleries/AddGalleries'
import EditGalleries from './Galleries/EditGalleries'
import { DatePicker } from "@material-ui/pickers";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useSelector, useDispatch } from 'react-redux'
import { getWeekly, getMonthly} from '../../../../../store/actions/vitoAction'

const LeftButton = withStyles((theme) => ({
    root: {
        width: '90%',
        height: '50px',
        backgroundColor: 'none',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        transition: '0.5s',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: '19px',
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "15px",
        lineHeight: "18px",
        color: "#999999",
        '&:hover': {
            backgroundColor: '#d8e0e5',
            color: '#E23F81',
            boxShadow: 'none',
        },
    },
}))(Button);

const LeftButtonActive = withStyles((theme) => ({
    root: {
        width: '90%',
        height: '50px',
        backgroundColor: '#E23F81',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        transition: '0.5s',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: '19px',
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "15px",
        lineHeight: "18px",
        color: "#FFFFFF",
        '&:hover': {
            backgroundColor: '#E23F81',
            color: '#FFFFFF',
            boxShadow: 'none',
        },
    },
}))(Button);

const useStyles = makeStyles({
    navLeft: {
        height: "100vh",
        background: "white",
        padding: "1.5em",
        paddingBottom: '0px',
    },
    rigth: {
        marginLeft: "1em",
        height: "90vh",
    },
    topBar: {
        background: "white"
    },
    formControl: {
        width: "100%",
    },

})

export default function PIN(props) {
    const { path, url } = useRouteMatch();
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [selectedDate, handleDateChange] = useState(new Date());
    const dispatch = useDispatch()
    const classes = useStyles();
    const history = useHistory()
    const [breadcrumb, setBreadcrumb] = useState([])
    const filterYears = useSelector(state=> state.filterState.years)
    const [done, setDone] = useState(false)

    function setCategory(category) {
        history.push(`/vito/report/${category}/${selectedDate.getFullYear()}`)
    }

    const handleDateChangeFunc = (date) => {
        handleDateChange(date)
        history.push(`/vito/report/${(history.location.pathname).split("/")[3]}/${date.getFullYear()}`)
    }

    useEffect(() => {
        setBreadcrumb((history.location.pathname).split("/"))
    }, [history.location.pathname])

    useEffect(()=>{
        let year
        if (selectedDate.getFullYear() > 2025) {
            handleDateChange(2025)
            year = filterYears.filter(e=> e.year === 2025)[0].id
        }
        if (selectedDate.getFullYear() < 2019) {
            handleDateChange(2019)
            year = filterYears.filter(e=> e.year === 2019)[0].id
        }
        if (selectedDate.getFullYear() <= 2025 && selectedDate.getFullYear() >= 2019) {
            year = filterYears.filter(e=> e.year === selectedDate.getFullYear())[0].id
        }
        dispatch(getWeekly(year))
        dispatch(getMonthly(year))
    },[selectedDate])

    return (
        <Fragment>
            <div className="dashboard" >
                <span style={breadcrumb.length == 1?{color: "#E23F81", textTransform: "capitalize"}:{textTransform: "capitalize"}}>
                    {breadcrumb.map((item, i) => i != 0 && i != breadcrumb.length-1? `${item} / `:null
                    )}
                </span>
                <span style={{color: "#E23F81", textTransform: "capitalize"}}>
                    {breadcrumb[breadcrumb.length-1]} 
            </span>
            </div>
            <div className="vito-profile">
                {/* <Box width='100%' display="flex"> */}
                    <Box width='120px' className={classes.navLeft}>
                        <Box minHeight="90vh">
                            <Box justifyContent="center">
                            {
                                !history.location.pathname.includes('/galleries') &&
                                <DatePicker
                                    DialogProps={{
                                        style:{
                                            zIndex: 99999999
                                        }
                                    }}
                                    className={classes.formControl}
                                    views={["year"]}
                                    label=""
                                    size="small"
                                    // minDate={new Date("2019-01-01")}
                                    // maxDate={new Date("2025-01-01")}
                                    minDate={new Date("2022-01-01")}
                                    maxDate={new Date("2022-12-01")}
                                    inputVariant="outlined"
                                    value={selectedDate}
                                    onChange={date => handleDateChangeFunc(date)}
                                    format="yyyy"
                                    InputProps={{
                                        style:{
                                            height: "34px",
                                            paddingRight: 5,
                                            cursor: 'pointer'
                                        },
                                        endAdornment: (
                                            <InputAdornment position="end" >
                                                <ArrowDropDownIcon style={{ color: '#C4C4C4' }} />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            }
                            </Box>
                            <Box paddingTop="2em">
                                {(history.location.pathname).includes(`/vito/report/weekly`) ? <LeftButtonActive>Weekly</LeftButtonActive> : <LeftButton onClick={() => setCategory('weekly')}>Weekly</LeftButton>}
                                {(history.location.pathname).includes(`/vito/report/monthly`) ? <LeftButtonActive >Monthly</LeftButtonActive> : <LeftButton onClick={() => setCategory('monthly')}>Monthly</LeftButton>}
                                {/* {(history.location.pathname).includes(`/vito/report/galleries`) ? <LeftButtonActive>Galleries</LeftButtonActive> : <LeftButton onClick={() => setCategory('galleries')}>Galleries</LeftButton>} */}
                            </Box>
                        </Box>

                    </Box>
                    {/* <Box width='85%' className={classes.rigth}> */}
                        <Box className="box-WeeklyUptd">
                            <Switch>
                                <Route path={`${path}/weekly/:year`} component={Weekly} />
                                <Route path={`${path}/monthly/:year`} component={Monthly} />
                                <Route path={`${path}/galleries/add`} component={AddGalleries} />
                                <Route path={`${path}/galleries/edit`} component={EditGalleries} />
                                <Route path={`${path}/galleries/:year`} component={Galleries} />
                            </Switch>
                        </Box>

                    {/* </Box> */}

                {/* </Box> */}
            </div>

        </Fragment>
    )
}