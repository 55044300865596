import React, { useState, useEffect } from 'react'
import './styles.scss'
import Maps from '../../../component/Maps'
import ReactTooltip from "react-tooltip";
import Chart from 'chart.js';
// COMPONENT
import { ClickAwayListener, Typography, TextField, withStyles, makeStyles, InputAdornment, Table, TableHead, TableRow, TableBody, TableCell, Collapse, Button } from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { SERVER } from '../../../../../server'
import DetailReportWeekly from '../../../component/Report/Modal/DetailReportWeekly/indexIndicator';

const useStyles = makeStyles((theme) => ({
    buttonLogin: {
        height: '34px',
        border: '1px solid #E23F81',
        boxSizing: 'border-box',
        background: 'white',
        borderRadius: '4px',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '13px',
        lineHeight: '15px',
        color: '#E23F81',
        boxShadow: 'none',
        marginRight: 30,
        '&:hover': {
            background: 'white',
            color: '#E23F81',
            boxShadow: 'none'
        },
    },
    formControl: {
        marginTop: 20,
        marginLeft: 20,
    },
    formControl1: {
        marginTop: 10,
    }
}));

const useStylesBtn = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    dropdown: {
        justifyContent: 'center',
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        width: '130px',
        position: 'absolute',
        top: 28,
        right: 0,
        left: -60,
        zIndex: 1,
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        boxShadow: '6px 5px 33px -14px rgba(0,0,0,0.75)'
    },
    label: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "16px",
        color: "#999999",
    },
    box: {
        borderBottom: '0.5px solid #DDDDDD',
        width: '100%',
        height: 35,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: 15,
        paddingRight: 15,
        color: '#999999',
        cursor: 'pointer',
        borderRadius: 4,
        '&:hover': {
            color: 'white',
            backgroundColor: '#E23F81'
        }
    },
    boxLast: {
        width: '100%',
        height: 35,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: 15,
        paddingRight: 15,
        color: '#999999',
        borderRadius: 4,
        cursor: 'pointer',
        '&:hover': {
            color: 'white',
            backgroundColor: '#E23F81'
        }
    }
}));

function ClickAway(props) {
    let { data, weekly } = props
    const classes = useStylesBtn();
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={classes.root}>
                <div className="rqs-dta-btn-option" style={{ backgroundColor: '#FFFFFF' }} onClick={handleClick} >
                    <MoreHorizIcon />
                </div>
                {open ? (
                    <div className={classes.dropdown}>
                        <div className={classes.box} >
                            <DetailReportWeekly
                                data={data}
                                weeklyList={weekly}
                            />
                        </div>
                    </div>
                ) : null}
            </div>
        </ClickAwayListener>
    );
}

export default function PIN(props) {
    const classes = useStyles()
    const vitos = useSelector(state=> state.vitoState.vitos)
    const [user, setUser] = useState(null);
    const [reportNow, setReportNow] = useState(null);
    const workplan = useSelector(state=> state.dashboardState.workplan)
    const report = useSelector(state=> state.dashboardState.report)
    const monthly = useSelector(state=> state.dashboardState.monthly)
    const monthlyDate = useSelector(state=> state.dashboardState.monthlyDate)
    const weeklyDate = useSelector(state=> state.dashboardState.weeklyDate)

    function showDate(dateParams) {
        let date = new Date(dateParams).getDate()
        let monthNumber = new Date(dateParams).getMonth()
        let month = ''
        let year = new Date(dateParams).getFullYear()
        switch (monthNumber) {
            case 0:
                month = 'January'
                break;
            case 1:
                month = 'February'
                break;
            case 2:
                month = 'March'
                break;
            case 3:
                month = 'April'
                break;
            case 4:
                month = 'May'
                break;
            case 5:
                month = 'June'
                break;
            case 6:
                month = 'July'
                break;
            case 7:
                month = 'August'
                break;
            case 8:
                month = 'September'
                break;
            case 9:
                month = 'October'
                break;
            case 10:
                month = 'November'
                break;
            case 11:
                month = 'December'
                break;
            default:
                month = '-'
                break;
        }
        return date + ' ' + month + ' ' + year 
    }

    function showHour(hourParams) {
        let hour = new Date(hourParams).getHours()
        let minutes = new Date(hourParams).getMinutes()
        return (hour > 9 ? hour : "0" + hour) + ":" + (minutes > 9 ? minutes : "0" + minutes)
    }

    function showMonth(dateParams) {
        let month = ''
        switch (dateParams) {
            case 0:
                month = 'January'
                break;
            case 1:
                month = 'February'
                break;
            case 2:
                month = 'March'
                break;
            case 3:
                month = 'April'
                break;
            case 4:
                month = 'May'
                break;
            case 5:
                month = 'June'
                break;
            case 6:
                month = 'July'
                break;
            case 7:
                month = 'August'
                break;
            case 8:
                month = 'September'
                break;
            case 9:
                month = 'October'
                break;
            case 10:
                month = 'November'
                break;
            case 11:
                month = 'December'
                break;
            default:
                month = '-'
                break;
        }
        return month
    }

    const modalUp = (geo) => {
        if (geo) {
            let vit = []
            vitos.map(vito=>{
                if ((vito.country).toLowerCase() === (geo).toLowerCase()) {
                    if ((vito.area && vito.area !== 'tidak ada area')) {
                        vit.push({name:`${vito.name} - ${vito.country}, ${vito.area}`, id: vito.id})
                    } else {
                        vit.push({name:`${vito.name} - ${vito.country}`, id: vito.id})
                    }
                }
            })
            Swal.fire({
                title: `<strong>${ geo }</strong>`,
                html: `
                <div class="modal-name" >
                    ${
                        vit.length !== 0 ?
                        vit.map((data, i)=>(
                            `<span class="txt-roll" key={i} > ${ data.name } </span>`
                        ))
                        :
                        `<span class="txt-roll" key={i} > VITO Not Found </span>`
                    }
                </div>`,
                showCloseButton: true,
                showCancelButton: false,
                showConfirmButton: false,
                focusConfirm: false,
            })
        }
    }

    const setUserCountry = (content) => {
        if (vitos, content) {
            let vit = []
            vit.push(content)
            vitos.map(vito=>{
                if ((vito.country).toLowerCase() === (content).toLowerCase()) {
                    if (vito.area) {
                        vit.push(`${vito.name} - ${vito.area}`)
                    } else {
                        vit.push(`${vito.name}`)
                    }
                }
            })
            setUser(vit);
        } else {
            setUser(null)
        }
    }

    const getWeek = (id) => {
        let find = '-'
        if (weeklyDate) {
            weeklyDate.map(data=>{
                if (data.id == id) {
                    find = data.week
                }
            })
        }
        return find
    }

    const monthString = (mon) => {
        switch (mon) {
            case 0:
                return 'January'
            case 1:
                return 'February'
            case 2:
                return 'March'
            case 3:
                return 'April'
            case 4:
                return 'May'
            case 5:
                return 'June'
            case 6:
                return 'July'
            case 7:
                return 'August'
            case 8:
                return 'September'
            case 9:
                return 'October'
            case 10:
                return 'November'
            case 11:
                return 'December'
            default:
                return '-'
        }
    }

    const showDateFix = (id) => {
        let find = '-'
        if (weeklyDate) {
            weeklyDate.map(data=>{
                if (data.id == id) {
                    find = data
                }
            })
        }

        if (find) {
            if (find.start && find.end) {
                const [day, month, year] = find.start.split("/")
                let dateStart = new Date(year, month - 1, day).getDate() 
                let monthStart = new Date(year, month - 1, day).getMonth()
                let yearStart = new Date(year, month - 1, day).getFullYear()
                let monthStartResult = monthString(monthStart)
                const [day1, month1, year1] = find.end.split("/")
                let dateEnd = new Date(year1, month1 - 1, day1).getDate() 
                let monthEnd = new Date(year1, month1 - 1, day1).getMonth()
                let yearEnd = new Date(year1, month1 - 1, day1).getFullYear()
                let monthEndResult = monthString(monthEnd)
                if (monthStart === monthEnd) {
                    return `(${dateStart} - ${dateEnd} ${monthStartResult})`
                } else {
                    return `(${dateStart} ${monthStartResult} - ${dateEnd} ${monthEndResult})`
                }
            }
        } else {
            return '-'
        }
    }

    const showSubmitDate = (dateParams) => {
        if (dateParams) {
            let start = new Date(dateParams).getDate()
            let startMonth = new Date(dateParams).getMonth()
            let monthStart = ''
            let yearResult = new Date(dateParams).getFullYear()
            switch (startMonth) {
                case 0 :
                    monthStart = '01'
                    break;
                case 1 :
                    monthStart = '02'
                    break;
                case 2 :
                    monthStart = '03'
                    break;
                case 3 :
                    monthStart = '04'
                    break;
                case 4 :
                    monthStart = '05'
                    break;
                case 5 :
                    monthStart = '06'
                    break;
                case 6 :
                    monthStart = '07'
                    break;
                case 7 :
                    monthStart = '08'
                    break;
                case 8 :
                    monthStart = '09'
                    break;
                case 9 :
                    monthStart = '10'
                    break;
                case 10 :
                    monthStart = '11'
                    break;
                case 11 :
                    monthStart = '12'
                    break;
                default:
                    monthStart = ''
                    break;
            }
            let hour = new Date(dateParams).getHours()
            let minute = new Date(dateParams).getMinutes()
            let second = new Date(dateParams).getSeconds()
            
            return `${yearResult}-${monthStart}-${start < 10 ? '0'+start : start}`
            // +' '+ (hour < 10 ? '0'+hour : hour) +':'+(minute < 10 ? '0'+minute : minute)+':'+(second < 10 ? '0'+second : second)
        }
    }

    const showSubmitTime = (dateParams) => {
        if (dateParams) {
            let hour = new Date(dateParams).getHours()
            let minute = new Date(dateParams).getMinutes()
            return `${hour < 10 ? '0'+hour : hour}:${minute < 10 ? '0'+minute : minute}`
        }
    }

    const checkIndicator = (time, deadline, status) => {
        let result = 'On Time'
        let find

        if (monthlyDate) {
            monthlyDate.map(data=>{
                if (data.id == deadline) {
                    find = data
                }
            })
        }

        if (find) {
            let date = new Date(time)
            const [day, month, year] = find.dates.end.split("/")
            let deadlineDate = new Date(year, month - 1, day)
            deadlineDate.setDate(deadlineDate.getDate() + 1);

            if (date <= deadlineDate) {
                result = 'On Time'
            } else {
                let timeDiff = Math.abs(deadlineDate.getTime() - date.getTime());
                let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)); 
                console.log(diffDays);
                if (diffDays <= 7) {
                    result = 'Late One Week'
                } else if (diffDays <= 14) {
                    result = 'Late Two Weeks'
                } else if (diffDays > 14) {
                    result = 'Late More Than Two Weeks'
                }
            }
        }

        if (status === result) {
            return true
        } else {
            return false
        }
    }

    const checkData = (data, mon) => {
        let Arr = []
        if (data) {
            let dat = data.filter(a=>a.MonthId == mon)
            if (dat.length !== 0) {
                let dat2 = dat[dat.length-1]
                Arr = [dat2]
            }
        }
        return Arr
    }

    useEffect(()=>{
        if (report) {
            let bank = []
            report.map(rep=>{
                let receptionist = bank.filter(j=>j.WeekId === rep.WeekId)[0]
                if (receptionist) {
                    let document = { category1 : {}, category2 : {}, category3: {} }
                    let pengajuan = false
                    if (rep.CategoryId == 1) {
                        receptionist.brankas.map((data, i)=>{
                            if (data.category1.id) {
                                pengajuan = true
                                document.category1 = rep
                                return
                            } else {
                                receptionist.brankas[i].category1 = rep
                                return
                            }
                        })
                    } else if (rep.CategoryId == 2) {
                        receptionist.brankas.map((data, i)=>{
                            if (data.category2.id) {
                                pengajuan = true
                                document.category2 = rep
                                return
                            } else {
                                receptionist.brankas[i].category2 = rep
                                return
                            }
                        })
                    } else if (rep.CategoryId == 3) {
                        receptionist.brankas.map((data, i)=>{
                            if (data.category3.id) {
                                pengajuan = true
                                document.category3 = rep
                                return
                            } else {
                                receptionist.brankas[i].category3 = rep
                                return
                            }
                        })
                    }
                    if (pengajuan) {
                        receptionist.span +=1
                        receptionist.brankas.unshift(document)
                    }
                } else {
                    let brankas = []
                    let document = { category1 : {}, category2 : {}, category3: {} }
                    if (rep.CategoryId == 1) {
                        document.category1 = rep
                    } else if (rep.CategoryId == 2) {
                        document.category2 = rep
                    } else if (rep.CategoryId == 3) {
                        document.category3 = rep
                    }
                    brankas.push(document)
                    bank.push({ WeekId: rep.WeekId, span : 2, brankas })
                }
            })
            // console.log(bank,'runnnnn');
            setReportNow(bank)
        }
    },[report])

    return (
        <div className="dashboard" >
            <span className="breadcrumb-txt-black" >
                Dashboard
            </span>
            <span className="breadcrumb-slash-black" >
                /
            </span>
            <div className="dashboard-container" >
                <div className="dashboard-maps-container"
                    style={{
                        paddingBottom: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        marginTop: 15,
                    }}
                >
                    <div className="header-dashboard-box"
                        style={{
                            paddingLeft: 15,
                        }}
                    >
                        <span className="header-dashboard-text">
                            VITO Users Location
                        </span>
                    </div>
                    <div className="mainbox-containe" style={{ paddingTop: 10, height: 510 }} >
                        <Maps modalUp={modalUp} setUserCountry={setUserCountry}/>
                        <ReactTooltip>
                        {
                            user &&
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >
                                {
                                    user && 
                                    user.map((use, i)=>(
                                        <span key={i}> { use } </span>
                                    ))
                                }
                            </div>
                        }
                        </ReactTooltip>
                    </div>
                </div>
                <div className="dashboard-maps-container"
                    style={{
                        marginTop: 15
                    }}
                >
                    <div className="header-dashboard-box">
                        <span className="header-dashboard-text">
                        Work Plan Summary Report  
                        </span>
                    </div>
                    {/* <div className="mainbox-containe" >
                        <div className="filter-contain-dashboard" >
                            <input type="text" className="filter-dashboard-tab" placeholder="Search here.." style={{ color: '#999999' }} />
                            <select className="filter-status-dashboard">
                                <option value="" selected >Filter Status</option>
                                <option value="On Time">On Time</option>
                                <option value="Late One Week">Late One Week</option>
                                <option value="Late Two Week">Late Two Week</option>
                                <option value="Late More Two Week">Late More Two Week</option>
                            </select>
                        </div>
                    </div> */}
                    <div className="fixed_header-div" style={{ height: 490 }}>
                        <table className="fixed_headers">
                            <thead>
                                <tr>
                                    <th align="left" style={{ width: 50, paddingLeft: 20 }} >NO</th>
                                    <th align="left" style={{ width: 100 }}>WEEK</th>
                                    <th align="left" style={{ width: 100 }}>SUBMIT DATE</th>
                                    <th align="left">WORK PLAN</th>
                                    <th align="left" style={{ width: 100 }}>ACTION</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    workplan &&
                                    workplan.map((data, i) => (
                                        <tr key={i} style={{ backgroundColor: i % 2 === 0 ? '#FAFAFA' : '#FFFFFF' }} >
                                            <td align="left" style={{ paddingLeft: 20 }} >{i += 1}</td>
                                            <td align="left" >
                                                <div className="table-dashboard-column" >
                                                    <span>
                                                        { data.WeekId && getWeek(data.WeekId) }
                                                    </span>
                                                    <span className="grey-table-txt" >
                                                        { data.WeekId && showDateFix(data.WeekId) }
                                                    </span>
                                                </div>
                                            </td>
                                            <td align="left">
                                                <div className="table-dashboard-column" >
                                                    <span>
                                                        {data.createdAt && showSubmitDate(data.createdAt)}
                                                    </span>
                                                    <span className="grey-table-txt" >
                                                        {data.createdAt && showSubmitTime(data.createdAt)}
                                                    </span>
                                                </div>
                                            </td>
                                            <td align="left">
                                                <div className="table-dashboard-column" >
                                                    <span>
                                                        { data && data.description ? data.description : '-' }
                                                    </span>
                                                </div>
                                            </td>
                                            <td align="left">
                                                <ClickAway id={data.name} data={data} weekly={weeklyDate}/>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="dashboard-container" style={{ marginBottom: 20 }} >
                <div className="dashboard-maps-container"
                    style={{
                        marginTop: 15
                    }}
                >
                    <div className="header-dashboard-box">
                        <span className="header-dashboard-text">
                            Weekly Summary Report { showMonth(new Date().getMonth()) }
                        </span>
                    </div>
                    <div className="fixed_header-div" style={{ height: 490 }}>
                        <table className="fixed_headers" >
                            <thead>
                                <tr>
                                    <th align="left" style={{ width: 50, paddingLeft: 20, backgroundColor: '#2C304D', color: '#EAEAEA' }} >NO</th>
                                    <th align="left" style={{ width: 100, backgroundColor: '#2C304D', color: '#EAEAEA' }}>WEEK PERIOD</th>
                                    <th align="left" style={{ width: 150, backgroundColor: '#2C304D', color: '#EAEAEA' }}>NETWORKING & PARTNERSHIP</th>
                                    <th align="left" style={{ width: 150, backgroundColor: '#2C304D', color: '#EAEAEA' }}>MARKET INTELLIGENCE</th>
                                    {/* <th align="left" style={{ width: 150, backgroundColor: '#2C304D', color: '#EAEAEA' }}>PROMOTIONAL SUPPORT</th> */}
                                </tr>
                            </thead>
                            {
                                reportNow &&
                                reportNow
                                .map((data, i) => (
                                    <tbody>
                                        <tr key={i} style={{ backgroundColor: i % 2 === 0 ? '#FAFAFA' : '#FFFFFF' }}>
                                            <td align="left" style={{ paddingLeft: 20 }} rowSpan={data.span}>{i += 1}</td>
                                            <td align="left" rowSpan={data.span}>
                                                <div className="table-dashboard-column">
                                                    <span>
                                                        Week { data.WeekId && getWeek(data.WeekId) }
                                                    </span>
                                                    <span className="grey-table-txt" >
                                                        { data.WeekId && showDateFix(data.WeekId) }
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                        {
                                            data && data.brankas &&
                                            data.brankas
                                            .map((document, index)=>(
                                            <tr key={index} style={{ backgroundColor: i % 2 !== 0 ? '#FAFAFA' : '#FFFFFF' }} >
                                                <td align="left">
                                                    <div className="table-dashboard-column" >
                                                        <span>
                                                            { document && document.category1 && document.category1.createdAt && showDate(document.category1.createdAt) }
                                                        </span>
                                                        <span className="grey-table-txt" style={{ fontWeight: 'bold' }}  >
                                                            { document && document.category1 && document.category1.createdAt && showHour(document.category1.createdAt) }
                                                        </span>
                                                    </div>
                                                </td>
                                                <td align="left">
                                                    <div className="table-dashboard-column" >
                                                        <span>
                                                            { document && document.category2 && document.category2.createdAt && showDate(document.category2.createdAt) }
                                                        </span>
                                                        <span className="grey-table-txt" style={{ fontWeight: 'bold' }} >
                                                            { document && document.category2 && document.category2.createdAt && showHour(document.category2.createdAt) }
                                                        </span>
                                                    </div>
                                                </td>
                                                <td align="left">
                                                    <div className="table-dashboard-column" >
                                                        <span>
                                                            { document && document.category3 && document.category3.createdAt && showDate(document.category3.createdAt) }
                                                        </span>
                                                        <span className="grey-table-txt" style={{ fontWeight: 'bold' }}  >
                                                            { document && document.category3 && document.category3.createdAt && showHour(document.category3.createdAt) }
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                            ))
                                        }
                                    </tbody>
                                    ))
                                }
                        </table>
                    </div>
                </div>
                <div className="dashboard-maps-container"
                    style={{
                        marginTop: 15
                    }}
                >
                    <div className="header-dashboard-box">
                        <span className="header-dashboard-text">
                            Monthly Summary Report { new Date().getFullYear() }
                        </span>
                    </div>
                    <div className="fixed_header-div" style={{ height: 490 }}>
                        <table className="fixed_headers">
                            <thead>
                                <tr>
                                    <th align="left" style={{ width: 50, paddingLeft: 20, backgroundColor: '#E23F81', color: '#EAEAEA' }} >NO</th>
                                    <th align="left" style={{ backgroundColor: '#E23F81', color: '#EAEAEA' }}>MONTH</th>
                                    <th align="left" style={{ backgroundColor: '#E23F81', color: '#EAEAEA' }}>ON TIME</th>
                                    <th align="left" style={{ backgroundColor: '#E23F81', color: '#EAEAEA' }}>NOT SUBMITTED</th>
                                    <th align="left" style={{ backgroundColor: '#E23F81', color: '#EAEAEA' }}>LATE ONE WEEK</th>
                                    <th align="left" style={{ backgroundColor: '#E23F81', color: '#EAEAEA' }}>LATE TWO WEEKS</th>
                                    <th align="left" style={{ backgroundColor: '#E23F81', color: '#EAEAEA' }}>LATE MORE THAN TWO WEEKS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    monthlyDate &&
                                    monthlyDate.map((mon, i) => (
                                        <tr key={i} style={{ backgroundColor: i % 2 === 0 ? '#FAFAFA' : '#FFFFFF' }} >
                                            <td align="left" style={{ paddingLeft: 20 }} >{i += 1}</td>
                                            <td align="left" >
                                                <div className="table-dashboard-column" >
                                                    <span>
                                                        { mon && mon.month }
                                                    </span>
                                                </div>
                                            </td>
                                            <td align="left" key={i}>
                                                {
                                                    checkData(monthly, mon.id)
                                                    .map((data, i)=>(
                                                        checkIndicator(data && data.createdAt, data && data.MonthId, 'On Time') &&
                                                        <div key={i} className="table-dashboard-column" >
                                                            <span>
                                                                {data && showSubmitDate(data.createdAt)}
                                                            </span>
                                                            <span className="grey-table-txt" >
                                                                {data && showSubmitTime(data.createdAt)}
                                                            </span>
                                                        </div>
                                                    ))
                                                }
                                            </td>
                                            <td align="left" key={i}>
                                                {
                                                    checkData(monthly, mon.id).length == 0 &&
                                                    <div className="table-dashboard-column" >
                                                        <span>
                                                            Not Submitted
                                                        </span>
                                                    </div>
                                                }
                                            </td>
                                            <td align="left" key={i}>
                                                {
                                                    checkData(monthly, mon.id)
                                                    .map((data, i)=>(
                                                        checkIndicator(data && data.createdAt, data &&data.MonthId, 'Late One Week') &&
                                                        <div key={i} className="table-dashboard-column" >
                                                            <span>
                                                                {data && showSubmitDate(data.createdAt)}
                                                            </span>
                                                            <span className="grey-table-txt" >
                                                                {data && showSubmitTime(data.createdAt)}
                                                            </span>
                                                        </div>
                                                    ))
                                                }
                                            </td>
                                            <td align="left" key={i}>
                                                {
                                                    checkData(monthly, mon.id)
                                                    .map((data, i)=>(
                                                        checkIndicator(data && data.createdAt, data &&data.MonthId, 'Late Two Weeks') &&
                                                        <div key={i} className="table-dashboard-column" >
                                                            <span>
                                                                {data && showSubmitDate(data.createdAt)}
                                                            </span>
                                                            <span className="grey-table-txt" >
                                                                {data && showSubmitTime(data.createdAt)}
                                                            </span>
                                                        </div>
                                                    ))
                                                }
                                            </td>
                                            <td align="left" key={i}>
                                                {
                                                    checkData(monthly, mon.id)
                                                    .map((data, i)=>(
                                                        checkIndicator(data && data.createdAt, data &&data.MonthId, 'Late More Than Two Weeks') &&
                                                        <div key={i} className="table-dashboard-column" >
                                                            <span>
                                                                {data && showSubmitDate(data.createdAt)}
                                                            </span>
                                                            <span className="grey-table-txt" >
                                                                {data && showSubmitTime(data.createdAt)}
                                                            </span>
                                                        </div>
                                                    ))
                                                }
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}