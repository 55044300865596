export const GET_USER = 'GET_USER'
export const GET_VITO = 'GET_VITO'
export const GET_EVALUATION = 'GET_EVALUATION'
export const GET_EVALUATIONONE = 'GET_EVALUATIONONE'
export const IS_LOGIN = 'IS_LOGIN'
export const IS_LOGOUT = 'IS_LOGOUT'
export const GET_ONEVITO = 'GET_ONEVITO'
export const GET_WEEKLY = 'GET_WEEKLY'
export const GET_MONTHLY = 'GET_MONTHLY'
export const GET_WEEKWORKPLAN = 'GET_WEEKWORKPLAN'
export const GET_MONTHGALERRY = 'GET_MONTHGALERRY'
export const GET_REPORTWEEKLYINDICATOR = 'GET_REPORTWEEKLYINDICATOR'
export const GET_WEEKLYINDI = 'GET_WEEKLYINDI'
export const GET_REPORTMONTHLYINDICATOR = 'GET_REPORTMONTHLYINDICATOR'
export const GET_MONTHLYINDI = 'GET_MONTHLYINDI'
export const GET_GALLERYINDICATOR = 'GET_GALLERYINDICATOR'
export const GET_DASHBOARDWORKPLAN = 'GET_DASHBOARDWORKPLAN'
export const GET_DASHBOARDREPORT = 'GET_DASHBOARDREPORT'
export const GET_DASHBOARDMONTHLY = 'GET_DASHBOARDMONTHLY'
export const GET_MONTHLYDASH = 'GET_MONTHLYDASH'
export const GET_WEEKLYDASH = 'GET_WEEKLYDASH'
export const GET_EVALUATIONVITOONE = 'GET_EVALUATIONVITOONE'
export const GET_REQUESTDATA = 'GET_REQUESTDATA'
export const GET_COORDINATORDATA = 'GET_COORDINATORDATA'
export const GET_COORDINATORDATAWEEKLY = 'GET_COORDINATORDATAWEEKLY'
export const GET_COORDINATORDATAQUARTERLY = 'GET_COORDINATORDATAQUARTERLY'
export const GET_COORDINATORDATAOUTLOOK = 'GET_COORDINATORDATAOUTLOOK'
export const GET_REPORTMONTHLYDASHBOARD = 'GET_REPORTMONTHLYDASHBOARD'
export const GET_REPORTWEEKLYDASHBOARD = 'GET_REPORTWEEKLYDASHBOARD'
export const GET_MONTHLYDASHBOARD = 'GET_MONTHLYDASHBOARD'
export const GET_WEEKLYDASHBOARD = 'GET_WEEKLYDASHBOARD'
export const GET_REPORTMONTHLYCOUNTDASHBOARD = 'GET_REPORTMONTHLYCOUNTDASHBOARD'
export const GET_REPORTWEEKLYCOUNTDASHBOARD = 'GET_REPORTWEEKLYCOUNTDASHBOARD'
export const GET_BAGANMONTHDASH = 'GET_BAGANMONTHDASH'
export const GET_WEEKLYCHARTDASH = 'GET_WEEKLYCHARTDASH'
export const GET_BAGANWEEKDASH = 'GET_BAGANWEEKDASH'
export const GET_NOTIFICATION = 'GET_NOTIFICATION'
export const GET_NOTIFICATIONCOUNT = 'GET_NOTIFICATIONCOUNT'
export const GET_NOTIFICATIONRESTART = 'GET_NOTIFICATIONRESTART'
export const GET_NOTIFICATIONVITOCOUNT = 'GET_NOTIFICATIONVITOCOUNT'
export const GET_NOTIFICATIONPICCOUNT = 'GET_NOTIFICATIONPICCOUNT'
export const GET_NOTIFICATIONPOCOUNT = 'GET_NOTIFICATIONPOCOUNT'
export const GET_TABULASI_DASHBOARD = 'GET_TABULASI_DASHBOARD'
export const GET_PARTNER = 'GET_PARTNER'
export const GET_PARTNER_FILTER = 'GET_PARTNER_FILTER'
export const GET_COUNTRY = 'GET_COUNTRY'