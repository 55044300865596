import axios from 'axios'
import Swal from 'sweetalert2'
import { SERVER } from '../../server'
import {
    IS_LOGOUT,
    GET_PARTNER,
    GET_PARTNER_FILTER
} from "../constanta";

export const getPartner = () => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            const { data } = await axios({
                method: 'GET',
                url: `${SERVER}/partner`,
                headers: { token }
            })
            dispatch({
                type: GET_PARTNER,
                data: data
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const deletePartner = (id) => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            Swal.showLoading()
            await axios({
                method: 'DELETE',
                url: `${SERVER}/partner/delete/${id}`,
                headers: { token }
            })
            Swal.close()
            await dispatch(getPartner())
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const getPartnerFilter = (by) => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            const { data } = await axios({
                method: 'GET',
                url: `${SERVER}/partner/filtered?by=${by}`,
                headers: { token }
            })
            dispatch({
                type: GET_PARTNER_FILTER,
                data: data
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}