import React, { useEffect, useState, Fragment } from 'react'
import { Box, Grid, makeStyles, FormControl, InputLabel, Select, MenuItem, withStyles, Button, IconButton } from '@material-ui/core'
import Typography from '@material-ui/core/Typography';
import DocumentIcon from '../../../../../images/document.svg'
import NoDocumentIcon from '../../../../../images/no-document.svg'
import PencilIcon from '../../../../../images/pencil.svg'
import TrashIcon from '../../../../../images/trash.svg'
import Swal from "sweetalert2";
import { useSelector, useDispatch } from 'react-redux'
import { getCoordinatorDataQuarterly } from '../../../../../store/actions/coordinatorDataAction'
import Axios from 'axios';
import { SERVER } from '../../../../../server'
import socketClient from "socket.io-client"
import GetAppIcon from '@material-ui/icons/GetApp'

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        width: '100%',
        height: '100px',
        background: "white",
        padding: "1em 0em",
        boxShadow: "none"
    },
    title: {
        fontWeight: "500",
        lineHeight: "16px",
        color: "#2C304D"
    },
    file: {
        width: "19vw",
        border: "1px solid #DDDDDD",
        boxSizing: "border-box",
        borderRadius: "8px",
        marginRight: "1em",
        marginBottom: "1em",
    },
    upload: {
        width: "286px",
        height: '280px',
        border: "2px dashed #E23F81",
        boxSizing: "border-box",
        borderRadius: "8px",
        margin: "20px",
        backgroundColor: '#F9F9F9'
    },
    boxFiles: {
        marginTop: "20px",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: 'space-between'
    },
    boxFiles2: {
        marginTop: "5px",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: 'space-between'
    },
    buttonRead: {
        color: "#E23F81",
        background: "rgba(226, 63, 129, 0.07)",
        borderRadius: "2px",
        width: "96px",
        height: '34px',
        border: "none",
        textTransform: "none",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "13px",
        lineHeight: "19px",
        color: "#E23F81",
    },
    buttonWhite: {
        color: "#E23F81",
        borderColor: "#E23F81",
        borderRadius: "4px",
        textTransform: "none",
        padding: "0.5em 3em",
    },
    buttonUpload: {
        background: "#E23F81",
        borderRadius: "4px",
        textTransform: "none",
        padding: "0.5em 3em",
        marginRight: "1em"
    },
    boxLabel: {
        background: "#F3FFF3",
        border: "1px solid #DDDDDD",
        borderRadius: "0px 8px 0px 8px",
        width: "115px",
        height: "30px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    buttonOK: {
        background: "#E23F81",
        padding: "0.5em 3em",
    },
    boxCircle: {
        borderRadius: "50%",
        height: "10.5em",
        width: "10.5em",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(226, 63, 129, 0.2)"
    },
    tabWeek:{
        textTransform: "capitalize",
        fontSize: "16px",
    },
    tabs: {
        width: 'calc( 100vw - 390px)',
        "& button[aria-selected='true']": {
            borderBottom: "3px solid #E23F81",
            borderRadius: '2px 2px 0px 0px',
        }
    }
})

export default function PIN(props) {
    const classes = useStyles();
    const Dispatch = useDispatch()
    // const [tahun, setTahun] = useState(2021);
    const [tahun, setTahun] = useState(new Date().getFullYear());
    const [tabValue, setTabValue] = useState(0);
    const filterYears = useSelector(state=> state.filterState.coordinatorQuarterly)
    const fileME = useSelector(state=> state.coordinatorDataState.filesQuarterly)
    const roleProfile = useSelector(state=> state.profileState.role)
    const socket = socketClient(SERVER, {transports: ['websocket'], secure: true});

    useEffect(()=>{
        Dispatch(getCoordinatorDataQuarterly(tahun))
        socket.on('coordinator-data-quarterly', (data) => {
            if (data.year == tahun) {
                Dispatch(getCoordinatorDataQuarterly(tahun))
            }
        })
    },[filterYears])

    const sendFile = (file, month, year) => {
        let data = new FormData()
        data.append('file',file)
        data.set('MonthId', month)
        data.set('YearId', year)
        Swal.showLoading()
        Axios({
            method: 'POST',
            url: `${SERVER}/coordinator-data/quarterly`,
            data: data,
            headers: {
                token : localStorage.getItem('token'),
            }
        })
        .then(({data})=>{
            setTimeout(function() {
                succesUpload()
            }, 300)
            Dispatch(getCoordinatorDataQuarterly(tahun))
        })
        .catch(err=>{
            failedUpload('Mengirim')
        })
    }

    const changeFile = (file, id) => {
        let data = new FormData()
        data.append('file',file)
        Swal.showLoading()
        Axios({
            method: 'PUT',
            url: `${SERVER}/coordinator-data/quarterly/${id}`,
            data: data,
            headers: {
                token : localStorage.getItem('token'),
            }
        })
        .then(({data})=>{
            setTimeout(function() {
                succesUpload()
            }, 300)
            Dispatch(getCoordinatorDataQuarterly(tahun))
        })
        .catch(err=>{
            failedUpload('Mengirim')
        })
    }

    const deleteFile = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
          }).then((result) => {
            if (result.isConfirmed) {
                Axios({
                    method: 'DELETE',
                    url: `${SERVER}/coordinator-data/quarterly/${id}`,
                    headers: {
                        token : localStorage.getItem('token'),
                    }
                })
                .then(({data})=>{
                    setTimeout(function() {
                        deleteUpload()
                    }, 300)
                    Dispatch(getCoordinatorDataQuarterly(tahun))
                })
                .catch(err=>{
                    failedUpload('Menghapus')
                })
            }
          })
    }

    function pilihFile(files, month, year) {
        sendFile(files.target.files[0], month, year)
    }

    function gantiFile(file, id, month) {
        changeFile(file.target.files[0], id, month)
    }

    const downloadFile = async ({file}) => {
        fetch(file, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/pdf',
            },
        })
        .then((response) => response.blob())
        .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
            'download',
            `${file.split('-FILE-')[1]}`,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        });
    }

    const getDate = (dateParams) => {
        if (dateParams) {
            let start = new Date(dateParams).getDate()
            let startMonth = new Date(dateParams).getMonth()
            let monthStart = ''
            let yearResult = new Date(dateParams).getFullYear()
            switch (startMonth) {
                case 0 :
                    monthStart = '01'
                    break;
                case 1 :
                    monthStart = '02'
                    break;
                case 2 :
                    monthStart = '03'
                    break;
                case 3 :
                    monthStart = '04'
                    break;
                case 4 :
                    monthStart = '05'
                    break;
                case 5 :
                    monthStart = '06'
                    break;
                case 6 :
                    monthStart = '07'
                    break;
                case 7 :
                    monthStart = '08'
                    break;
                case 8 :
                    monthStart = '09'
                    break;
                case 9 :
                    monthStart = '10'
                    break;
                case 10 :
                    monthStart = '11'
                    break;
                case 11 :
                    monthStart = '12'
                    break;
                default:
                    monthStart = ''
                    break;
            }
            let hour = new Date(dateParams).getHours()
            let minute = new Date(dateParams).getMinutes()
            let second = new Date(dateParams).getSeconds()
            
            return yearResult+'-'+ monthStart +'-'+ start +' '+ (hour < 10 ? '0'+hour : hour) +':'+(minute < 10 ? '0'+minute : minute)+':'+(second < 10 ? '0'+second : second) + ' WIB'
        }
    }

    const deleteUpload = () => {
        const SwalCustom = Swal.mixin({
            customClass: {
                confirmButton: 'btn-swal-wrong'
            },
            buttonsStyling: false
        })

        SwalCustom.fire({
            html: `
            <div class="modal-forget-wrapper" >
                <div class="modal-forget-wrapper-2" >
                <i class="fas fa-check fa-times-custom"></i>
                </div>
                <span class="modal-forget-wrapper-txt-header" >
                    Berhasil Menghapus File
                </span>
            </div>
        `,
            confirmButtonColor: '#d33',
            confirmButtonColor: 'Ok',
            showCancelButton: false,
            showCloseButton: true,
            reverseButton: true
        }).then((result) => {
            if (result.value) {
            } else {
            }
        })
    }

    const succesUpload = () => {
        const SwalCustom = Swal.mixin({
            customClass: {
                confirmButton: 'btn-swal-wrong'
            },
            buttonsStyling: false
        })

        SwalCustom.fire({
            html: `
            <div class="modal-forget-wrapper" >
              <div class="modal-forget-wrapper-2" >
                <i class="fas fa-check fa-times-custom"></i>
              </div>
              <span class="modal-forget-wrapper-txt-header" >
              Berhasil Menyimpan File
              </span>
            </div>
          `,
            confirmButtonColor: '#d33',
            confirmButtonColor: 'Ok',
            showCancelButton: false,
            showCloseButton: true,
            reverseButton: true
        }).then((result) => {
            if (result.value) {
            } else {
            }
        })
    }

    const failedUpload = (message) => {
        const SwalCustom = Swal.mixin({
            customClass: {
                confirmButton: 'btn-swal-wrong'
            },
            buttonsStyling: false
        })

        SwalCustom.fire({
            html: `
            <div class="modal-forget-wrapper" >
                <div class="modal-forget-wrapper-2" >
                    <i class="fas fa-exclamation fa-times-custom"></i>
                </div>
                <span class="modal-forget-wrapper-txt-header" >
                    Gagal ${message} Data
                </span>
                <span class="modal-forget-wrapper-txt" >
                </span>
            </div>
        `,
            confirmButtonColor: '#d33',
            confirmButtonColor: 'Ok',
            showCancelButton: false,
            showCloseButton: true,
            reverseButton: true
        }).then((result) => {
            if (result.value) {
            } else {
            }
        })
    }

    const getYearMonth = (data) => {
        if (data) {
            let MonthId = data.slice(1 , data.length)
            let id = data.split('')[0]
            let year
            switch (id) {
                case '1':
                    year = 2019
                    break;
                case '2':
                    year = 2020
                    break;
                case '3':
                    year = 2021
                    break;
                case '4':
                    year = 2022
                    break;
                case '5':
                    year = 2023
                    break;
                case '6':
                    year = 2024
                    break;
                case '7':
                    year = 2025
                    break;
                default:
                    year = 2025
                    break;
            }
            return new Date(`${year}/${MonthId}`)
        }
    }

    const getAuthen = (files, id) => {
        if (files && id) {
            let flag = false
            files.forEach(file=>{
                if (file.MonthId == id) {
                    flag = file
                    return
                }
            })
            return flag
        }
    }

    const changeYear = (id) => {
        if (id) setTahun(id)
    }

    const FilterMonthTab = (data, year) => {
        let result = []
        if (data) { result = data.filter(a=> a.id == year)[0] }
        return result.month
    }

    const FilterMonthData = (data, year) => {
        let result = []
        if (data) { result = data.filter(a=> a.id == year)[0] }
        return result.month
    }

    return (
        <Fragment>
            <div className="dashboard" >
                <span className="breadcrumb-txt-active" >
                    Koordinator VITO
            </span>
                <span className="breadcrumb-slash-active" >
                    / 3 Bulanan
            </span>
            </div>
            <div className="vito-table">
                <Box padding="2em" borderBottom="0.5px solid #DDDDDD" style={{ backgroundColor: 'white' }} >
                    <Typography className={classes.title} variant="h6" >Report {tahun}</Typography>
                </Box>
                <Box padding="2em" style={{ backgroundColor: 'white' }}>
                    <Box width="12em" marginLeft="1em" >
                        <FormControl variant="outlined" fullWidth size="small" color="secondary">
                            <InputLabel id="demo-simple-select-outlined-label">Pilih Tahun</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={tahun}
                                label="Pilih Tahun"
                                onChange={e=>changeYear(e.target.value)}
                            >
                                {
                                    filterYears &&
                                    filterYears.map((a,i)=>(
                                        <MenuItem value={a.id} key={i} >{a.year}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Box>
                    <Box className={classes.boxFiles}>
                        <div style={{ display: 'flex', flexDirection: 'column' }} >
                            <div className="text-header-coordinator-weeks" style={{ textTransform: 'capitalize', marginLeft: '1em' }}>
                                Laporan 3 Bulanan
                            </div>
                            <Box className={classes.boxFiles2}>
                                {
                                    FilterMonthData(filterYears, tahun) &&
                                    FilterMonthData(filterYears, tahun)
                                    .map((data, index) => {
                                        return (
                                            <Box key={index} >
                                                {
                                                    getAuthen(fileME, data.id) ?
                                                    <Box key={index} className={classes.upload} position="relative" style={{ backgroundColor: 'white', border: '1px solid #DDDDDD' }} >
                                                        <Box display="flex" justifyContent="flex-end" >
                                                            <Box className={classes.boxLabel} >
                                                                <Typography style={{ color: "#20AC20", fontSize: 14, fontWeight: 500, textTransform: 'capitalize' }}>{ data ? data.month ? data.month : '-' : '-' }</Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            height: '83%',
                                                        }}>
                                                            <img src={DocumentIcon} alt="icon" style={{ width: 44, height: 61, marginTop: 28 }} />
                                                            <div className="text-coordinator-file" style={{ marginTop: 22, display: 'flex', alignItems: 'flex-start', width: '100%', padding: '0px 15px' }}>
                                                                { getAuthen(fileME, data.id) && getAuthen(fileME, data.id).file && getAuthen(fileME, data.id).file.split('-FILE-')[1] }
                                                            </div>
                                                            <div className="text-coordinator-date" style={{ marginTop: 5, display: 'flex', alignItems: 'flex-start', width: '100%', padding: '0px 15px' }}>
                                                                Dikirim: { getAuthen(fileME, data.id) && getAuthen(fileME, data.id).updatedAt && getDate(getAuthen(fileME, data.id).updatedAt) }
                                                            </div>
                                                            <div style={{ marginTop: 'auto', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', width: '100%', padding: '0px 15px' }} >
                                                                <a href={`${getAuthen(fileME, data.id) && getAuthen(fileME, data.id).file}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }} >
                                                                    <Button variant="outlined" size="small" className={classes.buttonRead} >
                                                                        {
                                                                            getAuthen(fileME, data.id) && getAuthen(fileME, data.id).file && getAuthen(fileME, data.id).file.split('-FILE-')[1].includes('.pdf') ?
                                                                            'Lihat' : 'Unduh'
                                                                        }
                                                                    </Button>
                                                                </a>
                                                                {
                                                                    (
                                                                        roleProfile !== 'picpasar' && 
                                                                        roleProfile !== 'markom' &&
                                                                        roleProfile !== 'deputi' &&
                                                                        roleProfile !== 'direktorat'
                                                                    )&&
                                                                    <div className="btn-wrpe-123" style={{ marginLeft: 'auto', cursor: 'pointer', }} >
                                                                        <div
                                                                            style={{
                                                                                height: 32,
                                                                                width: 32,
                                                                                backgroundColor: '#FAFAFA',
                                                                                borderRadius: '80%',
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'center',
                                                                                cursor: 'pointer',
                                                                                marginRight: 16
                                                                            }}
                                                                        >
                                                                            <img src={PencilIcon} alt="icon" style={{
                                                                                cursor: 'pointer',
                                                                            }} />
                                                                        </div>
                                                                        <input
                                                                            onChange={(e) => gantiFile(e, getAuthen(fileME, data.id).id)}
                                                                            className="contained-button-file-1231"
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                            }}
                                                                            type="file"
                                                                            accept=".pdf, .ppt, .pptx, .xls, .xlsx"
                                                                        />
                                                                    </div>
                                                                }
                                                                {
                                                                    (
                                                                        roleProfile !== 'picpasar' && 
                                                                        roleProfile !== 'markom' &&
                                                                        roleProfile !== 'deputi' &&
                                                                        roleProfile !== 'direktorat'
                                                                    )&&
                                                                    <div
                                                                        style={{
                                                                            height: 32,
                                                                            width: 32,
                                                                            backgroundColor: '#FAFAFA',
                                                                            borderRadius: '80%',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                            cursor: 'pointer',
                                                                            marginLeft: '10px'
                                                                        }}
                                                                        onClick={e=>deleteFile(getAuthen(fileME, data.id).id)}
                                                                    >
                                                                        <img src={TrashIcon} alt="icon"/>
                                                                    </div>
                                                                }

                                                                <div
                                                                    style={{
                                                                        height: 32,
                                                                        width: 32,
                                                                        backgroundColor: '#FAFAFA',
                                                                        borderRadius: '80%',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                        cursor: 'pointer',
                                                                        marginLeft: '10px'
                                                                    }}
                                                                    onClick={e=>downloadFile(getAuthen(fileME, data.id))}
                                                                >
                                                                    <GetAppIcon size="small" color="disabled" />
                                                                </div>
                                                            </div>
                                                        </Box>
                                                    </Box>
                                                    :
                                                    <Box key={index} className={classes.upload} position="relative" >
                                                        <Box display="flex" justifyContent="flex-end">
                                                            <Box className={classes.boxLabel} >
                                                                <Typography style={{ color: "#20AC20", fontSize: 14, fontWeight: 500, textTransform: 'capitalize' }}>{ data ? data.month ? data.month : '-' : '-' }</Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            height: '83%',
                                                        }}>
                                                            <img src={NoDocumentIcon} alt="icon" style={{ width: 44, height: 61 }} />
                                                            <span className="text-coordinator-month" style={{ marginTop: 22 }}>
                                                                {
                                                                    (
                                                                        roleProfile !== 'picpasar' && 
                                                                        roleProfile !== 'markom' &&
                                                                        roleProfile !== 'deputi' &&
                                                                        roleProfile !== 'direktorat'
                                                                    )&&
                                                                    `Unggah `
                                                                }
                                                                Laporan
                                                            </span>
                                                            {
                                                                (
                                                                    roleProfile !== 'picpasar' && 
                                                                    roleProfile !== 'markom' &&
                                                                    roleProfile !== 'deputi' &&
                                                                    roleProfile !== 'direktorat'
                                                                )&&
                                                                <div className="btn-wrpe-123" style={{ marginTop: 22 }}>
                                                                    <Button variant="outlined" size="small" className="contained-button-file"
                                                                        style={{
                                                                            color: "#E23F81",
                                                                            borderColor: "#E23F81",
                                                                            borderRadius: "4px",
                                                                            textTransform: "none",
                                                                            padding: "0.5em 3em",
                                                                        }}
                                                                        component="span"
                                                                    >
                                                                        Pilih File
                                                                    </Button>
                                                                    <input
                                                                        onChange={(e) => pilihFile(e, data.id, tahun)}
                                                                        className="contained-button-file-1231"
                                                                        type="file"
                                                                        accept=".pdf, .ppt, .pptx, .xls, .xlsx"
                                                                    />
                                                                </div>
                                                            }
                                                        </Box>
                                                    </Box>
                                                }
                                            </Box>
                                        )
                                    })
                                }
                            </Box>
                        </div>
                    </Box>
                </Box>
            </div>

        </Fragment>
    )
}