import axios from 'axios'
import { SERVER } from '../../server'
import {
    IS_LOGOUT,
    GET_WEEKWORKPLAN,
} from "../constanta";

export const getWeekly = ( year ) => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            const { data } = await axios({
                method: 'GET',
                url: `${SERVER}/database/${year}`,
                headers: { token }
            })
            dispatch({
                type: GET_WEEKWORKPLAN,
                data : data
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}