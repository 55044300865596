import React,{ useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import Images from './Images'
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Box minHeight="3em">{children}</Box>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const useStyles = makeStyles({
    buttonView: {
        color: "white",
        background: "#E23F81",
        borderRadius: "20px",
        width: "100px",
        textTransform: "none",
        boxShadow: "none"
    },
})

export default function CustomizedDialogs(props) {
    const { photosAll } = props
    const [open, setOpen] = useState(false);
    const classes = useStyles()

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems:'center' }} >
            <ZoomOutMapIcon size="small" color="disabled" onClick={handleClickOpen}/>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" maxWidth="lg" open={open} paper={classes.borderDialog}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                </DialogTitle>
                 <Images photosAll={photosAll}/>
            </Dialog>
        </div>
    );
}