import React, { useState, useEffect } from 'react'
import { Box, Typography, FormControl, ClickAwayListener, Select, MenuItem, Menu, Button, Input } from '@material-ui/core'
import BoxCard from '../../../component/Workplan/BoxCard'
import SelectYearMonth from '../../../component/SelectYearMonth'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getWeekly } from '../../../../../store/actions/workplanAction'
import { useSelector, useDispatch } from 'react-redux'

const options = {
    // marketing: ["Partnership with Airline", "Partnership with Online Travel Agent (OTA) or Travel Agent/Tour Operator", "Partnership with Association", "Partnership with Related Travel Industry"],
    // networking: ["Market Update (Strategic Issues and Trends)", "Outbound Update (Data on Outbound and Market Share)", "Competitor Update"],
    // facilitation: ["Regional Promotion (SM, TM, FT, Festival)", "Online Promotion (Website & Social Media)", "PR-Ing and Customer Service"]
    marketing: ["Market Update", "Competitor Update", "Global Trend"],
    networking: ["Partnership with Academics","Partnership with Business (TA/TO, Airlines, Accommodation, and others)","Partnership with Communities","Partnership with Governments","Partnership with Media"]
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function PIN(props) {
    const [week, setWeek] = useState(null)
    const [first, setFirst] = useState(false)
    const [showSelect, setShowSelect] = useState(false)
    const [flag, setFlag] = useState(true)
    // const [years, setYears] = useState([2019, 2020, 2021, 2022, 2023, 2024, 2025])
    const [years, setYears] = useState([2022])
    const [months, setMonts] = useState(['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'])
    const [inputMonth, setInputMonth] = useState(months[new Date().getMonth()])
    const [inputYear, setInputYear] = useState(new Date().getFullYear())
    const [weeks, setWeeks] = useState([])
    const dispatch = useDispatch()
    const filterYears = useSelector(state=> state.filterState.years)
    const weekState = useSelector(state=> state.workplanState.weeks)
    const [weekFind, setWeekFind] = useState(null)

    useEffect(()=>{
        if (filterYears) {
            let year
            if (inputYear > 2025) {
                setInputYear(2026)
                year = filterYears.filter(e=> e.year == 2025)[0].id
            }
            if (inputYear < 2019) {
                setInputYear(2019)
                year = filterYears.filter(e=> e.year == 2019)[0].id
            }
            if (inputYear <= 2025 && inputYear >= 2019) {
                year = filterYears.filter(e=> e.year == inputYear)[0].id
            }
            dispatch(getWeekly(year))
        }
    },[inputYear])

    useEffect(()=>{
        if (weekState) {
            let week 
            switch (inputMonth) {
                case 'Januari':
                    week = 0
                    break;
                case 'Februari':
                    week = 1
                    break;
                case 'Maret':
                    week = 2
                    break;
                case 'April':
                    week = 3
                    break;
                case 'Mei':
                    week = 4
                    break;
                case 'Juni':
                    week = 5
                    break;
                case 'Juli':
                    week = 6
                    break;
                case 'Agustus':
                    week = 7
                    break;
                case 'September':
                    week = 8
                    break;
                case 'Oktober':
                    week = 9
                    break;
                case 'November':
                    week = 10
                    break;
                case 'Desember':
                    week = 11
                    break;
                default:
                    break;
            }
            setWeeks(weekState[week])
            // let data = weekState[week] && weekState[week].weekly[0].id
            let dataWeek = weekState[week] && weekState[week].weekly[0].week
            // setWeek(data)
            // console.log(data,'<-');
            setWeekFind(dataWeek)
        }
    },[inputMonth, weekState])

    const showDateFix = (dateParams) => {
        const [day, month, year] = dateParams.split("/")
        let date = new Date(year, month - 1, day)
        return date
    }

    useEffect(()=>{
        if (weeks) {
            if (weeks.weekly) {
                weeks.weekly.forEach((week)=>{
                    if (showDateFix(`${new Date().getDate()}/${new Date().getMonth()+1}/${new Date().getFullYear()}`) >= (showDateFix(week.start)) && (showDateFix(`${new Date().getDate()}/${new Date().getMonth()+1}/${new Date().getFullYear()}`) <= showDateFix(week.end))) {
                        setWeek(week.id)
                        setWeekFind(week.week)
                        return
                    }
                })
                setFirst(true)
            }
        }
    },[weeks])

    function changeMonth(e, month) {
        e.preventDefault()
        setInputMonth(month)
    }

    function nextYear() {
        let temp = ''
        for (let i = 0; i < years.length; i++) {
            if (years[i] == inputYear) {
                temp = years[i + 1]
            }
        }
        setInputYear(temp)
        setFlag(!flag)
    }

    function beforeYear() {
        let temp = ''
        for (let i = 0; i < years.length; i++) {
            if (years[i] == inputYear) {
                temp = years[i - 1]
            }
        }
        setInputYear(temp)
        setFlag(!flag)
    }

    function onChangeWeek(e) {
        setWeek(e.target.value)
        if (weeks) {
            if (weeks.weekly) {
                let find = weeks.weekly.filter(a=> a.id === e.target.value)[0]
                if (find) setWeekFind(find.week)
            }
        }
    }

    function changeShowSelect() {
        setShowSelect(!showSelect)
    }

    function closeSelect() {
        setShowSelect(false)
    }
    
    return (
        <>
            <div className="dashboard" >
                <span >
                    Work Plan /
            </span>
            </div>
            <div className="vito-table">
                <Box width='100%' style={{ backgroundColor: 'white' }}>
                    <Box padding="1em 1.5em" >
                        <Typography variant="h5" style={{ fontWeight: "500", textTransform: 'uppercase' }}>CREATE WORK PLAN, {inputMonth} {inputYear}</Typography>
                    </Box>
                    <Box paddingX="1.5em">
                        <Box>
                            <Box width={1 / 2} display="flex" position="relative">
                                <ClickAwayListener onClickAway={closeSelect}>
                                    <Box width={1 / 3} marginRight="1rem" >
                                        <Button display="flex" variant="outlined" style={{ height: "100%", width: "100%", textTransform: "none" }} onClick={changeShowSelect}>
                                            <Box display="flex" width="100%" alignItems="center" justifyContent="space-between">
                                                <Typography style={inputYear && inputMonth ? { color: "black" } : { color: "#C4C4C4" }}>{inputYear && inputMonth ? `${inputMonth}, ${inputYear}` : 'Choose Year & Month'}</Typography>
                                                <ExpandMoreIcon style={{ color: "#C4C4C4" }} />
                                            </Box>
                                        </Button>
                                        {showSelect ?
                                            <SelectYearMonth years={years} inputYear={inputYear} beforeYear={beforeYear} nextYear={nextYear} changeMonth={changeMonth} inputMonth={inputMonth} months={months} />
                                            : null}

                                    </Box>
                                </ClickAwayListener>
                                <Box width={1 / 4}><FormControl color="secondary" variant="outlined" fullWidth size="small" >
                                    <Select
                                        MenuProps={MenuProps}
                                        value={week}
                                        onChange={onChangeWeek}
                                    >
                                        {
                                            weeks &&
                                            weeks.weekly &&
                                            weeks.weekly.map((week, index) => {
                                                return (
                                                    <MenuItem key={index} value={week.id}>Week{week.week}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl></Box>
                            </Box>
                        </Box>
                        <Box borderTop="0.3px solid #DDDDDD" display="flex" paddingTop="1rem" marginTop="1rem" style={{ paddingBottom: '20px' }} >
                            {/* <Box width={1 / 3}>
                                <BoxCard weekFind={weekFind} title="NETWORKING & PARTNERSHIP" options={options.marketing} CategoryId={1} weekId={week} week={weeks && weeks.weekly && weeks.weekly.filter(e=> e.id == week)}/>
                            </Box>
                            <Box width={1 / 3} marginX="1rem">
                                <BoxCard weekFind={weekFind} title="MARKET INTELLIGENCE" options={options.networking} CategoryId={2} weekId={week} week={weeks && weeks.weekly && weeks.weekly.filter(e=> e.id == week)}/>
                            </Box>
                            <Box width={1 / 3}>
                                <BoxCard weekFind={weekFind} title="PROMOTIONAL SUPPORT" options={options.facilitation} CategoryId={3} weekId={week} week={weeks && weeks.weekly && weeks.weekly.filter(e=> e.id == week)}/>
                            </Box> */}
                            <Box width={1 / 2}>
                                <BoxCard weekFind={weekFind} title="NETWORKING & PARTNERSHIP" options={options.networking} CategoryId={1} weekId={week} week={weeks && weeks.weekly && weeks.weekly.filter(e=> e.id == week)}/>
                            </Box>
                            <Box width={1 / 2} marginX="1rem">
                                <BoxCard weekFind={weekFind} title="MARKET INTELLIGENCE" options={options.marketing} CategoryId={2} weekId={week} week={weeks && weeks.weekly && weeks.weekly.filter(e=> e.id == week)}/>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </div>
        </>
    )
}