import React,{ useState,useEffect } from 'react'
import './styles.scss'
import Maps from '../../../component/Maps'
import ReactTooltip from "react-tooltip";
import Chart from 'chart.js';
// COMPONENT
import { ClickAwayListener, Typography, TextField, withStyles, makeStyles, InputAdornment, Table, TableHead, TableRow, TableBody, TableCell, Collapse, Button } from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { getReportMonthChartDashboard, getWeeklyChartDash, getReportRightChartDashboard } from '../../../../../store/actions/dashboardAction'

const useStyles = makeStyles((theme) => ({
    buttonLogin:{
    height: '34px',
    border: '1px solid #E23F81',
    boxSizing: 'border-box',
    background: 'white',
    borderRadius: '4px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '15px',
    color: '#E23F81',
    boxShadow: 'none',
    marginRight: 30,
    '&:hover': {
        background: 'white',
        color: '#E23F81',
        boxShadow: 'none'
    },
    },
    formControl:{
        marginTop: 20,
        marginLeft: 20,
    },
    formControl1:{
        marginTop: 10,
    }
}));

const useStylesBtn = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    dropdown: {
        justifyContent: 'center',
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        width: '99px',
        position: 'absolute',
        top: 28,
        right: 0,
        left: -60,
        zIndex: 1,
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        boxShadow: '6px 5px 33px -14px rgba(0,0,0,0.75)'
    },
    label:{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "16px",
        color: "#999999",
    },
    box:{
        borderBottom: '0.5px solid #DDDDDD',
        width: '100%',
        height: 35,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: 15,
        paddingRight: 15,
        color: '#999999',
        cursor: 'pointer',
        borderRadius: 4,
        '&:hover':{
            color: 'white',
            backgroundColor: '#E23F81'
        }
    },
    boxLast:{
        width: '100%',
        height: 35,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: 15,
        paddingRight: 15,
        color: '#999999',
        borderRadius: 4,
        cursor: 'pointer',
        '&:hover':{
            color: 'white',
            backgroundColor: '#E23F81'
        }
    }
}));

function ClickAway(props) {
    let { id } = props
    const classes = useStylesBtn();
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={classes.root}>
                <div className="rqs-dta-btn-option" style={{ backgroundColor:'#FFFFFF' }} onClick={handleClick} >
                    <MoreHorizIcon/>
                </div>
                {open ? (
                <div className={classes.dropdown}>
                    <div className={classes.box} >
                        <i className="fas fa-pencil-alt"/>
                        <span style={{ marginLeft: 10 }} >
                            Edit
                        </span>
                    </div>
                    <div className={classes.boxLast} >
                        <i className="fas fa-trash"/>
                        <span style={{ marginLeft: 10 }}>
                            Hapus
                        </span>
                    </div>
                </div>
                ) : null}
            </div>
        </ClickAwayListener>
    );
}

export default function PIN(props){
    const history = useHistory()
    const Dispatch = useDispatch()
    const classes = useStyles()
    const [user, setUser] = useState(null);
    const [searchRightTop, setSearchRightTop] = useState(null);
    const [filterRightTop, setFilterRightTop] = useState(null);
    const [searchRightBottom, setSearchRightBottom] = useState(null);
    const [vitoFilter, setVitoFilter] = useState([]);
    const [vitoWeekFilter, setVitoWeekFilter] = useState([]);
    const [vitoMonthCountFilter, setVitoMonthCountFilter] = useState([]);
    const [vitoWeekCountFilter, setVitoWeekCountFilter] = useState([]);
    const [reportMonthCountFilter, setReportMonthCountFilter] = useState([]);
    const [reportWeekCountFilter, setReportWeekCountFilter] = useState([0,0]);
    const vitos = useSelector(state=> state.vitoState.vitos)
    const monthReport = useSelector(state=> state.reportState.monthlyDashboard)
    const weekReport = useSelector(state=> state.reportState.weeklyDashboard)
    const weekListBagan = useSelector(state=> state.reportState.weekListBagan)
    const monthReportChart = useSelector(state=> state.reportState.baganMonth)
    const weekReportChart = useSelector(state=> state.reportState.baganWeek)
    const monthly = useSelector(state=> state.vitoState.monthlyDashboard)
    const weekly = useSelector(state=> state.vitoState.weeklyDashboard)
    const monthlyYear = useSelector(state=> state.vitoState.monthlyCountDashboard)
    const weeklyYear = useSelector(state=> state.vitoState.weeklyCountDashboard)
    const filterYears = useSelector(state=> state.filterState.years)
    
    const [reportSummary, setReportSummary] = useState('monthly')
    const [reportSummaryNow, setReportSummaryNow] = useState('monthly')
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDateLeft, setSelectedDateLeft] = useState(new Date());
    const [weekSelected, setWeekSelected] = useState('');
    const [first, setFirst] = useState(false);

    useEffect(()=>{
        if (vitos && weeklyYear) {
            let bank = [...vitos]
            weeklyYear.map(data=>{
                const arr = bank.filter(a=> a.id == data.UserId)[0]
                if (arr) {
                    if (arr[checkIndicatorWeek(data.createdAt, data.MonthId)]) {
                        arr[checkIndicatorWeek(data.createdAt, data.MonthId)] = 0
                    }
                }
            })
            weeklyYear.map(data=>{
                const arr = bank.filter(a=> a.id == data.UserId)[0]
                if (arr) {
                    if (arr[checkIndicatorWeek(data.createdAt, data.MonthId)]) {
                        arr[checkIndicatorWeek(data.createdAt, data.MonthId)] = arr[checkIndicatorWeek(data.createdAt, data.MonthId)]+=1
                    } else {
                        arr[checkIndicatorWeek(data.createdAt, data.MonthId)] = 1
                    }
                }
            })
            setVitoWeekCountFilter(bank);
        }
    },[weeklyYear, vitos])

    useEffect(()=>{
        if (vitos && monthlyYear) {
            let bank = [...vitos]
            monthlyYear.map(data=>{
                const arr = bank.filter(a=> a.id == data.UserId)[0]
                if (arr) {
                    if (arr[checkIndicator(data.createdAt, data.MonthId)]) {
                        arr[checkIndicator(data.createdAt, data.MonthId)] = 0
                    }
                }
            })
            monthlyYear.map(data=>{
                const arr = bank.filter(a=> a.id == data.UserId)[0]
                if (arr) {
                    if (arr[checkIndicator(data.createdAt, data.MonthId)]) {
                        arr[checkIndicator(data.createdAt, data.MonthId)] = arr[checkIndicator(data.createdAt, data.MonthId)]+=1
                    } else {
                        arr[checkIndicator(data.createdAt, data.MonthId)] = 1
                    }
                }
            })
            setVitoMonthCountFilter(bank);
        }
    },[monthlyYear, vitos])

    useEffect(()=>{
        if (vitos && weekReport) {
            let bank = []
            vitos.map(b=>{
                if (!weekReport.filter(a=> b.id == (a ? a.UserId ? a.UserId : 0 : 0))[0]) {
                    bank.push(b)
                }
            })
            setVitoWeekFilter(bank)
        }
    },[weekReport, vitos])

    useEffect(()=>{
        if (vitos && monthReport) {
            let bank = []
            vitos.map(b=>{
                if (!monthReport.filter(a=> b.id == (a ? a.UserId ? a.UserId : 0 : 0))[0]) {
                    bank.push(b)
                }
            })
            setVitoFilter(bank)
        }
    },[monthReport, vitos])

    useEffect(()=>{
        if (monthReportChart) {
            let bank = {}
            let police = []
            monthReportChart.map(data=>{
                if (bank[checkIndicator(data.createdAt, data.MonthId)]) {
                    bank[checkIndicator(data.createdAt, data.MonthId)] = bank[checkIndicator(data.createdAt, data.MonthId)]+=1
                } else {
                    bank[checkIndicator(data.createdAt, data.MonthId)] = 1
                }
            })
            police = [
                bank['Tepat Waktu'] ? bank['Tepat Waktu'] : 0,
                bank['Terlambat Satu Minggu'] ? bank['Terlambat Satu Minggu'] : 0,
                bank['Terlambat Dua Minggu'] ? bank['Terlambat Dua Minggu'] : 0,
                bank['Terlambat Lebih Dari Dua Minggu'] ? bank['Terlambat Lebih Dari Dua Minggu'] : 0,
            ]
            setReportMonthCountFilter(police);
        }
    },[monthReportChart])

    useEffect(()=>{
        if (filterYears) {
            getDataMonthLeft(selectedDateLeft)
            getWeeklyList(selectedDate)
        }
    },[filterYears])
    
    useEffect(()=>{
        if (weekReportChart) {
            let bank = {}
            let police = []
            weekReportChart.map(data=>{
                if (bank[checkIndicatorWeek(data.createdAt, data.MonthId)]) {
                    bank[checkIndicatorWeek(data.createdAt, data.MonthId)] = bank[checkIndicatorWeek(data.createdAt, data.MonthId)]+=1
                } else {
                    bank[checkIndicatorWeek(data.createdAt, data.MonthId)] = 1
                }
            })
            police = [
                bank['Late'] ? bank['Late'] : 0,
                bank['On Time'] ? bank['On Time'] : 0,
            ]
            setReportWeekCountFilter(police);
        }
    },[weekReportChart])

    useEffect(()=>{
        if (weekListBagan && !first) {
            getIdMonth(selectedDate, weekListBagan).forEach((week)=>{
                if (showDateFix(`${new Date().getDate()}/${new Date().getMonth()+1}/${new Date().getFullYear()}`) >= (showDateFix(week.start)) && (showDateFix(`${new Date().getDate()}/${new Date().getMonth()+1}/${new Date().getFullYear()}`) <= showDateFix(week.end))) {
                    setWeekSelected(week.id)
                    getDataWeekRight(selectedDate, week.id)
                    setFirst(true)
                    return
                }
            })
        }
    },[weekListBagan])

    const handleWeekChange = (week) => {
        setWeekSelected(week)
        getDataWeekRight(selectedDate, week)
    }

    const getDataMonthLeft = (date) => {
        if (filterYears) {
            let year
            let month
            if (new Date(date).getFullYear() > 2025) {
                year = filterYears.filter(e=> e.year === 2025)[0].id
            }
            if (new Date(date).getFullYear() < 2019) {
                year = filterYears.filter(e=> e.year === 2019)[0].id
            }
            if (new Date(date).getFullYear() <= 2025 && new Date(date).getFullYear() >= 2019) {
                year = filterYears.filter(e=> e.year === new Date(date).getFullYear())[0].id
            }
            switch (new Date(date).getMonth()) {
                case 0 :
                    month = `${year}${1}`
                    break;
                case 1 :
                    month = `${year}${2}`
                    break;
                case 2 :
                    month = `${year}${3}`
                    break;
                case 3 :
                    month = `${year}${4}`
                    break;
                case 4 :
                    month = `${year}${5}`
                    break;
                case 5 :
                    month = `${year}${6}`
                    break;
                case 6 :
                    month = `${year}${7}`
                    break;
                case 7 :
                    month = `${year}${8}`
                    break;
                case 8 :
                    month = `${year}${9}`
                    break;
                case 9 :
                    month = `${year}${10}`
                    break;
                case 10 :
                    month = `${year}${11}`
                    break;
                case 11 :
                    month = `${year}${12}`
                    break;
                default:
                    month = '-'
                    break;
            } 
            Dispatch(getReportMonthChartDashboard(month, year))
        }
    }

    const getDataWeekRight = (date, week) => {
        if (filterYears) {
            let year
            let month
            if (new Date(date).getFullYear() > 2025) {
                year = filterYears.filter(e=> e.year === 2025)[0].id
            }
            if (new Date(date).getFullYear() < 2019) {
                year = filterYears.filter(e=> e.year === 2019)[0].id
            }
            if (new Date(date).getFullYear() <= 2025 && new Date(date).getFullYear() >= 2019) {
                year = filterYears.filter(e=> e.year === new Date(date).getFullYear())[0].id
            }
            switch (new Date(date).getMonth()) {
                case 0 :
                    month = `${year}${1}`
                    break;
                case 1 :
                    month = `${year}${2}`
                    break;
                case 2 :
                    month = `${year}${3}`
                    break;
                case 3 :
                    month = `${year}${4}`
                    break;
                case 4 :
                    month = `${year}${5}`
                    break;
                case 5 :
                    month = `${year}${6}`
                    break;
                case 6 :
                    month = `${year}${7}`
                    break;
                case 7 :
                    month = `${year}${8}`
                    break;
                case 8 :
                    month = `${year}${9}`
                    break;
                case 9 :
                    month = `${year}${10}`
                    break;
                case 10 :
                    month = `${year}${11}`
                    break;
                case 11 :
                    month = `${year}${12}`
                    break;
                default:
                    month = '-'
                    break;
            } 
            Dispatch(getReportRightChartDashboard(month, year, week))
        }
    }

    const getWeeklyList = (date) => {
        if (filterYears) {
            let year
            if (new Date(date).getFullYear() > 2025) {
                year = filterYears.filter(e=> e.year === 2025)[0].id
            }
            if (new Date(date).getFullYear() < 2019) {
                year = filterYears.filter(e=> e.year === 2019)[0].id
            }
            if (new Date(date).getFullYear() <= 2025 && new Date(date).getFullYear() >= 2019) {
                year = filterYears.filter(e=> e.year === new Date(date).getFullYear())[0].id
            }
            Dispatch(getWeeklyChartDash(year))
        }
    }

    const getIdMonth = (date, weekListBagan) => {
        if (filterYears) {
            let month
            let year
            let data = []
            if (new Date(date).getFullYear() > 2025) {
                year = filterYears.filter(e=> e.year === 2025)[0].id
            }
            if (new Date(date).getFullYear() < 2019) {
                year = filterYears.filter(e=> e.year === 2019)[0].id
            }
            if (new Date(date).getFullYear() <= 2025 && new Date(date).getFullYear() >= 2019) {
                year = filterYears.filter(e=> e.year === new Date(date).getFullYear())[0].id
            }
            switch (new Date(date).getMonth()) {
                case 0 :
                    month = `${year}${1}`
                    break;
                case 1 :
                    month = `${year}${2}`
                    break;
                case 2 :
                    month = `${year}${3}`
                    break;
                case 3 :
                    month = `${year}${4}`
                    break;
                case 4 :
                    month = `${year}${5}`
                    break;
                case 5 :
                    month = `${year}${6}`
                    break;
                case 6 :
                    month = `${year}${7}`
                    break;
                case 7 :
                    month = `${year}${8}`
                    break;
                case 8 :
                    month = `${year}${9}`
                    break;
                case 9 :
                    month = `${year}${10}`
                    break;
                case 10 :
                    month = `${year}${11}`
                    break;
                case 11 :
                    month = `${year}${12}`
                    break;
                default:
                    month = '0'
                    break;
            }
            if (weekListBagan) {
                weekListBagan.map(dt=>{
                    if (dt.id == month) {
                        data = dt.weekly
                    }
                })
            }
            return data
        }
    }

    const handleDateChangeLeft = (date) => {
        getDataMonthLeft(date)
        setSelectedDateLeft(date)
    }

    const handleDateChange = (date) => {
        setSelectedDate(date)
        getWeeklyList(date)
    }

    function showDate (dateParams) {
        let date = new Date(dateParams).getDate() 
        let monthNumber = new Date(dateParams).getMonth()
        let month = ''
        let year = new Date(dateParams).getFullYear()
        switch (monthNumber) {
            case 0 :
                month = '01'
                break;
            case 1 :
                month = '02'
                break;
            case 2 :
                month = '03'
                break;
            case 3 :
                month = '04'
                break;
            case 4 :
                month = '05'
                break;
            case 5 :
                month = '06'
                break;
            case 6 :
                month = '07'
                break;
            case 7 :
                month = '08'
                break;
            case 8 :
                month = '09'
                break;
            case 9 :
                month = '10'
                break;
            case 10 :
                month = '11'
                break;
            case 11 :
                month = '12'
                break;
            default:
                month = '-'
                break;
        }
        return year + '-' + month  + '-' + date
    }

    function showHour (hourParams) {
        let hour = new Date(hourParams).getHours()
        let minutes = new Date(hourParams).getMinutes()
        return (hour > 9 ? hour : "0" + hour ) + ":" + (minutes > 9 ? minutes : "0" + minutes)
    }

    function showMonth (dateParams) {
        let monthNumber = new Date(dateParams).getMonth()
        let month = ''
        switch (monthNumber) {
            case 0 :
                month = 'Januari'
                break;
            case 1 :
                month = 'Februari'
                break;
            case 2 :
                month = 'Maret'
                break;
            case 3 :
                month = 'April'
                break;
            case 4 :
                month = 'Mei'
                break;
            case 5 :
                month = 'Juni'
                break;
            case 6 :
                month = 'Juli'
                break;
            case 7 :
                month = 'Agustus'
                break;
            case 8 :
                month = 'September'
                break;
            case 9 :
                month = 'Oktober'
                break;
            case 10 :
                month = 'November'
                break;
            case 11 :
                month = 'Desember'
                break;
            default:
                month = '-'
                break;
        }
        return month
    }

    const showDateFix = (dateParams) => {
        const [day, month, year] = dateParams.split("/")
        let date = new Date(year, month - 1, day)
        return date
    }

    function max(input) {
        if (toString.call(input) !== "[object Array]")  
            return false;
        return Math.max.apply(null, input);
    }

    useEffect(()=> {
        if (reportWeekCountFilter) {
            let flagRED
            if (reportWeekCountFilter[0] === 0 && reportWeekCountFilter[1] === 0) {
                flagRED = true
            }
            var ctx = document.getElementById('myChartDougnout').getContext('2d');
            var myDougnoutChart = new Chart(ctx, {
                type: 'pie',
                // These labels appear in the legend and in the tooltips when hovering different arcs
                data: {
                    datasets: [{
                        label: 'Status',
                        data: flagRED ? [999] : reportWeekCountFilter,
                        backgroundColor: flagRED ? ["#b7b9ba"] :
                        [ "#FF0000", "#00B53D" ],
                        borderWidth: 2
                    }],
                    labels: flagRED ? ['Tidak Ada Laporan'] : [ 'Terlambat', 'Tepat Waktu' ],
                },
                options: {
                    legend: {
                        display: false,
                        labels: {
                            fontColor: "#000000",
                        }
                    },
                    cutoutPercentage: 60,
                    responsive: true,
                    maintainAspectRatio: false,
                    tooltips: {
                        callbacks: {
                            label: function(tooltipItem, data) {
                                var label = data.labels[tooltipItem.index];
                                var val = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                                return label + ':' + val + ' (' + (val * 100 / max(reportWeekCountFilter)).toFixed(2) + '%)';
                            }
                        }
                    }
                }
            });
            // ctx.canvas.parentNode.style.width = '60%'
            // ctx.canvas.parentNode.style.height = '40%'
        }
    },[reportWeekCountFilter])
    
    useEffect(()=> {
        let maxNum = 1
        if (reportMonthCountFilter.length !== 0) {
            maxNum += Math.max.apply(Math, reportMonthCountFilter)
        }
        var ctx = document.getElementById('myChartLine').getContext("2d");
        var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
        gradientStroke.addColorStop(0, '#80b6f4');
        gradientStroke.addColorStop(1, '#f49080');
        var gradientFill = ctx.createLinearGradient(500, 10, 40, 0);
        gradientFill.addColorStop(0, "rgba(76, 133, 255, 0)");
        gradientFill.addColorStop(1, "rgba(76, 133, 255, 1)");
        var myChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: ["Tepat Waktu", "Terlambat Satu Minggu", "Terlambat Dua Minggu", "Terlambat Lebih Dari Dua Minggu"],
                datasets: [{
                    label: "Data",
                    borderColor: '#3F83FB',
                    pointBorderColor: '#3F83FB',
                    pointBackgroundColor: '#355db2',
                    pointHoverBackgroundColor: '#355db2',
                    pointHoverBorderColor: '#3F83FB',
                    pointBorderWidth: 1,
                    pointHoverRadius: 10,
                    pointHoverBorderWidth: 1,
                    lineTension: 0,
                    borderDashOffset: 0.0,
                    pointRadius: 3,
                    fill: true,
                    backgroundColor: gradientFill,
                    borderWidth: 2,
                    data: reportMonthCountFilter
                }]
            },
            options: {
                maintainAspectRatio: false,
                animation: {
                    easing: "easeInOutBack"
                },
                legend: {
                    display: false,
                    position: "bottom"
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            fontColor: "#2C304D",
                            fontStyle: "bold",
                            beginAtZero: true,
                            maxTicksLimit: 5,
                            min: 0,
                            max: maxNum,
                            padding: 6,
                            fontSize: 10,
                            callback: function(value, index, values) {
                                return value;
                            },
                        },
                        gridLines: {
                            drawTicks: false,
                            display: false
                        }
                    }],
                    xAxes: [{
                        gridLines: {
                            zeroLineColor: "transparent"
                        },
                        ticks: {
                            fontSize: 8,
                            fontColor: "#2C304D",
                            fontStyle: "bold"
                        }
                    }]
                }
            }
        });
        ctx.canvas.parentNode.style.width = '95%'
        ctx.canvas.parentNode.style.height = '55%'
    },[reportMonthCountFilter])

    const modalUp = (geo) => {
        if (geo) {
            let vit = []
            vitos.map(vito=>{
                if ((vito.country).toLowerCase() === (geo).toLowerCase()) {
                    if (vito.area) {
                        vit.push({name:`${vito.name} - ${vito.country}, ${vito.area}`, id: vito.id})
                    } else {
                        vit.push({name:`${vito.name} - ${vito.country}`, id: vito.id})
                    }
                }
            })
            Swal.fire({
                title: `<strong>${ geo }</strong>`,
                html: `
                <div class="modal-name" >
                    ${
                        vit.length !== 0 ?
                        vit.map((data, i)=>(
                            `<a class="txt-roll" key={i} target="_blank" href="/vito/report/weekly/2020/${data.id}">${ data.name } </a>`
                        ))
                        :
                        `<span class="txt-roll" key={i} > tidak ada vito </span>`
                    }
                </div>`,
                showCloseButton: true,
                showCancelButton: false,
                showConfirmButton: false,
                focusConfirm: false,
            })
        }
    }

    const setUserCountry = (content) => {
        if (vitos, content) {
            let vit = []
            vit.push(content)
            vitos.map(vito=>{
                if ((vito.country).toLowerCase() === (content).toLowerCase()) {
                    if (vito.area) {
                        vit.push(`${vito.name} - ${vito.area}`)
                    } else {
                        vit.push(`${vito.name}`)
                    }
                }
            })
            setUser(vit);
        } else {
            setUser(null)
        }
    }

    const checkIndicator = (time, deadline) => {
        let result = 'Tepat Waktu'
        let find

        if (monthly) {
            monthly.map(data=>{
                if (data.id == deadline) {
                    find = data
                }
            })
        }

        if (find) {
            let date = new Date(time)
            const [day, month, year] = find.dates.end.split("/")
            let deadlineDate = new Date(year, month - 1, day)
            deadlineDate.setDate(deadlineDate.getDate() + 1);

            if (date <= deadlineDate) {
                result = 'Tepat Waktu'
            } else {
                let timeDiff = Math.abs(deadlineDate.getTime() - date.getTime());
                let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)); 
                if (diffDays <= 7) {
                    result = 'Terlambat Satu Minggu'
                } else if (diffDays <= 14) {
                    result = 'Terlambat Dua Minggu'
                } else if (diffDays > 14) {
                    result = 'Terlambat Lebih Dari Dua Minggu'
                }
            }
        }

        return result
    }

    const getWeek = (id) => {
        let find = '-'
        if (weekly) {
            weekly.map(data=>{
                if (data.id == id) {
                    find = data.week
                }
            })
        }
        return find
    }

    const checkIndicatorWeek = (time, deadline) => {
        let result = 'On Time'
        let find

        if (weekly) {
            weekly.map(data=>{
                if (data.id == deadline) {
                    find = data
                }
            })
        }

        if (find) {
            let date = new Date(time)
            const [day, month, year] = find.end.split("/")
            let deadlineDate = new Date(year, month - 1, day)
            deadlineDate.setDate(deadlineDate.getDate() + 1);

            if (date <= deadlineDate) {
                result = 'On Time'
            } else {
                result = 'Late'
            }
        }

        return result
    }

    const checkIndicatorWeekRight = (time, deadline) => {
        let result = 'Tepat Waktu'
        let find

        if (weekly) {
            weekly.map(data=>{
                if (data.id == deadline) {
                    find = data
                }
            })
        }

        if (find) {
            let date = new Date(time)
            const [day, month, year] = find.end.split("/")
            let deadlineDate = new Date(year, month - 1, day)
            deadlineDate.setDate(deadlineDate.getDate() + 1);

            if (date <= deadlineDate) {
                result = 'Tepat Waktu'
            } else {
                result = 'Terlambat'
            }
        }

        return result
    }

    const getName = (vito) => {
        let name
        let country
        let area
        if (vito) {
            if (vito.User) {
                name = vito.User.name
                country = vito.User.country
                area = (vito.User.area !== 'tidak ada area' && vito.User.area) && vito.User.area
            }
        }
        return `${name} - ${country} - ${area}`
    }

    const getNameFix = (vito) => {
        let name
        let country
        let area
        if (vito) {
            if (vito) {
                name = vito.name
                country = vito.country
                area = (vito.area !== 'tidak ada area' && vito.area) && vito.area
            }
        }
        return `${name} - ${country} - ${area}`
    }

    return(
        <div className="dashboard" >
            <span className="breadcrumb-txt-black" >
                Dashboard
            </span>
            <span className="breadcrumb-slash-black" >
                /
            </span>
            <div className="dashboard-container" >
                <div className="dashboard-maps-container"
                    style={{
                        paddingBottom: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        marginTop: 15,
                    }}
                >
                    <div className="header-dashboard-box"
                        style={{
                            paddingLeft: 15,
                        }}
                    >
                        <span className="header-dashboard-text">
                            Lokasi VITO
                        </span>
                        <Button variant="contained" className={classes.buttonLogin} onClick={() => history.push('/admin/vito')}>
                            Lihat VITO
                        </Button>
                    </div>
                    <div className="mainbox-containe" style={{ paddingTop: 10, height: 500 }} >
                        <Maps modalUp={modalUp} setUserCountry={setUserCountry}/>
                        <ReactTooltip>
                        {
                            user &&
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >
                                {
                                    user && 
                                    user.map((use, i)=>(
                                        <span key={i}> { use } </span>
                                    ))
                                }
                            </div>
                        }
                        </ReactTooltip>
                    </div>
                </div>
                <div className="dashboard-maps-container"
                    style={{
                        marginTop: 15                    
                    }}
                >
                    <div className="header-dashboard-box">
                        <span className="header-dashboard-text">
                        Ringkasan Laporan Bulan Ini
                        </span>
                    </div>
                    <div className="mainbox-containe" >
                        <div className="header-tabs-dashboard" >
                            {
                                reportSummary === 'monthly' ?
                                <div className="dashboard-list-tab" >
                                    Laporan Bulanan
                                </div>
                                :
                                <div className="dashboard-list-tab" onClick={e=>[setReportSummary('monthly'), setFilterRightTop(null)]} style={{ borderBottom : '0px' }}>
                                    Laporan Bulanan
                                </div>
                            }
                            {
                                reportSummary === 'weekly' ?
                                <div className="dashboard-list-tab">
                                    Laporan Mingguan
                                </div>
                                :
                                <div className="dashboard-list-tab" onClick={e=>[setReportSummary('weekly'), setFilterRightTop(null)]} style={{ borderBottom : '0px' }}>
                                    Laporan Mingguan
                                </div>
                            }
                        </div>
                        <div className="filter-contain-dashboard" >
                            <input type="text" onChange={e=>setSearchRightTop(e.target.value)} className="filter-dashboard-tab" placeholder="Cari di Sini.." style={{ color: '#999999' }}/>
                            {
                                reportSummary === 'monthly' ?
                                <select onChange={e=>setFilterRightTop(e.target.value)} className="filter-status-dashboard">
                                    <option value="" selected>Filter Status</option>
                                    <option value="Tepat Waktu"> Tepat Waktu </option>
                                    <option value="Terlambat Satu Minggu">Terlambat Satu Minggu</option>
                                    <option value="Terlambat Dua Minggu">Terlambat Dua Minggu</option>
                                    <option value="Terlambat Lebih Dari Dua Minggu">Terlambat Lebih Dari Dua Minggu</option>
                                    <option value="Belum Dikirim">Belum Dikirim</option>
                                </select>
                                :
                                <select onChange={e=>setFilterRightTop(e.target.value)} className="filter-status-dashboard">
                                    <option value="" selected>Filter Status</option>
                                    <option value="Tepat Waktu"> Tepat Waktu </option>
                                    <option value="Terlambat">Terlambat</option>
                                    <option value="Belum Dikirim">Belum Dikirim</option>
                                </select>
                            }
                        </div>
                    </div>
                    <div className="fixed_header-div" style={{ height: 380 }}>
                    {
                        reportSummary === 'monthly' &&
                        <table className="fixed_headers" >
                            <thead>
                                <tr>
                                    <th style={{ width: "2rem" }} >NO</th>
                                    <th >NAMA</th>
                                    <th >BULAN</th>
                                    <th >TANGGAL SUBMIT</th>
                                    <th >STATUS</th>
                                    {/* <th >ACTION</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    monthReport &&
                                    monthReport
                                    .filter(a=> searchRightTop ? getName(a).includes(searchRightTop) : a )
                                    .filter(a=> filterRightTop ? checkIndicator(a.createdAt, a.MonthId) === filterRightTop : a )
                                    .map((data,i)=>(
                                        <tr key={i} style={{ backgroundColor: i % 2 === 0 ? '#FAFAFA' : '#FFFFFF' }} >
                                            <td>{ i+=1 }</td>
                                            <td>{ data && data.User ? data.User.name ? data.User.name : '-' : '-' } { data && data.User && data.User.country && ' - ' + data.User.country } { data && data.User && data.User.area && ((data.User.area !== 'tidak ada area') && data.User.area) && ' - ' + data.User.area }</td>
                                            <td>{ showMonth(data && data.MonthId && data.MonthId) }</td>
                                            <td>
                                                <div className="table-dashboard-column" >
                                                    <span>
                                                        { showDate(data && data.createdAt && data.createdAt ) }
                                                    </span>
                                                    <span className="grey-table-txt" >
                                                        { showHour(data && data.createdAt && data.createdAt) }
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table-dashboard-column" >
                                                    <span>
                                                        Terkirim
                                                    </span>
                                                    <span className="grey-table-txt" >
                                                        { data && checkIndicator(data.createdAt, data.MonthId) }
                                                    </span>
                                                </div>
                                            </td>
                                            {/* <td>
                                                <ClickAway id={data.name} />
                                            </td> */}
                                        </tr>
                                    ))
                                }
                                {
                                    vitoFilter &&
                                    vitoFilter
                                    .filter(a=> searchRightTop ? getNameFix(a).includes(searchRightTop) : a )
                                    .filter(a=> filterRightTop ? filterRightTop === 'Belum Dikirim'  : a )
                                    .map((data,i)=>(
                                    <tr key={i} style={{ backgroundColor: i % 2 === 0 ? '#FAFAFA' : '#FFFFFF' }} >
                                        <td>{ (monthReport && monthReport.length && monthReport.length) + (i+=1) }</td>
                                        <td>{ data && data.name ? data.name : '-'  } { data && data.country && ` - ${data.country}` } { data && data.area && ((data.area !== 'tidak ada area') && data.area) && ` - ${data.area}` }</td>
                                        <td>{ showMonth(new Date().getMonth()) }</td>
                                        <td>
                                            <div className="table-dashboard-column" >
                                                <span>
                                                    -
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="table-dashboard-column" >
                                                <span>
                                                    Belum Dikirim
                                                </span>
                                            </div>
                                        </td>
                                        {/* <td>
                                            <ClickAway id={data.name} />
                                        </td> */}
                                    </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    }
                    {
                        reportSummary === 'weekly' &&
                        <table className="fixed_headers">
                            <thead>
                                <tr>
                                    <th style={{ width: "2rem" }} >NO</th>
                                    <th >NAME</th>
                                    <th >WEEK</th>
                                    <th >CATEGORY</th>
                                    <th >SUBMIT DATE</th>
                                    <th >STATUS</th>
                                    {/* <th >AKSI</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    weekReport &&
                                    weekReport
                                    .filter(a=> searchRightTop ? getName(a).includes(searchRightTop) : a )
                                    .filter(a=> filterRightTop ? checkIndicatorWeekRight(a.createdAt, a.MonthId) === filterRightTop : a )
                                    .map((data,i)=>(
                                    <tr key={i} style={{ backgroundColor: i % 2 === 0 ? '#FAFAFA' : '#FFFFFF' }} >
                                        <td>{ i+=1 }</td>
                                        <td>{ data && data.User ? data.User.name ? data.User.name : '-' : '-' } { data && data.User && data.User.country && ' - ' + data.User.country } { data && data.User && data.User.area && ((data.User.area !== 'tidak ada area') && data.User.area) && ' - ' + data.User.area }</td>
                                        <td>{ getWeek(data && data.WeekId) }</td>
                                        <td>{ data.category }</td>
                                        <td>
                                            <div className="table-dashboard-column" >
                                                <span>
                                                    { showDate(data && data.createdAt && data.createdAt ) }
                                                </span>
                                                <span className="grey-table-txt" >
                                                    { showHour(data && data.createdAt && data.createdAt) }
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="table-dashboard-column" >
                                                <span>
                                                    Terkirim
                                                </span>
                                                <span className="grey-table-txt" >
                                                    { data && checkIndicatorWeekRight(data && data.reportCreatedAt ? data.reportCreatedAt : data && data.createdAt, data.MonthId) }
                                                </span>
                                            </div>
                                        </td>
                                        {/* <td>
                                            <ClickAway id={data.name} />
                                        </td> */}
                                    </tr>
                                    ))
                                }
                                {
                                    vitoWeekFilter &&
                                    vitoWeekFilter
                                    .filter(a=> searchRightTop ? getNameFix(a).includes(searchRightTop) : a )
                                    .filter(a=> filterRightTop ? filterRightTop === 'Belum Dikirim'  : a )
                                    .map((data,i)=>(
                                    <tr key={i} style={{ backgroundColor: i % 2 === 0 ? '#FAFAFA' : '#FFFFFF' }} >
                                        <td>{ (weekReport && weekReport.length && weekReport.length) + (i+=1) }</td>
                                        <td>{ data && data.name ? data.name : '-'  } { data && data.country && ` - ${data.country}` } { data && data.area && ((data.area !== 'tidak ada area') && data.area) && ` - ${data.area}` }</td>
                                        <td>{ '-' }</td>
                                        <td>
                                            -
                                        </td>
                                        <td>
                                            -
                                        </td>
                                        <td>
                                            <div className="table-dashboard-column" >
                                                <span>
                                                    Belum Dikirim
                                                </span>
                                            </div>
                                        </td>
                                        {/* <td>
                                            <ClickAway id={data.name} />
                                        </td> */}
                                    </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    }
                    </div>
                </div>
            </div>
            <div className="dashboard-container" >
                <div className="dashboard-maps-container" style={{ marginRight: 5, marginTop: 15, backgroundColor: '#FAFAFA', width: '24.30%', padding: 0}} >
                    <div className="small-box-header" >
                        <span className="text-header-box1" >
                            Bagan Laporan Bulanan
                        </span>
                        <span className="text-header-box2" >
                            {showMonth(selectedDateLeft)} {new Date(selectedDateLeft).getFullYear()}
                        </span>
                    </div>
                    <div className="small-box-row-filter" >
                        <DatePicker
                            className={classes.formControl1}
                            views={["year","month"]}
                            minDate={new Date('2019/01/01')}
                            maxDate={new Date()}
                            label={!selectedDateLeft && "Periode Bulan"}
                            size="medium"
                            clearable
                            inputVariant="outlined"
                            value={selectedDateLeft}
                            onChange={date => handleDateChangeLeft(date)}
                            format="MMMM"
                            InputLabelProps={{
                                style:{
                                    marginTop: -7,
                                    paddingBottom: 0
                                },
                            }}
                            InputProps={{
                                style:{
                                    height: "40px",
                                    paddingRight: 5,
                                    cursor: 'pointer',
                                    width: 145
                                },
                                endAdornment: (
                                    <InputAdornment position="end" >
                                        <ArrowDropDownIcon style={{ color: '#C4C4C4' }} />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </div>
                    <div className="myChartDougnout-class" style={{ justifyContent: 'normal' }}>
                        <canvas id="myChartLine" />
                    </div>
                </div>
                <div className="dashboard-maps-container" style={{ marginTop: 15, backgroundColor: '#FAFAFA', width: '24.30%', padding: 0 }} >
                    <div className="small-box-header" style={{ backgroundColor: '#E23F81' }}>
                        <span className="text-header-box1" >
                            Bagan Laporan Mingguan
                        </span>
                        <span className="text-header-box2" >
                        {showMonth(selectedDate)} {new Date(selectedDate).getFullYear()}
                        </span>
                    </div>
                    <div className="small-box-row-filter" >
                        <DatePicker
                            className={classes.formControl1}
                            minDate={new Date('2019/01/01')}
                            views={["year","month"]}
                            maxDate={new Date()}
                            label={!selectedDate && "Periode Bulan"}
                            size="medium"
                            clearable
                            inputVariant="outlined"
                            value={selectedDate}
                            onChange={date => handleDateChange(date)}
                            format="MMMM"
                            InputLabelProps={{
                                style:{
                                    marginTop: -7,
                                    paddingBottom: 0
                                },
                            }}
                            InputProps={{
                                style:{
                                    height: "40px",
                                    paddingRight: 5,
                                    cursor: 'pointer',
                                    width: 145
                                },
                                endAdornment: (
                                    <InputAdornment position="end" >
                                        <ArrowDropDownIcon style={{ color: '#C4C4C4' }} />
                                    </InputAdornment>
                                )
                            }}
                        />
                        <select value={weekSelected} onChange={e=>handleWeekChange(e.target.value)} className="filter-weekly-dashboard" style={{ color: '#999999' }}>
                            {
                                weekListBagan &&
                                getIdMonth(selectedDate, weekListBagan)
                                .map((week, i)=>(
                                    <option key={i} value={week.id} style={{ color: '#999999' }}>Minggu Ke {week.week}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '100%', alignItems: 'center' }} >
                        <div className="myChartDougnout-class" >
                            {
                                <canvas id="myChartDougnout" />
                            }
                        </div>
                        <div className="chartjs-legend-custom" style={{ marginTop: 'auto' }}>
                            {/* <div className="legend-dot" >
                                <div className="dot-color" style={{ backgroundColor: "#F16821" }} />
                                <span className="dot-title" >
                                    Sedang Dikerjakan
                                </span>
                            </div> */}
                            <div className="legend-dot" >
                                <div className="dot-color" style={{ backgroundColor: "#00B53D" }} />
                                <span className="dot-title" >
                                Tepat Waktu
                                </span>
                            </div>
                            {/* <div className="legend-dot" >
                                <div className="dot-color" style={{ backgroundColor: "#C4C4C4" }} />
                                <div className="dot-title" >
                                Tidak Submit
                                </div>
                            </div> */}
                            <div className="legend-dot">
                                <div className="dot-color" style={{ backgroundColor: "#FF0000" }} />
                                <span className="dot-title" >
                                Terlambat
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dashboard-maps-container"
                    style={{
                        marginLeft: 10,
                        marginTop: 15
                    }}
                >
                    <div className="header-dashboard-box">
                        <span className="header-dashboard-text">
                        Ringkasan Laporan {new Date().getFullYear()}
                        </span>
                    </div>
                    <div className="mainbox-containe" >
                        <div className="header-tabs-dashboard" >
                            {
                                reportSummaryNow === 'monthly' ?
                                <div className="dashboard-list-tab" >
                                    Laporan Bulanan
                                </div>
                                :
                                <div className="dashboard-list-tab" onClick={e=>setReportSummaryNow('monthly')} style={{ borderBottom : '0px' }}>
                                    Laporan Bulanan
                                </div>
                            }
                            {
                                reportSummaryNow === 'weekly' ?
                                <div className="dashboard-list-tab">
                                    Laporan Mingguan
                                </div>
                                :
                                <div className="dashboard-list-tab" onClick={e=>setReportSummaryNow('weekly')} style={{ borderBottom : '0px' }}>
                                    Laporan Mingguan
                                </div>
                            }
                        </div>
                        <div className="filter-contain-dashboard" >
                            <input type="text" onChange={e=>setSearchRightBottom(e.target.value)} className="filter-dashboard-tab" placeholder="Cari di Sini.." style={{ color: '#999999' }}/>
                            {/* <select className="filter-status-dashboard">
                                <option value="" selected >Filter Status</option>
                                <option value="On Time"> Tepat Waktu </option>
                                <option value="Late One Week">Terlambat Satu Minggu</option>
                                <option value="Late Two Week">Terlambat Dua Minggu</option>
                                <option value="Late More Two Week">Terlambat Lebih Dari Dua Minggu</option>
                            </select> */}
                        </div>
                    </div>
                    <div className="fixed_header-div" style={{ overflowX: 'auto', height: 380  }}>
                    {
                        reportSummaryNow === 'monthly' &&
                        <table className="fixed_headers">
                            <thead>
                                <tr>
                                    <th style={{ width: "2rem" }} >NO</th>
                                    <th >NAMA</th>
                                    <th >TEPAT WAKTU</th>
                                    <th >TERLAMBAT SATU MINGGU</th>
                                    <th >TERLAMBAT DUA MINGGU</th>
                                    <th >TERLAMBAT LEBIH DARI DUA MINGGU</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    vitoMonthCountFilter &&
                                    vitoMonthCountFilter
                                    .filter(a=> searchRightBottom ? getNameFix(a).includes(searchRightBottom) : a )
                                    .map((data,i)=>(
                                    <tr key={i} style={{ backgroundColor: i % 2 === 0 ? '#FAFAFA' : '#FFFFFF' }} >
                                        <td>{ i+=1 }</td>
                                        <td>{ data && data.name ? data.name : '-'  } { data && data.country && ` - ${data.country}` } { data && data.area && ((data.area !== 'tidak ada area') && data.area) && ` - ${data.area}` }</td>
                                        <td align="center" >{ data && data['Tepat Waktu'] ? data['Tepat Waktu'] : '0' }</td>
                                        <td align="center" >{ data && data['Terlambat Satu Minggu'] ? data['Terlambat Satu Minggu'] : '0' }</td>
                                        <td align="center" >{ data && data['Terlambat Dua Minggu'] ? data['Terlambat Dua Minggu'] : '0' }</td>
                                        <td align="center" >{ data && data['Terlambat Lebih Dari Dua Minggu'] ? data['Terlambat Lebih Dari Dua Minggu'] : '0' }</td>
                                    </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    }
                    {
                        reportSummaryNow === 'weekly' &&
                        <table className="fixed_headers">
                            <thead>
                                <tr>
                                    <th style={{ width: "2rem" }} >NO</th>
                                    <th >NAMA</th>
                                    <th >TEPAT WAKTU</th>
                                    <th >TERLAMBAT</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    vitoWeekCountFilter &&
                                    vitoWeekCountFilter
                                    .filter(a=> searchRightBottom ? getNameFix(a).includes(searchRightBottom) : a )
                                    .map((data,i)=>(
                                    <tr key={i} style={{ backgroundColor: i % 2 === 0 ? '#FAFAFA' : '#FFFFFF' }} >
                                        <td>{ i+=1 }</td>
                                        <td>{ data && data.name ? data.name : '-'  } { data && data.country && ` - ${data.country}` } { data && data.area && ((data.area !== 'tidak ada area') && data.area) && ` - ${data.area}` }</td>
                                        <td align="center" >{ data && data['On Time'] ? data['On Time'] : '0' }</td>
                                        <td align="center" >{ data && data['Late'] ? data['Late'] : '0' }</td>
                                    </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    }
                    </div>
                </div>
            </div>
        </div>
    )
}