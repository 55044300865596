import React, { useState, useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles, Box, Typography, withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import logoWI from '../../../../images/bird.png'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import './styles.scss'
import CloseIcon from '@material-ui/icons/Close';
import BoxImg from './BoxImg'
import { useParams } from 'react-router-dom'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw, ContentState, convertFromHTML } from 'draft-js';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: "1rem",
        borderBottom: "1px solid #E1E1E1"
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const useStyles = makeStyles({
    buttonRead: {
        color: "#E23F81",
        background: "rgba(226, 63, 129, 0.1)",
        borderRadius: "2px",
        width: "100px",
        border: "none",
        textTransform: "none"
    },
    boxProfile: {
        borderTop: "0.5px solid #DDDDDD",
        borderBottom: "0.5px solid #DDDDDD",
        padding: "1em 2.5em",
        background: "#FAFAFA",
        display: 'flex',
        flexDirection: 'column'
    },
    titleWorkplan: {
        color: "#E23F81"
    },
    buttonKPI: {
        marginLeft: "2em",
        color: "#E23F81",
        border: "1px solid #E23F81",
        background: "rgba(226, 63, 129, 0.1)",
        borderRadius: "2px",
        textTransform: "none"
    },
    buttonView: {
        color: "white",
        background: "#E23F81",
        borderRadius: "20px",
        width: "100px",
        textTransform: "none",
        boxShadow: "none"
    },
    text1:{
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '24px',
        lineHeight: '28px',
        color: "#2C304D",
    },
    text2:{
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '16px',
        color: "#999999",
        marginTop: 7
    },
    text3:{
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '18px',
        color: "#444444",
        marginTop: 15
    }
})

export default function ScrollDialog(props) {
    const classes = useStyles()
    const [open, setOpen] = useState(false);
    const [scroll, setScroll] = useState('paper');
    const {
        data, weeklyDate
    } = props
    const { year } = useParams()

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);
    
    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getDate = (time) => {
        let date = new Date(time).getDate()
        let month = new Date(time).getMonth()
        let year = new Date(time).getFullYear()

        return `${year}-${month < 10 ? '0' + month : month}-${date < 10 ? '0' + date : date }`
    }

    const getWeek = (id) => {
        let find = '-'
        if (weeklyDate) {
            weeklyDate.map(data=>{
                if (data.id == id) {
                    find = data.week
                }
            })
        }
        return 'Week '+find
    }

    const descriptionElementRef = useRef(null);

    return (
        <div>
            <Button variant="contained" size="small" className={classes.buttonView} onClick={handleClickOpen('body')}>{props.text}</Button>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth="lg"
                disableEnforceFocus
            >
                <DialogTitle id="scroll-dialog-title" onClose={handleClose} ><Box paddingX="1em" style={{ textTransform: 'capitalize' }}>Detail Gallery</Box></DialogTitle>
                <Box className={classes.boxProfile} display="flex" style={{ maxHeight: 400, overflowY:'auto' }}>
                    <div className={classes.text1}>
                        { data && data.name ? data.name : data.WeekId && getWeek(data.WeekId) }
                    </div>
                    <div className={classes.text2}>
                        {data && data.created ? getDate(data.created) : data.reportCreatedAt ? getDate(data.reportCreatedAt) : data.createdAt && getDate(data.createdAt) }
                    </div>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        {
                            data &&
                            !data.status ?
                            <Editor
                                editorState={data && (data.description && data.description != 'null') ?
                                    EditorState.createWithContent(convertFromRaw(JSON.parse(data.description)))
                                    :
                                    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                }
                                toolbarHidden
                                readOnly
                                toolbar={false}
                                editorClassName={classes.text3}
                            />
                            :
                            <div style={{
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 'normal',
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: "#444444",
                                marginTop: 15
                            }} >
                                { data.description }
                            </div>
                        }
                    </DialogContentText>
                </Box>
                <DialogContent dividers={scroll === 'paper'}>
                    <Box display= "flex" flexWrap="wrap" justifyContent="center" alignItems="center" style={{ maxHeight: 250, overflowY:'auto' }}>
                        {
                            data &&
                            data.photo ?
                            data.photo.split(',').map((item, i)=> {
                                return (
                                    <BoxImg key={i} photos={item} photosAll={data && data.photo}/>
                                )
                            })
                            :
                            data &&
                            data.photos &&
                            data.photos.split(',').map((item, i)=> {
                                return (
                                    <BoxImg key={i} photos={item} photosAll={data && data.photos}/>
                                )
                            })
                        }
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
}
