import React, { useState, useRef, useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../../../store/actions/profileAction'
import './styles.scss'
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Logo from '../../images/logo2.png'
import ProfileUnknown from '../../images/unknown.png'
import ArrowDown from '../../../../images/arrow-down.svg'
import ArrowUp from '../../../../images/arrow-up.svg'
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import { Switch, Route, useHistory } from 'react-router-dom'
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';

import Dashboard from './Dashboard'
import Report from './Report'
import Tabulasi from './Tabulasi'
import TabulasiDetail from './Tabulasi/detail'
import Gallery from './Gallery'
import Vito from './Vito'
import RequestData from './RequestData'
import EvaluationVito from './Evaluation/Vito'
import EvaluationInputKPI from './Evaluation/InputKPI'
import EvaluationLihatKPI from './Evaluation/LihatKPI'
import CoordinatorMonthly from './Coordinator/monthly'
import CoordinatorWeekly from './Coordinator/weekly'
import CoordinatorQuarterly from './Coordinator/quarterly'
import CoordinatorOutlook from './Coordinator/outlook'
import User from './User'
import ReorderIcon from '@material-ui/icons/Reorder';
import Notification from './Notification'

import Partner from './Partner'
import AddPartner from './Partner/AddPartner'
import EditPartner from './Partner/EditPartner'

import CategoryReport from './Report/CategoryReport'
import DetailEvaluation from './Evaluation/Vito/DetailEvaluation'
import ModalChangePassword from '../../component/Modal/changePassword'
import Axios from 'axios'
import socketClient from "socket.io-client";
import { SERVER } from '../../../../server'
import Swal from 'sweetalert2'
import { getRequestData } from '../../../../store/actions/requestDataAction'
import { getReportMonthDashboard, getReportWeeklyDashboard, getReportMonthCountDashboard, getReportWeekCountDashboard, getTabulasiDashboard } from '../../../../store/actions/reportAction'
import { getWeeklyDashboard, getMonthlyDashboard } from '../../../../store/actions/vitoAction'
import { getNotificationCount, getNotificationPICCount, getOnlinePromotionCount } from '../../../../store/actions/notificationAction'
import { getPartner } from '../../../../store/actions/partnerAction'

const drawerWidth = 253;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    height: 62,
    backgroundColor: '#F2F3F9',
    borderBottom: '1px solid #E1E1E1',
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    borderRight: 'none',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    ...theme.mixins.toolbar,
  },
  content: {
    padding: theme.spacing(3),
    paddingTop: 15,
    flexGrow: 1,
    boxSizing: 'content-box',
    minHeight: 'calc(100vh)',
    backgroundColor: '#F2F3F9'
  },
  popupTop: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '10px',
    lineHeight: '12px',
    color: '#666666',
    height: 34
  },
  paperCustom: {
    padding: 0,
    marginTop: 5,
    boxShadow: "0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
  },
  notifications: {
    color: '#999999',
    fontSize: 25
  },
  arrowProfile: {
    color: '#CCCCCC',
    fontSize: 25
  },
  popperProfile: {
    padding: 0
  },
  divider: {
    height: 0.5,
    backgroundColor: '#DDDDDD'
  },
  iconBtn: {
    marginRight: 10,
    fontSize: 25
  },
  btnLessIcon: {
    color: '#E23F81'
  }
}));

const MenuButton = withStyles((theme) => ({
  root: {
    width: '90%',
    height: '50px',
    backgroundColor: 'none',
    borderRadius: '4px',
    boxShadow: 'none',
    textTransform: 'none',
    transition: '0.5s',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '19px',
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "18px",
    color: "#999999",
    '&:hover': {
      backgroundColor: '#d8e0e5',
      color: '#E23F81',
      boxShadow: 'none',
    },
  },
}))(Button);

const MenuButtonSmall = withStyles((theme) => ({
  root: {
    marginTop: 1,
    width: '75%',
    height: '40px',
    backgroundColor: 'none',
    borderRadius: '4px',
    boxShadow: 'none',
    textTransform: 'none',
    transition: '0.5s',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '19px',
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "18px",
    color: "#999999",
    '&:hover': {
      backgroundColor: '#d8e0e5',
      color: '#E23F81',
      boxShadow: 'none',
    },
  },
}))(Button);

const MenuButtonActive = withStyles((theme) => ({
  root: {
    width: '90%',
    height: '50px',
    backgroundColor: '#E23F81',
    borderRadius: '4px',
    boxShadow: 'none',
    textTransform: 'none',
    transition: '0.5s',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '19px',
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "18px",
    color: "#FFFFFF",
    '&:hover': {
      backgroundColor: '#E23F81',
      color: '#FFFFFF',
      boxShadow: 'none',
    },
  },
}))(Button);

const MenuButtonSmallActive = withStyles((theme) => ({
  root: {
    marginTop: 1,
    width: '75%',
    height: '40px',
    backgroundColor: '#E23F81',
    borderRadius: '4px',
    boxShadow: 'none',
    textTransform: 'none',
    transition: '0.5s',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '19px',
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "18px",
    color: "#FFFFFF",
    '&:hover': {
      backgroundColor: '#E23F81',
      color: '#FFFFFF',
      boxShadow: 'none',
    },
  },
}))(Button);

const SubMenuButton = withStyles((theme) => ({
  root: {
    width: '90%',
    height: '50px',
    backgroundColor: 'none',
    borderRadius: '4px',
    boxShadow: 'none',
    textTransform: 'none',
    transition: '0.5s',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '19px',
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "18px",
    color: "#999999",
    '&:hover': {
      backgroundColor: '#d8e0e5',
      color: '#E23F81',
      boxShadow: 'none',
    },
  },
}))(Button);

const SubMenuButtonActive = withStyles((theme) => ({
  root: {
    width: '90%',
    height: '50px',
    backgroundColor: '#E23F81',
    borderRadius: '4px',
    boxShadow: 'none',
    textTransform: 'none',
    transition: '0.5s',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '19px',
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "18px",
    color: "#FFFFFF",
    '&:hover': {
      backgroundColor: '#E23F81',
      color: '#FFFFFF',
      boxShadow: 'none',
    },
  },
}))(Button);

export default function Menu() {
  const classes = useStyles();
  const History = useHistory();
  const Dispatch = useDispatch();
  const anchorRef = useRef(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [open, setOpen] = useState(true);
  const [title, setTitle] = useState('')
  const nameProfile = useSelector(state=> state.profileState.name)
  const photoProfile = useSelector(state=> state.profileState.photo)
  const [modalToggle , setModalToggle] = useState(false);
  const [showPassword, setShowPassword] = useState(false)
  const [password, setPassword] = useState('')
  const [Null, setNull] = useState(false)
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false)
  const [passwordRepeat, setPasswordRepeat] = useState('')
  const [NullRepeat, setNullRepeat] = useState(false)
  const [error, setError] = useState(false)
  const roleProfile = useSelector(state=> state.profileState.role)
  const filterYears = useSelector(state=> state.filterState.years)
  const weekly = useSelector(state=> state.vitoState.weeklyDashboard)
  const notificationADMIN = useSelector(state=> state.notificationState.count)
  const notificationPIC = useSelector(state=> state.notificationState.countPIC)
  const notificationPO = useSelector(state=> state.notificationState.countPO)
  const myRole = useSelector(state=> state.profileState.role)
  const socket = socketClient(SERVER, {transports: ['websocket'], secure: true});
  const [subButton, setSubButton] = useState({ coordinator: false })
  const myid = useSelector(state=> state.profileState.id)
  const [listIdApproved] = useState([112, 28, 99, 100, 98, 97])

  useEffect(()=>{
    const newYear = new Date().getFullYear()
    if (newYear === 2019) {
      Dispatch(getTabulasiDashboard(1))
    } else if (newYear === 2020) {
      Dispatch(getTabulasiDashboard(2))
    } else if (newYear === 2021) {
      Dispatch(getTabulasiDashboard(3))
    } else if (newYear === 2022) {
      Dispatch(getTabulasiDashboard(4))
    } else if (newYear === 2023) {
      Dispatch(getTabulasiDashboard(5))
    } else if (newYear === 2024) {
      Dispatch(getTabulasiDashboard(6))
    } else if (newYear >= 2025) {
      Dispatch(getTabulasiDashboard(7))
    }
  },[])

  useEffect(()=>{
    Dispatch(getRequestData())
    socket.on('request-data', (data) => {
      Dispatch(getRequestData())
    })
  },[])

  useEffect(()=>{
    if (myRole && myid) {
      if (listIdApproved.includes(myid)) {
        Dispatch(getOnlinePromotionCount())
      } else if (myRole === 'picpasar') {
        Dispatch(getNotificationPICCount())
      } else if( myRole === 'admin' || myRole === 'markom' || myRole === 'deputi' ||myRole === 'direktorat' ||myRole === 'koordinatorvito' ||myRole === 'guest' ) {
        Dispatch(getNotificationCount())
      }
      socket.on('notification-admin', (data) => {
        if (myRole === 'picpasar') {
          Dispatch(getNotificationPICCount())
        } else if( myRole === 'admin' || myRole === 'markom' || myRole === 'deputi' ||myRole === 'direktorat' ||myRole === 'koordinatorvito' ||myRole === 'guest' ) {
          Dispatch(getNotificationCount())
        }
      });
    }
  },[myRole, myid])

  useEffect(()=>{
    if (filterYears) {
        let year
        let month
        if (new Date().getFullYear() > 2025) {
            year = filterYears.filter(e=> e.year === 2025)[0].id
        }
        if (new Date().getFullYear() < 2019) {
            year = filterYears.filter(e=> e.year === 2019)[0].id
        }
        if (new Date().getFullYear() <= 2025 && new Date().getFullYear() >= 2019) {
            year = filterYears.filter(e=> e.year === new Date().getFullYear())[0].id
        }
        Dispatch(getReportMonthCountDashboard(year))
        Dispatch(getReportWeekCountDashboard(year))
        Dispatch(getWeeklyDashboard(year))
        Dispatch(getMonthlyDashboard(year))
        switch (new Date().getMonth()) {
          case 0 :
            month = `${year}${1}`
            break;
          case 1 :
            month = `${year}${2}`
            break;
          case 2 :
            month = `${year}${3}`
            break;
          case 3 :
            month = `${year}${4}`
            break;
          case 4 :
            month = `${year}${5}`
            break;
          case 5 :
            month = `${year}${6}`
            break;
          case 6 :
            month = `${year}${7}`
            break;
          case 7 :
            month = `${year}${8}`
            break;
          case 8 :
            month = `${year}${9}`
            break;
          case 9 :
            month = `${year}${10}`
            break;
          case 10 :
            month = `${year}${11}`
            break;
          case 11 :
            month = `${year}${12}`
            break;
          default:
            month = '-'
            break;
      }
      Dispatch(getReportMonthDashboard(month))
    }
  },[filterYears])

  const showDateFix = (dateParams) => {
    const [day, month, year] = dateParams.split("/")
    let date = new Date(year, month - 1, day)
    return date
  }

  useEffect(()=>{
      if (weekly) {
        weekly.forEach((week)=>{
          if (showDateFix(`${new Date().getDate()}/${new Date().getMonth()+1}/${new Date().getFullYear()}`) >= (showDateFix(week.start)) && (showDateFix(`${new Date().getDate()}/${new Date().getMonth()+1}/${new Date().getFullYear()}`) <= showDateFix(week.end))) {
            Dispatch(getReportWeeklyDashboard(week.id))
            return
          }
        })
      }
  },[weekly])

  useEffect(() => {
    let temp = ''
    let split = History.location.pathname.split("/")
    if (split[2] === 'vito') {
      temp = 'VITO'
    } else if (History.location.pathname === '/admin/gallery' || split[split.length-1] === 'galleries') {
      temp = 'Galeri'
    } else if (History.location.pathname === '/admin' ) {
      temp = 'Dashboard'
    } else if (History.location.pathname === '/admin/report') {
      temp = 'Laporan'
    } else if (History.location.pathname === '/admin/evaluation/input-kpi') {
      temp = 'Input KPI'
    } else if (History.location.pathname === '/admin/evaluation/lihat-kpi') {
      temp = 'Lihat KPI'
    } else if (split[2] === 'evaluation') {
      temp = 'Evaluasi'
    } else if (History.location.pathname === '/admin/user') {
      temp = 'Daftar User'
    } else if (History.location.pathname === '/admin/request-data') {
      temp = 'Permintaan Laporan'
    } else if (History.location.pathname === '/admin/coordinator/weekly') {
      temp = 'Koordinator Laporan 2 Mingguan'
      setSubButton({
        coordinator : true
      })
    } else if (History.location.pathname === '/admin/coordinator/monthly') {
      temp = 'Koordinator Laporan Bulanan'
      setSubButton({
        coordinator : true
      })
    } else if (History.location.pathname === '/admin/coordinator/quarterly') {
      temp = 'Koordinator Laporan 3 Bulanan'
      setSubButton({
        coordinator : true
      })
    } else if (split[split.length-1] === 'weekly') {
      temp = 'Laporan Mingguan'
    } else if (split[split.length-1] === 'monthly') {
      temp = 'Laporan Bulanan'
    } else if (History.location.pathname === '/admin/partner') {
      temp = 'Partner'
    } else if (History.location.pathname === '/admin/partner/add') {
      temp = 'Partner'
    }

    setTitle(temp)
  }, [History.location.pathname])

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenPopup(false);
  };

  const handleToggle = () => {
    setOpenPopup((prevOpen) => !prevOpen);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleLogout = () => {
    Dispatch(logout(History))
  }

  const modalHandler = (e, data) => {
    if (e) {
        e.preventDefault();
    }
    setOpenPopup(false);
    setModalToggle(!modalToggle)
  }

  const passwordHandler = () => {
    setShowPassword(!showPassword)
  }

  const passwordHandlerRepeat = () => {
    setShowPasswordRepeat(!showPasswordRepeat)
  }

  const handleChangePass = () => {
    setNull(true)
    if(password && passwordRepeat) {
      Axios({
        method: 'PATCH',
        url: `${SERVER}/user/change-password`,
        data: { password, passwordRepeat },
        headers: { token: localStorage.getItem('token') }
      })
      .then(({data})=>{
        setNull(null)
        setNullRepeat(null)
        setError(null)
        modalHandler()
        setPasswordRepeat('')
        setPassword('')
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        Toast.fire({
          icon: 'success',
          title: 'Ganti Password Berhasil'
        })
        handleLogout()
      })
      .catch(error=>{
        if (error.response) {
          if (error.response.data) {
            if (error.response.data.message) {
              if (error.response.data.message === 'password tidak sama') {
                setNullRepeat(true)
              }
              setError(error.response.data.message)
            }
            if (error.response.data.errors) {
              setError(error.response.data.errors[0])
            }
          }
        }
      })
    }
  }

  const handleModalIN = (e) => {
    if (e) { e.preventDefault() }
    setModalToggle(!modalToggle)
    setShowPassword(null)
    setPassword('')
    setNull(null)
    setShowPasswordRepeat(null)
    setPasswordRepeat('')
    setNullRepeat(null)
    setError(null)
  }

  const actionSubButton = (key, action) => {
    switch (key) {
      case 'coordinator':
        setSubButton({
          coordinator: action
        })
        break;
      default:
        break;
    }
  }

  const goPage = (root) => {
    History.push(root)
  }

  useEffect(()=>{
    Dispatch(getPartner())
    socket.on('partner', (data) => {
      Dispatch(getPartner())
    })
  },[])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar,
          [classes.appBarShift],
        )}
      >
        <Toolbar>
        <ReorderIcon style={{ fontSize: "2.5em", color: "black", marginRight: "8px" }} />
          <span className="dashboard-head-txt" >
          {title}
          </span>
          <div className="container-appbar-right-side" >
            <div className="notif-container" onClick={e=> History.push('/admin/notification')} style={{ cursor: 'pointer' }} >
              <NotificationsIcon className={classes.notifications} />
              <div className="notification-icons" >
                { 
                  listIdApproved.includes(myid) ?
                    notificationPO
                  :
                  myRole === 'picpasar' ?
                    notificationPIC
                  :
                    ( myRole === 'admin' || myRole === 'markom' || myRole === 'deputi' ||myRole === 'direktorat' ||myRole === 'koordinatorvito' ||myRole === 'guest' ) ?
                    notificationADMIN
                  :
                  0
                }
              </div>
            </div>
            <div className="profile-navbar-btn"
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            >
              <img src={photoProfile ? photoProfile : ProfileUnknown} alt="photo profile" className="photo-profile-navbar" />
              <span className="profile-txt-name" > { nameProfile ? nameProfile : '-' } </span>
              {
                !openPopup ?
                  <ExpandMoreIcon className={classes.arrowProfile} />
                  :
                  <ExpandLessIcon className={classes.arrowProfile} />
              }
              <Popper open={openPopup} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center top' }}
                  >
                    <Paper className={classes.paperCustom} >
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList autoFocusItem={openPopup} id="menu-list-grow" className={classes.popperProfile} onKeyDown={handleListKeyDown}>
                          <MenuItem onClick={e=>[handleModalIN(e), handleClose(e)]} className="popup-list" >
                            <i className="fas fa-unlock unlock-custom" />
                            <span className="text-menu-item-popup" > Ganti Password </span>
                          </MenuItem>
                          <Divider className={classes.divider} />
                          <MenuItem onClick={handleLogout} className="popup-list">
                            <i className="fas fa-sign-out-alt unlock-custom" />
                            <span className="text-menu-item-popup"> Keluar </span>
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        style={{ display: ((History.location.pathname).includes('/admin/notification')) && 'none' }}
        variant="permanent"
        className={clsx(classes.drawer,
          [classes.drawerOpen]
        )}
        classes={{
          paper: clsx(
            [classes.drawerOpen]
          ),
        }}
      >
        <div className="container-img-sidebar" >
          <img src={Logo} className="logo-sidebar" alt="logo" />
        </div>
        <div className="button-sidebar-wrapper">
          {
            roleProfile !== 'guest' &&
            History.location.pathname === '/admin' ?
            <MenuButtonActive>
              <DashboardIcon className={classes.iconBtn} />
              Dashboard
            </MenuButtonActive>
              :
            roleProfile !== 'guest' &&
            <MenuButton onClick={e => History.push('/admin')} >
              <DashboardIcon className={classes.iconBtn} />
              Dashboard
            </MenuButton>
          }
          {
            History.location.pathname === '/admin/vito' ?
              <MenuButtonActive>
                <AccountCircleIcon className={classes.iconBtn} />
              VITO
            </MenuButtonActive>
              :
              <MenuButton onClick={e => History.push('/admin/vito')} >
                <AccountCircleIcon className={classes.iconBtn} />
              VITO
            </MenuButton>
          }
          {
            (((History.location.pathname).includes('/admin/report')) || ((History.location.pathname).includes('/admin/gallery'))) ?
              <MenuButtonActive onClick={e => History.push('/admin/report')}>
                <LibraryBooksIcon className={classes.iconBtn}/>
              Laporan
            </MenuButtonActive>
              :
              <MenuButton onClick={e => History.push('/admin/report')} >
                <LibraryBooksIcon className={classes.iconBtn} />
              Laporan
            </MenuButton>
          }
          {
            roleProfile !== 'guest' &&
              (((History.location.pathname).includes('/admin/tabulasi'))) ?
                <MenuButtonActive onClick={e => History.push('/admin/tabulasi')}>
                  <CollectionsBookmarkIcon className={classes.iconBtn}/>
                Tabulasi
              </MenuButtonActive>
            :
            roleProfile !== 'guest' &&
              <MenuButton onClick={e => History.push('/admin/tabulasi')} >
                <CollectionsBookmarkIcon className={classes.iconBtn} />
                Tabulasi
              </MenuButton>
          }
          {
            (roleProfile !== 'markom' && roleProfile !== 'guest') &&
            (((History.location.pathname).includes('/admin/evaluation'))) ?
              <MenuButtonActive>
              <SignalCellularAltIcon className={classes.iconBtn}/>
                Evaluasi VITO
            </MenuButtonActive>
            :
            (roleProfile !== 'markom' && roleProfile !== 'guest') &&
            <MenuButton onClick={e => History.push('/admin/evaluation/vito')} >
              <SignalCellularAltIcon className={classes.iconBtn} />
                Evaluasi VITO
            </MenuButton>
          }

          {/* {
            (openEvaluation) ?
              <>
                <MenuButtonActive onClick={() => setOpenEvaluation(!openEvaluation)} >
                  <i className="fas fa-clipboard-list iconBtn" />
              Evaluation
              <div className="dropdown-sidebar-btn" >
                    <ExpandLessIcon className={classes.btnLessIcon} />
                  </div>
                </MenuButtonActive>
                
                {
                  History.location.pathname === '/admin/evaluation/input-kpi' ?
                    <MenuButtonSmallActive>
                      <i className="fas fa-file-alt iconBtn-file" />
                      Input Evaluation
                    </MenuButtonSmallActive>
                    :
                    <MenuButtonSmall onClick={() => History.push('/admin/evaluation/input-kpi')} >
                      <i className="fas fa-file-alt iconBtn-file" />
                      Input Evaluation
                    </MenuButtonSmall>
                }
                {
                  History.location.pathname === '/admin/evaluation/lihat-kpi' ?
                    <MenuButtonSmallActive>
                      <i className="fas fa-file-alt iconBtn-file" />
                      Lihat Evaluation
                    </MenuButtonSmallActive>
                    :
                    <MenuButtonSmall onClick={() => History.push('/admin/evaluation/lihat-kpi')} >
                      <i className="fas fa-file-alt iconBtn-file" />
                    Lihat Evaluation
                  </MenuButtonSmall>
                }
              </>
              :
              (!openEvaluation && !(History.location.pathname).includes('/admin/evaluation')) ?
                <MenuButton onClick={() => [History.push('/admin/evaluation/vito'), setOpenEvaluation(!openEvaluation), setOpenReport(false)]} >
                  <i className="fas fa-clipboard-list iconBtn" />
              Evaluation
              <div className="dropdown-sidebar-btn" >
                    <ExpandMoreIcon />
                  </div>
                </MenuButton>
                :
                <MenuButtonActive onClick={() => setOpenEvaluation(!openEvaluation)} >
                  <i className="fas fa-clipboard-list iconBtn" />
              Evaluation
              <div className="dropdown-sidebar-btn" >
                    <ExpandMoreIcon className={classes.btnLessIcon} />
                  </div>
                </MenuButtonActive> */}
          {
            (
              roleProfile !== 'picpasar' &&
              roleProfile !== 'markom' &&
              roleProfile !== 'deputi' &&
              roleProfile !== 'direktorat' &&
              roleProfile !== 'koordinatorvito' &&
              roleProfile !== 'guest'
            ) &&
            History.location.pathname === '/admin/user' ?
            <MenuButtonActive>
              <i className="fas fa-users iconBtn" style={{fontSize: "1rem"}}></i>
              Pengguna
            </MenuButtonActive>
              :
            (
              roleProfile !== 'picpasar' &&
              roleProfile !== 'markom' &&
              roleProfile !== 'deputi' &&
              roleProfile !== 'direktorat' &&
              roleProfile !== 'koordinatorvito' &&
              roleProfile !== 'guest'
            ) &&
            <MenuButton onClick={e => History.push('/admin/user')} >
              <i className="fas fa-users iconBtn" style={{fontSize: "1rem"}}></i>
              Pengguna
            </MenuButton>
          }
          {
            (
              roleProfile !== 'picpasar' &&
              roleProfile !== 'deputi' &&
              roleProfile !== 'direktorat' &&
              roleProfile !== 'koordinatorvito' &&
              roleProfile !== 'guest'
            ) &&
            History.location.pathname === '/admin/request-data' ?
            <MenuButtonActive>
              <i className="fas fa-copy iconBtn" />
              Permintaan Laporan
            </MenuButtonActive>
              :
            (
              roleProfile !== 'picpasar' &&
              roleProfile !== 'deputi' &&
              roleProfile !== 'direktorat' &&
              roleProfile !== 'koordinatorvito' &&
              roleProfile !== 'guest'
            ) &&
            <MenuButton onClick={e => History.push('/admin/request-data')} >
              <i className="fas fa-copy iconBtn" />
              Permintaan Laporan
            </MenuButton>
          }
          {
            roleProfile !== 'guest' &&
            subButton.coordinator ?
            <Fragment>
              <MenuButton onClick={e => actionSubButton('coordinator',false)}>
                <i className="fas fa-database iconBtn" />
                <div
                  style={{
                    textAlign: 'left'
                  }}
                >
                  {/* Laporan Tim<br/>Pendamping VITO */}
                  Laporan Management<br/>Support Team (MST)
                </div>
                <img src={ArrowUp} alt="icon" style={{ width: 18, height: 18, marginLeft: 'auto', marginRight: 10 }} />
              </MenuButton>
              {
                History.location.pathname === '/admin/coordinator/weekly' ?
                  <SubMenuButtonActive >
                    <i className="fas fa-database iconBtn" style={{ opacity: 0 }}/>
                    2 Mingguan
                  </SubMenuButtonActive>
                :
                  <SubMenuButton onClick={e=> goPage('/admin/coordinator/weekly') } >
                    <i className="fas fa-database iconBtn" style={{ opacity: 0 }}/>
                    2 Mingguan
                  </SubMenuButton>
              }
              {
                History.location.pathname === '/admin/coordinator/monthly' ?
                  <SubMenuButtonActive >
                    <i className="fas fa-database iconBtn" style={{ opacity: 0 }}/>
                    Bulanan
                  </SubMenuButtonActive>
                :
                  <SubMenuButton onClick={e=> goPage('/admin/coordinator/monthly') } >
                    <i className="fas fa-database iconBtn" style={{ opacity: 0 }}/>
                    Bulanan
                  </SubMenuButton>
              }
              {
                History.location.pathname === '/admin/coordinator/quarterly' ?
                  <SubMenuButtonActive >
                    <i className="fas fa-database iconBtn" style={{ opacity: 0 }}/>
                    3 Bulanan
                  </SubMenuButtonActive>
                :
                  <SubMenuButton onClick={e=> goPage('/admin/coordinator/quarterly') } >
                    <i className="fas fa-database iconBtn" style={{ opacity: 0 }}/>
                    3 Bulanan
                  </SubMenuButton>
              }
              {
                History.location.pathname === '/admin/coordinator/outlook' ?
                  <SubMenuButtonActive >
                    <i className="fas fa-database iconBtn" style={{ opacity: 0 }}/>
                    Outlook
                  </SubMenuButtonActive>
                :
                  <SubMenuButton onClick={e=> goPage('/admin/coordinator/outlook') } >
                    <i className="fas fa-database iconBtn" style={{ opacity: 0 }}/>
                    Outlook
                  </SubMenuButton>
              }
            </Fragment>
              :
            roleProfile !== 'guest' &&
            <Fragment>
              <MenuButton onClick={e => actionSubButton('coordinator',true)} >
                <i className="fas fa-database iconBtn" />
                <div
                  style={{
                    textAlign: 'left'
                  }}
                >
                  {/* Laporan Tim<br/>Pendamping VITO */}
                  Laporan Management<br/>Support Team (MST)
                </div>
                <img src={ArrowDown} alt="icon" style={{ width: 18, height: 18, marginLeft: 'auto', marginRight: 10 }} />
              </MenuButton>
            </Fragment>
          }  
          {
            History.location.pathname === '/admin/partner' || History.location.pathname === '/admin/partner/add' ?
              <MenuButtonActive>
              <i className="fas fa-handshake" style={{ marginRight: 10, fontSize: 20 }} />
              Partner
            </MenuButtonActive>
              :
              <MenuButton onClick={e => History.push('/admin/partner')} >
              <i className="fas fa-handshake" style={{ marginRight: 10, fontSize: 20 }} />
              Partner
            </MenuButton>
          }
        </div>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
          <Route path="/admin/tabulasi/:userId/:monthId/:yearId" component={TabulasiDetail} />
          <Route path="/admin/report/galleries/add" component={CategoryReport} />
          <Route path="/admin/report/monthly/add" component={CategoryReport} />
          <Route path="/admin/report/monthly/:id" component={CategoryReport} />
          <Route path="/admin/report/weekly/:id" component={CategoryReport} />
          <Route path="/admin/report/galleries/:id" component={CategoryReport} />
          <Route path="/admin/user" component={User} />
          <Route path="/admin/evaluation/lihat-kpi" component={EvaluationLihatKPI} />
          <Route path="/admin/evaluation/input-kpi" component={EvaluationInputKPI} />
          <Route path="/admin/evaluation/vito/detail/:id" component={DetailEvaluation} />
          <Route path="/admin/evaluation/vito" component={EvaluationVito} />
          <Route path="/admin/vito" component={Vito} />
          <Route path="/admin/gallery" component={Gallery} />
          <Route path="/admin/report" component={Report} />
          <Route path="/admin/tabulasi" component={Tabulasi} />
          <Route path="/admin/request-data" component={RequestData} />
          <Route path="/admin/coordinator/monthly" component={CoordinatorMonthly} />
          <Route path="/admin/coordinator/weekly" component={CoordinatorWeekly} />
          <Route path="/admin/coordinator/quarterly" component={CoordinatorQuarterly} />
          <Route path="/admin/coordinator/outlook" component={CoordinatorOutlook} />
          <Route path="/admin/notification" component={Notification} />
          <Route path="/admin/partner/add" component={AddPartner} />
          <Route path="/admin/partner/edit/:id" component={EditPartner} />
          <Route path="/admin/partner" component={Partner} />
          <Route path="/admin" component={Dashboard} />
        </Switch>
      </main>
      <ModalChangePassword 
          show={modalToggle} modalClosed={modalHandler} children={"modal"}
          passwordHandler={passwordHandler}
          passwordHandlerRepeat={passwordHandlerRepeat}
          password={password}
          setPassword={setPassword}
          Null={Null}
          showPassword={showPassword}
          passwordRepeat={passwordRepeat}
          NullRepeat={NullRepeat}
          showPasswordRepeat={showPasswordRepeat}
          setPasswordRepeat={setPasswordRepeat}
          error={error}
          handleChangePass={handleChangePass}
          setNull={setNull}
          setNullRepeat={setNullRepeat}
      />
    </div>
  );
}