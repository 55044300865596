import React, { Fragment, useEffect, useState } from 'react'
import { Box, Grid, makeStyles, FormControl, InputLabel, Select, MenuItem, withStyles, Button, InputAdornment  } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import './styles.scss'
import { useParams, useHistory, Switch, Route, useRouteMatch, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Weekly from '../../Report/CategoryReport/Weekly'
import Monthly from '../../Report/CategoryReport/Montly'
import Galleries from './Galleries'
import { DatePicker } from "@material-ui/pickers";
import AddGallery from './Galleries/AddGalleries'
import AddMonthlyReport from './Montly/Add'
import { useDispatch } from 'react-redux'
import { getOneVito, getWeekly, getMonthly } from '../../../../../../store/actions/vitoAction'

function createData(id, name, country, email, phone) {
    return { id, name, country, email, phone };
}

const rows = [
    createData(1, "Ms. Nattamon Limthanachai", "Thailand", ["oh@asialife.co.th"], "+66 843 4374 437"),
    createData(2, "Ms. Anna Kiseleva", "Rusia", ["kiseleva@effixcom.ru"], "+7 329 3827 7832"),
    createData(3, "Mr. Mostafa El Enany", "Saudi Arabia", ["vitosaudiarabia@hotmail.com"], "+966 9845 8594 84"),
    createData(4, "Mr. Kelvin Ong", "Malaysia", ["vito.my@aviareps.com"], "+60 8945 5948 5489"),
    createData(5, "Ms. Susan van Egmond", "Netherland", ["susan@tourismmarketingconcepts.com"], "+31 4983 4893 43"),
    createData(6, "Mr. Sulaiman Shedek", "Singapora", ["info@tourism-indonesia.sg", "vito-singapore@hotmail.com"], "+65 9458 5485 45"),
    createData(7, "Ms. Janet Juang", "China - Guangzhou", ["vito-guangzhou@tlmchina.com"], "+86 598 548 548"),
    createData(8, "Mr. Emil Hardy Ridwan", "Australia - Melbourne", ["info@vitomelbourne.com.au"], "+61 74 584 548"),
];

const LeftButton = withStyles((theme) => ({
    root: {
        width: '100%',
        height: '50px',
        backgroundColor: 'none',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        transition: '0.5s',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: '19px',
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "15px",
        lineHeight: "18px",
        color: "#999999",
        '&:hover': {
            backgroundColor: '#d8e0e5',
            color: '#E23F81',
            boxShadow: 'none',
        },
    },
}))(Button);

const LeftButtonActive = withStyles((theme) => ({
    root: {
        width: '100%',
        height: '50px',
        backgroundColor: '#E23F81',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        transition: '0.5s',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: '19px',
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "15px",
        lineHeight: "18px",
        color: "#FFFFFF",
        '&:hover': {
            backgroundColor: '#E23F81',
            color: '#FFFFFF',
            boxShadow: 'none',
        },
    },
}))(Button);

const useStyles = makeStyles({
    navLeft: {
        height: "100vh",
        background: "white",
        padding: "1.5em",
        paddingBottom: '0px',
    },
    rigth: {
        marginLeft: "1em",
        height: "100%",
        width: '100%'
    },
    topBar: {
        background: "white"
    },
    formControl: {
        width: "100%",
        cursor: 'pointer'
    },
})

function useQuery(){
    return new URLSearchParams(useLocation().search)
}

export default function PIN(props) {
    const { path, url } = useRouteMatch();
    const [value, setValue] = React.useState(0);
    const [selectedDate, handleDateChange] = useState(new Date());
    const classes = useStyles();
    const dispatch = useDispatch()
    const query = useQuery()
    const history = useHistory()
    const { id } = useParams()
    const [breadcrumb, setBreadcrup] = useState()
    const filterYears = useSelector(state=> state.filterState.years)
    const [done, setDone] = useState(false)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function setCategory(category) {
        if (category === 'monthly') {
            history.push(`/admin/report/monthly/${id}?year=${selectedDate.getFullYear()}`)
        } else if (category === 'weekly') {
            history.push(`/admin/report/weekly/${id}?year=${selectedDate.getFullYear()}`)
        } else if (category === 'galleries') {
            history.push(`/admin/report/galleries/${id}`)
        }
    }

    useEffect(() => {
        handleDateChange(
            query.get('year') ?
            new Date(query.get('year')) :
            new Date().getFullYear() > 2025 ? new Date(2025) :
            new Date().getFullYear() < 2019 ? new Date(2019) :
            new Date()
        )
        setDone(true)
        dispatch(getOneVito(id))
    },[])

    useEffect(()=>{
        if (done) {
            let year
            if (selectedDate.getFullYear() > 2025) {
                handleDateChange(2025)
                year = filterYears.filter(e=> e.year === 2025)[0].id
            }
            if (selectedDate.getFullYear() < 2019) {
                handleDateChange(2019)
                year = filterYears.filter(e=> e.year === 2019)[0].id
            }
            if (selectedDate.getFullYear() <= 2025 && selectedDate.getFullYear() >= 2019) {
                year = filterYears.filter(e=> e.year === selectedDate.getFullYear())[0].id
            }
            dispatch(getWeekly(year))
            dispatch(getMonthly(year))
        }
    },[done, selectedDate])

    return (
        <Fragment>
            <div className="dashboard" >
                <span className="breadcrumb-txt-black" >
                Laporan /
                </span>
                <span className="breadcrumb-slash-black" >
                    {breadcrumb}
            </span>
            </div>
            <div className="vito-profile">
                <Box width='120px' className={classes.navLeft}>
                    <Box height="90vh">
                        <Box justifyContent="center">
                        {
                            !history.location.pathname.includes('/galleries') &&
                            <DatePicker
                                className={classes.formControl}
                                views={["year"]}
                                label=""
                                size="small"
                                minDate={new Date("2019-01-01")}
                                maxDate={new Date("2025-01-01")}
                                inputVariant="outlined"
                                value={selectedDate}
                                onChange={date => handleDateChange(date)}
                                format="yyyy"
                                InputProps={{
                                    style:{
                                        height: "34px",
                                        paddingRight: 5,
                                        cursor: 'pointer'
                                    },
                                    endAdornment: (
                                        <InputAdornment position="end" >
                                            <ArrowDropDownIcon style={{ color: '#C4C4C4' }} />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        }
                        </Box>
                        <Box paddingTop="2em">
                            {(history.location.pathname).includes(`/admin/report/weekly`) ? <LeftButtonActive>Mingguan</LeftButtonActive> : <LeftButton onClick={() => setCategory('weekly')}>Mingguan</LeftButton>}
                            {(history.location.pathname).includes(`/admin/report/monthly`) ? <LeftButtonActive >Bulanan</LeftButtonActive> : <LeftButton onClick={() => setCategory('monthly')}>Bulanan</LeftButton>}
                            {/* {(history.location.pathname).includes(`/admin/report/galleries`) ? <LeftButtonActive>Galeri</LeftButtonActive> : <LeftButton onClick={() => setCategory('galleries')}>Galeri</LeftButton>} */}
                        </Box>
                    </Box>
                </Box>
                <Box className="box-WeeklyUptd">
                    <Switch>
                        <Route path={`/admin/report/weekly/:id`} component={Weekly} />
                        <Route path={`/admin/report/monthly/add`} component={AddMonthlyReport} />
                        <Route path={`/admin/report/monthly/:id`} component={Monthly} />
                        <Route path={`/admin/report/galleries/add`} component={AddGallery} />
                        <Route path={`/admin/report/galleries/:id`} component={Galleries} />
                    </Switch>
                </Box>
            </div>

        </Fragment>
    )
}