import {
    GET_MONTHLY,
    GET_ONEVITO,
    GET_VITO,
    GET_WEEKLY,
    GET_MONTHGALERRY,
    GET_MONTHLYDASHBOARD,
    GET_WEEKLYDASHBOARD,
    GET_REPORTMONTHLYCOUNTDASHBOARD,
    GET_REPORTWEEKLYCOUNTDASHBOARD
} from "../constanta";

const initialState = {
    vitos: [],
    vito: null,
    weekly: [],
    monthly: [],
    monthGallery: [],
    weeklyDashboard: [],
    monthlyDashboard: [],
    monthlyCountDashboard: [],
    weeklyCountDashboard: [],
}

export default (state = initialState, action)=> {
    switch(action.type){
        case GET_MONTHLY:
            return {
                ...state,
                monthly: action.data
            }
        case GET_WEEKLY:
            return {
                ...state,
                weekly: action.data
            }
        case GET_VITO:
            return {
                ...state,
                vitos: action.data
            }
        case GET_ONEVITO:
            return {
                ...state,
                vito: action.data
            }
        case GET_MONTHGALERRY:
            return {
                ...state,
                monthGallery: action.data
            }
        case GET_MONTHLYDASHBOARD:
            return {
                ...state,
                monthlyDashboard: action.data
            }
        case GET_WEEKLYDASHBOARD:
            return {
                ...state,
                weeklyDashboard: action.data
            }
        case GET_REPORTMONTHLYCOUNTDASHBOARD:
            return {
                ...state,
                monthlyCountDashboard: action.data
            }
        case GET_REPORTWEEKLYCOUNTDASHBOARD:
            return {
                ...state,
                weeklyCountDashboard: action.data
            }
        default: {
            return state
        }
    }
}