import React, { useState } from "react";
import "./styles.scss";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom'
import Axios from 'axios'
import { SERVER } from '../../../server'
import Swal from 'sweetalert2'

const ButtonOk = withStyles((theme) => ({
  root: {
      width: '180px',
      height: '44px',
      color: '#ffffff',
      backgroundColor: '#E23F81',
      fontFamily: ['Roboto', 'sans-serif'],
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '18px',
      textAlign: 'center',
      lineHeight: '21px',
      boxSizing: 'border-box',
      borderRadius: '4px',
      boxShadow: 'none',
      textTransform: 'none',
      marginRight: 20,
      '&:hover': {
          backgroundColor: '#E23F81',
          boxShadow: 'none',
      },
  },
}))(Button);

const ButtonCancel = withStyles((theme) => ({
  root: {
      width: '180px',
      height: '44px',
      color: '#E23F81',
      backgroundColor: 'none',
      fontFamily: ['Roboto', 'sans-serif'],
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '18px',
      textAlign: 'center',
      lineHeight: '21px',
      boxSizing: 'border-box',
      borderRadius: '4px',
      boxShadow: 'none',
      textTransform: 'none',
      border: '1px solid #E23F81',
      '&:hover': {
          backgroundColor: 'none',
          boxShadow: 'none',
      },
  },
}))(Button);

export default function RegisterPage() {
  const History = useHistory()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [jabatan, setJabatan] = useState('')
  const [instansi, setInstansi] = useState('')
  const [request, setRequest] = useState('')
  const [forWhat, setForWhat] = useState('')

  const handleSave = () => {
    const Toast = Swal.mixin({
      position: 'center',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    
    if ( name && email && phone && jabatan && instansi && request && forWhat ){
      Axios({
        method: 'post',
        url: `${SERVER}/request-data`,
        data: {
          name,
          email,
          phone,
          jabatan,
          instansi,
          request,
          forWhat
        },
        headers: {
            token : localStorage.getItem('token'),
        }
      })
      .then(({data})=>{
        Toast.fire({
          icon: 'success',
          title: `data telah terkirim`
        })
        History.goBack()
      })
      .catch(err=>{
          if (err.response) {
              if (err.response.data) {
                  if (err.response.data.message) {
                      Toast.fire({
                          icon: 'error',
                          title: (err.response.data.message).includes('password kurang dari 8') ? 'password kurang dari 8 karakter' : err.response.data.message
                      })
                  }
              }
          }
      })
    } else {
      Toast.fire({
        icon: 'warning',
        title: `isi seluruh data`
      })
    }
  }

  return (
    <div className="container-send-data">
      <span className="send-data-header" >
        Welcome To VITO Online Report
      </span>
      <span className="send-data-header-txt" style={{ marginTop: 10 }} >
        Ministry of Tourism and Creative Economy of the Republic of Indonesia
      </span>
      <div className="container-white-send-data-wrapper" >
        <div className="txt-back-wrapper-send-data" onClick={()=>History.goBack()} >
          <ArrowBackIcon style={{ color: "#E23F81", fontSize: 20, marginRight: 5 }} />
          <span className="txt-back-send-data" >
            Back
          </span>
        </div>
        <div className="container-white-send-data" >
          <div className="send-data-header-wrapper" >
            <span className="send-data-header-txt" >
              Fill this Form
            </span>
          </div>
          <div className="input-send-data-wrapper" >
            <input type="text" className="input-send-data" placeholder="Name" onChange={e=>setName(e.target.value)}/>
            <input type="text" className="input-send-data" placeholder="Email" style={{ marginTop: 20 }} onChange={e=>setEmail(e.target.value)}/>
            <input type="number" className="input-send-data" placeholder="Phone Number" style={{ marginTop: 20 }} onChange={e=>setPhone(e.target.value)}/>
            <input type="text" className="input-send-data" placeholder="Title" style={{ marginTop: 20 }} onChange={e=>setJabatan(e.target.value)}/>
            <input type="text" className="input-send-data" placeholder="Institution" style={{ marginTop: 20 }} onChange={e=>setInstansi(e.target.value)}/>
            <select className="minimal input-send-data" style={{ marginTop: 20, color: !request && "#AEAEAE", backgroundColor: request && 'white' }} onChange={e=>setRequest(e.target.value)}>
              <option value="" style={{ color: !(request == '') && "#AEAEAE" }}> Documents </option>
              <option value="weekly" style={{ color: !(request == 'weekly') && "#AEAEAE" }}>Weekly Report</option>
              <option value="monthly" style={{ color: !(request == 'monthly') && "#AEAEAE" }}>Monthly Report</option>
            </select>
            <input type="text" className="input-send-data" placeholder="Purpose" style={{ marginTop: 20 }} onChange={e=>setForWhat(e.target.value)}/>
          </div>
        </div>
        <div className="footer-send-data" >
          <ButtonOk onClick={()=>handleSave()} >Send</ButtonOk>
          <ButtonCancel onClick={()=>History.goBack()} >Cancel</ButtonCancel>
        </div>
      </div>
    </div>
  );
}
