import React, { useEffect } from 'react';
import {Switch, Route, useHistory} from 'react-router-dom';
import MenuAdmin from './View/Admin/pages/Menu'
import MenuVito from './View/VITO/pages/Menu'
import Login from './View/Login'
import RequestUser from "./View/Login/SendData";
import ForgetPass from "./View/Login/ForgetPassword";
import NewPass from "./View/Login/NewPassword";
import ChangePass from "./View/Login/ChangePassword";
import AddVito from './View/Admin/pages/Menu/Vito/AddVito'
import EditVito from './View/Admin/pages/Menu/Vito/EditVito'
import ViewVito from './View/Admin/pages/Menu/Vito/ViewVito'
import ViewVitoProfile from './View/VITO/component/ViewVito'
import EditVitoProfile from './View/VITO/component/EditVito'
import AddUser from './View/Admin/pages/Menu/User/AddUser'
import EditUser from './View/Admin/pages/Menu/User/EditUser'
import FileViewer from './View/VITO/component/FileViewer'
import './App.css';
import { getUser } from './store/actions/userAction'
import { profileToken, logout } from './store/actions/profileAction'
import { getVito, getVitoPIC } from './store/actions/vitoAction'
import { useDispatch, useSelector } from 'react-redux'
import socketClient from "socket.io-client";
import { SERVER } from './server'
import { getCountry } from './store/actions/countryAction'
import { getPartner, getPartnerFilter } from './store/actions/partnerAction';

function App() {
  const Dispatch = useDispatch()
  const History = useHistory()
  const roleProfile = useSelector(state=> state.profileState.role)
  const socket = socketClient(SERVER, {transports: ['websocket'], secure: true});
  // socket.on('test-connect', (data) => {
  //   console.log(`I'm connected with the back-end`, data );
  // });

  useEffect (() => {
    if (localStorage.getItem('token')) {
      Dispatch(profileToken(localStorage.getItem('token'), History))
    } else {
      Dispatch(logout(History))
    }
  }, [])

  useEffect(()=>{
    if (localStorage.getItem('token')) {
      Dispatch(getCountry())

      if (roleProfile) {
        if (roleProfile !== 'picpasar') {
          Dispatch(getUser())
          // Dispatch(getVito())
          if (roleProfile === 'direktorat') {
            Dispatch(getVitoPIC())
          } else {
            Dispatch(getVito())
          }
          if (roleProfile === 'vito') {
            Dispatch(getPartnerFilter('vito'))
          } else {
            Dispatch(getPartner())
          }
        } else if (roleProfile === 'picpasar'){
          Dispatch(getVitoPIC())
          Dispatch(getPartnerFilter('picpasar'))
        }

        socket.on('get-vito', (data) => {
          if (roleProfile !== 'picpasar') {
            Dispatch(getVito())
          }
        })

        socket.on('get-user', (data) => {
          if (roleProfile !== 'picpasar') {
            Dispatch(getUser())
          }
        })

        socket.on('get-vitoPIC', (data) => {
          // if (roleProfile === 'picpasar') {
          if (roleProfile === 'picpasar' || roleProfile === 'direktorat') {
            Dispatch(getVitoPIC())
          }
        })

        socket.on('get-partner', (data) => {
          Dispatch(getPartner())
          if (roleProfile === 'picpasar') {
            Dispatch(getPartnerFilter('picpasar'))
          } else if (roleProfile === 'vito'){
            Dispatch(getPartnerFilter('vito'))
          } else {
            Dispatch(getPartner())
          }
        })
      }
    }
  },[roleProfile])

  return (
    <Switch>
      <Route path="/vito/edit" component={EditVitoProfile} />
      <Route path="/vito/profil" component={ViewVitoProfile} />
      <Route path="/vito" component={MenuVito} />

      <Route path="/admin/user/add" component={AddUser} />
      <Route path="/admin/user/edit/:id" component={EditUser} />
      <Route path="/admin/vito/add" component={AddVito} />
      <Route path="/admin/vito/edit/:id" component={EditVito} />
      <Route path="/admin/vito/:id" component={ViewVito} />
      <Route path="/admin" component={MenuAdmin} />

      <Route path="/request-user" component={RequestUser} />
      <Route path="/forget-password" component={ForgetPass} />
      <Route path="/new-password" component={NewPass} />
      <Route path="/change-password" component={ChangePass} />
      <Route path="/document/:header" component={FileViewer} />
      <Route path="/" component={Login} />
    </Switch>
  );
}

export default App;
