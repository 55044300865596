import React,{ useState } from 'react'
import './styles.scss'
import { InputLabel, TextField, withStyles, makeStyles, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, ClickAwayListener, FormControl,Select, Button, MenuItem } from '@material-ui/core'
import { useHistory } from 'react-router-dom';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useSelector, useDispatch } from 'react-redux'
import { deleteUser } from '../../../../../store/actions/userAction'
import ModalChangePassword from '../../../component/Modal/changePassword'
import Axios from 'axios'
import { SERVER } from '../../../../../server'
import Swal from 'sweetalert2'

// COMPONENT
const useStyles = makeStyles((theme) => ({
    table:{
        overflowY: 'hidden'
    },
    tableContainer:{
        backgroundColor: "#F3F3F3",
        border: '0.5px solid #CCCCCC',
        boxSizing: 'border-box',
        borderRadius: '4px',
        marginBottom : 20,
        boxShadow: 'none',
        overflowY: 'hidden'
    },
    tableRow:{
        backgroundColor: "#F3F3F3",
    },
    tableRowTxt:{
        fontfamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '16px',
        color: '#999999'
    },
    tableRowTxtBody:{
        fontfamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '16px',
        color: '#2C304D',
        paddingTop: 5,
        paddingBottom: 5,
    },
    tableRowTxtBodyUppercase:{
        fontfamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '16px',
        textTransform: 'capitalize',
        color: '#2C304D',
        paddingTop: 5,
        paddingBottom: 5,
    },
    filter2:{
        width: '200px',
    }
}));

const useStylesBtn = makeStyles((theme) => ({
    root: {
        position: 'absolute',
    },
    dropdown: {
        justifyContent: 'center',
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        width: '155px',
        position: 'absolute',
        // top: 28,
        // right: 0,
        left: -60,
        zIndex: 99,
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        boxShadow: '6px 5px 33px -14px rgba(0,0,0,0.75)'
    },
    label:{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "16px",
        color: "#999999",
    },
    box:{
        borderBottom: '0.5px solid #DDDDDD',
        width: '100%',
        height: 35,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: 15,
        paddingRight: 15,
        color: '#999999',
        cursor: 'pointer',
        borderRadius: 4,
        '&:hover':{
            color: 'white',
            backgroundColor: '#E23F81'
        }
    },
    boxLast:{
        width: '100%',
        height: 35,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: 15,
        paddingRight: 15,
        color: '#999999',
        borderRadius: 4,
        cursor: 'pointer',
        '&:hover':{
            color: 'white',
            backgroundColor: '#E23F81'
        }
    }
}));

function ClickAway(props) {
    let { id, name, history } = props
    const classes = useStylesBtn();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch()
    const [modalToggle , setModalToggle] = useState(false);
    const [showPassword, setShowPassword] = useState(false)
    const [password, setPassword] = useState('')
    const [Null, setNull] = useState(false)
    const [showPasswordRepeat, setShowPasswordRepeat] = useState(false)
    const [passwordRepeat, setPasswordRepeat] = useState('')
    const [NullRepeat, setNullRepeat] = useState(false)
    const [error, setError] = useState(false)

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    const deleteUserAction = () => {
        dispatch(deleteUser(id))
        setOpen(false);
    }

    const updateUserAction = () => {
        history.push('/admin/user/edit/'+id)
    }

    const modalHandler = (e, data) => {
        if (e) {
            e.preventDefault();
        }
        handleClickAway()
        setModalToggle(!modalToggle)
    }

    const passwordHandler = () => {
        setShowPassword(!showPassword)
    }

    const passwordHandlerRepeat = () => {
        setShowPasswordRepeat(!showPasswordRepeat)
    }

    const handleChangePass = () => {
        setNull(true)
        if(password && passwordRepeat) {
            Axios({
                method: 'PATCH',
                url: `${SERVER}/user/${id}`,
                data: { password, passwordRepeat },
                headers: { token: localStorage.getItem('token') }
            })
            .then(({data})=>{
                setNull(null)
                setNullRepeat(null)
                setError(null)
                modalHandler()
                setPasswordRepeat('')
                setPassword('')
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
                Toast.fire({
                    icon: 'success',
                    title: 'Ganti Password Berhasil'
                })
            })
            .catch(error=>{
                if (error.response) {
                    if (error.response.data) {
                        if (error.response.data.message) {
                            if (error.response.data.message === 'password tidak sama') {
                                setNullRepeat(true)
                            }
                            setError(error.response.data.message)
                        }
                        if (error.response.data.errors) {
                            setError(error.response.data.errors[0])
                        }
                    }
                }
            })
        }
    }

    const handleModalIN = (e) => {
        if (e) {
            e.preventDefault();
        }
        handleClickAway()
        setModalToggle(!modalToggle)
        setShowPassword(null)
        setPassword('')
        setNull(null)
        setShowPasswordRepeat(null)
        setPasswordRepeat('')
        setNullRepeat(null)
        setError(null)
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div>
                <div className="rqs-dta-btn-option" style={{ backgroundColor:'#FFFFFF', marginTop: 0 }} onClick={handleClick} >
                    <MoreHorizIcon/>
                </div>
                <div className={classes.root}>
                    { open ? (
                    <div className={classes.dropdown} >
                        <div className={classes.box} onClick={()=>updateUserAction()}>
                            <i className="fas fa-pencil-alt"/>
                            <span style={{ marginLeft: 10 }} >
                                Ubah
                            </span>
                        </div>
                        <div className={classes.box} onClick={(e)=>handleModalIN(e)}>
                            <i className="fas fa-pencil-alt"/>
                            <span style={{ marginLeft: 10 }} >
                                Ganti Password
                            </span>
                        </div>
                        <div className={classes.boxLast} onClick={()=>deleteUserAction()}>
                            <i className="fas fa-trash"/>
                            <span style={{ marginLeft: 10 }}>
                                Hapus
                            </span>
                        </div>
                    </div>
                    ) : null}
                </div>
            <ModalChangePassword 
                show={modalToggle} modalClosed={modalHandler} children={"modal"} name={name}
                passwordHandler={passwordHandler}
                passwordHandlerRepeat={passwordHandlerRepeat}
                password={password}
                setPassword={setPassword}
                Null={Null}
                showPassword={showPassword}
                passwordRepeat={passwordRepeat}
                NullRepeat={NullRepeat}
                showPasswordRepeat={showPasswordRepeat}
                setPasswordRepeat={setPasswordRepeat}
                error={error}
                handleChangePass={handleChangePass}
                setNull={setNull}
                setNullRepeat={setNullRepeat}
            />
            </div>
        </ClickAwayListener>
    );
}

const AddButton = withStyles({
    root: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "13px",
        lineHeight: "16px",
        color: "#FFFFFF",
        background: '#E23F81',
        borderRadius: '4px',
        width: '135px',
        height: '36px',
        textTransform: "capitalize",
        '&:hover': {
            color: "#FFFFFF",
            background: '#E23F81',
        },
        '&:active': {
        },
        '&:focus': {
        },
    },
})(Button);

export default function Report(){
    const classes = useStyles();
    const history = useHistory()
    const users = useSelector(state=> state.userState.users)
    const [searchFilter, setSearchFilter] = useState('')
    const [search, setSearch] = useState('')
    const filterRole = useSelector(state=> state.filterState.roleAdmin)
    const roleProfile = useSelector(state=> state.profileState.role)

    function addUser () {
        history.push('/admin/user/add')
    }

    return(
        <div className="dashboard" >
            <span className="breadcrumb-txt-black" >
            Daftar Pengguna
            </span>
            <span className="breadcrumb-slash-black" >
                /
            </span>
            <div className="user-vito-container" >
                <div className="user-header-wrapper" >
                    <span className="user-vito-header">
                        Daftar Pengguna
                    </span>
                </div>
                <div className="user-filter-container" >
                    <TextField onChange={e=>setSearch(e.target.value)} label="Cari User" size="small" variant="outlined" color="secondary" borderColor="#C4C4C4" />
                    <FormControl variant="outlined" color="secondary" size="small" className={classes.filter2} style={{ marginLeft: 10 }}>
                        <InputLabel id="demo-simple-select-outlined-label" >Filter Pengguna</InputLabel>
                        <Select
                            size="small"
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={searchFilter}
                            onChange={e=>setSearchFilter(e.target.value)}
                            label="Filter Pengguna"
                        >
                            <MenuItem value="">Semua</MenuItem>
                            {
                                filterRole &&
                                filterRole.map((role, i)=>(
                                        <MenuItem key={i} value={role.initial}>{ role.role }</MenuItem>
                                    ))
                                }
                        </Select>
                    </FormControl>
                    <div style={{ marginLeft: 'auto' }} >
                        {
                            (
                                roleProfile !== 'picpasar' &&
                                roleProfile !== 'markom' &&
                                roleProfile !== 'guest'
                            ) &&
                            <AddButton onClick={addUser} style={{ fontWeight: '500' }} >
                                Tambah Pengguna
                            </AddButton>
                        }
                    </div>
                </div>
                <div className="user-vito-body-container" >
                <TableContainer className={classes.tableContainer}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead className={classes.tableRow}>
                            <TableRow>
                                <TableCell className={classes.tableRowTxt} align="left">NO</TableCell>
                                <TableCell className={classes.tableRowTxt} align="left">NAMA LENGKAP</TableCell>
                                <TableCell className={classes.tableRowTxt} align="left">EMAIL</TableCell>
                                <TableCell className={classes.tableRowTxt} align="left">PENGGUNA</TableCell>
                                <TableCell className={classes.tableRowTxt} align="left">NO. TELP</TableCell>
                                <TableCell className={classes.tableRowTxt} align="left">AKSI</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                users
                                .filter(a=>search ? ((((a.name).toLowerCase()).includes((search).toLowerCase())) || (((a.email).toLowerCase()).includes((search).toLowerCase()))) : a)
                                .filter(a=>searchFilter ? ((a.role).toLowerCase()) === ((searchFilter).toLowerCase()) : a)
                                .map((user,i)=>(
                                    <TableRow key={i} style={{ height: '52px', backgroundColor: i % 2 === 0 ? '#FAFAFA' : '#FFFFFF' }} >
                                        <TableCell className={classes.tableRowTxtBody} align="left">{ i+=1 }</TableCell>
                                        <TableCell className={classes.tableRowTxtBody} align="left">{ user && user.name ? user.name : '-' }</TableCell>
                                        <TableCell className={classes.tableRowTxtBody} align="left">{ user && user.email ? user.email : '-' }</TableCell>
                                        <TableCell className={classes.tableRowTxtBodyUppercase} align="left">{
                                            user && user.role ?
                                            user.role === 'picpasar' ?
                                            'Penanggung Jawab Pasar' :
                                            user.role === 'koordinatorvito' ?
                                            'Tim Koordinator Vito' :
                                            user.role === 'guest' ?
                                            'Tamu' :
                                            user.role : "Master"
                                        }</TableCell>
                                        <TableCell className={classes.tableRowTxtBody} align="left">{ user && user.phone ? user.phone != 'null' ? user.phone : '-' : '-' }</TableCell>
                                        <TableCell className={classes.tableRowTxtBodyX} align="left"> <ClickAway id={user.id} history={history} name={user.name} style={{ top: 0 }} /> </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                </div>
            </div>
        </div>
    )
}