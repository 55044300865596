import React, { useEffect, useState, Fragment } from 'react'
import { Box, Grid, makeStyles, Button, Checkbox, FormControlLabel, FormControl, RadioGroup, InputLabel, MenuItem, Select, TextField} from '@material-ui/core'
import Typography from '@material-ui/core/Typography';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import UploadImg from '../../../../../images/uploadImg.png'
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import { useHistory, useParams } from 'react-router-dom'
import { da } from 'date-fns/locale';
import Axios from 'axios'
import { SERVER } from '../../../../../../../server'
import { getOneVito } from '../../../../../../../store/actions/evaluationActions'
import { useDispatch, useSelector } from 'react-redux'
import { convertToRaw } from 'draft-js';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        padding: "2em",
        width: '98%',
        minHeight: '98%',
        background: "white",
        boxShadow: "none"
    },
    editor: {
        fontSize: "16px",
        lineHeight: "19px",
        padding: "15px 21px",
        minHeight: "100%"
    },
    boxYellow: {
        background: "rgba(218, 157, 2, 0.1)",
        border: "1px solid #DA9D02",
        padding: "0.5em 1em",
        margin: "1em 0em"
    },
    boxDelete: {
        background: "white",
        position: "absolute",
        width: "1.8rem",
        height: "1.8rem",
        top: "-1rem",
        left: "14rem",
        display: "flex",
        justifyContent: "center",
        borderRadius: "50%",
        zIndex: "99999999"
    },
    dropZone: {
        minHeight: '100%'
    },
    boxRadioActive: {
        background: "white",
        border: "1px solid  #DDDDDD",
        width: "50%",
        margin: "0em",
        marginBottom: "0.5rem",
        borderRadius: "4px",
        color: "#E23F81"
    },
    boxRadio: {
        background: "white",
        border: "1px solid  #DDDDDD",
        width: "50%",
        margin: "0em",
        marginBottom: "0.5rem",
        borderRadius: "4px",
    },
})

export default function PIN(props) {
    const classes = useStyles()
    const history = useHistory()
    const Dispatch = useDispatch()
    const [flag, setFlag] = useState(false)
    const [photos, setPhotos] = useState([])
    const [photosend, setPhotosend] = useState([])
    const [data, setData] = useState(null)
    const { year } = useParams()
    const [week, setWeek] = useState(null)
    const [sendText, setSend] = useState(null)
    const [readText, setRead] = useState(null)
    const [credit, setCredit] = useState(false);
    const [indicator, setIndicator] = useState(false);
    const vito = useSelector(state=> state.evaluationState.vito)
    const [savingTriggered, setSavingTriggered] = useState(false);
    const [tagMarkom, setTagMarkom] = useState(false)
    const [tagRegional, setTagRegional] = useState(false)
    const [tagEkraf, setTagEkraf] = useState(false)
    const partners = useSelector(state=> state.partnerState.partnersFilter)
    const [tagPartner, setTagPartner] = useState([])
    // const [partnersId, setPartnersId] = useState([])
    // const [partnerId, setPartnerId] = useState('')

    useEffect(()=>{
        if (history.location.state) {
            if (history.location.state.data) {
                setData(history.location.state.data)
            }
            if (history.location.state.weeklyList) {
                let weekFix
                history.location.state.weeklyList.forEach((week)=>{
                    if (week.id == history.location.state.data.WeekId) {
                        weekFix = week
                        return
                    }
                })
                setWeek(weekFix)
            }
        } else {
            history.replace('/vito/report/weekly/'+new Date().getFullYear())
        }
    },[])

    function addFiles(fileObjs) {
        let temp = []
        let tempSend = []
        fileObjs.map((item) => {
            if (item) {
                if (item.file) {
                    if ((item.file.type).includes('application/')) {
                        temp.push(item.file.name)
                    } else {
                        temp.push(URL.createObjectURL(item.file))
                    }
                    tempSend.push(item.file)
                }
            }
        })
        setFlag(!flag)
        setPhotos([...photos, ...temp]);
        setPhotosend([...photosend, ...tempSend]);
    }

    function deletePhoto(index) {
        let temp = photos
        temp.splice(index, 1)
        setPhotos(temp)
        let temp2 = photosend
        temp2.splice(index, 1)
        setPhotosend(temp2)
        setFlag(!flag)
    }

    function cancel() {
        history.goBack()
    }

    const Category = (id) => {
        let title
        switch (id) {
            case 1:
                title="NETWORKING & PARTNERSHIP"
                break;
            case 2:
                title="MARKET INTELLIGENCE"
                break;
            // case 3:
            //     title="PROMOTIONAL SUPPORT"
            //     break;
            default:
                break;
        }
        return title
    }

    const onEditorStateChange = (event) => {
        let editorState = event; 
        let editorSourceHTML = JSON.stringify(convertToRaw(event.getCurrentContent()))
        setRead(editorState)
        setSend(editorSourceHTML)
    }

    const saving = () => {
        setSavingTriggered(true)
        const dataSend = new FormData()
        photosend.map((item) => {
            dataSend.append('photo', item)
        })

        // let PartId = [...partnersId]
        // if (partnerId) {
        //     PartId.push(partnerId)
        // }

        dataSend.set('report', sendText)
        dataSend.set('credit', credit)
        dataSend.set('indicator', indicator)
        dataSend.set('tagMarkom', tagMarkom)
        dataSend.set('tagRegional', tagRegional)
        dataSend.set('tagEkraf', tagEkraf)
        // dataSend.set('tagPartner', String(PartId))
        // dataSend.set('countPartner', PartId === [] ? 0 : String(PartId).split(',').length)
        dataSend.set('tagPartner', tagPartner)
        dataSend.set('countPartner', String(tagPartner).split(',').length)

        Axios({
            method: 'PATCH',
            url: `${SERVER}/report/weekly/${data.id}`,
            data: dataSend,
            headers: {
                token : localStorage.getItem('token'),
            }
        })
        .then(({data})=>{
            cancel()
        })
        .catch(err=>{
        })
    }

    const changeCredit = (event) => {
        if (credit == event.target.value){
            setCredit(false)
            setIndicator('')
        } else{
            setCredit(event.target.value)
        }
    }

    const changeIndicator = (event) => {
        if (indicator == event.target.value){
            setIndicator('')
        } else{
            setIndicator(event.target.value) 
        }
    }

    const changeTagMarkom = (event) => {
        if (tagMarkom == event.target.value){
            setTagMarkom(false)
        } else{
            setTagMarkom(event.target.value) 
        }
    }

    const changeTagRegional = (event) => {
        if (tagRegional == event.target.value){
            setTagRegional(false)
        } else{
            setTagRegional(event.target.value) 
        }
    }

    const changeTagEkraf = (event) => {
        if (tagEkraf == event.target.value){
            setTagEkraf(false)
        } else{
            setTagEkraf(event.target.value) 
        }
    }

    // const addPartner = () => {
    //     if (partnerId) {
    //         let arr = [...partnersId]
    //         arr.push(partnerId)
    //         setPartnersId(arr)
    //         setPartnerId('')
    //     }
    // }

    // const removePartner = (i) => {
    //     let arr = []
    //     partnersId.map((n, index)=>{
    //         if (index !== i) {
    //             arr.push(n)
    //         }
    //     })
    //     setPartnersId(arr)
    // }

    // const checkingId = (id) => {
    //     let flag = false
    //     partnersId.map(di=>{
    //         if (di === id) {
    //             flag = true
    //         }
    //     })
    //     return flag
    // }

    // const getPartnerName = (id) => {
    //     if (partners){
    //         let name
    //         partners.map(partner=>{
    //             if (partner.id === id) {
    //                 name = `${partner.name} - ${partner.contactPerson} (${partner.countryOrigin})`
    //             }
    //         })
    //         return name
    //     }
    // }

    const getSelected = (ids) => {
        if (partners) {
            let selected = []
            for (let i = 0; i < ids.length; i++) {
                let id = parseInt(ids[i])
                partners.map(country=>{
                    if (country.id === id) {
                        selected.push(country)
                    }
                })
            }
            return selected;
        }
    }

    const getId = (object) => {
        let id = []
        object.map(obj=>{
            id.push(obj.id)
        })
        return id
    }

    return (
        <Fragment>
            <Box className={classes.root}>
                <Box>
                    <Typography variant="h6" style={{ fontWeight: "500" }}>
                        CREATE REPORT {Category(data && data.CategoryId)} Week {week && week.week}, {year && year}
                    </Typography>
                </Box>
                <Box>
                    <Box className={classes.boxYellow}>
                        <Typography>{data ? data.category ? data.category.includes('Partnership with Airline') ? 'Partnership with Airlines' : data.category : data.category : '-'}</Typography>
                    </Box>
                    <Box border="1px solid #DDDDDD" padding="1em">
                        <Typography>Work Plan: <span style={{ color: "#E23F81" }}> {data && data.description} </span>
                        </Typography>
                    </Box>
                </Box>
                <Box border="1px solid #DDDDDD" marginTop="1em" minHeight="38vh">
                    <Editor
                        editorState={readText}
                        editorClassName={classes.editor}
                        placeholder="Create report here.."
                        onEditorStateChange={onEditorStateChange}
                    />
                </Box>
                <Box display="flex" position="relative" flexDirection="column" justifyContent="center" alignItems="center" border="1px solid #DDDDDD" marginTop="1em" minHeight="30vh">
                    <Box position="absolute" width="100%" height="100%" zIndex="99" style={{ opacity: "0" }}><DropzoneAreaBase
                        onAdd={addFiles}
                        acceptedFiles={['image/jpeg', 'image/png', 'image/jpg', 'application/pdf', '.doc','.docx']}
                        // onDelete={(fileObj) => console.log('Removed File:', fileObj)}
                        showAlerts={false}
                        dropzoneText=""
                        dropzoneClass={classes.dropZone}
                    />
                    </Box>
                    <Box marginY="1rem"><img src={UploadImg} style={{ width: "4rem", height: "4rem" }} /></Box>
                    <Typography style={{ color: '#999999' }}>Upload Here (JPG/PNG/PDF/WORD | Max File Size 3 MB)</Typography>
                    <Typography style={{ color: "#E23F81" }}>Choose</Typography>
                    <Box display="flex" marginTop="1em">
                        {
                            photos.map((photo, index) => {
                                return (
                                    <Box key={index} width="15rem" marginRight="3rem" marginBottom="1rem" position="relative"
                                        style={{
                                            border: (photo && (photo.includes('.pdf') || photo.includes('.doc'))) &&'1px solid #e35981',
                                            borderRadius: (photo && (photo.includes('.pdf') || photo.includes('.doc'))) && '4px',
                                            maxHeight: (photo && (photo.includes('.pdf') || photo.includes('.doc'))) && '105px'
                                        }}
                                    >
                                        <Box className={classes.boxDelete}>
                                            <Button onClick={() => deletePhoto(index)}>
                                                <CancelSharpIcon style={{ fontSize: "2rem", color: "#E23F81" }} />
                                            </Button>
                                        </Box>
                                        {
                                            photo &&
                                            photo.includes('.pdf') ?
                                                <div
                                                    style={{
                                                        padding: 5,
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <i className="fas fa-file-pdf"
                                                        style={{ fontSize: "80px", color: '#E23F81' }}
                                                    />
                                                    <div
                                                        style={{
                                                            wordBreak: 'break-all',
                                                            padding: 5,
                                                            marginTop: 'auto',
                                                            boxSizing: 'border-box'
                                                        }}
                                                    >{ photo }</div>
                                                </div>
                                            :
                                            photo &&
                                            photo.includes('.doc') ?
                                                <div
                                                    style={{
                                                        padding: 5,
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <i className="fas fa-file-word"
                                                        style={{ fontSize: "80px", color: '#E23F81' }}
                                                    />
                                                    <div
                                                        style={{
                                                            wordBreak: 'break-all',
                                                            padding: 5,
                                                            marginTop: 'auto',
                                                            boxSizing: 'border-box'
                                                        }}
                                                    >{ photo }</div>
                                                </div>
                                            :
                                            <img src={photo} alt="images" className="image-upload-content"
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "4px"
                                                }}
                                            />
                                        }
                                    </Box>
                                )
                            })
                        }
                    </Box>
                </Box>

                {
                    vito ?
                    <FormControlLabel
                        value={true}
                        disabled={data && data.category == "Global Trend" ? true : false}
                        label={'I want to submit this report as my credit'}
                        control={
                            <Checkbox
                                checked={credit ? true : false}
                                onChange={changeCredit}
                                value={true}
                            />
                        }
                    />
                    :
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <FormControlLabel
                            value={true}
                            disabled
                            label={'I want to submit this report as my credit'}
                            control={
                                <Checkbox
                                    checked={credit ? true : false}
                                    onChange={changeCredit}
                                    value={true}
                                />
                            }
                        />
                        <span style={{ color: 'red', marginTop: -5 }} >
                            please contact admin to create your form evaluation, to open your credit!
                        </span>
                    </div>
                }
                {
                    credit && data && data.CategoryId == '1' &&
                    data && (data.category).includes('Partnership with Academics') ?
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1vw' }} >
                            <span className="txt-indi-ctr" > Indicators </span>
                            <Box marginTop="0.5vw">
                                <FormControl component="fieldset" style={{ width: "100%", justifyContent: "space-between" }}>
                                    <RadioGroup style={{ justifyContent: "space-between" }} value={indicator} onChange={changeIndicator}>
                                        <FormControlLabel
                                            disabled={ data && (data.credit !== 'disetujui' && data.credit !== 'ditolak') ? false : true }
                                            value={'Collaboration Activities with Academics Partners'}
                                            label={`Collaboration Activities with Academics Partners 
                                                ${
                                                    data && (data.category).includes('Partnership with Academics') ? vito && `(Target: ${vito.A1a} | Realisation: ${vito.A1Ra})` : `(Target:${0} | Realisation:${0})`
                                                }
                                            `}
                                            // className={indicator == 'Collaboration Activities with Academics Partners' ? classes.boxRadioActive : classes.boxRadio} 
                                            className={classes.boxRadioActive}
                                            style={{width: "100%"}}
                                            control={
                                                <Checkbox
                                                    checked={indicator == 'Collaboration Activities with Academics Partners' ? true : false}
                                                    // checked={credit ? true : false}
                                                    onChange={changeIndicator}
                                                    value={'Collaboration Activities with Academics Partners'}
                                                />
                                            }
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </div>
                    :
                    credit && data && data.CategoryId == '1' &&
                    data && (data.category).includes('Partnership with Business (TA/TO, Airlines, Accommodation, and others)') ?
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1vw' }} >
                            <span className="txt-indi-ctr" > Indicators </span>
                            <Box marginTop="0.5vw">
                                <FormControl component="fieldset" style={{ width: "100%", justifyContent: "space-between" }}>
                                    <RadioGroup style={{ justifyContent: "space-between" }} value={indicator} onChange={changeIndicator}>
                                        <FormControlLabel
                                            disabled={ data && (data.credit !== 'disetujui' && data.credit !== 'ditolak') ? false : true }
                                            value={'Collaboration Activities with Bussiness/Industry Partners'}
                                            label={`Collaboration Activities with Bussiness/Industry Partners 
                                                ${
                                                    data && (data.category).includes('Partnership with Business (TA/TO, Airlines, Accommodation, and others)') ? vito && `(Target: ${vito.A2a} | Realisation: ${vito.A2Ra})` : `(Target:${0} | Realisation:${0})`
                                                }
                                            `}
                                            // className={indicator == 'Collaboration Activities with Bussiness/Industry Partners' ? classes.boxRadioActive : classes.boxRadio} 
                                            className={classes.boxRadioActive}
                                            style={{width: "100%"}}
                                            control={
                                                <Checkbox
                                                    checked={indicator == 'Collaboration Activities with Bussiness/Industry Partners' ? true : false}
                                                    // checked={credit ? true : false}
                                                    onChange={changeIndicator}
                                                    value={'Collaboration Activities with Bussiness/Industry Partners'}
                                                />
                                            }
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </div>
                    :
                    credit && data && data.CategoryId == '1' &&
                    data && (data.category).includes('Partnership with Communities') ?
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1vw' }} >
                            <span className="txt-indi-ctr" > Indicators </span>
                            <Box marginTop="0.5vw">
                                <FormControl component="fieldset" style={{ width: "100%", justifyContent: "space-between" }}>
                                    <RadioGroup style={{ justifyContent: "space-between" }} value={indicator} onChange={changeIndicator}>
                                        <FormControlLabel
                                            disabled={ data && (data.credit !== 'disetujui' && data.credit !== 'ditolak') ? false : true }
                                            value={'Collaboration Activities with Communities Partners'}
                                            label={`Collaboration Activities with Communities Partners 
                                                ${
                                                    data && (data.category).includes('Partnership with Communities') ? vito && `(Target: ${vito.A3a} | Realisation: ${vito.A3Ra})` : `(Target:${0} | Realisation:${0})`
                                                }
                                            `}
                                            // className={indicator == 'Collaboration Activities with Communities Partners' ? classes.boxRadioActive : classes.boxRadio} 
                                            className={classes.boxRadioActive}
                                            style={{width: "100%"}}
                                            control={
                                                <Checkbox
                                                    checked={indicator == 'Collaboration Activities with Communities Partners' ? true : false}
                                                    // checked={credit ? true : false}
                                                    onChange={changeIndicator}
                                                    value={'Collaboration Activities with Communities Partners'}
                                                />
                                            }
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </div>
                    :
                    credit && data && data.CategoryId == '1' &&
                    data && (data.category).includes('Partnership with Governments') ?
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1vw' }} >
                            <span className="txt-indi-ctr" > Indicators </span>
                            <Box marginTop="0.5vw">
                                <FormControl component="fieldset" style={{ width: "100%", justifyContent: "space-between" }}>
                                    <RadioGroup style={{ justifyContent: "space-between" }} value={indicator} onChange={changeIndicator}>
                                        <FormControlLabel
                                            disabled={ data && (data.credit !== 'disetujui' && data.credit !== 'ditolak') ? false : true }
                                            value={'Collaboration Activities with Governments Partners'}
                                            label={`Collaboration Activities with Governments Partners 
                                                ${
                                                    data && (data.category).includes('Partnership with Governments') ? vito && `(Target: ${vito.A4a} | Realisation: ${vito.A4Ra})` : `(Target:${0} | Realisation:${0})`
                                                }
                                            `}
                                            // className={indicator == 'Collaboration Activities with Governments Partners' ? classes.boxRadioActive : classes.boxRadio} 
                                            className={classes.boxRadioActive}
                                            style={{width: "100%"}}
                                            control={
                                                <Checkbox
                                                    checked={indicator == 'Collaboration Activities with Governments Partners' ? true : false}
                                                    // checked={credit ? true : false}
                                                    onChange={changeIndicator}
                                                    value={'Collaboration Activities with Governments Partners'}
                                                />
                                            }
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </div>
                    :
                    credit && data && data.CategoryId == '1' &&
                        data && (data.category).includes('Partnership with Media') ?
                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1vw' }} >
                                <span className="txt-indi-ctr" > Indicators </span>
                                <Box marginTop="0.5vw">
                                    <FormControl component="fieldset" style={{ width: "100%", justifyContent: "space-between" }}>
                                        <RadioGroup style={{ justifyContent: "space-between" }} value={indicator} onChange={changeIndicator}>
                                            <FormControlLabel
                                                disabled={ data && (data.credit !== 'disetujui' && data.credit !== 'ditolak') ? false : true }
                                                value={'Collaboration Activities with Media Partners'}
                                                label={`Collaboration Activities with Media Partners 
                                                    ${
                                                        data && (data.category).includes('Partnership with Media') ? vito && `(Target: ${vito.A5a} | Realisation: ${vito.A5Ra})` : `(Target:${0} | Realisation:${0})`
                                                    }
                                                `}
                                                // className={indicator == 'Collaboration Activities with Media Partners' ? classes.boxRadioActive : classes.boxRadio} 
                                                className={classes.boxRadioActive}
                                                style={{width: "100%"}}
                                                control={
                                                    <Checkbox
                                                        checked={indicator == 'Collaboration Activities with Media Partners' ? true : false}
                                                        // checked={credit ? true : false}
                                                        onChange={changeIndicator}
                                                        value={'Collaboration Activities with Media Partners'}
                                                    />
                                                }
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                            </div>
                        
                    :
                    credit && data && data.CategoryId == '2' &&
                    data && (data.category).includes('Market Update') ?
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1vw' }} >
                            <span className="txt-indi-ctr" > Indicators </span>
                            <Box marginTop="0.5vw">
                                <FormControl component="fieldset" style={{ width: "100%", justifyContent: "space-between" }}>
                                    <RadioGroup style={{ justifyContent: "space-between" }} value={indicator} onChange={changeIndicator}>
                                        <FormControlLabel
                                            disabled={ data && (data.credit !== 'disetujui' && data.credit !== 'ditolak') ? false : true }
                                            value={'Number of Market Strategic Review'}
                                            label={`Number of Market Strategic Review 
                                                ${
                                                    data && (data.category).includes('Market Update') ? vito && `(Target: ${vito.B1a} | Realisation: ${vito.B1Ra})` : `(Target:${0} | Realisation:${0})`
                                                }
                                            `}
                                            // className={indicator == 'Number of Market Strategic Review' ? classes.boxRadioActive : classes.boxRadio} 
                                            className={classes.boxRadioActive}
                                            style={{width: "100%"}}
                                            control={
                                                <Checkbox
                                                    checked={indicator == 'Number of Market Strategic Review' ? true : false}
                                                    // checked={credit ? true : false}
                                                    onChange={changeIndicator}
                                                    value={'Number of Market Strategic Review'}
                                                />
                                            }
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </div>
                    :
                    credit && data && data.CategoryId == '2' &&
                    data && (data.category).includes('Competitor Update') &&
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1vw' }} >
                            <span className="txt-indi-ctr" > Indicators </span>
                            <Box marginTop="0.5vw">
                                <FormControl component="fieldset" style={{ width: "100%", justifyContent: "space-between" }}>
                                    <RadioGroup style={{ justifyContent: "space-between" }} value={indicator} onChange={changeIndicator}>
                                        <FormControlLabel
                                            value={'Number of Analysis of Competitor Activities'}
                                            label={`Number of Analysis of Competitor Activities 
                                                ${
                                                    data && (data.category).includes('Competitor Update') ? vito && `(Target: ${vito.B2a} | Realisation: ${vito.B2Ra})` : `(Target:${0} | Realisation:${0})`
                                                }
                                            `}
                                            // className={indicator == 'Number of Analysis of Competitor Activities' ? classes.boxRadioActive : classes.boxRadio} 
                                            className={classes.boxRadioActive} 
                                            style={{width: "100%"}}
                                            disabled={ data && (data.credit !== 'disetujui' && data.credit !== 'ditolak') ? false : true }
                                            control={
                                                <Checkbox
                                                    checked={indicator == 'Number of Analysis of Competitor Activities' ? true : false}
                                                    // checked={credit ? true : false}
                                                    onChange={changeIndicator}
                                                    value={'Number of Analysis of Competitor Activities'}
                                                />
                                            }
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </div>
                }

                    <Box border="1px solid #DDDDDD" padding="1em" marginTop="1em">
                        <Typography>Tags:</Typography>
                        <FormControlLabel
                            value={true}
                            label={'Markom'}
                            control={
                                <Checkbox
                                    checked={tagMarkom ? true : false}
                                    onChange={changeTagMarkom}
                                    value={true}
                                />
                            }
                        />
                        <FormControlLabel
                            value={true}
                            label={'Regional'}
                            control={
                                <Checkbox
                                    checked={tagRegional ? true : false}
                                    onChange={changeTagRegional}
                                    value={true}
                                />
                            }
                        />
                        <FormControlLabel
                            value={true}
                            label={'Ekraf'}
                            control={
                                <Checkbox
                                    checked={tagEkraf ? true : false}
                                    onChange={changeTagEkraf}
                                    value={true}
                                />
                            }
                        />
                    </Box>

                    <Box border="1px solid #DDDDDD" padding="1em" marginTop="1em">
                                {/* <Typography>Partner List: <sup style={{color:'red'}}></sup></Typography>
                                    {
                                        partnersId.length !== 0 &&
                                            partnersId &&
                                            partnersId.map((partnerId,i)=>(
                                            <div key={i} style={{ display: 'flex', alignItems: 'center', marginTop: i!==0&&'0.5em'  }} >
                                                <span style={{
                                                    border: '1px solid #cdcdcd',
                                                    borderRadius: '4px',
                                                    width: '100%',
                                                    padding: '10px',
                                                    paddingLeft: '12px'
                                                }} >
                                                    { getPartnerName(partnerId) }
                                                </span>
                                                <i className="fas fa-minus-square" onClick={e=>removePartner(i)} style={{ fontSize: 30, marginLeft: 10, color: '#e35981', cursor: 'pointer' }} />
                                            </div>
                                        ))  
                                    }
                                    {
                                        <div style={{ display: 'flex', alignItems: 'center' }} >
                                            <FormControl color="secondary" variant="outlined" fullWidth size="small" className={classes.formControl}>
                                                <InputLabel id="demo-simple-select-outlined-label">Select Partner</InputLabel>
                                                <Select
                                                    name="tagPartner"
                                                    value={partnerId}
                                                    onChange={e=> setPartnerId(e.target.value)}
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    label="Select Partner"
                                                >
                                                    <MenuItem value="" >  </MenuItem>
                                                    {
                                                        partners &&
                                                        partners
                                                        .filter(a=> !(checkingId(a.id)))
                                                        .map((partner,i)=>(
                                                            <MenuItem style={{ textTransform: 'capitalize' }} key={i} value={partner.id}>{ `${partner.name} - ${partner.contactPerson} (${partner.countryOrigin})` }</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                            <i className="fas fa-plus-square" onClick={e=>addPartner()} style={{ fontSize: 30, marginTop: 19, marginLeft: 10, color: '#e35981', cursor: 'pointer' }} />
                                        </div>
                                    } */}
                        <Autocomplete
                            multiple
                            id="tags-outlined"
                            options={partners}
                            getOptionLabel={(option) => `${option.name} - ${option.contactPerson} (${option.countryOrigin})`}
                            value={getSelected(tagPartner)}
                            filterSelectedOptions
                            onChange={(event, value) => setTagPartner(getId(value))}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Partner List"
                                    placeholder="Select (text here...)"
                                    name='tagPartner'
                                    variant='outlined'
                                />
                            )}
                        />
                    </Box>

                <Box display="flex" alignItems="center" marginTop="2em">
                    {
                        savingTriggered ? 
                        <Button type="submit" variant="contained" color="secondary" style={{ backgroundColor: "#E23F81", textTransform: "none", boxShadow: "none", padding: "0.5em 3em" }}>Loading</Button>
                        :
                        <Button onClick={saving} type="submit" variant="contained" color="secondary" style={{ backgroundColor: "#E23F81", textTransform: "none", boxShadow: "none", padding: "0.5em 3em" }}>Submit</Button>
                    }
                    <Button variant="outlined" onClick={cancel} color="secondary" style={{ textTransform: "none", padding: "0.5em 3em", marginLeft: "2em", }} >Cancel</Button>
                </Box>
            </Box>
        </Fragment>
    )
}