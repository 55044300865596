import {
    GET_WEEKWORKPLAN,
} from "../constanta";

const initialState = {
    weeks: [],
}

export default (state = initialState, action)=> {
    switch(action.type){
        case GET_WEEKWORKPLAN:
            return {
                ...state,
                weeks: action.data
            }
        default: {
            return state
        }
    }
}