import { makeStyles, Typography, Box, FormControl, RadioGroup, FormControlLabel, Checkbox, TextField, Button } from '@material-ui/core'
import React, { useState, useEffect, useRef } from 'react'
import Card from '../Card'
import { SERVER } from '../../../../../server'
import Axios from 'axios'
import Swal from 'sweetalert2'
import RefreshIcon from '@material-ui/icons/Refresh';

const useStyles = makeStyles({
    root: {
        backgroundColor: "#F2F3F9",
        boxSizing: 'content-box'
    },
    boxRadioActive: {
        background: "white",
        border: "1px solid  #DDDDDD",
        width: "100%",
        margin: "0em",
        marginBottom: "0.5rem",
        borderRadius: "4px",
        color: "#E23F81"
    },
    boxRadio: {
        background: "white",
        border: "1px solid  #DDDDDD",
        width: "100%",
        margin: "0em",
        marginBottom: "0.5rem",
        borderRadius: "4px",
    },
});

export default function Index(props) {
    const classes = useStyles()
    const [category, setCategory] = useState("");
    const [options, setOptions] = useState(props.options)
    const [showTextfield, setShowTextfield] = useState(false)
    const [textfield, setTextfield] = useState('')
    const messagesEndRef = useRef(null)
    const [report, setReport] = useState(null)
    const {
        week,
        weekId,
        CategoryId,
        weekFind
    } = props

    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(scrollToBottom, [showTextfield]);

    const dataRead = (CategoryId, weekId) =>{
        Swal.showLoading()
        if (CategoryId && weekId) {
            // if (sessionStorage.getItem(`${CategoryId}${weekId}`)) {
            //     setReport(JSON.parse(sessionStorage.getItem(`${CategoryId}${weekId}`)))
            //     Swal.close()
            // } else {
                Axios({
                    method: 'GET',
                    url: `${SERVER}/report/status/${CategoryId}/${weekId}/workplan`,
                    headers: {
                        token : localStorage.getItem('token'),
                    }
                })
                .then(({data})=>{
                    setReport(data)
                    Swal.close()
                    // sessionStorage.setItem(`${CategoryId}${weekId}`,JSON.stringify(data))
                })
                .catch(err=>{
                })
            // }
        }
    }

    useEffect(()=>{
        if (CategoryId && weekId) {
            dataRead(CategoryId, weekId)
        }
    },[CategoryId, weekId])

    const changeCategory = (event) => {
        if (category == event.target.value){
            setCategory('')
        } else{
            setCategory(event.target.value) 
        }
    }

    function changeShowTextField () {
        setShowTextfield(true)
    }

    function cancel () {
        setShowTextfield(false)
        setCategory('')
        setShowTextfield('')
    }

    const showDate = (dateParams) => {
        if (dateParams) {
            const [day, month, year] = dateParams.start.split("/")
            const [day2, month2, year2] = dateParams.end.split("/")
            let start = new Date(year, month - 1, day).getDate()
            let startMonth = new Date(year, month - 1, day).getMonth()
            let monthStart = ''
            let yearResult = new Date(year, month - 1, day).getFullYear()
            switch (startMonth) {
                case 0 :
                    monthStart = 'Jan'
                    break;
                case 1 :
                    monthStart = 'Feb'
                    break;
                case 2 :
                    monthStart = 'Mar'
                    break;
                case 3 :
                    monthStart = 'Apr'
                    break;
                case 4 :
                    monthStart = 'Mei'
                    break;
                case 5 :
                    monthStart = 'Jun'
                    break;
                case 6 :
                    monthStart = 'Jul'
                    break;
                case 7 :
                    monthStart = 'Agust'
                    break;
                case 8 :
                    monthStart = 'Sep'
                    break;
                case 9 :
                    monthStart = 'Okt'
                    break;
                case 10 :
                    monthStart = 'Nov'
                    break;
                case 11 :
                    monthStart = 'Des'
                    break;
                default:
                    monthStart = ''
                    break;
            }

            let end = new Date(year2, month2 - 1, day2).getDate()
            let endMonth = new Date(year2, month2 - 1, day2).getMonth()
            let monthEnd = ''
            switch (endMonth) {
                case 0 :
                    monthEnd = 'Jan'
                    break;
                case 1 :
                    monthEnd = 'Feb'
                    break;
                case 2 :
                    monthEnd = 'Mar'
                    break;
                case 3 :
                    monthEnd = 'Apr'
                    break;
                case 4 :
                    monthEnd = 'Mei'
                    break;
                case 5 :
                    monthEnd = 'Jun'
                    break;
                case 6 :
                    monthEnd = 'Jul'
                    break;
                case 7 :
                    monthEnd = 'Agust'
                    break;
                case 8 :
                    monthEnd = 'Sep'
                    break;
                case 9 :
                    monthEnd = 'Okt'
                    break;
                case 10 :
                    monthEnd = 'Nov'
                    break;
                case 11 :
                    monthEnd = 'Des'
                    break;
                default:
                    monthEnd = ''
                    break;
            }
            return start + ' '+ monthStart + ' - ' + end  + ' ' + monthEnd +' '+ yearResult
            }
    }

    const saving = () => {
        let data = {
            category : category,
            description : textfield,
            WeekId : weekId,
            status: 'workplan',
            CategoryId,
            weekFind
        }
        Axios({
            method: 'POST',
            url: `${SERVER}/report/created-report`,
            data: data,
            headers: {
                token : localStorage.getItem('token'),
            }
        })
        .then(({data})=>{
            // sessionStorage.removeItem(`${CategoryId}${weekId}`)
            dataRead(CategoryId, weekId)
            cancel()
        })
        .catch(err=>{
        })
    }

    const refresh = () => {
        dataRead(CategoryId, weekId)
    }

    return (
        <>
            <Box className={classes.root}>
                <Box display="flex" justifyContent="space-between" alignItems="center" padding="1rem">
                    <Typography style={{fontWeight: "500", fontSize:"1rem"}}>{props.title}</Typography>
                    <Typography variant="caption" style={{ color: "#999999", textAlign: 'right' }}>Week {week ? week[0] ? week[0].week : '-' : '-'} ({week ? week[0] ? showDate(week && week[0]) : ' - ' : ' - '})</Typography>
                </Box>
                <Box maxHeight="55vh" overflow="scroll" className="cardBoxWorkplan">
                    <Box maxHeight="30vh" overflow="scroll" className="cardBoxWorkplan" paddingX="1rem" display="flex" flexDirection="column-reverse">
                        {
                            report &&
                            report.map((item, index) => {
                                return (
                                    <Card weekFind={weekFind} CategoryId={CategoryId} weekId={weekId} read={dataRead} key={index} data={item} options={options} />
                                )
                            })
                        }
                    </Box>
                    <Box borderTop="0.5px solid #DDDDDD" padding="0.5rem 1rem">
                        <FormControl component="fieldset" style={{ width: "100%", justifyContent: "space-between" }}>
                            <RadioGroup style={{ justifyContent: "space-between" }} value={category} onChange={changeCategory}>
                                {
                                    options.map((option,i) => {
                                        return (
                                            <FormControlLabel key={i} className={category == option ? classes.boxRadioActive : classes.boxRadio} value={option} control={
                                                <Checkbox
                                                    checked={category == option ? true : false}
                                                    onChange={changeCategory}
                                                    value={option}
                                                />}
                                                label={option ? option.includes('Partnership with Airline') ? 'Partnership with Airlines' : option : option}
                                            />
                                        )
                                    })
                                }
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    {
                        showTextfield ?
                            <Box padding="0.5rem 1rem">
                                <TextField color="secondary" onChange={e=>setTextfield(e.target.value)} placeholder="Type your report title (plan) here.. (max 140 char)" fullWidth style={{ background: "white" }} variant="outlined" multiline rows={2} inputProps={{ maxLength: 140 }} />
                            </Box> : null
                    }
                    <div ref={messagesEndRef} />
                </Box>
                <Box padding="0.5rem 1rem">
                    {
                        !showTextfield ?
                            <Button variant={category? "contained": "outlined"} color={category? "secondary": "default"} disabled={category? false:true} onClick={changeShowTextField} fullWidth style={category?{textTransform: "none", background: "#E23F81"}:{ textTransform: "none", color: "#E23F81"}} >Add New Work Plan</Button> :
                            <Box display="flex" alignItems="center">
                                <Button type="submit" onClick={saving} size="small" fullWidth variant="contained" color="secondary" style={{ backgroundColor: "#E23F81", textTransform: "none", boxShadow: "none", padding: "0.5em 3em" }}>Save</Button>
                                <Button variant="outlined" size="small" color="secondary" style={{ textTransform: "none", padding: "0.5em 3em", marginLeft: "10px" }} onClick={cancel}>Cancel</Button>
                            </Box>
                    }
                    <Button variant="contained" color="default" onClick={refresh} fullWidth style={{ backgroundColor: '#E23F81', color: "#ffffff", marginTop: 5 }} >
                        <RefreshIcon />
                    </Button>
                </Box>
            </Box>
        </>
    )
}
