// LOCAL
// const SERVER = "http://localhost:5000"
// const domain = "http://localhost:3000"

// STAGING
// const SERVER = "https://vito-backend.blendmedia.co.id" // deprecated
// const domain  = "https://vito-staging.blendmedia.co.id" // deprecated

// PRODUCTION
const SERVER = "https://vor.optimusdigital.id"
const domain  = "https://vito.indonesia.travel"

export {SERVER, domain} // {backend, frontend}