import React, { useState, useEffect, Fragment } from "react";
import { Box, Popover, makeStyles } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import "./styles.scss";
import { useParams, useHistory, Link } from "react-router-dom";
import CardBox from "../../../../component/Report/CardBox";
import { useSelector } from "react-redux";
import DetailReport from "../../../../component/Report/Modal/DetailReportWeekly/viewAll";
import { domain } from "../../../../../../server";
import { saveAs } from "file-saver";
import { Packer } from "docx";
import { createDocVito } from "../../../../component/FileViewer/doc";

const options = {
  //marketing: ["Partnership with Airline", "Partnership with Online Travel Agent (OTA) or Travel Agent/Tour Operator", "Partnership with Association", "Partnership with Related Travel Industry"],
  //networking: ["Market Update (Isu strategi dan tren)", "Outbound Update (Jumlah outbound dan market share)", "Competitor Update"],
  //facilitation: ["Regional Promotion (SM, TM, FT, Festival)", "Online Promotion (Website & Social Media)", "PR-Ing and Customer Service"]
  marketing: [
    "Partnership with Academics",
    "Partnership with Business (TA/TO, Airlines, Accommodation, and others)",
    "Partnership with Communities",
    "Partnership with Governments",
    "Partnership with Media",
  ],
  networking: ["Market Update", "Competitor Update", "Global Trend"],
};

const useStyles = makeStyles({
  root: {
    height: "100px",
    background: "white",
    padding: "1em 0em",
    boxShadow: "none",
  },
  content: {
    marginTop: "1em",
    width: "100%",
    height: "89%",
    background: "white",
  },
  tabWeek: {
    textTransform: "none",
    fontSize: "1.5em",
  },
  report: {
    fontWeight: "500",
    lineHeight: "16px",
    color: "#2C304D",
  },
  box: {
    borderBottom: "0.5px solid #DDDDDD",
    width: "100%",
    height: 35,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingLeft: 15,
    paddingRight: 15,
    color: "#999999",
    cursor: "pointer",
    borderRadius: 4,
    "&:hover": {
      color: "white",
      backgroundColor: "#E23F81",
      width: "-webkit-fill-available",
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function PIN(props) {
  const [value, setValue] = useState(0);
  const [tabs, setTabs] = useState([
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
    40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55,
  ]);
  const classes = useStyles();
  const History = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const { year } = useParams();
  const weeklyList = useSelector((state) => state.vitoState.weekly);
  const [weekId, setWeekId] = useState(null);
  const [dataPrint, setData] = useState([]);
  const [dataSelection, setDataSelection] = useState([]);
  const nameProfile = useSelector((state) => state.profileState.name);
  const photoProfile = useSelector((state) => state.profileState.photo);
  const countryProfile = useSelector((state) => state.profileState.country);
  const emailProfile = useSelector((state) => state.profileState.email);
  const areaProfile = useSelector((state) => state.profileState.area);
  const [openModal, setOpenModal] = useState(false);
  const [dataCategory1, setDataCategory1] = useState([]);
  const [dataCategory2, setDataCategory2] = useState([]);
  const [dataCategory3, setDataCategory3] = useState([]);

  const viewAll = () => {
    setDataSelection([dataCategory1, dataCategory2, dataCategory3]);
    handleClose();
    setOpenModal(true);
  };

  const showDate = (dateParams) => {
    const [day, month, year] = dateParams.split("/");
    let date = new Date(year, month - 1, day).getDate();
    let monthNumber = new Date(year, month - 1, day).getMonth();
    let monthResult = "";
    let yearResult = new Date(year, month - 1, day).getFullYear();
    switch (monthNumber) {
      case 0:
        monthResult = "January";
        break;
      case 1:
        monthResult = "February";
        break;
      case 2:
        monthResult = "March";
        break;
      case 3:
        monthResult = "April";
        break;
      case 4:
        monthResult = "May";
        break;
      case 5:
        monthResult = "June";
        break;
      case 6:
        monthResult = "July";
        break;
      case 7:
        monthResult = "August";
        break;
      case 8:
        monthResult = "September";
        break;
      case 9:
        monthResult = "October";
        break;
      case 10:
        monthResult = "November";
        break;
      case 11:
        monthResult = "December";
        break;
      default:
        monthResult = "";
        break;
    }
    return date + " " + monthResult + " " + yearResult;
  };

  const showDateFix = (dateParams) => {
    let [day, month, year] = dateParams.split("/");
    let date = new Date(year, parseInt(month) - 1, day).getTime();
    return date;
  };

  const handleWeekName = () => {
    let weekFix;
    if (weeklyList) {
      weeklyList.forEach((week) => {
        if (week.id == weekId) {
          weekFix = week.week;
          return;
        }
      });
    }
    return weekFix;
  };

  const handleChange = (e, newValue) => {
    setValue(newValue);
    if (weeklyList) {
      setWeekId(weeklyList[newValue].id);
    }
  };

  useEffect(() => {
    if (
      weeklyList &&
      new Date().getFullYear() === new Date(year).getFullYear()
    ) {
      let weekFix;
      weeklyList.forEach((week) => {
        if (
          showDateFix(
            `${new Date().getDate()}/${
              new Date().getMonth() + 1
            }/${new Date().getFullYear()}`
          ) >= showDateFix(week.start) &&
          showDateFix(
            `${new Date().getDate()}/${
              new Date().getMonth() + 1
            }/${new Date().getFullYear()}`
          ) <= showDateFix(week.end)
        ) {
          weekFix = week;
          return;
        }
      });
      setValue(weekFix && weekFix.week - 1);
      setWeekId(weekFix && weekFix.id);
    } else {
      setValue(0);
    }
  }, [weeklyList, year]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const shareWhatsapp = () => {
    window.open(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(
        `${domain}/document/weekly?year=${year}&week=${handleWeekName()}&identity=${weekId}&vito=${emailProfile}`
      )}`,
      "_blank"
    );
  };

  const downloadDoc = () => {
    const document = new createDocVito();
    let weekIdBase = value;
    const doc = document.create(
      [dataCategory1, dataCategory2, dataCategory3],
      nameProfile,
      countryProfile,
      areaProfile,
      weeklyList,
      weekId
    );
    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `${countryProfile} - Week ${(weekIdBase += 1)}.docx`);
    });
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Fragment>
      <Box
        style={{
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
        }}
        className="appbar-WeeklyBox"
      >
        {/* <Box height="15%" maxWidth="68vw" > */}
        {/* <AppBar position="static" className={classes.root} color="default"> */}
        <Tabs
          className="appbar-WeeklyUptd"
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="secondary"
          textColor="secondary"
          aria-label="scrollable force tabs example"
        >
          {weeklyList &&
            weeklyList.map((tab, i) => (
              <Tab
                key={i}
                label={
                  <React.Fragment>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      height="60px"
                      justifyContent="center"
                    >
                      <Typography className={classes.tabWeek}>
                        Week {tab.week}
                      </Typography>
                      <Typography style={{ fontSize: "10px" }}>
                        {showDate(tab.start)} - {showDate(tab.end)}
                      </Typography>
                    </Box>
                  </React.Fragment>
                }
              ></Tab>
            ))}
        </Tabs>
        {/* </AppBar> */}
        {/* </Box> */}
      </Box>
      <Box className="content-weekly" style={{ marginTop: "1em", padding: 0 }}>
        <Box padding="1em" style={{ display: "flex", alignItems: "center" }}>
          <Typography className={classes.report} variant="h6">
            Weekly Report
          </Typography>
          <div style={{ marginLeft: "auto" }}>
            <div className="action-weekly-btn" onClick={handleClick}>
              {" "}
              ACTION{" "}
            </div>
            <Popover
              style={{ marginTop: 5 }}
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <div className={classes.box} onClick={viewAll}>
                <i className="fas fa-window-restore" />
                <span style={{ marginLeft: 10 }}>View All</span>
              </div>
              {/* <div className={classes.box} onClick={shareWhatsapp}>
                <i className="fab fa-whatsapp fa-1x" />
                <span style={{ marginLeft: 10 }}>Share WhatsApp</span>
              </div> */}
              <Link
                to={{
                  pathname: `/document/weekly?year=${year}&week=${handleWeekName()}&identity=${weekId}&vito=${emailProfile}`,
                }}
                target="_BLANK"
                style={{ textDecoration: "none" }}
              >
                <div className={classes.box}>
                  <i className="fas fa-cloud-download-alt" />
                  <span style={{ marginLeft: 10 }}>Print / Download .pdf</span>
                </div>
              </Link>
              {/* <div className={classes.box} onClick={downloadDoc}>
                <i className="fas fa-cloud-download-alt" />
                <span style={{ marginLeft: 10 }}>Print / Download .docx</span>
              </div> */}
            </Popover>
          </div>
        </Box>
        <Box className="content-max-weekly">
          {/* { */}
          {/* tabs.map((tab, i) => {
                            return ( */}
          {/* <TabPanel key={i} value={value} index={tab - 1}> */}
          {/* Item {tab} */}
          {/* <Box display="flex"> */}
          <Box
            style={{
              maxWidth: `calc((100vw - 530px) / 2)`,
              minWidth: `calc((100vw - 530px) / 2)`,
              marginLeft: 10,
            }}
            paddingTop="0px"
          >
            <CardBox
              getDataFirst={setDataCategory1}
              title="NETWORKING & PARTNERSHIP"
              options={options.marketing}
              CategoryId={1}
              weekId={weekId}
              weeklyList={weeklyList}
            />
          </Box>
          <Box
            style={{
              maxWidth: `calc((100vw - 540px) / 2)`,
              minWidth: `calc((100vw - 540px) / 2)`,
              marginLeft: 10,
              marginRight: 10,
            }}
            paddingTop="0px"
          >
            <CardBox
              getDataFirst={setDataCategory2}
              title="MARKET INTELLIGENCE"
              options={options.networking}
              CategoryId={2}
              weekId={weekId}
              weeklyList={weeklyList}
            />
          </Box>
          {/*
          
          <Box
            style={{
              maxWidth: `calc((100vw - 530px) / 3)`,
              minWidth: `calc((100vw - 530px) / 3)`,
              marginRight: 10,
            }}
            paddingTop="0px"
          >
            
            <CardBox
              getDataFirst={setDataCategory3}
              title="PROMOTIONAL SUPPORT"
              options={options.facilitation}
              CategoryId={3}
              weekId={weekId}
              weeklyList={weeklyList}
        />
          </Box>
          */}

          {/* </Box> */}
          {/* </TabPanel> */}
          {/* ) */}
          {/* }) */}
          {/* } */}
        </Box>
      </Box>

      <DetailReport
        countryProfile={countryProfile}
        areaProfile={areaProfile}
        setOpen={setOpenModal}
        open={openModal}
        nameProfile={nameProfile}
        photoProfile={photoProfile}
        data={dataSelection}
        weeklyList={weeklyList}
        weekId={weekId}
      />
    </Fragment>
  );
}
