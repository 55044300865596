import axios from 'axios'
import Swal from 'sweetalert2'
import { SERVER } from '../../server'
import {
    IS_LOGOUT,
    GET_USER,
} from "../constanta";

export const getUser = ( sendToken ) => async dispatch => {
    let tokenLocal = localStorage.getItem('token')
    if( tokenLocal || sendToken ) {
        try {
            let token = sendToken ? sendToken : tokenLocal
            const { data } = await axios({
                method: 'get',
                url: `${SERVER}/user`,
                headers: { token }
            })
            dispatch({
                type: GET_USER,
                data : data
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const deleteUser = ( id ) => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            Swal.showLoading()
            await axios({
                method: 'DELETE',
                url: `${SERVER}/user/${id}`,
                headers: { token }
            })
            Swal.close()
            await dispatch(getUser())
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}