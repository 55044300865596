import React, { useState, useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles, Box, Typography, withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import logoWI from '../../../../images/bird.png'
import Comment from '../../Card/Comment'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import './styles.scss'
import CloseIcon from '@material-ui/icons/Close';
import BoxImg from './BoxImg'
import { useParams } from 'react-router-dom'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw, ContentState, convertFromHTML } from 'draft-js';
import MessageIcon from '@material-ui/icons/Message';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: "1rem",
        borderBottom: "1px solid #E1E1E1"
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const images = [
    {
      original: 'https://picsum.photos/id/1018/1000/600/',
      thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
      original: 'https://picsum.photos/id/1015/1000/600/',
      thumbnail: 'https://picsum.photos/id/1015/250/150/',
    },
    {
      original: 'https://picsum.photos/id/1019/1000/600/',
      thumbnail: 'https://picsum.photos/id/1019/250/150/',
    },
];

const useStyles = makeStyles({
    buttonRead: {
        color: "#E23F81",
        background: "rgba(226, 63, 129, 0.1)",
        borderRadius: "2px",
        width: "100px",
        border: "none",
        textTransform: "none"
    },
    boxProfile: {
        borderTop: "0.5px solid #DDDDDD",
        borderBottom: "0.5px solid #DDDDDD",
        padding: "1em 2.5em",
        background: "#FAFAFA",
    },
    titleWorkplan: {
        color: "#E23F81"
    },
    buttonKPI: {
        marginLeft: "2em",
        color: "#E23F81",
        border: "1px solid #E23F81",
        background: "rgba(226, 63, 129, 0.1)",
        borderRadius: "2px",
        textTransform: "none"
    }
})

export default function ScrollDialog(props) {
    const classes = useStyles()
    const [open, setOpen] = useState(false);
    const [scroll, setScroll] = useState('paper');
    const [img, setImg] = useState([1, 2, 3])
    const [week, setWeek] = useState(null)
    const {
        photoProfile,
        data,
        weeklyList
    } = props
    const { year } = useParams()

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);
    
    useEffect(()=>{
        if (weeklyList) {
            let weekFix
            weeklyList.forEach((week)=>{
                if (week.id == data.WeekId) {
                    weekFix = week
                    return
                }
            })
            setWeek(weekFix)
        }
    },[weeklyList])

    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = useRef(null);

    const showDate = (dateParams) => {
        if (dateParams) {
            let start = new Date(dateParams).getDate()
            let startMonth = new Date(dateParams).getMonth()
            let monthStart = ''
            let yearResult = new Date(dateParams).getFullYear()
            switch (startMonth) {
                case 0 :
                    monthStart = 'Januari'
                    break;
                case 1 :
                    monthStart = 'Februari'
                    break;
                case 2 :
                    monthStart = 'Maret'
                    break;
                case 3 :
                    monthStart = 'April'
                    break;
                case 4 :
                    monthStart = 'Mei'
                    break;
                case 5 :
                    monthStart = 'Juni'
                    break;
                case 6 :
                    monthStart = 'Juli'
                    break;
                case 7 :
                    monthStart = 'Agustus'
                    break;
                case 8 :
                    monthStart = 'September'
                    break;
                case 9 :
                    monthStart = 'Oktober'
                    break;
                case 10 :
                    monthStart = 'November'
                    break;
                case 11 :
                    monthStart = 'Desember'
                    break;
                default:
                    monthStart = ''
                    break;
            }
            let hour = new Date(dateParams).getHours()
            let minute = new Date(dateParams).getMinutes()
            let second = new Date(dateParams).getSeconds()
            
            return start + ' '+ monthStart +' '+ yearResult +' '+ (hour < 10 ? '0'+hour : hour) +':'+(minute < 10 ? '0'+minute : minute)+':'+(second < 10 ? '0'+second : second)
        }
    }

    const showDateWeek = (dateParams) => {
        if (dateParams) {
            const [day, month, year] = dateParams.split("/")
            let date = new Date(year, month - 1, day).getDate() 
            let monthNumber = new Date(year, month - 1, day).getMonth()
            let monthResult = ''
            let yearResult = new Date(year, month - 1, day).getFullYear()
            switch (monthNumber) {
                case 0 :
                    monthResult = 'January'
                    break;
                case 1 :
                    monthResult = 'February'
                    break;
                case 2 :
                    monthResult = 'March'
                    break;
                case 3 :
                    monthResult = 'April'
                    break;
                case 4 :
                    monthResult = 'May'
                    break;
                case 5 :
                    monthResult = 'June'
                    break;
                case 6 :
                    monthResult = 'July'
                    break;
                case 7 :
                    monthResult = 'August'
                    break;
                case 8 :
                    monthResult = 'September'
                    break;
                case 9 :
                    monthResult = 'October'
                    break;
                case 10 :
                    monthResult = 'November'
                    break;
                case 11 :
                    monthResult = 'December'
                    break;
                default:
                    monthResult = ''
                    break;
            }
            return date + ' ' + monthResult  + ' ' + yearResult
        }
    }

    return (
        <div >
            <Box borderRadius="50%" padding="0.25rem" style={{ background: "#FAFAFA" }} onClick={handleClickOpen('body')}>
                <IconButton size="small" aria-label="delete">
                    <MessageIcon size="small" color="disabled" />
                </IconButton>
            </Box>
            {/* <Button variant="outlined" size="small" className={classes.buttonRead} >Read More</Button> */}
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth="lg"
                disableEnforceFocus
            >
                <DialogTitle id="scroll-dialog-title" onClose={handleClose} ><Box paddingX="1em" style={{ textTransform: 'capitalize' }}>View Report {data && data.category} Week {week && week.week}, {year && year}</Box></DialogTitle>
                <Box className={classes.boxProfile} display="flex">
                    <Box display="flex" alignItems="center">
                        <Box height="4em" width="4em" border={1} borderRadius="50%" borderColor="grey.500" marginRight="1em">
                            <img width="100%" height="100%" style={{ borderRadius: '50%' }} src={data && data.User ? data.User.photo ? data.User.photo : logoWI : logoWI} />
                        </Box>
                        <Box display="flex" flexDirection="column">
                            <Box display= "flex" alignItems= "center">
                                <Typography variant="h6">{data && data.User ? data.User.name ? data.User.name : '-' : '-'}</Typography>
                                <Box borderLeft="0.5px solid #DDDDDD" marginLeft="1.5em" paddingX="1.5em">
                                    <Typography>Week {week && week.week} ({showDateWeek(week && week.start)} - {showDateWeek(week && week.end)})</Typography>
                                </Box>
                            </Box>
                            <Typography>{data && data.category}</Typography>
                            <Typography variant="caption" className={classes.caption}>Created : { showDate(data && data.createdAt)}</Typography>
                        </Box>
                    </Box>
                </Box>
                <DialogContent dividers={scroll === 'paper'}>
                    <Box>
                        <Typography style={{color: "#DA9D02"}}>{data && data.category} </Typography>
                    </Box>
                    <Box style={{ marginTop: '0.5em' }} >
                        {
                            data && data.credit != 'false' &&
                            <span style={{color: "rgb(174, 125, 1)", background: 'rgb(249, 239, 214)', borderRadius: 2, padding: '5px 10px' }}>
                                {
                                    data && data.credit === 'meminta' ? 'I submit this report as my credit' :
                                    data && data.credit === 'ditolak' ? 'Your Credit is Rejected' :
                                    data && data.credit === 'disetujui' && 'Your Credit is Approved'
                                }
                            </span>
                        }
                    </Box>
                    <Box style={{ marginTop: '1em', marginBottom: '1vw' }} >
                        {
                            data && (data.indicator != 'false' && data.indicator) &&
                            <span style={{color: "#2C304D", background: 'rgba(44, 48, 77, 0.08)', borderRadius: 2, padding: '5px 10px' }}>
                                    { data && data.indicator }
                            </span>
                        }
                    </Box>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <Editor
                            editorState={data && (data.report && data.report != 'null') ?
                                EditorState.createWithContent(convertFromRaw(JSON.parse(data.report)))
                                :
                                EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                            }
                            toolbarHidden
                            readOnly
                            toolbar={false}
                            editorClassName={classes.editor}
                        />
                    </DialogContentText>
                    <Box display= "flex" >
                        {
                            data &&
                            data.photos &&
                            data.photos.split(',').map((item, i)=> {
                                return (
                                    <BoxImg key={i} photos={item} photosAll={data && data.photos}/>
                                )
                            })
                        }
                    </Box>
                </DialogContent>
                <Comment photoProfile={photoProfile} data={data} handleClose={handleClose}/>
            </Dialog>
        </div>
    );
}
