import {
    GET_COORDINATORDATA,
    GET_COORDINATORDATAWEEKLY,
    GET_COORDINATORDATAQUARTERLY,
    GET_COORDINATORDATAOUTLOOK
} from "../constanta";

const initialState = {
    files : [],
    filesWeekly: [],
    filesQuarterly: [],
    filesOutlook: []
}

export default (state = initialState, action)=> {
    switch(action.type){
        case GET_COORDINATORDATA:
            return {
                ...state,
                files: action.data
            }
        case GET_COORDINATORDATAWEEKLY:
            return {
                ...state,
                filesWeekly: action.data
            }
        case GET_COORDINATORDATAQUARTERLY:
            return {
                ...state,
                filesQuarterly: action.data
            }
        case GET_COORDINATORDATAOUTLOOK:
            return {
                ...state,
                filesOutlook: action.data
            }
        default: {
            return state
        }
    }
}