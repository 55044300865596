import { Box, IconButton, Typography } from '@material-ui/core'
import React from 'react'
import DeleteAlert from '../../Report/Modal/DeleteAlert'
import DeleteCreditAlert from '../../Report/Modal/DeleteAlert/deleteReportCreditCard'
import EditWorkplan from '../../Workplan/EditWorkplan'

export default function Index(props) {
    const {
        data,
        read,
        CategoryId,
        weekId,
        weekFind
    } = props

    return (
        <>
            <Box border="0.3px solid #DDDDDD" style={{ background: "white", padding: "0.5rem 1rem", marginBottom: "0.5rem" }}>
                <Box paddingY="0.5rem" marginBottom="0.5rem" maxHeight="6rem" overflow="scroll">
                    <Typography>
                        {data && data.description}
                    </Typography>
                </Box>
                <Box display="flex" paddingY="0.5rem" justifyContent='space-between' borderTop="0.5px solid #DDDDDD" alignItems="center">
                    <Box>
                        <Typography style={{ color: "#DA9D02" }}>
                            {data && data.category ? data.category.includes('Partnership with Airline') ? 'Partnership with Airlines' : data.category : data.category}
                        </Typography>
                    </Box>
                    <Box display="flex">
                        {
                            data &&
                            (data.credit === 'meminta' || data.credit === 'disetujui' || data.credit === 'ditolak') ?
                            <DeleteCreditAlert CategoryId={CategoryId} weekId={weekId} read={read} data={data} categoryReport="Work Plan"/>
                            :
                            <DeleteAlert weekFind={weekFind} CategoryId={CategoryId} weekId={weekId} read={read} data={data} categoryReport="Work Plan"/>
                        }
                        <EditWorkplan weekFind={weekFind} CategoryId={CategoryId} weekId={weekId} read={read} options={props.options} data={data}/>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
