import { Box, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import Card from './Card'



export default function Index(props) {
    const [datas, setDatas] = useState(props.datas)
    const [category, setCategory] = useState('')
    return (
        <>
            <Box display="flex" flexWrap="wrap" justifyContent="space-between">

                {
                    datas && datas.map((data, i) => {
                        return (
                            <Card key={i} category = {data.category}/>
                        )
                    })
                }
            </Box>
        </>
    )
}
