import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles, Box, Typography, withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import logoWI from '../../../../images/bird.png'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import './styles.scss'
import CloseIcon from '@material-ui/icons/Close';
import { useLocation } from 'react-router-dom'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw, ContentState, convertFromHTML } from 'draft-js';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: "1rem",
        borderBottom: "1px solid #E1E1E1"
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const images = [
    {
      original: 'https://picsum.photos/id/1018/1000/600/',
      thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
      original: 'https://picsum.photos/id/1015/1000/600/',
      thumbnail: 'https://picsum.photos/id/1015/250/150/',
    },
    {
      original: 'https://picsum.photos/id/1019/1000/600/',
      thumbnail: 'https://picsum.photos/id/1019/250/150/',
    },
];

function useQuery(){
    return new URLSearchParams(useLocation().search)
}

const useStyles = makeStyles({
    buttonRead: {
        color: "#E23F81",
        background: "rgba(226, 63, 129, 0.1)",
        borderRadius: "2px",
        width: "100px",
        border: "none",
        textTransform: "none"
    },
    boxProfile: {
        borderTop: "0.5px solid #DDDDDD",
        borderBottom: "0.5px solid #DDDDDD",
        padding: "1em 2.5em",
        background: "#FAFAFA",
    },
    titleWorkplan: {
        color: "#E23F81"
    },
    buttonKPI: {
        marginLeft: "2em",
        color: "#E23F81",
        border: "1px solid #E23F81",
        background: "rgba(226, 63, 129, 0.1)",
        borderRadius: "2px",
        textTransform: "none"
    }
})

export default function ScrollDialog(props) {
    const classes = useStyles()
    const [scroll, setScroll] = useState('paper');
    const [img, setImg] = useState([1, 2, 3])
    const [week, setWeek] = useState(null)
    const query = useQuery()
    const {
        nameProfile,
        photoProfile,
        data,
        weeklyList,
        open,
        setOpen,
        weekId,
        countryProfile,
        areaProfile
    } = props

    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);
    
    useEffect(()=>{
        if (weeklyList) {
            let weekFix
            weeklyList.forEach((week)=>{
                if (week.id == weekId) {
                    weekFix = week
                    return
                }
            })
            setWeek(weekFix)
        }
    },[weeklyList, weekId])

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = useRef(null);

    const showDate = (dateParams) => {
        if (dateParams) {
            let start = new Date(dateParams).getDate()
            let startMonth = new Date(dateParams).getMonth()
            let monthStart = ''
            let yearResult = new Date(dateParams).getFullYear()
            switch (startMonth) {
                case 0 :
                    monthStart = 'Januari'
                    break;
                case 1 :
                    monthStart = 'Februari'
                    break;
                case 2 :
                    monthStart = 'Maret'
                    break;
                case 3 :
                    monthStart = 'April'
                    break;
                case 4 :
                    monthStart = 'Mei'
                    break;
                case 5 :
                    monthStart = 'Juni'
                    break;
                case 6 :
                    monthStart = 'Juli'
                    break;
                case 7 :
                    monthStart = 'Agustus'
                    break;
                case 8 :
                    monthStart = 'September'
                    break;
                case 9 :
                    monthStart = 'Oktober'
                    break;
                case 10 :
                    monthStart = 'November'
                    break;
                case 11 :
                    monthStart = 'Desember'
                    break;
                default:
                    monthStart = ''
                    break;
            }
            let hour = new Date(dateParams).getHours()
            let minute = new Date(dateParams).getMinutes()
            let second = new Date(dateParams).getSeconds()
            
            return start + ' '+ monthStart +' '+ yearResult +' '+ (hour < 10 ? '0'+hour : hour) +':'+(minute < 10 ? '0'+minute : minute)+':'+(second < 10 ? '0'+second : second)
        }
    }

    const showDateWeek = (dateParams) => {
        if (dateParams) {
            const [day, month, year] = dateParams.split("/")
            let date = new Date(year, month - 1, day).getDate() 
            let monthNumber = new Date(year, month - 1, day).getMonth()
            let monthResult = ''
            let yearResult = new Date(year, month - 1, day).getFullYear()
            switch (monthNumber) {
                case 0 :
                    monthResult = 'Januari'
                    break;
                case 1 :
                    monthResult = 'Februari'
                    break;
                case 2 :
                    monthResult = 'Maret'
                    break;
                case 3 :
                    monthResult = 'April'
                    break;
                case 4 :
                    monthResult = 'Mei'
                    break;
                case 5 :
                    monthResult = 'Juni'
                    break;
                case 6 :
                    monthResult = 'Juli'
                    break;
                case 7 :
                    monthResult = 'Agustus'
                    break;
                case 8 :
                    monthResult = 'September'
                    break;
                case 9 :
                    monthResult = 'Oktober'
                    break;
                case 10 :
                    monthResult = 'November'
                    break;
                case 11 :
                    monthResult = 'Desember'
                    break;
                default:
                    monthResult = ''
                    break;
            }
            return date + ' ' + monthResult  + ' ' + yearResult
        }
    }

    return (
        <div >
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth="lg"
                disableEnforceFocus
            >
                <DialogTitle id="scroll-dialog-title" onClose={handleClose} >
                    <Box paddingX="1em" style={{ textTransform: 'capitalize' }}>Lihat Semua Laporan Minggu {week && week.week}, {query.get('year') && query.get('year')}</Box>
                </DialogTitle>
                <Box className={classes.boxProfile} display="flex">
                    <Box display="flex" alignItems="center">
                        <Box height="4em" width="4em" border={1} borderRadius="50%" borderColor="grey.500" marginRight="1em">
                            <img width="100%" height="100%" style={{ borderRadius: '50%' }} src={photoProfile ? photoProfile : logoWI } />
                        </Box>
                        <Box display="flex" flexDirection="column">
                            <Box display= "flex" alignItems= "center" style={{ color: '#e35981' }} >
                                <Typography style={{ fontWeight: 'bold' }}>{countryProfile && countryProfile} - {areaProfile && areaProfile}</Typography>
                                <Box borderLeft="0.5px solid #DDDDDD" marginLeft="0.5em" paddingLeft="0.5em">
                                    <Typography style={{ fontWeight: 'bold' }}>{nameProfile ? nameProfile : '-'}</Typography>
                                </Box>
                                <Box borderLeft="0.5px solid #DDDDDD" marginLeft="0.5em" paddingLeft="0.5em">
                                    <Typography style={{ fontWeight: 'bold' }} >Week {week && week.week} ({showDateWeek(week && week.start)} - {showDateWeek(week && week.end)})</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <DialogContent style={{ padding: 0 }} dividers={scroll === 'paper'}>
                    <div className="boxbig-view-all" style={{ padding: '20px 40px' }}>
                        <span className="header-view-all"  >
                            1. NETWORKING & PARTNERSHIP
                        </span>
                        <div style={{ paddingLeft: 15, marginTop: 20, display: 'flex', flexDirection: 'column' }} >
                            <div>
                                <span className="indicator-view-all"/>
                                {
                                    data && data[0] &&
                                    data[0]
                                    .filter(a=> (!a.category || a.category == 'null'))
                                    .map((a, i)=>(
                                        (a.status === 'workplan' || a.status === 'report') ?
                                        <div className="box-content-98" key={i}>
                                            <div className="box-content-header-98" >
                                                <strong> a {i+=1}. Workplan: </strong> {a && a.description ? a.description : '-'}
                                            </div>
                                            <div className="box-content-987" >
                                                <span className="box-content-report-txt-123" > Report: </span>
                                                <Editor
                                                    editorState={a && ((a.report != 'null') && (a.report)) ?
                                                        EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                                        :
                                                        EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                                    }
                                                    toolbarHidden
                                                    readOnly
                                                    toolbar={false}
                                                    editorClassName="box-content-txt-1231"
                                                />
                                            </div>
                                        </div>
                                        :
                                        a.status === "onlyreport" &&
                                        <div className="box-content-98" key={i}>
                                            <div className="box-content-header-98" >
                                                <strong> a {i+=1}. Report without Workplan: </strong>
                                            </div>
                                            <div className="box-content-987" style={{ marginTop: 0 }}>
                                                <Editor
                                                    editorState={a && ((a.report != 'null') && (a.report)) ?
                                                        EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                                        :
                                                        EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                                    }
                                                    toolbarHidden
                                                    readOnly
                                                    toolbar={false}
                                                    editorClassName="box-content-txt-1231"
                                                />
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <div style={{ marginTop: 20 }} >
                                <span className="indicator-view-all">
                                    A. Partnership with Academics
                                </span>
                                {
                                    data && data[0] &&
                                    data[0]
                                    .filter(a=> (a.category).includes('Partnership with Academics') )
                                    .map((a, i)=>(
                                        (a.status === 'workplan' || a.status === 'report') ?
                                        <div className="box-content-98" key={i}>
                                            <div className="box-content-header-98" >
                                                <strong> a {i+=1}. Workplan: </strong> {a && a.description ? a.description : '-'}
                                            </div>
                                            <div className="box-content-987" >
                                                <span className="box-content-report-txt-123" > Report: </span>
                                                <Editor
                                                    editorState={a && ((a.report != 'null') && (a.report)) ?
                                                        EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                                        :
                                                        EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                                    }
                                                    toolbarHidden
                                                    readOnly
                                                    toolbar={false}
                                                    editorClassName="box-content-txt-1231"
                                                />
                                            </div>
                                        </div>
                                        :
                                        a.status === "onlyreport" &&
                                        <div className="box-content-98" key={i}>
                                            <div className="box-content-header-98" >
                                                <strong> a {i+=1}. Report without Workplan: </strong>
                                            </div>
                                            <div className="box-content-987" style={{ marginTop: 0 }}>
                                                <Editor
                                                    editorState={a && ((a.report != 'null') && (a.report)) ?
                                                        EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                                        :
                                                        EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                                    }
                                                    toolbarHidden
                                                    readOnly
                                                    toolbar={false}
                                                    editorClassName="box-content-txt-1231"
                                                />
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            
                            <div style={{ marginTop: 20 }} >
                                <span className="indicator-view-all">
                                    B. Partnership with Business (TA/TO, Airlines, Accommodation, and others)
                                </span>
                                {
                                    data && data[0] &&
                                    data[0]
                                    .filter(a=> (a.category).includes('Partnership with Business (TA/TO, Airlines, Accommodation, and others)') )
                                    .map((a, i)=>(
                                        (a.status === 'workplan' || a.status === 'report') ?
                                        <div className="box-content-98" key={i}>
                                            <div className="box-content-header-98" >
                                                <strong> b {i+=1}. Workplan: </strong> {a && a.description ? a.description : '-'}
                                            </div>
                                            <div className="box-content-987" >
                                                <span className="box-content-report-txt-123" > Report: </span>
                                                <Editor
                                                    editorState={a && ((a.report != 'null') && (a.report)) ?
                                                        EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                                        :
                                                        EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                                    }
                                                    toolbarHidden
                                                    readOnly
                                                    toolbar={false}
                                                    editorClassName="box-content-txt-1231"
                                                />
                                            </div>
                                        </div>
                                        :
                                        a.status === "onlyreport" &&
                                        <div className="box-content-98" key={i}>
                                            <div className="box-content-header-98" >
                                                <strong> b {i+=1}. Report without Workplan: </strong>
                                            </div>
                                            <div className="box-content-987" style={{ marginTop: 0 }}>
                                                <Editor
                                                    editorState={a && ((a.report != 'null') && (a.report)) ?
                                                        EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                                        :
                                                        EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                                    }
                                                    toolbarHidden
                                                    readOnly
                                                    toolbar={false}
                                                    editorClassName="box-content-txt-1231"
                                                />
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            
                            <div style={{ marginTop: 20 }} >
                                <span className="indicator-view-all">
                                    C. Partnership with Communities
                                </span>
                                {
                                    data && data[0] &&
                                    data[0]
                                    .filter(a=> (a.category).includes('Partnership with Communities') )
                                    .map((a, i)=>(
                                        (a.status === 'workplan' || a.status === 'report') ?
                                        <div className="box-content-98" key={i}>
                                            <div className="box-content-header-98" >
                                                <strong> c {i+=1}. Workplan: </strong> {a && a.description ? a.description : '-'}
                                            </div>
                                            <div className="box-content-987" >
                                                <span className="box-content-report-txt-123" > Report: </span>
                                                <Editor
                                                    editorState={a && ((a.report != 'null') && (a.report)) ?
                                                        EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                                        :
                                                        EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                                    }
                                                    toolbarHidden
                                                    readOnly
                                                    toolbar={false}
                                                    editorClassName="box-content-txt-1231"
                                                />
                                            </div>
                                        </div>
                                        :
                                        a.status === "onlyreport" &&
                                        <div className="box-content-98" key={i}>
                                            <div className="box-content-header-98" >
                                                <strong> c {i+=1}. Report without Workplan: </strong>
                                            </div>
                                            <div className="box-content-987" style={{ marginTop: 0 }}>
                                                <Editor
                                                    editorState={a && ((a.report != 'null') && (a.report)) ?
                                                        EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                                        :
                                                        EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                                    }
                                                    toolbarHidden
                                                    readOnly
                                                    toolbar={false}
                                                    editorClassName="box-content-txt-1231"
                                                />
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            
                            <div style={{ marginTop: 20 }} >
                                <span className="indicator-view-all">
                                    D. Partnership with Governments
                                </span>
                                {
                                    data && data[0] &&
                                    data[0]
                                    .filter(a=> (a.category).includes('Partnership with Governments') )
                                    .map((a, i)=>(
                                        (a.status === 'workplan' || a.status === 'report') ?
                                        <div className="box-content-98" key={i}>
                                            <div className="box-content-header-98" >
                                                <strong> d {i+=1}. Workplan: </strong> {a && a.description ? a.description : '-'}
                                            </div>
                                            <div className="box-content-987" >
                                                <span className="box-content-report-txt-123" > Report: </span>
                                                <Editor
                                                    editorState={a && ((a.report != 'null') && (a.report)) ?
                                                        EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                                        :
                                                        EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                                    }
                                                    toolbarHidden
                                                    readOnly
                                                    toolbar={false}
                                                    editorClassName="box-content-txt-1231"
                                                />
                                            </div>
                                        </div>
                                        :
                                        a.status === "onlyreport" &&
                                        <div className="box-content-98" key={i}>
                                            <div className="box-content-header-98" >
                                                <strong> d {i+=1}. Report without Workplan: </strong>
                                            </div>
                                            <div className="box-content-987" style={{ marginTop: 0 }}>
                                                <Editor
                                                    editorState={a && ((a.report != 'null') && (a.report)) ?
                                                        EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                                        :
                                                        EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                                    }
                                                    toolbarHidden
                                                    readOnly
                                                    toolbar={false}
                                                    editorClassName="box-content-txt-1231"
                                                />
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>

                            <div style={{ marginTop: 20 }} >
                                <span className="indicator-view-all">
                                    D. Partnership with Media
                                </span>
                                {
                                    data && data[0] &&
                                    data[0]
                                    .filter(a=> (a.category).includes('Partnership with Media') )
                                    .map((a, i)=>(
                                        (a.status === 'workplan' || a.status === 'report') ?
                                        <div className="box-content-98" key={i}>
                                            <div className="box-content-header-98" >
                                                <strong> d {i+=1}. Workplan: </strong> {a && a.description ? a.description : '-'}
                                            </div>
                                            <div className="box-content-987" >
                                                <span className="box-content-report-txt-123" > Report: </span>
                                                <Editor
                                                    editorState={a && ((a.report != 'null') && (a.report)) ?
                                                        EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                                        :
                                                        EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                                    }
                                                    toolbarHidden
                                                    readOnly
                                                    toolbar={false}
                                                    editorClassName="box-content-txt-1231"
                                                />
                                            </div>
                                        </div>
                                        :
                                        a.status === "onlyreport" &&
                                        <div className="box-content-98" key={i}>
                                            <div className="box-content-header-98" >
                                                <strong> d {i+=1}. Report without Workplan: </strong>
                                            </div>
                                            <div className="box-content-987" style={{ marginTop: 0 }}>
                                                <Editor
                                                    editorState={a && ((a.report != 'null') && (a.report)) ?
                                                        EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                                        :
                                                        EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                                    }
                                                    toolbarHidden
                                                    readOnly
                                                    toolbar={false}
                                                    editorClassName="box-content-txt-1231"
                                                />
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>

                    <div className="divider-view-all" />

                    <div className="boxbig-view-all" style={{ padding: '20px 40px' }} >
                        <span className="header-view-all"  >
                            2. MARKET INTELLIGENCE
                        </span>
                        <div style={{ paddingLeft: 15, marginTop: 20, display: 'flex', flexDirection: 'column' }} >
                            <div>
                                <span className="indicator-view-all"/>
                                {
                                    data && data[1] &&
                                    data[1]
                                    .filter(a=> (!a.category || !a.category === 'null') )
                                    .map((a, i)=>(
                                        (a.status === 'workplan' || a.status === 'report') ?
                                        <div className="box-content-98" key={i}>
                                            <div className="box-content-header-98" >
                                                <strong> {i+=1}. Workplan: </strong> {a && a.description ? a.description : '-'}
                                            </div>
                                            <div className="box-content-987" >
                                                <span className="box-content-report-txt-123" > Report: </span>
                                                <Editor
                                                    editorState={a && ((a.report != 'null') && (a.report)) ?
                                                        EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                                        :
                                                        EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                                    }
                                                    toolbarHidden
                                                    readOnly
                                                    toolbar={false}
                                                    editorClassName="box-content-txt-1231"
                                                />
                                            </div>
                                        </div>
                                        :
                                        a.status === "onlyreport" &&
                                        <div className="box-content-98" key={i}>
                                            <div className="box-content-header-98" >
                                                <strong> {i+=1}. Report without Workplan: </strong>
                                            </div>
                                            <div className="box-content-987" style={{ marginTop: 0 }}>
                                                <Editor
                                                    editorState={a && ((a.report != 'null') && (a.report)) ?
                                                        EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                                        :
                                                        EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                                    }
                                                    toolbarHidden
                                                    readOnly
                                                    toolbar={false}
                                                    editorClassName="box-content-txt-1231"
                                                />
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <div style={{ marginTop: 20 }} >
                                <span className="indicator-view-all">
                                    A. Market Update
                                </span>
                                {
                                    data && data[1] &&
                                    data[1]
                                    .filter(a=> (a.category).includes('Market Update') )
                                    .map((a, i)=>(
                                        (a.status === 'workplan' || a.status === 'report') ?
                                        <div className="box-content-98" key={i}>
                                            <div className="box-content-header-98" >
                                                <strong> a {i+=1}. Workplan: </strong> {a && a.description ? a.description : '-'}
                                            </div>
                                            <div className="box-content-987" >
                                                <span className="box-content-report-txt-123" > Report: </span>
                                                <Editor
                                                    editorState={a && ((a.report != 'null') && (a.report)) ?
                                                        EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                                        :
                                                        EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                                    }
                                                    toolbarHidden
                                                    readOnly
                                                    toolbar={false}
                                                    editorClassName="box-content-txt-1231"
                                                />
                                            </div>
                                        </div>
                                        :
                                        a.status === "onlyreport" &&
                                        <div className="box-content-98" key={i}>
                                            <div className="box-content-header-98" >
                                                <strong> a {i+=1}. Report without Workplan: </strong>
                                            </div>
                                            <div className="box-content-987" style={{ marginTop: 0 }}>
                                                <Editor
                                                    editorState={a && ((a.report != 'null') && (a.report)) ?
                                                        EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                                        :
                                                        EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                                    }
                                                    toolbarHidden
                                                    readOnly
                                                    toolbar={false}
                                                    editorClassName="box-content-txt-1231"
                                                />
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            
                            <div style={{ marginTop: 20 }} >
                                <span className="indicator-view-all">
                                    B. Competitor Update
                                </span>
                                {
                                    data && data[1] &&
                                    data[1]
                                    .filter(a=> (a.category).includes('Competitor Update') )
                                    .map((a, i)=>(
                                        (a.status === 'workplan' || a.status === 'report') ?
                                        <div className="box-content-98" key={i}>
                                            <div className="box-content-header-98" >
                                                <strong> b {i+=1}. Workplan: </strong> {a && a.description ? a.description : '-'}
                                            </div>
                                            <div className="box-content-987" >
                                                <span className="box-content-report-txt-123" > Report: </span>
                                                <Editor
                                                    editorState={a && ((a.report != 'null') && (a.report)) ?
                                                        EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                                        :
                                                        EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                                    }
                                                    toolbarHidden
                                                    readOnly
                                                    toolbar={false}
                                                    editorClassName="box-content-txt-1231"
                                                />
                                            </div>
                                        </div>
                                        :
                                        a.status === "onlyreport" &&
                                        <div className="box-content-98" key={i}>
                                            <div className="box-content-header-98" >
                                                <strong> b {i+=1}. Report without Workplan: </strong>
                                            </div>
                                            <div className="box-content-987" style={{ marginTop: 0 }}>
                                                <Editor
                                                    editorState={a && ((a.report != 'null') && (a.report)) ?
                                                        EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                                        :
                                                        EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                                    }
                                                    toolbarHidden
                                                    readOnly
                                                    toolbar={false}
                                                    editorClassName="box-content-txt-1231"
                                                />
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            
                            <div style={{ marginTop: 20 }} >
                                <span className="indicator-view-all">
                                    C. Global Trend
                                </span>
                                {
                                    data && data[1] &&
                                    data[1]
                                    .filter(a=> (a.category).includes('Global Trend') )
                                    .map((a, i)=>(
                                        (a.status === 'workplan' || a.status === 'report') ?
                                        <div className="box-content-98" key={i}>
                                            <div className="box-content-header-98" >
                                                <strong> c {i+=1}. Workplan: </strong> {a && a.description ? a.description : '-'}
                                            </div>
                                            <div className="box-content-987" >
                                                <span className="box-content-report-txt-123" > Report: </span>
                                                <Editor
                                                    editorState={a && ((a.report != 'null') && (a.report)) ?
                                                        EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                                        :
                                                        EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                                    }
                                                    toolbarHidden
                                                    readOnly
                                                    toolbar={false}
                                                    editorClassName="box-content-txt-1231"
                                                />
                                            </div>
                                        </div>
                                        :
                                        a.status === "onlyreport" &&
                                        <div className="box-content-98" key={i}>
                                            <div className="box-content-header-98" >
                                                <strong> c {i+=1}. Report without Workplan: </strong>
                                            </div>
                                            <div className="box-content-987" style={{ marginTop: 0 }}>
                                                <Editor
                                                    editorState={a && ((a.report != 'null') && (a.report)) ?
                                                        EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                                        :
                                                        EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                                    }
                                                    toolbarHidden
                                                    readOnly
                                                    toolbar={false}
                                                    editorClassName="box-content-txt-1231"
                                                />
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            
                        </div>
                    </div>

                    <div className="divider-view-all" />

                    {/* <div className="boxbig-view-all" style={{ padding: '20px 40px' }} >
                        <span className="header-view-all"  >
                            3. PROMOTIONAL SUPPORT
                        </span>
                        <div style={{ paddingLeft: 15, marginTop: 20, display: 'flex', flexDirection: 'column' }} >
                            <div>
                                <span className="indicator-view-all"/>
                                {
                                    data && data[2] &&
                                    data[2]
                                    .filter(a=> (!a.category || !a.category === 'null'))
                                    .map((a, i)=>(
                                        (a.status === 'workplan' || a.status === 'report') ?
                                        <div className="box-content-98" key={i}>
                                            <div className="box-content-header-98" >
                                                <strong> {i+=1}. Workplan: </strong> {a && a.description ? a.description : '-'}
                                            </div>
                                            <div className="box-content-987" >
                                                <span className="box-content-report-txt-123" > Report: </span>
                                                <Editor
                                                    editorState={a && ((a.report != 'null') && (a.report)) ?
                                                        EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                                        :
                                                        EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                                    }
                                                    toolbarHidden
                                                    readOnly
                                                    toolbar={false}
                                                    editorClassName="box-content-txt-1231"
                                                />
                                            </div>
                                        </div>
                                        :
                                        a.status === "onlyreport" &&
                                        <div className="box-content-98" key={i}>
                                            <div className="box-content-header-98" >
                                                <strong> {i+=1}. Report without Workplan: </strong>
                                            </div>
                                            <div className="box-content-987" style={{ marginTop: 0 }}>
                                                <Editor
                                                    editorState={a && ((a.report != 'null') && (a.report)) ?
                                                        EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                                        :
                                                        EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                                    }
                                                    toolbarHidden
                                                    readOnly
                                                    toolbar={false}
                                                    editorClassName="box-content-txt-1231"
                                                />
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>

                            <div style={{ marginTop: 20 }}>
                                <span className="indicator-view-all">
                                    A. Regional Promotion (SM, TM, FT, Festival)
                                </span>
                                {
                                    data && data[2] &&
                                    data[2]
                                    .filter(a=> (a.category).includes('Regional Promotion') )
                                    .map((a, i)=>(
                                        (a.status === 'workplan' || a.status === 'report') ?
                                        <div className="box-content-98" key={i}>
                                            <div className="box-content-header-98" >
                                                <strong> a {i+=1}. Workplan: </strong> {a && a.description ? a.description : '-'}
                                            </div>
                                            <div className="box-content-987" >
                                                <span className="box-content-report-txt-123" > Report: </span>
                                                <Editor
                                                    editorState={a && ((a.report != 'null') && (a.report)) ?
                                                        EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                                        :
                                                        EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                                    }
                                                    toolbarHidden
                                                    readOnly
                                                    toolbar={false}
                                                    editorClassName="box-content-txt-1231"
                                                />
                                            </div>
                                        </div>
                                        :
                                        a.status === "onlyreport" &&
                                        <div className="box-content-98" key={i}>
                                            <div className="box-content-header-98" >
                                                <strong> a {i+=1}. Report without Workplan: </strong>
                                            </div>
                                            <div className="box-content-987" style={{ marginTop: 0 }}>
                                                <Editor
                                                    editorState={a && ((a.report != 'null') && (a.report)) ?
                                                        EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                                        :
                                                        EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                                    }
                                                    toolbarHidden
                                                    readOnly
                                                    toolbar={false}
                                                    editorClassName="box-content-txt-1231"
                                                />
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            
                            <div style={{ marginTop: 20 }} >
                                <span className="indicator-view-all">
                                    B. Online Promotion (Website & Social Media)
                                </span>
                                {
                                    data && data[2] &&
                                    data[2]
                                    .filter(a=> (a.category).includes('Online Promotion') )
                                    .map((a, i)=>(
                                        (a.status === 'workplan' || a.status === 'report') ?
                                        <div className="box-content-98" key={i}>
                                            <div className="box-content-header-98" >
                                                <strong> b {i+=1}. Workplan: </strong> {a && a.description ? a.description : '-'}
                                            </div>
                                            <div className="box-content-987" >
                                                <span className="box-content-report-txt-123" > Report: </span>
                                                <Editor
                                                    editorState={a && ((a.report != 'null') && (a.report)) ?
                                                        EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                                        :
                                                        EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                                    }
                                                    toolbarHidden
                                                    readOnly
                                                    toolbar={false}
                                                    editorClassName="box-content-txt-1231"
                                                />
                                            </div>
                                        </div>
                                        :
                                        a.status === "onlyreport" &&
                                        <div className="box-content-98" key={i}>
                                            <div className="box-content-header-98" >
                                                <strong> b {i+=1}. Report without Workplan: </strong>
                                            </div>
                                            <div className="box-content-987" style={{ marginTop: 0 }}>
                                                <Editor
                                                    editorState={a && ((a.report != 'null') && (a.report)) ?
                                                        EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                                        :
                                                        EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                                    }
                                                    toolbarHidden
                                                    readOnly
                                                    toolbar={false}
                                                    editorClassName="box-content-txt-1231"
                                                />
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            
                            <div style={{ marginTop: 20 }} >
                                <span className="indicator-view-all">
                                    C. PR-Ing and Customer Service
                                </span>
                                {
                                    data && data[2] &&
                                    data[2]
                                    .filter(a=> (a.category).includes('Customer Service') )
                                    .map((a, i)=>(
                                        (a.status === 'workplan' || a.status === 'report') ?
                                        <div className="box-content-98" key={i}>
                                            <div className="box-content-header-98" >
                                                <strong> c {i+=1}. Workplan: </strong> {a && a.description ? a.description : '-'}
                                            </div>
                                            <div className="box-content-987" >
                                                <span className="box-content-report-txt-123" > Report: </span>
                                                <Editor
                                                    editorState={a && ((a.report != 'null') && (a.report)) ?
                                                        EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                                        :
                                                        EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                                    }
                                                    toolbarHidden
                                                    readOnly
                                                    toolbar={false}
                                                    editorClassName="box-content-txt-1231"
                                                />
                                            </div>
                                        </div>
                                        :
                                        a.status === "onlyreport" &&
                                        <div className="box-content-98" key={i}>
                                            <div className="box-content-header-98" >
                                                <strong> c {i+=1}. Report without Workplan: </strong>
                                            </div>
                                            <div className="box-content-987" style={{ marginTop: 0 }}>
                                                <Editor
                                                    editorState={a && ((a.report != 'null') && (a.report)) ?
                                                        EditorState.createWithContent(convertFromRaw(JSON.parse(a.report)))
                                                        :
                                                        EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML('<p>-</p>')))
                                                    }
                                                    toolbarHidden
                                                    readOnly
                                                    toolbar={false}
                                                    editorClassName="box-content-txt-1231"
                                                />
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            
                        </div>
                    </div> */}
                    
                </DialogContent>
            </Dialog>
        </div>
    );
}
