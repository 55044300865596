import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import logoWI from '../../../../images/bird.png'

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        color: "#E23F81"
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const useStyles = makeStyles({
    buttonRead: {
        color: "#E23F81",
        background: "rgba(226, 63, 129, 0.1)",
        borderRadius: "2px",
        width: "100px",
        border: "none",
        textTransform: "none"
    },
    content: {
        width: "50em",
        padding: "0 1rem",
        background: "white"
    },
    text: {
        margin: "1rem 0rem"
    }
})

export default function CustomizedDialogs() {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles()

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button variant="outlined" size="small" className={classes.buttonRead} onClick={handleClickOpen}>Read More</Button>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" maxWidth="lg"  open={open} paper={classes.borderDialog}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Sales Mission
                </DialogTitle>
                    <Box className={classes.content}>
                    <Typography variant="h6">KPI Approved</Typography>
                    <Typography variant="caption">Created : 12 June 2020 00:19:47</Typography>
                    <Typography className={classes.text}>Held at Sunway Pyramid from the 5th to 7th April 2019. The event was held successfully at Blue Atrium, Sunway Pyramid with the participation of ten local travel agencies. The appearance of local celebrity, Zulin Aziz at the event had created more impact to the. Lorem ipsum dolor sit amet consectetur adipisicing elit. Error animi, sint optio sapiente ut illo vitae delectus soluta molestias ratione dolorum assumenda obcaecati voluptatum veniam odio vel dicta nostrum ducimus!</Typography>
                    </Box>
            </Dialog>
        </div>
    );
}