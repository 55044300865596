import axios from 'axios'
import Swal from 'sweetalert2'
import { SERVER } from '../../server'
import {
    IS_LOGOUT,
    GET_VITO,
    GET_ONEVITO,
    GET_WEEKLY,
    GET_MONTHLY,
    GET_MONTHGALERRY,
    GET_MONTHLYDASHBOARD,
    GET_WEEKLYDASHBOARD
} from "../constanta";

export const getVito = ( sendToken ) => async dispatch => {
    let tokenLocal = localStorage.getItem('token')
    if( tokenLocal || sendToken ) {
        try {
            let token = sendToken ? sendToken : tokenLocal
            const { data } = await axios({
                method: 'get',
                url: `${SERVER}/vito`,
                headers: { token }
            })
            dispatch({
                type: GET_VITO,
                data : data
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const getVitoPIC = ( sendToken ) => async dispatch => {
    let tokenLocal = localStorage.getItem('token')
    if( tokenLocal || sendToken ) {
        try {
            let token = sendToken ? sendToken : tokenLocal
            const { data } = await axios({
                method: 'get',
                url: `${SERVER}/vito/pic`,
                headers: { token }
            })
            dispatch({
                type: GET_VITO,
                data : data
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const deleteVito = ( id ) => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            Swal.showLoading()
            await axios({
                method: 'DELETE',
                url: `${SERVER}/vito/${id}`,
                headers: { token }
            })
            Swal.close()
            await dispatch(getVito())
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const getOneVito = ( id ) => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            const { data } = await axios({
                method: 'GET',
                url: `${SERVER}/vito/get-name/${id}`,
                headers: { token }
            })
            dispatch({
                type: GET_ONEVITO,
                data : data
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const getWeekly = ( year ) => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            const { data } = await axios({
                method: 'GET',
                url: `${SERVER}/database/list/${year}`,
                headers: { token }
            })
            dispatch({
                type: GET_WEEKLY,
                data : data
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const getMonthly = ( year ) => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            const { data } = await axios({
                method: 'GET',
                url: `${SERVER}/database/${year}`,
                headers: { token }
            })
            dispatch({
                type: GET_MONTHLY,
                data : data
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const getMonthlyGallery = ( year ) => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            const { data } = await axios({
                method: 'GET',
                url: `${SERVER}/database/${year}`,
                headers: { token }
            })
            dispatch({
                type: GET_MONTHGALERRY,
                data : data
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const getMonthlyDashboard = ( year ) => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            const { data } = await axios({
                method: 'GET',
                url: `${SERVER}/database/${year}`,
                headers: { token }
            })
            dispatch({
                type: GET_MONTHLYDASHBOARD,
                data : data
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}

export const getWeeklyDashboard = ( year ) => async dispatch => {
    let token = localStorage.getItem('token')
    if( token ) {
        try {
            const { data } = await axios({
                method: 'GET',
                url: `${SERVER}/database/list/${year}`,
                headers: { token }
            })
            dispatch({
                type: GET_WEEKLYDASHBOARD,
                data : data
            })
        } catch (err) {
        }
    } else {
        dispatch({
            type: IS_LOGOUT
        })
    }
}