import { Box, Typography } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import Detail from '../Modal/DetailLihatKPI'

export default function Index(props) {
    const [background, setBackground] = useState("#F3F3F3")
    useEffect(() => {
        if (!props.category) {
            setBackground("#FEF5F9")
        }
    }, [])

    return (
        <>
            <Box borderRadius="8px" width="46%" margin="1rem" marginLeft='0rem' style={{ background: `${background}` }} padding="1rem">
                <Box display="flex" justifyContent="space-between">
                    <Box display="flex"><Typography color="secondary" variant="h6">Sales Mission</Typography>
                        {props.category ? <Box><Typography style={{ color: "#999999", marginLeft: "1rem" }} variant="h6">(KPI Approved)</Typography></Box> : null}</Box>
                    <Box><Typography style={{ color: "#999999" }}>May 22, 2020</Typography></Box>
                </Box>
                <Box marginY="0.5rem">
                    <Typography variant="h6">Wonderful Indonesia Travel Fair with Citilink II 2019 </Typography>
                </Box>
                <Box>
                    <Box maxHeight="4rem" overflow="scroll">
                        <Typography style={{ color: "#999999" }}>Held at Sunway Pyramid from the 5th to 7th April 2019. The event was held successfully at Blue Atrium, Sunway Pyramid with the participation of ten local travel agencies. The appearance of local celebrity, Zulin Aziz at the event had created more impact to the Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod, fuga consequuntur. Fugit enim deserunt, dolores tempore excepturi quo atque aut ex ea voluptas, fuga soluta amet! Dolores quisquam maiores quibusdam?</Typography>
                    </Box>
                    <Box width={1 / 2} marginTop="1rem">
                        <Detail/>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
