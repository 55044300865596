import { Box, Button, Grid, makeStyles, TextField, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import {useHistory} from 'react-router-dom'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import logoWI from '../../../../images/bird.png'
import Axios from 'axios'
import { SERVER } from '../../../../../../server'

const useStyle = makeStyles({
    headBox: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        height: "10em",
        borderRadius: "0.5em 0.5em 0 0",
        background: "linear-gradient(91.42deg, #F3488D 0%, #5433ED 100%)"
    },
    root: {
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "#F2F3F9",
        height: "100vh"
    },
    profile: {
        background: "white",
        padding: "2em",
        borderRadius: "4px"
    },
    buttonUpload: {
        color: "#E23F81",
        border: "1px solid #E23F81",
        borderRadius: "4px",
        textTransform: "none"
    },
    icon: {
        fontSize: "10em",
        color: "#F2F3F9"
    },
    label: {
        color: "#999999",
    },
    labelx:{
        color: "#999999",
        textTransform: 'uppercase'
    },
    boxRelative : {
        position:"relative",
        height:"5em",
        display:"flex",
        background:"white",
        justifyContent:"center"
    },
    detail: {
        background: "white",
        paddingTop: "5%",
        paddingX: "20%",
        borderRadius: "4px",
        paddingBottom: "15%",
        borderRadius: "0 0 0.5em 0.5em",
        borderTop: "0.3px solid #DDDDDD;"
    },
    circleIcon: {
        borderRadius:"50%",
        width:"10em",
        height:"10em",
        border:"4px solid #FFEDF4",
        position:"absolute",
        top:"-5em",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        background: "white"
    },
    buttonEdit : {
        background: "rgba(255, 255, 255, 0.08)",
        border: "0.5px solid #EAEAEA",
        borderRadius: "4px",
        textTransform: "none",
        color: "white",
        margin: "1em",
        padding: "0.5em 1.5em"
    }
})

export default function View({ match }) {
    const classes = useStyle()
    const history = useHistory()
    const [profile, setProfile] = useState(null)

    useEffect(()=>{
        Axios({
            method: 'GET',
            url: `${SERVER}/vito/${match.params.id}`,
            headers: { token : localStorage.getItem('token') }
        })
        .then(({ data })=>{
            setProfile(data)
        })
        .catch(err=>{
            console.log(err);
        })
    },[])

    function back () {
        history.push('/admin/vito')
    }

    function edit () {
        history.push(`/admin/vito/edit/${match.params.id}`)
    }

    return (
        <>
            <Box spacing={3} className={classes.root} >
                <Typography variant="h3"><b>VITO Profil</b></Typography>
                <Box width="50%">
                    <Box>
                        <Button style={{ color: "#E23F81" }} onClick={back} startIcon={<ArrowBackIcon />}>Kembali</Button>
                    </Box>
                    <Box width="100%" display="flex" flexDirection="column" justifyContent="center" textAlign="center">
                        <Box className={classes.headBox}>
                            {/* <Button className={classes.buttonEdit} onClick={edit}>Edit Profile</Button> */}
                        </Box>
                        <Box className={classes.boxRelative}>
                            <Box className={classes.circleIcon}>
                                {
                                    profile &&
                                    profile.photo ?
                                    <img style={{ borderRadius: '50%' }} src={profile.photo} width="100%" height="100%"/>
                                    :
                                    <img src={logoWI} width="100%" height="100%"/>
                                }
                            </Box>
                        </Box>
                        <Box className={classes.profile}>
                            <Typography variant="h4"><b>{ profile && profile.name ? profile.name : '-' }</b></Typography>
                            <Typography className={classes.labelx}>({ profile && profile.country ? profile.country : '-' })</Typography>
                        </Box>
                        <Box display="flex" className={classes.detail}>
                            <Box width={1 / 2} display="flex" flexDirection="column" marginLeft="20%">
                                <Box textAlign="left">
                                    <Typography className={classes.label} >Email</Typography>
                                    <Typography>{ profile && profile.email ? profile.email : "-" }</Typography>
                                </Box>
                                <Box textAlign="left" marginTop="1em">
                                    <Typography className={classes.label} >No. Telepon</Typography>
                                    <Typography>{ profile && profile.phone ? profile.phone : "-" }</Typography>
                                </Box>
                            </Box>
                            <Box width={1 / 2}  display="flex" flexDirection="column" marginLeft="10%">
                                <Box textAlign="left">
                                    <Typography  className={classes.label} >Nama Kantor</Typography>
                                    <Typography>{ profile && profile.companyName ? profile.companyName : '-' }</Typography>
                                </Box>
                                <Box textAlign="left" marginTop="1em">
                                    <Typography className={classes.label} >Alamat Kantor</Typography>
                                    <Typography>{ profile && profile.companyAddress ? profile.companyAddress : "-" }</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>


                </Box>

            </Box>


        </>
    )
}
