import React, { useEffect, useState } from 'react'
import './styles.scss'
import ProfileUnknown from '../../../../../images//unknown.png'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getNotification, readNotification, restart, getNotificationPIC, getNotificationPO } from '../../../../../store/actions/notificationAction'
import socketClient from "socket.io-client";
import { SERVER } from '../../../../../server'

export default function Notification() {
    const History = useHistory()
    const Dispatch = useDispatch()
    const notification = useSelector(state=> state.notificationState.notification)
    const myEmail = useSelector(state=> state.profileState.email)
    const myRole = useSelector(state=> state.profileState.role)
    let [counting, setCounting] = useState(1)
    const socket = socketClient(SERVER, {transports: ['websocket'], secure: true});
    const [refresh,SetRefresh] = useState(false)
    const myid = useSelector(state=> state.profileState.id)
    const [listIdApproved] = useState([112, 28, 99, 100, 98, 97])

    useEffect(()=>{
        Dispatch(restart())
    },[])

    useEffect(()=>{
        if (listIdApproved.includes(myid)) {
            Dispatch(restart())
            Dispatch(getNotificationPO())
        } else if (myRole === 'picpasar') {
            Dispatch(restart())
            Dispatch(getNotificationPIC())
        } else if( myRole === 'admin' || myRole === 'markom' || myRole === 'deputi' ||myRole === 'direktorat' ||myRole === 'koordinatorvito' ||myRole === 'guest' ) {
            Dispatch(restart())
            Dispatch(getNotification())
        }
    },[myRole, myid])

    useEffect(()=>{
        socket.on('notification-admin', (data) => {
            SetRefresh(true)
        })
    },[])

    function showDate(dateParams) {
        let date = new Date(dateParams).getDate()
        let day = new Date(dateParams).getDay()
        let monthNumber = new Date(dateParams).getMonth()
        let month = ''
        let year = new Date(dateParams).getFullYear()
        let days = ['Minggu','Senin','Selasa','Rabu','Kamis','Jumat','Sabtu'];
        switch (monthNumber) {
            case 0:
                month = 'Januari'
                break;
            case 1:
                month = 'Februari'
                break;
            case 2:
                month = 'Maret'
                break;
            case 3:
                month = 'April'
                break;
            case 4:
                month = 'Mei'
                break;
            case 5:
                month = 'Juni'
                break;
            case 6:
                month = 'Juli'
                break;
            case 7:
                month = 'Agustus'
                break;
            case 8:
                month = 'September'
                break;
            case 9:
                month = 'Oktober'
                break;
            case 10:
                month = 'November'
                break;
            case 11:
                month = 'Desember'
                break;
            default:
                month = '-'
                break;
        }
        let hour = new Date(dateParams).getHours()
        let minute = new Date(dateParams).getMinutes()
        let second = new Date(dateParams).getSeconds()
        return days[day]  + ', ' + date + ' ' + month + ' ' + year +' '+ (hour < 10 ? '0'+hour : hour) +':'+(minute < 10 ? '0'+minute : minute)+':'+(second < 10 ? '0'+second : second)+' WIB'
    }

    const muatUlang = (myRole) => {
        if (listIdApproved.includes(myid)) {
            Dispatch(restart())
            Dispatch(getNotificationPO())
        } else if (myRole === 'picpasar') {
            Dispatch(restart())
            Dispatch(getNotificationPIC())
            SetRefresh(false)
        } else if( myRole === 'admin' || myRole === 'markom' || myRole === 'deputi' ||myRole === 'direktorat' ||myRole === 'koordinatorvito' ||myRole === 'guest' ) {
            Dispatch(restart())
            Dispatch(getNotification())
            SetRefresh(false)
        }
    }

    const readMore = () => {
        if (listIdApproved.includes(myid)) {
            Dispatch(getNotificationPO(counting))
            setCounting(counting+=1)
        } else if (myRole === 'picpasar') {
            Dispatch(getNotificationPIC(counting))
            setCounting(counting+=1)
        } else if( myRole === 'admin' || myRole === 'markom' || myRole === 'deputi' ||myRole === 'direktorat' ||myRole === 'koordinatorvito' ||myRole === 'guest' ) {
            Dispatch(getNotification(counting))
            setCounting(counting+=1)
        }
    }

    const readNote = (id, users) => {
        if (users) {
            if (!users.split(',').filter(a=>a == myEmail)[0]) {
                Dispatch(readNotification(id, users.split(','), myRole))
            }
        } else {
            Dispatch(readNotification(id, ['test@email.id'], myRole))
        }
    }

    return(
        <div className="container-notification" style={{ paddingTop: 0 }}>

            <div style={{ display: 'flex', alignItems: 'center', width: '50%', cursor: 'pointer' }} onClick={e=>History.goBack()} >
                <i className="fas fa-arrow-left" style={{ color: '#E23F81', marginRight: 10, fontSize: '23px' }} />
                <span style={{ color: '#E23F81', fontSize: '16px' }} >
                    Kembali
                </span>
            </div>
            <div className="notification-box" >
                <div className="notification-box-top" >
                    <span className="notification-notif-text" >
                        Pemberitahuan
                    </span>
                    {
                        refresh &&
                        <span onClick={e=>muatUlang(myRole)} className="notification-text2" style={{ backgroundColor: '#d35273', color: 'white', padding: 10, borderRadius: 10 }} >
                            {/* Tandai semua sudah dibaca */}
                            <i className="fa fa-refresh fa-1x" style={{ fontSize: 15, marginRight: 5 }} />
                            <span>
                                Notifikasi Baru, Muat Ulang
                            </span>
                        </span>
                    }
                </div>
                <div className="notification-box-mid" >

                    {
                        notification &&
                        notification.map((data, i)=>(
                        <div className="notification-card"
                            key={i}
                            style={{ backgroundColor: data && data.users && data.users.split(',').filter(a=>a == myEmail)[0] && 'white' }}
                            onClick={e=> data && data.link && [History.push(data && data.link), readNote(data.id, data.users)]}
                        >
                            <img src={ProfileUnknown} alt="photo" className="photo-notification" />
                            <div className="notification-box-small" >
                                <span className="notification-txt1" >
                                    <b> { data && data.User ? data.User ? data.User.name : '-' : '-' } </b>
                                    { data && data.note ? data.note : '-' }
                                </span>
                                <span className="notification-txt1small" >
                                    { showDate(data && data.createdAt) }
                                </span>
                            </div>
                        </div>
                        ))
                    }
                    
                </div>
                <div className="notification-box-bottom" >
                    <span className="notification-notif-text2" onClick={e=>readMore()}>
                        Lihat Selanjutnya
                    </span>
                </div>
            </div>
        </div>
    )
}