import React, { useState, useEffect, Fragment } from 'react'
import { Box, Popover, makeStyles, FormControl, InputLabel, Select, MenuItem, withStyles, Button } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import './styles.scss'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import CardBox from '../../../../../component/Vito/CardBox'
import { useSelector } from 'react-redux'
import { domain } from '../../../../../../../server'
import DetailReport from '../../../../../component/FileViewer/viewAll'
import { saveAs } from "file-saver";
import { Packer } from "docx";
import { createDocVito } from '../../../../../component/FileViewer/doc'

const useStyles = makeStyles({
    root: {
        // flexGrow: 1,
        // width: '100%',
        height: '100px',
        background: "white",
        padding: "1em 0em",
        boxShadow: "none"
    },
    content: {
        paddingTop: "1em",
        marginTop: "2em",
        height: "calc( 100vh - 60px)",
        background: "white"
    },
    tabWeek: {
        textTransform: "none",
        fontSize: "20px"
    },
    report: {
        fontWeight: "500",
        lineHeight: "16px",
        color: "#2C304D"
    },
    box:{
        borderBottom: '0.5px solid #DDDDDD',
        width: '100%',
        height: 35,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: 15,
        paddingRight: 15,
        color: '#999999',
        cursor: 'pointer',
        borderRadius: 4,
        '&:hover':{
            color: 'white',
            backgroundColor: '#E23F81',
            width: '-webkit-fill-available'
        }
    },
})

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function useQuery(){
    return new URLSearchParams(useLocation().search)
}

export default function PIN(props) {
    const [value, setValue] = useState(0);
    const [dataPrint,setData] = useState([])
    const [dataSelection,setDataSelection] = useState([])
    const [openModal, setOpenModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [weekId, setWeekId] = useState(null);
    const query = useQuery()
    const networking = { workplanReport: [1, 3, 5] }
    const [tabs, setTabs] = useState([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55])
    const classes = useStyles();
    const history = useHistory()
    const vito = useSelector(state=> state.vitoState.vito)
    const weeklyList = useSelector(state=> state.vitoState.weekly)
    const photoProfile = useSelector(state=> state.profileState.photo)
    const emailProfile = useSelector(state=> state.profileState.email)
    const [year, setYear] = useState(null)
    const [dataCategory1,setDataCategory1] = useState([])
    const [dataCategory2,setDataCategory2] = useState([])
    const [dataCategory3,setDataCategory3] = useState([])

    const viewAll = () => {
        setDataSelection([dataCategory1, dataCategory2, dataCategory3])
        setOpenModal(true)
        handleClose()
    }

    useEffect(()=>{
        setYear(query.get('year'))
        if (query.get('week')) {
            setValue(Number(query.get('week') - 1))
            if (weeklyList) {
                setWeekId(weeklyList[query.get('week') - 1] && weeklyList[query.get('week') - 1].id)
            }
        } else {
            if (weeklyList) {
                setWeekId(weeklyList[value] && weeklyList[value].id)
            }
        }
    },[weeklyList])

    const showDate = (dateParams) => {
        const [day, month, year] = dateParams.split("/")
        let date = new Date(year, month - 1, day).getDate() 
        let monthNumber = new Date(year, month - 1, day).getMonth()
        let monthResult = ''
        let yearResult = new Date(year, month - 1, day).getFullYear()
        switch (monthNumber) {
            case 0 :
                monthResult = 'Januari'
                break;
            case 1 :
                monthResult = 'Februari'
                break;
            case 2 :
                monthResult = 'Maret'
                break;
            case 3 :
                monthResult = 'April'
                break;
            case 4 :
                monthResult = 'Mei'
                break;
            case 5 :
                monthResult = 'Juni'
                break;
            case 6 :
                monthResult = 'Juli'
                break;
            case 7 :
                monthResult = 'Agustus'
                break;
            case 8 :
                monthResult = 'September'
                break;
            case 9 :
                monthResult = 'Oktober'
                break;
            case 10 :
                monthResult = 'November'
                break;
            case 11 :
                monthResult = 'Desember'
                break;
            default:
                monthResult = ''
                break;
        }
        return date + ' ' + monthResult  + ' ' + yearResult
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleWeekName = () => {
        let weekFix
        if (weeklyList) {
            weeklyList.forEach((week)=>{
                if (week.id == weekId) {
                    weekFix = week.week
                    return
                }
            })
        }
        return weekFix
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (weeklyList) {
            setWeekId(weeklyList[newValue].id)
        }
    };

    const shareWhatsapp = () => {
        window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(`${domain}/document/weekly?year=${weeklyList && weeklyList[1] && weeklyList[1].start && new Date(weeklyList[1].start).getFullYear()}&week=${handleWeekName()}&identity=${weekId}&vito=${vito && vito.email ? vito.email : '-'}`)}`,'_blank');
    }

    const downloadDoc = () => {
        const document = new createDocVito()
        let weekIdBase = value
        let nameProfile = vito && vito.name ? vito.name : '-'
        let countryProfile = vito && vito.country ? vito.country : '-'
        let areaProfile = vito && vito.area ? vito.area : '-'

        const doc = document.create([dataCategory1, dataCategory2, dataCategory3], nameProfile, countryProfile, areaProfile, weeklyList, weekId )
        Packer.toBlob(doc).then(blob => {
            saveAs(blob, `${countryProfile} - Minggu ${weekIdBase+=1}.docx`);
        });
        handleClose()
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Fragment>
            <Box style={{ backgroundColor: 'white', display: 'flex', justifyContent: 'center' }} className="appbar-WeeklyBox" >
                {/* <Box height="15%" width="100%"  > */}
                    {/* <AppBar position="static" className={classes.root} color="default"> */}
                        <Tabs
                            className="appbar-WeeklyUptd"
                            value={value}
                            onChange={handleChange}
                            variant='scrollable'
                            scrollButtons="on"
                            indicatorColor="secondary"
                            textColor="secondary"
                            aria-label="scrollable force tabs example"
                        >{
                            weeklyList &&
                            weeklyList.map((tab, i) => (
                                <Tab key={i} label={
                                    <Box display="flex" flexDirection="column" alignItems="center" height="60px" justifyContent="center" >
                                        <Typography className={classes.tabWeek}>Minggu {tab.week}</Typography>
                                        <Typography style={{ fontSize: '10px' }}>{showDate(tab.start)} - {showDate(tab.end)}</Typography>
                                    </Box>
                                    }>
                                </Tab>))
                            }
                        </Tabs>
                    {/* </AppBar> */}
                {/* </Box> */}
            </Box>
            <Box className="content-weekly" style={{ marginTop: '1em', padding: 0 }} >
                <Box padding="1em" style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography className={classes.report} variant="h6" >Laporan Mingguan - { vito && vito.name ? vito.name : '-' }</Typography>
                    <div style={{ marginLeft: 'auto' }} >
                        <div className="action-weekly-btn" onClick={handleClick}> AKSI </div>
                        <Popover
                            style={{ marginTop: 5 }}
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <div className={classes.box} onClick={viewAll}>
                                <i className="fas fa-window-restore"/>
                                <span style={{ marginLeft: 10 }} >
                                    Lihat Semua
                                </span>
                            </div>
                            {/* <div className={classes.box} onClick={shareWhatsapp}>
                                <i className="fab fa-whatsapp fa-1x"/>
                                <span style={{ marginLeft: 10 }} >
                                    Bagikan ke WA
                                </span>
                            </div> */}
                            <Link to={{pathname: `/document/weekly?year=${year}&week=${handleWeekName()}&identity=${weekId}&vito=${vito && vito.email ? vito.email : '-'}`}} target="_BLANK" style={{ textDecoration: 'none' }} >
                                <div className={classes.box}>
                                    <i className="fas fa-cloud-download-alt"/>
                                    <span style={{ marginLeft: 10 }}>
                                        Cetak / Unduh .pdf
                                    </span>
                                </div>
                            </Link>
                            {/* <div className={classes.box} onClick={downloadDoc}>
                                <i className="fas fa-cloud-download-alt" />
                                <span style={{ marginLeft: 10 }}>
                                    Cetak / Unduh .docx
                                </span>
                            </div> */}
                        </Popover>
                    </div>
                </Box>
                <Box className="content-max-weekly">
                    {/* {
                        tabs.map((tab, i) => {
                            return ( */}
                            {/* <TabPanel value={value} > */}
                                {/* <Box display="flex" style={{ boxSizing: 'content-box' }}> */}
                                    <Box style={{ maxWidth: `calc((100vw - 530px) / 2)`, minWidth: `calc((100vw - 530px) / 2)`, marginLeft: 10 }} paddingTop="0px" >
                                        <CardBox dataCategory={setDataCategory1} title="NETWORKING & PARTNERSHIP" CategoryId={1} vito={vito} Week={weeklyList[value]} weeklyList={weeklyList}/>
                                    </Box>
                                    <Box style={{ maxWidth: `calc((100vw - 540px) / 2)`, minWidth: `calc((100vw - 540px) / 2)`, marginLeft: 10, marginRight: 10 }} paddingTop="0px">
                                        <CardBox dataCategory={setDataCategory2} title="MARKET INTELLIGENCE" CategoryId={2} vito={vito} Week={weeklyList[value]} weeklyList={weeklyList}/>
                                    </Box>
                                    {/* <Box style={{ maxWidth: `calc((100vw - 530px) / 3)`, minWidth: `calc((100vw - 530px) / 3)`, marginRight: 10 }} paddingTop="0px">
                                        <CardBox dataCategory={setDataCategory3} title="PROMOTIONAL SUPPORT" CategoryId={3} vito={vito} Week={weeklyList[value]} weeklyList={weeklyList}/>
                                    </Box> */}
                                {/* </Box> */}
                            {/* </TabPanel> */}
                            {/* ) */}
                        {/* })
                    } */}
                </Box>

            </Box>

            <DetailReport
                countryProfile={vito && vito.country ? vito.country : '-'}
                areaProfile={vito && vito.area ? vito.area : '-' }
                setOpen={setOpenModal}
                open={openModal}
                nameProfile={vito && vito.name ? vito.name : '-'}
                photoProfile={photoProfile}
                data={dataSelection}
                weeklyList={weeklyList}
                weekId={weekId}
            />

        </Fragment>
    )
}