import React, { useState } from 'react'
import { Box, makeStyles, Button, TextField, IconButton } from '@material-ui/core'
import Typography from '@material-ui/core/Typography';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import NewAlbum from '../../../../../component/Report/Modal/NewAlbum'
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import UploadImg from '../../../../../images/uploadImg.png'

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        padding: "2em",
        width: '98%',
        minHeight: '98%',
        background: "white",
        boxShadow: "none"
    },
    editor: {
        fontSize: "16px",
        lineHeight: "19px",
        padding: "15px 21px",
        minHeight: "100%"
    },
    boxDelete: {
        background: "white",
        position: "absolute",
        width: "1.8rem",
        height: "1.8rem",
        top: "-1rem",
        left: "14rem",
        display: "flex",
        justifyContent: "center",
        borderRadius: "50%",
        zIndex: "99999999"
    },
    dropZone: {
        height: "100%"
    }
})

export default function PIN(props) {
    const classes = useStyles()
    const [images, setImages] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9])
    const [isGalleries, setIsGalleries] = useState(false)
    const [album, setAlbum] = useState('')
    const [flag, setFlag] = useState(false)
    const [photos, setPhotos] = useState([])
    const [albumList, setAlbumList] = useState(["Sales Mission", "Travel Mart", "Festival", "Fam Trip", "Other"])

    function onChangeAlbum(e) {
        setAlbum(e.target.value)
    }

    function addFiles(fileObjs) {
        let temp = photos
        fileObjs.map((item) => {
            temp.push(URL.createObjectURL(item.file))
        })

        // setPhotos(temp)
        setFlag(!flag)
    }

    function deletePhoto(index) {
        let temp = photos

        temp.splice(index, 1)
        setPhotos(temp)
        setFlag(!flag)
    }

    return (
        <>
            <Box className={classes.root}>
                <Box>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>ADD NEW GALLERY</Typography>
                </Box>
                <Box display="flex" marginTop="2em">
                    <Box width={5 / 6} marginRight="1em">
                        <Autocomplete
                            id="combo-box-demo"
                            options={albumList}
                            size="small"
                            color="secondary"
                            fullWidth
                            renderInput={(params) => <TextField {...params} label="Select Album" color="secondary" variant="outlined" />}
                        />
                    </Box>
                    <Box width={1 / 6}>
                        <NewAlbum />
                    </Box>
                </Box>
                <Box border="1px solid #DDDDDD" marginTop="1em" minHeight="38vh">
                    <Editor
                        //   editorState={editorState}
                        editorClassName={classes.editor}
                        placeholder="Description"
                    //   onEditorStateChange={this.onEditorStateChange}
                    />
                </Box>
                <Box display="flex" position="relative" flexDirection="column" justifyContent="center" alignItems="center" border="1px solid #DDDDDD" marginTop="1em" minHeight="30vh">
                    <Box position="absolute" width="100%" height="100%" zIndex="99" style={{ opacity: "0" }}><DropzoneAreaBase
                        onAdd={(fileObjs) => addFiles(fileObjs)}
                        // onDelete={(fileObj) => console.log('Removed File:', fileObj)}
                        showAlerts={false}
                        dropzoneText=""
                        dropzoneClass={classes.dropZone}
                    />
                    </Box>
                    <Box marginY="1rem"><img src={UploadImg} style={{ width: "4rem", height: "4rem" }} /></Box>
                    <Typography style={{ color: '#999999' }}>Upload Image File Here (JPG/PNG)</Typography>

                    <Typography style={{ color: "#E23F81" }}>Choose Image</Typography>

                    <Box display="flex" marginTop="1rem">
                        {
                            photos.map((photo, index) => {
                                return (
                                    <Box key={index} width="15rem" height="15rem" marginRight="3rem" marginBottom="1rem" position="relative">
                                        <Box className={classes.boxDelete}><IconButton onClick={() => deletePhoto(index)}><CancelSharpIcon style={{ fontSize: "2rem", color: "#E23F81", cursor: "pointer" }} /></IconButton></Box>
                                        <img src={photo} alt="images" className="image-upload-content"
                                            style={{
                                                width: "100%",
                                                borderRadius: "4px"
                                            }} />
                                    </Box>
                                )
                            })

                        }
                    </Box>
                </Box>
                <Box display="flex" alignItems="center" marginTop="1em">
                    <Button type="submit" variant="contained" color="secondary" style={{ backgroundColor: "#E23F81", textTransform: "none", boxShadow: "none", padding: "0.5em 3em" }}>Submit</Button>
                    <Button variant="outlined" color="secondary" style={{ textTransform: "none", padding: "0.5em 3em", marginLeft: "2em" }} >Cancel</Button>
                </Box>
            </Box>
        </>
    )
}