import React, { useEffect, useState } from 'react'
import { Box, Typography, TextField, withStyles, makeStyles, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Paper, requirePropFactory, Button } from '@material-ui/core'
import DetailRekomendasi from '../../../../../component/LihatKPI/Modal/DetailRekomendasi'
import DetailIndikator from '../../../../../component/LihatKPI/Modal/DetailIndikator'
import logoWI from '../../../../../images/bird.png'
import { useSelector, useDispatch } from 'react-redux'
import { getOne, updateScore } from '../../../../../../../store/actions/evaluationActions'
import { useParams } from 'react-router-dom'
import socketClient from "socket.io-client";
import { SERVER } from '../../../../../../../server'
// COMPONENT

const StyledTableCell = withStyles((theme) => ({
    root: {
        border: "1px solid rgba(224, 224, 224, 1)"
    },
    head: {
        backgroundColor: "#F3F3F3",
        color: "#999999",
        fontWeight: 500,
        padding: "0.5em 1em",
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "#FAFAFA",
        },

    },
}))(TableRow);

function createData(no, komponen, indikator, target, realisasi, nilai) {
    return { no, komponen, indikator, target, realisasi, nilai };
}

const data3 = [
    { nama: "Mr. A", nilaiKualitatif: "65.0", nilaiKuantitatif: "18.0", skor: "83.0", huruf: "A", ket: "Excellent", rekomendasi: "To be re-appointed next year" },
]

const dataPassingGrade = [
    {rentangNIlai: ">= 80", nilaiHuruf: "A", keterangan: "Excellent", rekomendasi: "To be re-appointed next year"},
    {rentangNIlai: "70 - 79", nilaiHuruf: "B", keterangan: "Good", rekomendasi: "To be re-appointed next year"},
    {rentangNIlai: "60 - 69", nilaiHuruf: "C", keterangan: "Fair", rekomendasi: "To look for comparison(s)"},
    {rentangNIlai: "50 - 59", nilaiHuruf: "D", keterangan: "Poor", rekomendasi: "To be replaced next year"}
]

const useStyles = makeStyles({
    boxProfile: {
        padding: "2em",
        background: "white",
    },
    caption: {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#999999"

    },
    tableroot: {
        marginTop: "1em",
        background: "white",
    },
    table: {
        minWidth: 700,
        boxShadow: "none"
    },
    buttonViewProfile: {
        background: "#FCECF2",
        color: "#E23F81",
        boxShadow: "none"
    },
    titleTable: {
        padding: "1em 2em",
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor: "#DDDDDD",
    },
    tableContainer: {
        boxShadow: "none",
        border: "1px solid #CCCCCC",
        borderRadius: "4px"
    },
    boxKeterangan: {
        display: "flex",
        marginLeft: "1em",
        width: "40%",
        padding: "2em",
        background: "white",
        justifyContent: "space-around"
    }

});

export default function PIN(props) {
    const classes = useStyles();
    const Dispatch = useDispatch()
    const user = useSelector(state=> state.evaluationState.user)
    const roleProfile = useSelector(state=> state.profileState.role)
    const socket = socketClient(SERVER, {transports: ['websocket'], secure: true});
    const { id } = useParams()
    const [editA1a, setEditA1a] = useState(false)
    const [editA1aValue, setEditA1aValue] = useState(0)
    const [editA2a, setEditA2a] = useState(false)
    const [editA2aValue, setEditA2aValue] = useState(0)
    const [editA3a, setEditA3a] = useState(false)
    const [editA3aValue, setEditA3aValue] = useState(0)
    const [editA4a, setEditA4a] = useState(false)
    const [editA4aValue, setEditA4aValue] = useState(0)
    const [editA5a, setEditA5a] = useState(false)
    const [editA5aValue, setEditA5aValue] = useState(0)

    const [editB1a, setEditB1a] = useState(false)
    const [editB1aValue, setEditB1aValue] = useState(0)
    const [editB2a, setEditB2a] = useState(false)
    const [editB2aValue, setEditB2aValue] = useState(0)
    const [editB3a, setEditB3a] = useState(false)
    const [editB3aValue, setEditB3aValue] = useState(0)

    const [editC1a, setEditC1a] = useState(false)
    const [editC1aValue, setEditC1aValue] = useState(0)
    const [editC2a, setEditC2a] = useState(false)
    const [editC2aValue, setEditC2aValue] = useState(0)
    const [editC2b, setEditC2b] = useState(false)
    const [editC2bValue, setEditC2bValue] = useState(0)
    const [editC3a, setEditC3a] = useState(false)
    const [editC3aValue, setEditC3aValue] = useState(0)
    const [editC3b, setEditC3b] = useState(false)
    const [editC3bValue, setEditC3bValue] = useState(0)
    
    const [editQCompetency1, setEditQCompetency1] = useState(false)
    const [editQCompetency1Value, setEditQCompetency1Value] = useState(null)
    const [editQCompetency2, setEditQCompetency2] = useState(false)
    const [editQCompetency2Value, setEditQCompetency2Value] = useState(null)
    const [editQCompetency3, setEditQCompetency3] = useState(false)
    const [editQCompetency3Value, setEditQCompetency3Value] = useState(null)

    const [editQCollab1, setEditQCollab1] = useState(false)
    const [editQCollab1Value, setEditQCollab1Value] = useState(null)
    const [editQCollab2, setEditQCollab2] = useState(false)
    const [editQCollab2Value, setEditQCollab2Value] = useState(null)
    const [editQCollab3, setEditQCollab3] = useState(false)
    const [editQCollab3Value, setEditQCollab3Value] = useState(null)

    const [editQCharacter1, setEditQCharacter1] = useState(false)
    const [editQCharacter1Value, setEditQCharacter1Value] = useState(null)
    const [editQCharacter2, setEditQCharacter2] = useState(false)
    const [editQCharacter2Value, setEditQCharacter2Value] = useState(null)
    const [editQCharacter3, setEditQCharacter3] = useState(false)
    const [editQCharacter3Value, setEditQCharacter3Value] = useState(null)

    useEffect(()=>{
        Dispatch(getOne(null,4,id))
        socket.on('evaluation', (data) => {
            if (id == data.id) {
                Dispatch(getOne(null,4,id))
            }
        });
    },[])

    useEffect(()=>{
        if (user) {
            if (user.A1a) {
                setEditA1aValue(user.A1a)
            }
            if (user.A2a) {
                setEditA2aValue(user.A2a)
            }
            if (user.A3a) {
                setEditA3aValue(user.A3a)
            }
            if (user.A4a) {
                setEditA4aValue(user.A4a)
            }
            if (user.A5a) {
                setEditA5aValue(user.A5a)
            }

            if (user.B1a) {
                setEditB1aValue(user.B1a)
            }
            if (user.B2a) {
                setEditB2aValue(user.B2a)
            }
            if (user.B3a) {
                setEditB3aValue(user.B3a)
            }

            if (user.C1a) {
                setEditC1aValue(user.C1a)
            }
            if (user.C2a) {
                setEditC2aValue(user.C2a)
            }
            if (user.C2b) {
                setEditC2bValue(user.C2b)
            }
            if (user.C3a) {
                setEditC3aValue(user.C3a)
            }
            if (user.C3b) {
                setEditC3bValue(user.C3b)
            }

            if (user.QCompetency1) {
                setEditQCompetency1Value(user.QCompetency1)
            }
            if (user.QCompetency2) {
                setEditQCompetency2Value(user.QCompetency2)
            }
            if (user.QCompetency3) {
                setEditQCompetency3Value(user.QCompetency3)
            }

            if (user.QCollab1) {
                setEditQCollab1Value(user.QCollab1)
            }
            if (user.QCollab2) {
                setEditQCollab2Value(user.QCollab2)
            }
            if (user.QCollab3) {
                setEditQCollab3Value(user.QCollab3)
            }

            if (user.QCharacter1) {
                setEditQCharacter1Value(user.QCharacter1)
            }
            if (user.QCharacter2) {
                setEditQCharacter2Value(user.QCharacter2)
            }
            if (user.QCharacter3) {
                setEditQCharacter3Value(user.QCharacter3)
            }
        }
    },[user])

    const handleEdit = (e, from) => {
        if (String(e.target.value).length <= 4) {
            if (String(e.target.value) != 'e') {
                if (from === 'A1a') {
                    setEditA1aValue(e.target.value)
                }
                if (from === 'A2a') {
                    setEditA2aValue(e.target.value)
                }
                if (from === 'A3a') {
                    setEditA3aValue(e.target.value)
                }
                if (from === 'A4a') {
                    setEditA4aValue(e.target.value)
                }
                if (from === 'A5a') {
                    setEditA5aValue(e.target.value)
                }

                if (from === 'B1a') {
                    setEditB1aValue(e.target.value)
                }
                if (from === 'B2a') {
                    setEditB2aValue(e.target.value)
                }
                if (from === 'B3a') {
                    setEditB3aValue(e.target.value)
                }

                if (from === 'C1a') {
                    setEditC1aValue(e.target.value)
                }
                if (from === 'C2a') {
                    setEditC2aValue(e.target.value)
                }
                if (from === 'C2b') {
                    setEditC2bValue(e.target.value)
                }
                if (from === 'C3a') {
                    setEditC3aValue(e.target.value)
                }
                if (from === 'C3b') {
                    setEditC3bValue(e.target.value)
                }
            }
        }
    }

    const Saving = (code) => {
        if (code === 'A1a') {
            Dispatch(updateScore(null,id,code,editA1aValue))
            setEditA1a(false)
        } else if (code === 'A2a') {
            Dispatch(updateScore(null,id,code,editA2aValue))
            setEditA2a(false)
        } else if (code === 'A3a') {
            Dispatch(updateScore(null,id,code,editA3aValue))
            setEditA3a(false)
        } else if (code === 'A4a') {
            Dispatch(updateScore(null,id,code,editA4aValue))
            setEditA4a(false)
        } else if (code === 'A5a') {
            Dispatch(updateScore(null,id,code,editA5aValue))
            setEditA5a(false)
        } else if (code === 'B1a') {
            Dispatch(updateScore(null,id,code,editB1aValue))
            setEditB1a(false)
        } else if (code === 'B2a') {
            Dispatch(updateScore(null,id,code,editB2aValue))
            setEditB2a(false)
        } else if (code === 'B3a') {
            Dispatch(updateScore(null,id,code,editB3aValue))
            setEditB3a(false)
        } else if (code === 'C1a') {
            Dispatch(updateScore(null,id,code,editC1aValue))
            setEditC1a(false)
        } else if (code === 'C2a') {
            Dispatch(updateScore(null,id,code,editC2aValue))
            setEditC2a(false)
        } else if (code === 'C2b') {
            Dispatch(updateScore(null,id,code,editC2bValue))
            setEditC2b(false)
        } else if (code === 'C3a') {
            Dispatch(updateScore(null,id,code,editC3aValue))
            setEditC3a(false)
        } else if (code === 'C3b') {
            Dispatch(updateScore(null,id,code,editC3bValue))
            setEditC3b(false)
        } else if (code === 'QCompetency1') {
            Dispatch(updateScore(null,id,code,editQCompetency1Value))
            setEditQCompetency1(false)
        } else if (code === 'QCompetency2') {
            Dispatch(updateScore(null,id,code,editQCompetency2Value))
            setEditQCompetency2(false)
        } else if (code === 'QCompetency3') {
            Dispatch(updateScore(null,id,code,editQCompetency3Value))
            setEditQCompetency3(false)
        } else if (code === 'QCollab1') {
            Dispatch(updateScore(null,id,code,editQCollab1Value))
            setEditQCollab1(false)
        } else if (code === 'QCollab2') {
            Dispatch(updateScore(null,id,code,editQCollab2Value))
            setEditQCollab2(false)
        } else if (code === 'QCollab3') {
            Dispatch(updateScore(null,id,code,editQCollab3Value))
            setEditQCollab3(false)
        } else if (code === 'QCharacter1') {
            Dispatch(updateScore(null,id,code,editQCharacter1Value))
            setEditQCharacter1(false)
        } else if (code === 'QCharacter2') {
            Dispatch(updateScore(null,id,code,editQCharacter2Value))
            setEditQCharacter2(false)
        } else if (code === 'QCharacter3') {
            Dispatch(updateScore(null,id,code,editQCharacter3Value))
            setEditQCharacter3(false)
        }
    }

    const handleScoreLetter = (letter) => {
        let num = 0
        switch (letter) {
            case 'A+':
                num = 100
                break;
            case 'A':
                num = 90
                break;
            case 'A-':
                num = 80
                break;
            case 'B+':
                num = 70
                break;
            case 'B':
                num = 65
                break;
            case 'B-':
                num = 60
                break;
            case 'C+':
                num = 55
                break;
            case 'C':
                num = 50
                break;
            case 'C-':
                num = 45
                break;
            default:
                num = 0
                break;
        }
        return num
    }

    const getNQualitative = (nilai1,nilai2,nilai3, percentage) => {
        let N = handleScoreLetter(nilai1) + handleScoreLetter(nilai2) + handleScoreLetter(nilai3)
        let total = ((percentage/ 100) * N)
        return total
    }

    const getSQualitative = (nilai1,nilai2,nilai3) => {
        let N = nilai1 + nilai2 + nilai3
        let total = (N/3).toFixed(2)
        return total
    }

    const getFQualitative = (nilai1, nilai2, nilai3, percentage) => {
        let N = (nilai1 + nilai2 + nilai3)/3
        let total = ((percentage/ 100) * N).toFixed(2)
        return total
    }

    const getSQuantitative = (target, realitation) => {
        let total = Math.round((realitation/target)*100)
        if (total > 100) total = 100
        return total
    }

    // const getATQuantitative = (nilai1, nilai2, nilai3, nilai4) => {
    //     let total = Math.round((nilai1 + nilai2 + nilai3 + nilai4)/4)
    //     return total
    // }
    const getATQuantitative = (nilai1, nilai2, nilai3, nilai4, nilai5) => {
        let total = Math.round((nilai1 + nilai2 + nilai3 + nilai4 + nilai5)/5)
        if (total > 100) total = 100
        return total
    }

    const getBJQuantitative = (nilai1, nilai2, nilai3) => {
        let total = Math.round(nilai1 + nilai2 + nilai3)
        return total
    }

    // const getBTQuantitative = (nilai1, nilai2, nilai3) => {
    //     let total = Math.round((nilai1 + nilai2 + nilai3)/3)
    //     return total
    // }
    const getBTQuantitative = (nilai1, nilai2) => {
        let total = Math.round((nilai1 + nilai2)/2)
        return total
    }

    const getCTQuantitative = (nilai1, nilai2, nilai3, nilai4, nilai5) => {
        let total = Math.round((nilai1 + nilai2 + nilai3 + nilai4 + nilai5)/5)
        return total
    }

    // const getQScore = (A, B, C, percentage) => {
    //     let N = (A + B + C)/3
    //     let total = ((percentage/ 100) * N).toFixed(2)
    //     return total
    // }
    const getQScore = (A, B, percentage) => {
        let N = (A + B)/2
        let total = ((percentage/ 100) * N).toFixed(2)
        return total
    }

    const getTotalyScore = (nilai1, nilai2) => {
        return (Number(nilai1) + Number(nilai2)).toFixed(2)
    }

    const getLetterScore = (score) => {
        console.log('letter ', score)
        let letter = '-'
        if (score >=80) {
            letter = 'A'
        } else if (score >=70 && score <80) {
            letter = 'B'
        } else if (score >=60 && score <70) {
            letter = 'C'
        } else if (score >=50 && score <60) {
            letter = 'D'
        } else if (score >=0 && score <50) {
            letter = 'D'
        }
        return letter
    }

    const getRemarkScore = (score) => {
        console.log('remark ', score)
        let remark = '-'
        if (score >=80) {
            remark = 'Excellent'
        } else if (score >=70 && score <80) {
            remark = 'Good'
        } else if (score >=60 && score <70) {
            remark = 'Fair'
        } else if (score >=50 && score <60) {
            remark = 'Poor'
        } else if (score >=0 && score <50) {
            remark = 'Poor'
        }
        return remark
    }

    const getRecommendScore = (score) => {
        let recommend = '-'
        if (score >=80) {
            recommend = 'To be re-appointed next year'
        } else if (score >=70 && score <80) {
            recommend = 'To be re-appointed next year'
        } else if (score >=60 && score <70) {
            recommend = 'To look for comparison(s)'
        } else if (score >=50 && score <60) {
            recommend = 'To be replaced next year'
        } else if (score >=0 && score <50) {
            recommend = 'To be replaced next year'
        }
        return recommend
    }

    return (
        <>
            <div className="dashboard" >
                <span className="breadcrumb-txt-active" >
                    Evaluation VITO
            </span>
                <span className="breadcrumb-slash-active" >
                    /
            </span>
                <span className="breadcrumb-txt-black" >
                    Detail Evaluation
            </span>
                <span className="breadcrumb-slash-black" >
                    /
            </span>
            </div>
            <div>
                <Box margin="1em">
                    <Box height="7em" width="100%" display="flex">
                        <Box className={classes.boxProfile} display="flex" width="60%">
                            <Box height="3.5em" width="3.5em" border={1} borderRadius="50%" borderColor="grey.500" marginRight="2em" display="flex" justifyContent="center" alignItems="center">
                                <img width="100%" height="100%" src={logoWI} />
                            </Box>
                            <Box display="flex" flexDirection="column">
                                <Typography variant="h6">{ user && user.User && user.User.name ? user.User.name : '-' }</Typography>
                                <Typography variant="caption" className={classes.caption} style={{ textTransform: 'uppercase' }} >{ user && user.User && user.User.country ? user.User.country : '' } { user && user.User && (user.User.area && user.User.area !== 'tidak ada area') ? '- ' + user.User.area : '' }</Typography>
                            </Box>
                        </Box>
                        <Box className={classes.boxKeterangan}>
                            <Typography style={{ color: "#666666" }}>Remarks</Typography>
                            <Box>
                                <Typography>I = Indicator</Typography>
                                <Typography>T = Target</Typography>
                            </Box>
                            <Box>
                                <Typography>R = Realisation</Typography>
                                <Typography>S = Score</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.tableroot}>
                        <Box className={classes.titleTable}>
                            <Typography variant="h6">I. QUANTITATIVE PERFORMANCE SCORING </Typography>
                        </Box>

                        <Box padding="1em 2em">
                            <Box marginY="1em"><Typography color="primary">A. NETWORKING & PARTNERSHIP</Typography></Box>
                            <TableContainer component={Paper} className={classes.tableContainer}>
                                <Table className={classes.table} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell rowSpan={2}>NO</StyledTableCell>
                                            <StyledTableCell rowSpan={2} align="left">COMPONENT</StyledTableCell>
                                            <StyledTableCell rowSpan={2} align="left">INDICATOR (I)</StyledTableCell>
                                            <StyledTableCell align="center">TARGET (T)</StyledTableCell>
                                            <StyledTableCell align="center">REALISATION (R)</StyledTableCell>
                                            <StyledTableCell rowSpan={2} align="center">SCORE (S)</StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell align="center">2022</StyledTableCell>
                                            <StyledTableCell align="center">Q2 - Q4</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow>
                                            <StyledTableCell align="left">
                                                1
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                Partnership with Academics
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                Collaboration Activities with Academics Partners
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {
                                                    (
                                                        roleProfile !== 'markom' &&
                                                        roleProfile !== 'deputi' &&
                                                        roleProfile !== 'direktorat' &&
                                                        roleProfile !== 'koordinatorvito' &&
                                                        roleProfile !== 'guest'
                                                    ) ?
                                                    <div style={{ border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}  >
                                                        {
                                                            editA1a ? 
                                                            <>
                                                                <input type="number" onChange={e=>handleEdit(e, 'A1a')} value={editA1aValue} className="btn-tbls-1" />
                                                                <div className="btn-tbls-2" onClick={e=>Saving('A1a')}>
                                                                    Save
                                                                </div>
                                                                <i className="fas fa-times-circle circle-styl" onClick={e=>setEditA1a(false)} />
                                                            </>
                                                            :
                                                            <>
                                                                <div className="btn-tbls-1" style={{ cursor: 'default' }}>
                                                                    {user && user.A1a ? user.A1a : 0}
                                                                </div>
                                                                <div className="btn-tbls-2" onClick={e=>setEditA1a(true)}>
                                                                    Edit
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                    :
                                                    user && user.A1a ? user.A1a : 0
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell align="center" style={{ color: '#E23F81' }} >
                                                {user && user.A1Ra ? user.A1Ra : 0}
                                            </StyledTableCell>
                                            <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>
                                                {
                                                    user && user.A1a ?
                                                    getSQuantitative(user && user.A1a ? user.A1a : 0, user && user.A1Ra ? user.A1Ra : 0)
                                                    :
                                                    0
                                                }
                                            </StyledTableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <StyledTableCell align="left">
                                                2
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                Partnership with Business (TA/TO, Airlines, Accommodation, and others)
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                Collaboration Activities with Bussiness/Industry Partners
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {
                                                    (
                                                        roleProfile !== 'markom' &&
                                                        roleProfile !== 'deputi' &&
                                                        roleProfile !== 'direktorat' &&
                                                        roleProfile !== 'koordinatorvito' &&
                                                        roleProfile !== 'guest'
                                                    ) ?
                                                    <div style={{ border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}  >
                                                        {
                                                            editA2a ? 
                                                            <>
                                                                <input type="number" onChange={e=>handleEdit(e, 'A2a')} value={editA2aValue} className="btn-tbls-1" />
                                                                <div className="btn-tbls-2" onClick={e=>Saving('A2a')}>
                                                                    Save
                                                                </div>
                                                                <i className="fas fa-times-circle circle-styl" onClick={e=>setEditA2a(false)} />
                                                            </>
                                                            :
                                                            <>
                                                                <div className="btn-tbls-1" style={{ cursor: 'default' }}>
                                                                    {user && user.A2a ? user.A2a : 0}
                                                                </div>
                                                                <div className="btn-tbls-2" onClick={e=>setEditA2a(true)}>
                                                                    Edit
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                    :
                                                    user && user.A2a ? user.A2a : 0
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell align="center" style={{ color: '#E23F81' }} >
                                                {user && user.A2Ra ? user.A2Ra : 0}
                                            </StyledTableCell>
                                            <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>
                                                {
                                                    user && user.A2a ?
                                                    getSQuantitative(user && user.A2a ? user.A2a : 0, user && user.A2Ra ? user.A2Ra : 0)
                                                    :
                                                    0
                                                }
                                            </StyledTableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <StyledTableCell align="left">
                                                3
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                Partnership with Communities
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                Collaboration Activities with Communities Partners
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {
                                                    (
                                                        roleProfile !== 'markom' &&
                                                        roleProfile !== 'deputi' &&
                                                        roleProfile !== 'direktorat' &&
                                                        roleProfile !== 'koordinatorvito' &&
                                                        roleProfile !== 'guest'
                                                    ) ?
                                                    <div style={{ border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}  >
                                                        {
                                                            editA3a ? 
                                                            <>
                                                                <input type="number" onChange={e=>handleEdit(e, 'A3a')} value={editA3aValue} className="btn-tbls-1" />
                                                                <div className="btn-tbls-2" onClick={e=>Saving('A3a')}>
                                                                    Save
                                                                </div>
                                                                <i className="fas fa-times-circle circle-styl" onClick={e=>setEditA3a(false)} />
                                                            </>
                                                            :
                                                            <>
                                                                <div className="btn-tbls-1" style={{ cursor: 'default' }}>
                                                                    {user && user.A3a ? user.A3a : 0}
                                                                </div>
                                                                <div className="btn-tbls-2" onClick={e=>setEditA3a(true)}>
                                                                    Edit
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                    :
                                                    user && user.A3a ? user.A3a : 0
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell align="center" style={{ color: '#E23F81' }} >
                                                {user && user.A3Ra ? user.A3Ra : 0}
                                            </StyledTableCell>
                                            <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>
                                                {
                                                    user && user.A3a ?
                                                    getSQuantitative(user && user.A3a ? user.A3a : 0, user && user.A3Ra ? user.A3Ra : 0)
                                                    :
                                                    0
                                                }
                                            </StyledTableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <StyledTableCell align="left">
                                                4
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                Partnership with Governments
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                Collaboration Activities with Governments Partners
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {
                                                    (
                                                        roleProfile !== 'markom' &&
                                                        roleProfile !== 'deputi' &&
                                                        roleProfile !== 'direktorat' &&
                                                        roleProfile !== 'koordinatorvito' &&
                                                        roleProfile !== 'guest'
                                                    ) ?
                                                    <div style={{ border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}  >
                                                        {
                                                            editA4a ? 
                                                            <>
                                                                <input type="number" onChange={e=>handleEdit(e, 'A4a')} value={editA4aValue} className="btn-tbls-1" />
                                                                <div className="btn-tbls-2" onClick={e=>Saving('A4a')}>
                                                                    Save
                                                                </div>
                                                                <i className="fas fa-times-circle circle-styl" onClick={e=>setEditA4a(false)} />
                                                            </>
                                                            :
                                                            <>
                                                                <div className="btn-tbls-1" style={{ cursor: 'default' }}>
                                                                    {user && user.A4a ? user.A4a : 0}
                                                                </div>
                                                                <div className="btn-tbls-2" onClick={e=>setEditA4a(true)}>
                                                                    Edit
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                    :
                                                    user && user.A4a ? user.A4a : 0
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell align="center" style={{ color: '#E23F81' }} >
                                                {user && user.A4Ra ? user.A4Ra : 0}
                                            </StyledTableCell>
                                            <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>
                                                {
                                                    user && user.A4a ?
                                                    getSQuantitative(user && user.A4a ? user.A4a : 0, user && user.A4Ra ? user.A4Ra : 0)
                                                    :
                                                    0
                                                }
                                            </StyledTableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <StyledTableCell align="left">
                                                5
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                Partnership with Media
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                Collaboration Activities with Media Partners
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {
                                                    (
                                                        roleProfile !== 'markom' &&
                                                        roleProfile !== 'deputi' &&
                                                        roleProfile !== 'direktorat' &&
                                                        roleProfile !== 'koordinatorvito' &&
                                                        roleProfile !== 'guest'
                                                    ) ?
                                                    <div style={{ border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}  >
                                                        {
                                                            editA5a ? 
                                                            <>
                                                                <input type="number" onChange={e=>handleEdit(e, 'A5a')} value={editA5aValue} className="btn-tbls-1" />
                                                                <div className="btn-tbls-2" onClick={e=>Saving('A5a')}>
                                                                    Save
                                                                </div>
                                                                <i className="fas fa-times-circle circle-styl" onClick={e=>setEditA5a(false)} />
                                                            </>
                                                            :
                                                            <>
                                                                <div className="btn-tbls-1" style={{ cursor: 'default' }}>
                                                                    {user && user.A5a ? user.A5a : 0}
                                                                </div>
                                                                <div className="btn-tbls-2" onClick={e=>setEditA5a(true)}>
                                                                    Edit
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                    :
                                                    user && user.A5a ? user.A5a : 0
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell align="center" style={{ color: '#E23F81' }} >
                                                {user && user.A5Ra ? user.A5Ra : 0}
                                            </StyledTableCell>
                                            <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>
                                                {
                                                    user && user.A5a ?
                                                    getSQuantitative(user && user.A5a ? user.A5a : 0, user && user.A5Ra ? user.A5Ra : 0)
                                                    :
                                                    0
                                                }
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell colSpan={5}>
                                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                                    <Box maxWidth="75%">
                                                    <p>• Target for each VITO region must be based on data baseline/information on potential pentahelix’s partners in the region.</p>
                                                    <p>• All the Score are NO MORE than 100.</p>
                                                    <p>• If the Realisation is bigger than the Target, we will put the score in 100.</p>
                                                    </Box>
                                                    <Typography variant="subtitle2">TOTAL</Typography>
                                                </Box>
                                            </StyledTableCell>
                                                <StyledTableCell align="center">{
                                                    getATQuantitative(
                                                        user && user.A1a ?
                                                        getSQuantitative(user && user.A1a ? user.A1a : 0, user && user.A1Ra ? user.A1Ra : 0)
                                                        :
                                                        0,
                                                        user && user.A2a ?
                                                        getSQuantitative(user && user.A2a ? user.A2a : 0, user && user.A2Ra ? user.A2Ra : 0)
                                                        :
                                                        0,
                                                        user && user.A3a ?
                                                        getSQuantitative(user && user.A3a ? user.A3a : 0, user && user.A3Ra ? user.A3Ra : 0)
                                                        :
                                                        0,
                                                        user && user.A4a ?
                                                        getSQuantitative(user && user.A4a ? user.A4a : 0, user && user.A4Ra ? user.A4Ra : 0)
                                                        :
                                                        0,
                                                        user && user.A5a ?
                                                        getSQuantitative(user && user.A5a ? user.A5a : 0, user && user.A5Ra ? user.A5Ra : 0)
                                                        :
                                                        0
                                                    )
                                                }</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </Box>

                        <Box padding="1em 2em">
                            <Box marginY="1em"><Typography color="primary">B. MARKET INTELLIGENCE</Typography></Box>
                            <TableContainer component={Paper} className={classes.tableContainer}>
                                <Table className={classes.table} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell rowSpan={2}>NO</StyledTableCell>
                                            <StyledTableCell rowSpan={2} align="left">COMPONENT</StyledTableCell>
                                            <StyledTableCell rowSpan={2} align="left">INDICATOR (I)</StyledTableCell>
                                            <StyledTableCell align="center">TARGET (T)</StyledTableCell>
                                            <StyledTableCell align="center">REALISATION (R)</StyledTableCell>
                                            <StyledTableCell rowSpan={2} align="center">SCORE (S)</StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell align="center">2022</StyledTableCell>
                                            <StyledTableCell align="center">Q2 - Q4</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow>
                                            <StyledTableCell align="center">
                                                1
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                Market Update
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                Number of Market Strategic Review
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {
                                                    (
                                                        roleProfile !== 'markom' &&
                                                        roleProfile !== 'deputi' &&
                                                        roleProfile !== 'direktorat' &&
                                                        roleProfile !== 'koordinatorvito' &&
                                                        roleProfile !== 'guest'
                                                    ) ?
                                                    <div style={{ border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}  >
                                                        {
                                                            editB1a ? 
                                                            <>
                                                                <input type="number" onChange={e=>handleEdit(e, 'B1a')} value={editB1aValue} className="btn-tbls-1" />
                                                                <div className="btn-tbls-2" onClick={e=>Saving('B1a')}>
                                                                    Save
                                                                </div>
                                                                <i className="fas fa-times-circle circle-styl" onClick={e=>setEditB1a(false)} />
                                                            </>
                                                            :
                                                            <>
                                                                <div className="btn-tbls-1" style={{ cursor: 'default' }}>
                                                                    {user && user.B1a ? user.B1a : 0}
                                                                </div>
                                                                <div className="btn-tbls-2" onClick={e=>setEditB1a(true)}>
                                                                    Edit
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                    :
                                                    user && user.B1a ? user.B1a : 0
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell align="center" style={{ color: '#E23F81' }} >
                                                {user && user.B1Ra ? user.B1Ra : 0}
                                            </StyledTableCell>
                                            <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>
                                                {
                                                    user && user.B1a ?
                                                    getSQuantitative(user && user.B1a ? user.B1a : 0, user && user.B1Ra ? user.B1Ra : 0)
                                                    :
                                                    0
                                                }
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell align="center">
                                                2
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                Competitor Update
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                Number of Analysis of Competitor Activities
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {
                                                    (
                                                        roleProfile !== 'markom' &&
                                                        roleProfile !== 'deputi' &&
                                                        roleProfile !== 'direktorat' &&
                                                        roleProfile !== 'koordinatorvito' &&
                                                        roleProfile !== 'guest'
                                                    ) ?
                                                    <div style={{ border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}  >
                                                        {
                                                            editB2a ? 
                                                            <>
                                                                <input type="number" onChange={e=>handleEdit(e, 'B2a')} value={editB2aValue} className="btn-tbls-1" />
                                                                <div className="btn-tbls-2" onClick={e=>Saving('B2a')}>
                                                                    Save
                                                                </div>
                                                                <i className="fas fa-times-circle circle-styl" onClick={e=>setEditB2a(false)} />
                                                            </>
                                                            :
                                                            <>
                                                                <div className="btn-tbls-1" style={{ cursor: 'default' }}>
                                                                    {user && user.B2a ? user.B2a : 0}
                                                                </div>
                                                                <div className="btn-tbls-2" onClick={e=>setEditB2a(true)}>
                                                                    Edit
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                    :
                                                    user && user.B2a ? user.B2a : 0
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell align="center" style={{ color: '#E23F81' }} >
                                                {user && user.B2Ra ? user.B2Ra : 0}
                                            </StyledTableCell>
                                            <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>
                                                {
                                                    user && user.B2a ?
                                                    getSQuantitative(user && user.B2a ? user.B2a : 0, user && user.B2Ra ? user.B2Ra : 0)
                                                    :
                                                    0
                                                }
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell colSpan={5}>
                                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                                    <Box maxWidth="75%">
                                                        <p>• Target for each VITO region must be based on monthly data baseline/information about the market situation in the region.</p>
                                                        <p>• All the Score are NO MORE than 100.</p>
                                                        <p>• If the Realisation is bigger than the Target, we will put the score in 100.</p>
                                                    </Box>
                                                    <Typography variant="subtitle2">TOTAL</Typography>
                                                </Box>
                                            </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {
                                                        getBTQuantitative(
                                                            user && user.B1a ?
                                                            getSQuantitative(user && user.B1a ? user.B1a : 0, user && user.B1Ra ? user.B1Ra : 0)
                                                            :
                                                            0,
                                                            user && user.B2a ?
                                                            getSQuantitative(user && user.B2a ? user.B2a : 0, user && user.B2Ra ? user.B2Ra : 0)
                                                            :
                                                            0
                                                        )
                                                    }
                                                </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </Box>

                    </Box>

                    <Box className={classes.tableroot}>
                        <Box className={classes.titleTable}>
                            <Typography variant="h6">II. QUALITATIVE PERFORMANCE SCORING </Typography>
                        </Box>
                        <Box padding="1em 2em" marginTop="1em">
                            <TableContainer component={Paper} className={classes.tableContainer}>
                                <Table className={classes.table} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell rowSpan={2}>NO</StyledTableCell>
                                            <StyledTableCell rowSpan={2} align="left">COMPONENT</StyledTableCell>
                                            <StyledTableCell rowSpan={2} align="left">INDICATOR (I)</StyledTableCell>
                                            <StyledTableCell rowSpan={2} align="left">SCORING REFERENCE</StyledTableCell>
                                            <StyledTableCell colSpan={3} align="center">SCORE (S)</StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell align="left">LETTER</StyledTableCell>
                                            <StyledTableCell align="left">NUMBER</StyledTableCell>
                                            <StyledTableCell align="left">N</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow>
                                            <StyledTableCell align="left" rowSpan={3} >
                                                1
                                            </StyledTableCell>
                                            <StyledTableCell align="left" rowSpan={3} >
                                                Collaboration (40%)
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                Able to cooperate with pentahelix elements (ABCGM)
                                            </StyledTableCell>
                                            <StyledTableCell align="left" rowSpan={9}>
                                                <span>
                                                    Very Good (<b>A+</b>) 
                                                </span>
                                                <br/>
                                                <br/>
                                                <span>
                                                    Good (<b>A</b>) 
                                                </span>
                                                <br/>
                                                <br/>
                                                <span>
                                                    Almost Good (<b>A-</b>) 
                                                </span>
                                                <br/>
                                                <br/>
                                                <span>
                                                    Very Sufficient (<b>B+</b>) 
                                                </span>
                                                <br/>
                                                <br/>
                                                <span>
                                                    Sufficient (<b>B</b>) 
                                                </span>
                                                <br/>
                                                <br/>
                                                <span>
                                                    Almost Sufficient (<b>B-</b>) 
                                                </span>
                                                <br/>
                                                <br/>
                                                <span>
                                                    Very Insufficient (<b>C+</b>) 
                                                </span>
                                                <br/>
                                                <br/>
                                                <span>
                                                    Insufficient (<b>C</b>) 
                                                </span>
                                                <br/>
                                                <br/>
                                                <span>
                                                    Almost Insufficient (<b>C-</b>) 
                                                </span>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {
                                                    (
                                                        roleProfile !== 'markom' &&
                                                        roleProfile !== 'deputi' &&
                                                        roleProfile !== 'direktorat' &&
                                                        roleProfile !== 'koordinatorvito' &&
                                                        roleProfile !== 'guest'
                                                    ) ?
                                                    <div style={{ border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}  >
                                                        {
                                                            editQCollab1 ? 
                                                            <>
                                                                <select className="btn-tbls-1" value={editQCollab1Value} onChange={e=>setEditQCollab1Value(e.target.value)}>
                                                                    <option value="">-</option>
                                                                    <option value="A+">A+</option>
                                                                    <option value="A">A</option>
                                                                    <option value="A-">A-</option>
                                                                    <option value="B+">B+</option>
                                                                    <option value="B">B</option>
                                                                    <option value="B-">B-</option>
                                                                    <option value="C+">C+</option>
                                                                    <option value="C">C</option>
                                                                    <option value="C-">C-</option>
                                                                </select>
                                                                <div className="btn-tbls-2" onClick={e=>Saving('QCollab1')}>
                                                                    Save
                                                                </div>
                                                                <i className="fas fa-times-circle circle-styl" onClick={e=>setEditQCollab1(false)} />
                                                            </>
                                                            :
                                                            <>
                                                                <div className="btn-tbls-1" style={{ cursor: 'default' }}>
                                                                    {user && user.QCollab1 ? user.QCollab1 : '-'}
                                                                </div>
                                                                <div className="btn-tbls-2" onClick={e=>setEditQCollab1(true)}>
                                                                    Edit
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                    :
                                                    user && user.QCollab1 ? user.QCollab1 : '-'
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                { handleScoreLetter(user && user.QCollab1) }
                                            </StyledTableCell>
                                            <StyledTableCell align="center" rowSpan={3} style={{ fontWeight: 'bold' }} >
                                                { getNQualitative(user && user.QCollab1, user && user.QCollab2, user && user.QCollab3, 40) }
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell align="left" >
                                                Able to cooperate with units at the MoTCE
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {
                                                    (
                                                        roleProfile !== 'markom' &&
                                                        roleProfile !== 'deputi' &&
                                                        roleProfile !== 'direktorat' &&
                                                        roleProfile !== 'koordinatorvito' &&
                                                        roleProfile !== 'guest'
                                                    ) ?
                                                    <div style={{ border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}  >
                                                        {
                                                            editQCollab2 ? 
                                                            <>
                                                                <select className="btn-tbls-1" value={editQCollab2Value} onChange={e=>setEditQCollab2Value(e.target.value)}>
                                                                    <option value="">-</option>
                                                                    <option value="A+">A+</option>
                                                                    <option value="A">A</option>
                                                                    <option value="A-">A-</option>
                                                                    <option value="B+">B+</option>
                                                                    <option value="B">B</option>
                                                                    <option value="B-">B-</option>
                                                                    <option value="C+">C+</option>
                                                                    <option value="C">C</option>
                                                                    <option value="C-">C-</option>
                                                                </select>
                                                                <div className="btn-tbls-2" onClick={e=>Saving('QCollab2')}>
                                                                    Save
                                                                </div>
                                                                <i className="fas fa-times-circle circle-styl" onClick={e=>setEditQCollab2(false)} />
                                                            </>
                                                            :
                                                            <>
                                                                <div className="btn-tbls-1" style={{ cursor: 'default' }}>
                                                                    {user && user.QCollab2 ? user.QCollab2 : '-'}
                                                                </div>
                                                                <div className="btn-tbls-2" onClick={e=>setEditQCollab2(true)}>
                                                                    Edit
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                    :
                                                    user && user.QCollab2 ? user.QCollab2 : '-'
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                { handleScoreLetter(user && user.QCollab2) }
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell align="left" >
                                                Able to maintain relation/collaboration with pentahelix elements (ABCGM)
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {
                                                    (
                                                        roleProfile !== 'markom' &&
                                                        roleProfile !== 'deputi' &&
                                                        roleProfile !== 'direktorat' &&
                                                        roleProfile !== 'koordinatorvito' &&
                                                        roleProfile !== 'guest'
                                                    ) ?
                                                    <div style={{ border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}  >
                                                        {
                                                            editQCollab3 ? 
                                                            <>
                                                                <select className="btn-tbls-1" value={editQCollab3Value} onChange={e=>setEditQCollab3Value(e.target.value)}>
                                                                    <option value="">-</option>
                                                                    <option value="A+">A+</option>
                                                                    <option value="A">A</option>
                                                                    <option value="A-">A-</option>
                                                                    <option value="B+">B+</option>
                                                                    <option value="B">B</option>
                                                                    <option value="B-">B-</option>
                                                                    <option value="C+">C+</option>
                                                                    <option value="C">C</option>
                                                                    <option value="C-">C-</option>
                                                                </select>
                                                                <div className="btn-tbls-2" onClick={e=>Saving('QCollab3')}>
                                                                    Save
                                                                </div>
                                                                <i className="fas fa-times-circle circle-styl" onClick={e=>setEditQCollab3(false)} />
                                                            </>
                                                            :
                                                            <>
                                                                <div className="btn-tbls-1" style={{ cursor: 'default' }}>
                                                                    {user && user.QCollab3 ? user.QCollab3 : '-'}
                                                                </div>
                                                                <div className="btn-tbls-2" onClick={e=>setEditQCollab3(true)}>
                                                                    Edit
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                    :
                                                    user && user.QCollab3 ? user.QCollab3 : '-'
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                { handleScoreLetter(user && user.QCollab3) }
                                            </StyledTableCell>
                                        </StyledTableRow>

                                        <StyledTableRow>
                                            <StyledTableCell align="left" rowSpan={3} >
                                                2
                                            </StyledTableCell>
                                            <StyledTableCell align="left" rowSpan={3} >
                                                Competency (30%)
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                Able to provide Marketing Strategic Insight
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {
                                                    (
                                                        roleProfile !== 'markom' &&
                                                        roleProfile !== 'deputi' &&
                                                        roleProfile !== 'direktorat' &&
                                                        roleProfile !== 'koordinatorvito' &&
                                                        roleProfile !== 'guest'
                                                    ) ?
                                                    <div style={{ border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}  >
                                                        {
                                                            editQCompetency1 ? 
                                                            <>
                                                                <select className="btn-tbls-1" value={editQCompetency1Value} onChange={e=>setEditQCompetency1Value(e.target.value)}>
                                                                    <option value="">-</option>
                                                                    <option value="A+">A+</option>
                                                                    <option value="A">A</option>
                                                                    <option value="A-">A-</option>
                                                                    <option value="B+">B+</option>
                                                                    <option value="B">B</option>
                                                                    <option value="B-">B-</option>
                                                                    <option value="C+">C+</option>
                                                                    <option value="C">C</option>
                                                                    <option value="C-">C-</option>
                                                                </select>
                                                                <div className="btn-tbls-2" onClick={e=>Saving('QCompetency1')}>
                                                                    Save
                                                                </div>
                                                                <i className="fas fa-times-circle circle-styl" onClick={e=>setEditQCompetency1(false)} />
                                                            </>
                                                            :
                                                            <>
                                                                <div className="btn-tbls-1" style={{ cursor: 'default' }}>
                                                                    {user && user.QCompetency1 ? user.QCompetency1 : '-'}
                                                                </div>
                                                                <div className="btn-tbls-2" onClick={e=>setEditQCompetency1(true)}>
                                                                    Edit
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                    :
                                                    user && user.QCompetency1 ? user.QCompetency1 : '-'
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                { handleScoreLetter(user && user.QCompetency1) }
                                            </StyledTableCell>
                                            <StyledTableCell align="center" rowSpan={3} style={{ fontWeight: 'bold' }}>
                                                { getNQualitative(user && user.QCompetency1, user && user.QCompetency2, user && user.QCompetency3, 30) }
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell align="left" >
                                                Able to develop and implement marketing and promotion strategies
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {
                                                    (
                                                        roleProfile !== 'markom' &&
                                                        roleProfile !== 'deputi' &&
                                                        roleProfile !== 'direktorat' &&
                                                        roleProfile !== 'koordinatorvito' &&
                                                        roleProfile !== 'guest'
                                                    ) ?
                                                    <div style={{ border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}  >
                                                        {
                                                            editQCompetency2 ? 
                                                            <>
                                                                <select className="btn-tbls-1" value={editQCompetency2Value} onChange={e=>setEditQCompetency2Value(e.target.value)}>
                                                                    <option value="">-</option>
                                                                    <option value="A+">A+</option>
                                                                    <option value="A">A</option>
                                                                    <option value="A-">A-</option>
                                                                    <option value="B+">B+</option>
                                                                    <option value="B">B</option>
                                                                    <option value="B-">B-</option>
                                                                    <option value="C+">C+</option>
                                                                    <option value="C">C</option>
                                                                    <option value="C-">C-</option>
                                                                </select>
                                                                <div className="btn-tbls-2" onClick={e=>Saving('QCompetency2')}>
                                                                    Save
                                                                </div>
                                                                <i className="fas fa-times-circle circle-styl" onClick={e=>setEditQCompetency2(false)} />
                                                            </>
                                                            :
                                                            <>
                                                                <div className="btn-tbls-1" style={{ cursor: 'default' }}>
                                                                    {user && user.QCompetency2 ? user.QCompetency2 : '-'}
                                                                </div>
                                                                <div className="btn-tbls-2" onClick={e=>setEditQCompetency2(true)}>
                                                                    Edit
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                    :
                                                    user && user.QCompetency2 ? user.QCompetency2 : '-'
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                { handleScoreLetter(user && user.QCompetency2) }
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell align="left" >
                                                Able to adapt, innovate, and collaborate in order to respond the dynamic situation
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {
                                                    (
                                                        roleProfile !== 'markom' &&
                                                        roleProfile !== 'deputi' &&
                                                        roleProfile !== 'direktorat' &&
                                                        roleProfile !== 'koordinatorvito' &&
                                                        roleProfile !== 'guest'
                                                    ) ?
                                                    <div style={{ border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}  >
                                                        {
                                                            editQCompetency3 ? 
                                                            <>
                                                                <select className="btn-tbls-1" value={editQCompetency3Value} onChange={e=>setEditQCompetency3Value(e.target.value)}>
                                                                    <option value="">-</option>
                                                                    <option value="A+">A+</option>
                                                                    <option value="A">A</option>
                                                                    <option value="A-">A-</option>
                                                                    <option value="B+">B+</option>
                                                                    <option value="B">B</option>
                                                                    <option value="B-">B-</option>
                                                                    <option value="C+">C+</option>
                                                                    <option value="C">C</option>
                                                                    <option value="C-">C-</option>
                                                                </select>
                                                                <div className="btn-tbls-2" onClick={e=>Saving('QCompetency3')}>
                                                                    Save
                                                                </div>
                                                                <i className="fas fa-times-circle circle-styl" onClick={e=>setEditQCompetency3(false)} />
                                                            </>
                                                            :
                                                            <>
                                                                <div className="btn-tbls-1" style={{ cursor: 'default' }}>
                                                                    {user && user.QCompetency3 ? user.QCompetency3 : '-'}
                                                                </div>
                                                                <div className="btn-tbls-2" onClick={e=>setEditQCompetency3(true)}>
                                                                    Edit
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                    :
                                                    user && user.QCompetency3 ? user.QCompetency3 : '-'
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                { handleScoreLetter(user && user.QCompetency3) }
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        
                                        <StyledTableRow>
                                            <StyledTableCell align="left" rowSpan={3} >
                                                3
                                            </StyledTableCell>
                                            <StyledTableCell align="left" rowSpan={3} >
                                                Character (30%)
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                Able to prioritize responsibilities as a VITO
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {
                                                    (
                                                        roleProfile !== 'markom' &&
                                                        roleProfile !== 'deputi' &&
                                                        roleProfile !== 'direktorat' &&
                                                        roleProfile !== 'koordinatorvito' &&
                                                        roleProfile !== 'guest'
                                                    ) ?
                                                    <div style={{ border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}  >
                                                        {
                                                            editQCharacter1 ? 
                                                            <>
                                                                <select className="btn-tbls-1" value={editQCharacter1Value} onChange={e=>setEditQCharacter1Value(e.target.value)}>
                                                                    <option value="">-</option>
                                                                    <option value="A+">A+</option>
                                                                    <option value="A">A</option>
                                                                    <option value="A-">A-</option>
                                                                    <option value="B+">B+</option>
                                                                    <option value="B">B</option>
                                                                    <option value="B-">B-</option>
                                                                    <option value="C+">C+</option>
                                                                    <option value="C">C</option>
                                                                    <option value="C-">C-</option>
                                                                </select>
                                                                <div className="btn-tbls-2" onClick={e=>Saving('QCharacter1')}>
                                                                    Save
                                                                </div>
                                                                <i className="fas fa-times-circle circle-styl" onClick={e=>setEditQCharacter1(false)} />
                                                            </>
                                                            :
                                                            <>
                                                                <div className="btn-tbls-1" style={{ cursor: 'default' }}>
                                                                    {user && user.QCharacter1 ? user.QCharacter1 : '-'}
                                                                </div>
                                                                <div className="btn-tbls-2" onClick={e=>setEditQCharacter1(true)}>
                                                                    Edit
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                    :
                                                    user && user.QCharacter1 ? user.QCharacter1 : '-'
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                { handleScoreLetter(user && user.QCharacter1) }
                                            </StyledTableCell>
                                            <StyledTableCell align="center" rowSpan={3} style={{ fontWeight: 'bold' }}>
                                                { getNQualitative(user && user.QCharacter1, user && user.QCharacter2, user && user.QCharacter3, 30) }
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell align="left" >
                                                Able to initiatively provide added value in promoting Indonesia Tourism and Creative Economy
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {
                                                    (
                                                        roleProfile !== 'markom' &&
                                                        roleProfile !== 'deputi' &&
                                                        roleProfile !== 'direktorat' &&
                                                        roleProfile !== 'koordinatorvito' &&
                                                        roleProfile !== 'guest'
                                                    ) ?
                                                    <div style={{ border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}  >
                                                        {
                                                            editQCharacter2 ? 
                                                            <>
                                                                <select className="btn-tbls-1" value={editQCharacter2Value} onChange={e=>setEditQCharacter2Value(e.target.value)}>
                                                                    <option value="">-</option>
                                                                    <option value="A+">A+</option>
                                                                    <option value="A">A</option>
                                                                    <option value="A-">A-</option>
                                                                    <option value="B+">B+</option>
                                                                    <option value="B">B</option>
                                                                    <option value="B-">B-</option>
                                                                    <option value="C+">C+</option>
                                                                    <option value="C">C</option>
                                                                    <option value="C-">C-</option>
                                                                </select>
                                                                <div className="btn-tbls-2" onClick={e=>Saving('QCharacter2')}>
                                                                    Save
                                                                </div>
                                                                <i className="fas fa-times-circle circle-styl" onClick={e=>setEditQCharacter2(false)} />
                                                            </>
                                                            :
                                                            <>
                                                                <div className="btn-tbls-1" style={{ cursor: 'default' }}>
                                                                    {user && user.QCharacter2 ? user.QCharacter2 : '-'}
                                                                </div>
                                                                <div className="btn-tbls-2" onClick={e=>setEditQCharacter2(true)}>
                                                                    Edit
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                    :
                                                    user && user.QCharacter2 ? user.QCharacter2 : '-'
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                { handleScoreLetter(user && user.QCharacter2) }
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell align="left" >
                                                Able to follow up situational inquiries from the MoTCE
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {
                                                    (
                                                        roleProfile !== 'markom' &&
                                                        roleProfile !== 'deputi' &&
                                                        roleProfile !== 'direktorat' &&
                                                        roleProfile !== 'koordinatorvito' &&
                                                        roleProfile !== 'guest'
                                                    ) ?
                                                    <div style={{ border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}  >
                                                        {
                                                            editQCharacter3 ? 
                                                            <>
                                                                <select className="btn-tbls-1" value={editQCharacter3Value} onChange={e=>setEditQCharacter3Value(e.target.value)}>
                                                                    <option value="">-</option>
                                                                    <option value="A+">A+</option>
                                                                    <option value="A">A</option>
                                                                    <option value="A-">A-</option>
                                                                    <option value="B+">B+</option>
                                                                    <option value="B">B</option>
                                                                    <option value="B-">B-</option>
                                                                    <option value="C+">C+</option>
                                                                    <option value="C">C</option>
                                                                    <option value="C-">C-</option>
                                                                </select>
                                                                <div className="btn-tbls-2" onClick={e=>Saving('QCharacter3')}>
                                                                    Save
                                                                </div>
                                                                <i className="fas fa-times-circle circle-styl" onClick={e=>setEditQCharacter3(false)} />
                                                            </>
                                                            :
                                                            <>
                                                                <div className="btn-tbls-1" style={{ cursor: 'default' }}>
                                                                    {user && user.QCharacter3 ? user.QCharacter3 : '-'}
                                                                </div>
                                                                <div className="btn-tbls-2" onClick={e=>setEditQCharacter3(true)}>
                                                                    Edit
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                    :
                                                    user && user.QCharacter3 ? user.QCharacter3 : '-'
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                { handleScoreLetter(user && user.QCharacter3) }
                                            </StyledTableCell>
                                        </StyledTableRow>

                                    </TableBody>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell colSpan={6} align="right"><Typography variant="subtitle2">SCORE</Typography></StyledTableCell>
                                            <StyledTableCell align="left">{
                                                getSQualitative(
                                                    getNQualitative(user && user.QCompetency1, user && user.QCompetency2, user && user.QCompetency3, 30),
                                                    getNQualitative(user && user.QCollab1, user && user.QCollab2, user && user.QCollab3, 40),
                                                    getNQualitative(user && user.QCharacter1, user && user.QCharacter2, user && user.QCharacter3, 30)
                                                )
                                            }</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer>
                        </Box>

                    </Box>

                    <Box className={classes.tableroot}>
                        <Box className={classes.titleTable}>
                            <Typography variant="h6">III. FINAL EVALUATION</Typography>
                        </Box>

                        <Box padding="1em 2em" marginTop="0.5em">
                            <TableContainer component={Paper} className={classes.tableContainer}>
                                <Table className={classes.table} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">VITO NAME</StyledTableCell>
                                            <StyledTableCell align="center">QUANTITATIVE SCORE<br />(A+B)/2 x 60%</StyledTableCell>
                                            <StyledTableCell align="center">QUALITATIVE SCORE<br /> 40%</StyledTableCell>
                                            <StyledTableCell>SCORE</StyledTableCell>
                                            <StyledTableCell>LETTER SCORE</StyledTableCell>
                                            <StyledTableCell>REMARKS</StyledTableCell>
                                            <StyledTableCell>RECOMMENDATION</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data3.map((data, index) => {
                                            return (
                                                <StyledTableRow key={index} >
                                                    <StyledTableCell align="left">{ user && user.User && user.User.name ? user.User.name : '-' }</StyledTableCell>
                                                    <StyledTableCell align="center">{
                                                        getQScore(
                                                            getATQuantitative(
                                                                user && user.A1a ?
                                                                getSQuantitative(user && user.A1a ? user.A1a : 0, user && user.A1Ra ? user.A1Ra : 0)
                                                                :
                                                                0,
                                                                user && user.A2a ?
                                                                getSQuantitative(user && user.A2a ? user.A2a : 0, user && user.A2Ra ? user.A2Ra : 0)
                                                                :
                                                                0,
                                                                user && user.A3a ?
                                                                getSQuantitative(user && user.A3a ? user.A3a : 0, user && user.A3Ra ? user.A3Ra : 0)
                                                                :
                                                                0,
                                                                user && user.A4a ?
                                                                getSQuantitative(user && user.A4a ? user.A4a : 0, user && user.A4Ra ? user.A4Ra : 0)
                                                                :
                                                                0,
                                                                user && user.A5a ?
                                                                getSQuantitative(user && user.A5a ? user.A5a : 0, user && user.A5Ra ? user.A5Ra : 0)
                                                                :
                                                                0
                                                            ),
                                                            getBTQuantitative(
                                                                user && user.B1a ?
                                                                getSQuantitative(user && user.B1a ? user.B1a : 0, user && user.B1Ra ? user.B1Ra : 0)
                                                                :
                                                                0,
                                                                user && user.B2a ?
                                                                getSQuantitative(user && user.B2a ? user.B2a : 0, user && user.B2Ra ? user.B2Ra : 0)
                                                                :
                                                                0
                                                            ),
                                                            60
                                                        )
                                                    }</StyledTableCell>
                                                    <StyledTableCell align="center">{
                                                        getFQualitative(
                                                            getNQualitative(user && user.QCompetency1, user && user.QCompetency2, user && user.QCompetency3, 30),
                                                            getNQualitative(user && user.QCollab1, user && user.QCollab2, user && user.QCollab3, 40),
                                                            getNQualitative(user && user.QCharacter1, user && user.QCharacter2, user && user.QCharacter3, 30),
                                                            40
                                                        )
                                                    }
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">{
                                                        getTotalyScore(
                                                            getQScore(
                                                                getATQuantitative(
                                                                    user && user.A1a ?
                                                                    getSQuantitative(user && user.A1a ? user.A1a : 0, user && user.A1Ra ? user.A1Ra : 0)
                                                                    :
                                                                    0,
                                                                    user && user.A2a ?
                                                                    getSQuantitative(user && user.A2a ? user.A2a : 0, user && user.A2Ra ? user.A2Ra : 0)
                                                                    :
                                                                    0,
                                                                    user && user.A3a ?
                                                                    getSQuantitative(user && user.A3a ? user.A3a : 0, user && user.A3Ra ? user.A3Ra : 0)
                                                                    :
                                                                    0,
                                                                    user && user.A4a ?
                                                                    getSQuantitative(user && user.A4a ? user.A4a : 0, user && user.A4Ra ? user.A4Ra : 0)
                                                                    :
                                                                    0,
                                                                    user && user.A5a ?
                                                                    getSQuantitative(user && user.A5a ? user.A5a : 0, user && user.A5Ra ? user.A5Ra : 0)
                                                                    :
                                                                    0
                                                                ),
                                                                getBTQuantitative(
                                                                    user && user.B1a ?
                                                                    getSQuantitative(user && user.B1a ? user.B1a : 0, user && user.B1Ra ? user.B1Ra : 0)
                                                                    :
                                                                    0,
                                                                    user && user.B2a ?
                                                                    getSQuantitative(user && user.B2a ? user.B2a : 0, user && user.B2Ra ? user.B2Ra : 0)
                                                                    :
                                                                    0
                                                                ),
                                                                60
                                                            ),
                                                            getFQualitative(
                                                                getNQualitative(user && user.QCompetency1, user && user.QCompetency2, user && user.QCompetency3, 30),
                                                                getNQualitative(user && user.QCollab1, user && user.QCollab2, user && user.QCollab3, 40),
                                                                getNQualitative(user && user.QCharacter1, user && user.QCharacter2, user && user.QCharacter3, 30),
                                                                40
                                                            )
                                                        )
                                                    }</StyledTableCell>
                                                    <StyledTableCell align="center">{
                                                        getLetterScore(
                                                            getTotalyScore(
                                                                getQScore(
                                                                    getATQuantitative(
                                                                        user && user.A1a ?
                                                                        getSQuantitative(user && user.A1a ? user.A1a : 0, user && user.A1Ra ? user.A1Ra : 0)
                                                                        :
                                                                        0,
                                                                        user && user.A2a ?
                                                                        getSQuantitative(user && user.A2a ? user.A2a : 0, user && user.A2Ra ? user.A2Ra : 0)
                                                                        :
                                                                        0,
                                                                        user && user.A3a ?
                                                                        getSQuantitative(user && user.A3a ? user.A3a : 0, user && user.A3Ra ? user.A3Ra : 0)
                                                                        :
                                                                        0,
                                                                        user && user.A4a ?
                                                                        getSQuantitative(user && user.A4a ? user.A4a : 0, user && user.A4Ra ? user.A4Ra : 0)
                                                                        :
                                                                        0,
                                                                        user && user.A5a ?
                                                                        getSQuantitative(user && user.A5a ? user.A5a : 0, user && user.A5Ra ? user.A5Ra : 0)
                                                                        :
                                                                        0
                                                                    ),
                                                                    getBTQuantitative(
                                                                        user && user.B1a ?
                                                                        getSQuantitative(user && user.B1a ? user.B1a : 0, user && user.B1Ra ? user.B1Ra : 0)
                                                                        :
                                                                        0,
                                                                        user && user.B2a ?
                                                                        getSQuantitative(user && user.B2a ? user.B2a : 0, user && user.B2Ra ? user.B2Ra : 0)
                                                                        :
                                                                        0
                                                                    ),
                                                                    60
                                                                ),
                                                                getFQualitative(
                                                                    getNQualitative(user && user.QCompetency1, user && user.QCompetency2, user && user.QCompetency3, 30),
                                                                    getNQualitative(user && user.QCollab1, user && user.QCollab2, user && user.QCollab3, 40),
                                                                    getNQualitative(user && user.QCharacter1, user && user.QCharacter2, user && user.QCharacter3, 30),
                                                                    40
                                                                )
                                                            )
                                                        )
                                                    }</StyledTableCell>
                                                    <StyledTableCell align="left">{
                                                        getRemarkScore(
                                                            getTotalyScore(
                                                                getQScore(
                                                                    getATQuantitative(
                                                                        user && user.A1a ?
                                                                        getSQuantitative(user && user.A1a ? user.A1a : 0, user && user.A1Ra ? user.A1Ra : 0)
                                                                        :
                                                                        0,
                                                                        user && user.A2a ?
                                                                        getSQuantitative(user && user.A2a ? user.A2a : 0, user && user.A2Ra ? user.A2Ra : 0)
                                                                        :
                                                                        0,
                                                                        user && user.A3a ?
                                                                        getSQuantitative(user && user.A3a ? user.A3a : 0, user && user.A3Ra ? user.A3Ra : 0)
                                                                        :
                                                                        0,
                                                                        user && user.A4a ?
                                                                        getSQuantitative(user && user.A4a ? user.A4a : 0, user && user.A4Ra ? user.A4Ra : 0)
                                                                        :
                                                                        0,
                                                                        user && user.A5a ?
                                                                        getSQuantitative(user && user.A5a ? user.A5a : 0, user && user.A5Ra ? user.A5Ra : 0)
                                                                        :
                                                                        0
                                                                    ),
                                                                    getBTQuantitative(
                                                                        user && user.B1a ?
                                                                        getSQuantitative(user && user.B1a ? user.B1a : 0, user && user.B1Ra ? user.B1Ra : 0)
                                                                        :
                                                                        0,
                                                                        user && user.B2a ?
                                                                        getSQuantitative(user && user.B2a ? user.B2a : 0, user && user.B2Ra ? user.B2Ra : 0)
                                                                        :
                                                                        0
                                                                    ),
                                                                    60
                                                                ),
                                                                getFQualitative(
                                                                    getNQualitative(user && user.QCompetency1, user && user.QCompetency2, user && user.QCompetency3, 30),
                                                                    getNQualitative(user && user.QCollab1, user && user.QCollab2, user && user.QCollab3, 40),
                                                                    getNQualitative(user && user.QCharacter1, user && user.QCharacter2, user && user.QCharacter3, 30),
                                                                    40
                                                                )
                                                            )
                                                        )
                                                    }</StyledTableCell>
                                                    <StyledTableCell align="left">{
                                                        getRecommendScore(
                                                            getTotalyScore(
                                                                getQScore(
                                                                    getATQuantitative(
                                                                        user && user.A1a ?
                                                                        getSQuantitative(user && user.A1a ? user.A1a : 0, user && user.A1Ra ? user.A1Ra : 0)
                                                                        :
                                                                        0,
                                                                        user && user.A2a ?
                                                                        getSQuantitative(user && user.A2a ? user.A2a : 0, user && user.A2Ra ? user.A2Ra : 0)
                                                                        :
                                                                        0,
                                                                        user && user.A3a ?
                                                                        getSQuantitative(user && user.A3a ? user.A3a : 0, user && user.A3Ra ? user.A3Ra : 0)
                                                                        :
                                                                        0,
                                                                        user && user.A4a ?
                                                                        getSQuantitative(user && user.A4a ? user.A4a : 0, user && user.A4Ra ? user.A4Ra : 0)
                                                                        :
                                                                        0,
                                                                        user && user.A5a ?
                                                                        getSQuantitative(user && user.A5a ? user.A5a : 0, user && user.A5Ra ? user.A5Ra : 0)
                                                                        :
                                                                        0
                                                                    ),
                                                                    getBTQuantitative(
                                                                        user && user.B1a ?
                                                                        getSQuantitative(user && user.B1a ? user.B1a : 0, user && user.B1Ra ? user.B1Ra : 0)
                                                                        :
                                                                        0,
                                                                        user && user.B2a ?
                                                                        getSQuantitative(user && user.B2a ? user.B2a : 0, user && user.B2Ra ? user.B2Ra : 0)
                                                                        :
                                                                        0
                                                                    ),
                                                                    60
                                                                ),
                                                                getFQualitative(
                                                                    getNQualitative(user && user.QCompetency1, user && user.QCompetency2, user && user.QCompetency3, 30),
                                                                    getNQualitative(user && user.QCollab1, user && user.QCollab2, user && user.QCollab3, 40),
                                                                    getNQualitative(user && user.QCharacter1, user && user.QCharacter2, user && user.QCharacter3, 30),
                                                                    40
                                                                )
                                                            )
                                                        )
                                                    }</StyledTableCell>
                                                </StyledTableRow>
                                            )
                                        }
                                        )}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>

                    <Box className={classes.tableroot}>
                        <Box padding="1em 2em" marginTop="1em">
                            <Typography color="primary">Letter Score to Number Conversion (QUALITATIVE):</Typography>
                            <Box display="flex" marginTop="1em">
                                <Box width={1 / 4}>
                                    <TableContainer component={Paper} className={classes.tableContainer}>
                                        <Table aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell align="center">NO</StyledTableCell>
                                                    <StyledTableCell align="center">LETTER SCORE</StyledTableCell>
                                                    <StyledTableCell align="center">NUMBER</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <StyledTableRow>
                                                    <StyledTableCell align="center">1</StyledTableCell>
                                                    <StyledTableCell align="center">A+</StyledTableCell>
                                                    <StyledTableCell align="center">100</StyledTableCell>
                                                </StyledTableRow>
                                                <StyledTableRow>
                                                    <StyledTableCell align="center">2</StyledTableCell>
                                                    <StyledTableCell align="center">A</StyledTableCell>
                                                    <StyledTableCell align="center">90</StyledTableCell>
                                                </StyledTableRow>
                                                <StyledTableRow>
                                                    <StyledTableCell align="center">3</StyledTableCell>
                                                    <StyledTableCell align="center">A-</StyledTableCell>
                                                    <StyledTableCell align="center">80</StyledTableCell>
                                                </StyledTableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                                <Box width={1 / 4} marginX="2em">
                                    <TableContainer component={Paper} className={classes.tableContainer}>
                                        <Table aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell align="center">NO</StyledTableCell>
                                                    <StyledTableCell align="center">LETTER SCORE</StyledTableCell>
                                                    <StyledTableCell align="center">NUMBER</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <StyledTableRow>
                                                    <StyledTableCell align="center">1</StyledTableCell>
                                                    <StyledTableCell align="center">B+</StyledTableCell>
                                                    <StyledTableCell align="center">70</StyledTableCell>
                                                </StyledTableRow>
                                                <StyledTableRow>
                                                    <StyledTableCell align="center">2</StyledTableCell>
                                                    <StyledTableCell align="center">B</StyledTableCell>
                                                    <StyledTableCell align="center">65</StyledTableCell>
                                                </StyledTableRow>
                                                <StyledTableRow>
                                                    <StyledTableCell align="center">3</StyledTableCell>
                                                    <StyledTableCell align="center">B-</StyledTableCell>
                                                    <StyledTableCell align="center">60</StyledTableCell>
                                                </StyledTableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                                <Box width={1 / 4}>
                                    <TableContainer component={Paper} className={classes.tableContainer}>
                                        <Table aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell align="center">NO</StyledTableCell>
                                                    <StyledTableCell align="center">LETTER SCORE</StyledTableCell>
                                                    <StyledTableCell align="center">NUMBER</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <StyledTableRow>
                                                    <StyledTableCell align="center">1</StyledTableCell>
                                                    <StyledTableCell align="center">C+</StyledTableCell>
                                                    <StyledTableCell align="center">55</StyledTableCell>
                                                </StyledTableRow>
                                                <StyledTableRow>
                                                    <StyledTableCell align="center">2</StyledTableCell>
                                                    <StyledTableCell align="center">C</StyledTableCell>
                                                    <StyledTableCell align="center">50</StyledTableCell>
                                                </StyledTableRow>
                                                <StyledTableRow>
                                                    <StyledTableCell align="center">3</StyledTableCell>
                                                    <StyledTableCell align="center">C-</StyledTableCell>
                                                    <StyledTableCell align="center">45</StyledTableCell>
                                                </StyledTableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Box>
                        </Box>

                        <Box borderTop="0.5px solid #DDDDDD" padding="1em 2em" marginTop="1em">
                            <Typography color="primary">Passing Grade (FINAL EVALUATION)</Typography>
                            <Box display="flex" marginTop="1em">
                                <Box>
                                    <TableContainer component={Paper} className={classes.tableContainer}>
                                        <Table aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell align="center">NO</StyledTableCell>
                                                    <StyledTableCell align="center">SCORE RANGE</StyledTableCell>
                                                    <StyledTableCell align="center">LETTER SCORE</StyledTableCell>
                                                    <StyledTableCell align="center">REMARKS</StyledTableCell>
                                                    <StyledTableCell align="center">RECOMMENDATION</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {dataPassingGrade.map((data, index) => {
                                                    return (
                                                        <StyledTableRow key={index} >
                                                            <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                                            <StyledTableCell align="center">{data.rentangNIlai}</StyledTableCell>
                                                            <StyledTableCell align="center">{data.nilaiHuruf}</StyledTableCell>
                                                            <StyledTableCell align="left">{data.keterangan}</StyledTableCell>
                                                            <StyledTableCell align="left"><Typography>{data.rekomendasi}</Typography></StyledTableCell>
                                                        </StyledTableRow>
                                                    )
                                                }
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Box>
                        </Box>

                    </Box>

                </Box>  

            </div>
        </>

    )
}