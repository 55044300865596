import React, {useState} from 'react'
import { Box } from '@material-ui/core'
import Gallery from '../../Gallery/BoxImg2'

export default function Index(props) {
    const { photos, photosAll } = props
    const [display, setDisplay] = useState("none")
    function hoverBox() {
        setDisplay("block")
    }

    function noHoverBox() {
        setDisplay("none")
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            }}
        >
            <Box borderRadius="4px" color="primary" width="124px" height="106px" marginRight="2em" onMouseEnter={hoverBox} display="flex" justifyContent="center" position="relative" onMouseLeave={noHoverBox} className="img">
                {
                    photos &&
                    photos.includes('.pdf') ?
                    <i className="fas fa-file-pdf image" style={{ width:"100%", height: "100%", fontSize: "80px", color: '#E23F81' }}/>
                    :
                    photos &&
                    photos.includes('.doc') ?
                    <i className="fas fa-file-word image" style={{ width:"100%", height: "100%", fontSize: "80px", color: '#E23F81' }}/>
                    :
                    <img zIndex="modal" src={props.photos} className="image" style={{ borderRadius: 5, objectFit: 'cover' }}/>
                }
                <Box display={display} position="absolute" width="100%" height="100%" >
                    <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%" style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
                        <Gallery text="Lihat" text2="Unduh" photos={photos} photosAll={photosAll}/>
                    </Box>
                </Box>
            </Box>
            { photos && ((photos.includes('.pdf') || photos.includes('.doc'))) && photos.split('-IMAGE-')[1] && (photos.split('-IMAGE-')[1]).substring(0,13)+'...' }
        </div>
    )
}
