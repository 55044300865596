import React, { useState, useEffect, useRef } from 'react'
import { Box, makeStyles, Typography, Button, CardActions, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem } from '@material-ui/core'
import WorkplanReport from '../Card/WorkplanReport'
import Workplan from '../Card/Workplan'
import ReportWhitoutWorkplan from '../Card/ReportWithoutWorkplan'
import Axios from 'axios'
import { SERVER } from '../../../../../server'
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import socketClient from "socket.io-client";
import CircularProgress from '@material-ui/core/CircularProgress';
import RefreshIcon from '@material-ui/icons/Refresh';
import Swal from 'sweetalert2'

const useStyle = makeStyles({
    root: {
        width: '100%',
        background: "#F2F3F9",
        borderRadius: "5px",
    },
    title: {
        fontWeight: "500",
        lineHeight: "16px",
        color: "#2C304D"
    },
    cards: {
        minHeight: "calc( 72vh - 60px)",
        maxHeight: "calc( 72vh - 60px)",
        overflowX: "hidden",
        overflowY: "scroll",
        padding: "1em",
        paddingTop: '0em'
    }
})

export default function PIN(props) {
    const classes = useStyle()
    const [openFilter, setOpenFilter] = useState(false);
    const [filter, setFilter] = useState('');
    const [loading, setLoading] = useState(false);
    const anchorRef = useRef(null);
    const [report, setReport] = useState([])
    const socket = socketClient(SERVER, {transports: ['websocket'], secure: true});

    const {
        CategoryId,
        vito,
        Week,
        weeklyList,
        dataCategory
    } = props

    useEffect(()=>{
        if (CategoryId && Week && vito) {
            socket.on('report-weekly', (data) => {
                if ((CategoryId == data.categoryId) && (Week.id == data.weekId) && (vito.id == data.vitoId)) {
                    dataRead(CategoryId, Week.id, vito.id)
                }
            })
        }
    },[CategoryId, Week, vito])

    const handleToggle = () => {
        setOpenFilter((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpenFilter(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpenFilter(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(openFilter);
    useEffect(() => {
        if (prevOpen.current === true && openFilter === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = openFilter;
    }, [openFilter]);

    const dataRead = (CategoryId, weekId, VitoId) =>{
        if (CategoryId && weekId && VitoId) {
            Swal.fire({
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: () => {
                    Swal.showLoading()
                }
            })
            Axios({
                method: 'GET',
                url: `${SERVER}/report/admin/${VitoId}/${CategoryId}/${weekId}`,
                headers: {
                    token : localStorage.getItem('token'),
                }
            })
            .then(({data})=>{
                setLoading(true)
                // console.log(data, CategoryId);
                setReport(data)
                dataCategory(data)
                Swal.close()
            })
            .catch(err=>{
                Swal.close()
            })
        }
    }

    useEffect(()=>{
        if (CategoryId && Week && vito) {
            dataRead(CategoryId, Week.id, vito.id)
        }
    },[CategoryId, Week, vito])

    const refresh = () => {
        setLoading(false)
        dataRead(CategoryId, Week.id, vito.id)
    }

    return (
        <Box className={classes.root}>
            <Box style={{ minHeight: 30, maxHeight: 30,  padding: '1em', boxSizing: 'content-box' }} >
                <span style={{ fontWeight: 'bold', fontSize: '1.2vw' }} >
                    {props.title}
                </span>
            </Box>
            <Box className={classes.cards}>
                {
                    loading ?
                    report &&
                    report.length === 0 ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '50vh' }}>
                        <AssignmentLateIcon style={{ fontSize: '12vw', color: '#e35981' }} />
                        <span style={{ fontSize: 15 }} >
                            Tidak Ada Report
                        </span>
                    </div>
                    :
                    report &&
                    report
                    .filter(a=> filter ? a.status == filter : a)
                    .map((data,i)=>(
                        data.status === "workplan" ?
                        <Workplan key={i} data={data} weeklyList={weeklyList} /> :
                        data.status === "report" ?
                        <WorkplanReport key={i} data={data} weeklyList={weeklyList} dataRead={dataRead} CategoryId={CategoryId} vito={vito} Week={Week} /> :
                        data.status === "onlyreport" &&
                        <ReportWhitoutWorkplan key={i} data={data} weeklyList={weeklyList} dataRead={dataRead} CategoryId={CategoryId} vito={vito} Week={Week}/>
                    ))
                    :
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '50vh' }}>
                        <CircularProgress color="secondary"/>
                        <span style={{ fontSize: 15 }} >
                            
                        </span>
                    </div>
                    
                }
            </Box>
            {/* <Box padding="1em" paddingBottom="0">
                <Button
                    variant="outlined" color="secondary" fullWidth style={{ textTransform: "none" }}
                    ref={anchorRef}
                    aria-controls={openFilter ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                >
                    Filter
                </Button>
                <Popper open={openFilter} anchorEl={anchorRef.current} role={undefined} transition disablePortal  style={{ zIndex: 99999999 }} >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={openFilter} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        <MenuItem onClick={e=>[setFilter(''), handleClose(e)]}>Tampilkan Semua</MenuItem>
                                        <MenuItem onClick={e=>[setFilter('weekly'), handleClose(e)]}>Work Plan</MenuItem>
                                        <MenuItem onClick={e=>[setFilter('report'), handleClose(e)]}>Laporan</MenuItem>
                                        <MenuItem onClick={e=>[setFilter('onlyreport'), handleClose(e)]}>Laporan Tanpa Work Plan</MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Box> */}
            <Button variant="contained" color="default" onClick={refresh} fullWidth style={{ backgroundColor: '#E23F81', color: "#ffffff", marginTop: 5 }} >
                <RefreshIcon />
            </Button>
        </Box>
    )
}
